import BaseScene from './BaseScene'
import RootsParticles from '_webgl/components/RootsParticles'
import { CameraHelper, Color, DirectionalLight, DirectionalLightHelper, Euler, LinearFilter, SRGBColorSpace, Vector3, WebGLRenderTarget } from 'three'
import store from '_store'
import { types } from '@theatre/core'
import BackgroundSphere from '_webgl/components/BackgroundSphere'
import AmbientParticles from '_webgl/components/AmbientParticles'
import { E } from '_utils/index'
import RevealRootsParticleSpline from '_webgl/components/RevealRootsParticleSpline'

export default class ChapterFourTwoScene extends BaseScene {
	constructor() {
		super('chapter-four-two', {
			fluidEnabled: true,
			audioFiles: ['chapter-4-1', 'chapter-4-2', 'chapter-4-3']
		})

		Object.assign(this.components, {
			background: new BackgroundSphere({
				scene: this,
				position: new Vector3(0, 8, -140),
				rotation: new Euler(0, 0, 0),
				scale: new Vector3(220, 220, 220),
				materialOpts: {
					uniforms: {
						uBaseColor1: { value: new Color(0xd1aa75) },
						uBaseColor2: { value: new Color(0xb37d49) },
						uBaseColor3: { value: new Color(0x030302) },

						uStep1: { value: 0.16, gui: { min: 0, max: 1, step: 0.001 } },
						uStep2: { value: 0.58, gui: { min: 0, max: 1, step: 0.001 } },
						uStep3: { value: 0.98, gui: { min: 0, max: 1, step: 0.001 } },

						uBrushScale: { value: 4.4, gui: { min: 0, max: 10, step: 0.001 } },
						uBrushMinEdge: { value: 0.5, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushMaxEdge: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushBlend: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushOffset: { value: new Vector3(0.0, 0.0, 0.0), gui: { min: 0, max: 1, step: 0.001 } }
					}
				}
			}),
			roots: new RootsParticles({ data: this.objectData.paths, scene: this }),
			revealRoot: new RevealRootsParticleSpline({ data: this.objectData.paths, scene: this }),
			ambientParticles: new AmbientParticles({
				bounds: new Vector3(50, 50, 50)
			})
		})

		Object.assign(this.cameraLayers, {
			particles: 1,
			renderBackground: 2
		})

		Object.assign(this.globalUniforms, {
			shadow: {
				uShadowColor: { value: new Color(0x1d1812) },
				uShadowIntensity: { value: 0.95 }
			},
			sun: {
				uLightDirection: { value: new Vector3() },
				uLightPosition: { value: new Vector3() }
			},
			fog: {
				uEnableFog: { value: true },
				uDebugFog: { value: false },
				uFogNear_D: { value: 172 },
				uFogFar_D: { value: 250 },
				uFogStrength_D: { value: 1. },
				uFogNear_H: { value: -85 },
				uFogFar_H: { value: -161 },
				uFogStrength_H: { value: 0.0 },
				uSceneFogMix: { value: 1 },
				tBackground: { value: null }
			}
		})

		const shadowTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Shadow', {
			color: store.theatre.helper.parseColor(this.globalUniforms.shadow.uShadowColor.value, 'shadow color'),
			intensity: types.number(this.globalUniforms.shadow.uShadowIntensity.value, { label: 'shadow intensity', range: [0, 1], nudgeMultiplier: 0.01 })
		})

		shadowTheatreObject.onValuesChange(values => {
			this.globalUniforms.shadow.uShadowColor.value.copy(values.color)
			this.globalUniforms.shadow.uShadowIntensity.value = values.intensity
		})

		const fogConfig = {}

		const fogUniformCallbacks = store.theatre.helper.autoAddUniforms(fogConfig, this.globalUniforms.fog, [], [], "")
		const fogTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Fog', fogConfig)
		fogTheatreObject.onValuesChange(values => {
			fogUniformCallbacks.forEach(callback => callback(null, values))
		})

		this._debugMeshes = {}
	}

	build() {
		this.buildLights()

		this.buildBackgroundRenderTarget()

		super.build()
	}

	buildBackgroundRenderTarget() {
		this.backgroundRenderTarget = new WebGLRenderTarget(store.window.w, store.window.h, { colorSpace: SRGBColorSpace })
		this.backgroundRenderTarget.texture.minFilter = LinearFilter
		this.backgroundRenderTarget.texture.magFilter = LinearFilter
		this.backgroundRenderTarget.texture.generateMipmaps = false
		this.backgroundRenderTarget.stencilBuffer = false
		this.backgroundRenderTarget.depthBuffer = false

		this.globalUniforms.fog.tBackground.value = this.backgroundRenderTarget.texture
	}

	addFluidSimToTheatre() {
		const sheetObject = super.addFluidSimToTheatre()

		sheetObject.initialValue = {
			fluid: {
				force: 60
			},
			simulation: {
				mouseRadius: 0.1,
				pressure: 0.975,
				curlStrength: 0.2,
				dissipation: 0.023,
				viscosity: 0.006
			}
		}
	}

	addPostFXToTheatre() {
		const sheetObject = super.addPostFXToTheatre()

		sheetObject.initialValue = {
			motionBlur: {
				enabled: true,
				blurAmount: 0.02
			},
			bloom: {
				enabled: false
			},
			vignette: {
				strength: 0.575,
				color: store.theatre.helper.parseColor(new Color(0x110b07)).default
			},
			noise: {
				strength: 0.204
			},
			chromaticAberration: {
				enabled: true
			}
		}
	}

	buildLights() {
		this.sun = new DirectionalLight(0xFFFFFF, 1)

		this.sun.shadow.autoUpdate = true
		this.sun.position.set(-28.7, 129.3, -62.3)
		this.sun.target.position.set(-4.9, 65.1, -114.3)
		this.sun.castShadow = true
		this.sun.shadow.mapSize.width = store.mq.touch.matches ? 1024 : (store.isLowTierGPU ? 512 : 2048)
		this.sun.shadow.mapSize.height = store.mq.touch.matches ? 1024 : (store.isLowTierGPU ? 512 : 2048)

		this.sun.shadow.camera.near = 49.8
		this.sun.shadow.camera.far = 143.6
		this.sun.shadow.camera.left = -49.1
		this.sun.shadow.camera.right = 2.2
		this.sun.shadow.camera.top = 51.6
		this.sun.shadow.camera.bottom = -22.4
		this.sun.shadow.blurSamples = 6

		this.sun.shadow.radius = 3
		this.sun.shadow.bias = -0.00039
		this.sun.shadow.normalBias = 0.00073

		this.add(this.sun)
		this.add(this.sun.target)

		this.sunHelper = new DirectionalLightHelper(this.sun)
		this.sunHelper.visible = false
		this.add(this.sunHelper)

		this.sunShadowHelper = new CameraHelper(this.sun.shadow.camera)
		this.sunShadowHelper.visible = false
		this.add(this.sunShadowHelper)

		const sunStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Sun', {
			visible: types.boolean(this.sunHelper.visible, { label: 'sun helper' }),
			shadowHelper: types.boolean(this.sunShadowHelper.visible, { label: 'shadow helper' }),
			shadowCamera: types.compound({
				near: types.number(this.sun.shadow.camera.near, { nudgeMultiplier: 0.1 }),
				far: types.number(this.sun.shadow.camera.far, { nudgeMultiplier: 0.1 }),
				left: types.number(this.sun.shadow.camera.left, { nudgeMultiplier: 0.1 }),
				right: types.number(this.sun.shadow.camera.right, { nudgeMultiplier: 0.1 }),
				top: types.number(this.sun.shadow.camera.top, { nudgeMultiplier: 0.1 }),
				bottom: types.number(this.sun.shadow.camera.bottom, { nudgeMultiplier: 0.1 }),
				bias: types.number(this.sun.shadow.bias, { nudgeMultiplier: 0.0001 }),
				normalBias: types.number(this.sun.shadow.normalBias, { nudgeMultiplier: 0.0001 }),
				blurSamples: types.number(this.sun.shadow.blurSamples, { nudgeMultiplier: 1 })
			}),
			shadowRadius: types.number(this.sun.shadow.radius, { nudgeMultiplier: 0.1 })
		})

		sunStudioObject.onValuesChange(values => {
			this.sunHelper.visible = values.visible
			this.sunShadowHelper.visible = values.shadowHelper
			this.sun.shadow.camera.near = values.shadowCamera.near
			this.sun.shadow.camera.far = values.shadowCamera.far
			this.sun.shadow.camera.left = values.shadowCamera.left
			this.sun.shadow.camera.right = values.shadowCamera.right
			this.sun.shadow.camera.top = values.shadowCamera.top
			this.sun.shadow.camera.bottom = values.shadowCamera.bottom
			this.sun.shadow.bias = values.shadowCamera.bias
			this.sun.shadow.normalBias = values.shadowCamera.normalBias
			this.sun.shadow.blurSamples = values.shadowCamera.blurSamples
			this.sun.shadow.radius = values.shadowRadius

			this.sun.shadow.camera.updateProjectionMatrix()
			this.sun.shadow.needsUpdate = true
			store.WebGL.renderer.shadowMap.needsUpdate = true
			this.sunHelper.update()
			this.sunShadowHelper.update()
		})

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			enableShadows: types.boolean(this.sun.castShadow, { label: 'enable shadows' }),
			shadowMapEnabled: types.boolean(store.WebGL.renderer.shadowMap.enabled, { label: 'shadow map enabled' }),
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.position.z, { nudgeMultiplier: 0.1 })
			})

		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.position.set(values.target.x, values.target.y, values.target.z)
			this.sun.castShadow = values.enableShadows

			this.sun.shadow.camera.updateProjectionMatrix()
			this.sun.shadow.needsUpdate = true
			store.WebGL.renderer.shadowMap.needsUpdate = true
			this.sunHelper.update()
			this.sunShadowHelper.update()

			store.WebGL.renderer.shadowMap.enabled = values.shadowMapEnabled
		})
	}

	start() {
		super.start()
		store.WebGL.renderer.shadowMap.enabled = this.shadowTheatreOverrides.enabled ?? true

		E.on('FPSChecked', this.onFPSChecked)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			// 2nd step: turn off shadows if motion blur is already off
			// if (!store.WebGL.motionBlurPass.enabled) {
			// 	this.sun.castShadow = false
			// 	store.WebGL.renderer.shadowMap.enabled = false

			// 	this.shadowTheatreOverrides.enabled = false
			// }

			// 1st step: turn off motion blur (in Webgl.js)
			if (store.WebGL.motionBlurPass.enabled) {
				store.WebGL.motionBlurPass.enabled = false

				this.postFxTheatreOverrides.motionBlur.enabled = false
			}
		}
	}

	stop() {
		super.stop()
		store.WebGL.renderer.shadowMap.enabled = false

		E.off('FPSChecked', this.onFPSChecked)
	}

	animate() {
		super.animate()

		this.renderBackgroundTexture()

		this.upateLightUniforms()

		if (this._debugMeshes?.shadow && this.sun.shadow.map) this._debugMeshes.shadow.material.uniforms.tTexture.value = this.sun.shadow.map.texture
	}

	renderBackgroundTexture() {
		// hide all objects except those on the background layer
		if (this.cameraLayers.renderBackground) {
			this.activeCamera.layers.disableAll()
			this.activeCamera.layers.enable(this.cameraLayers.renderBackground)
		}

		this.background = null

		// update texture
		store.WebGL.renderer.setRenderTarget(this.backgroundRenderTarget)
		store.WebGL.renderer.clear()
		store.WebGL.renderer.render(this, this.activeCamera)
		store.WebGL.renderer.setRenderTarget(null)

		this.background = this.backgroundRenderTarget.texture

		// enable the main layer again
		if (this.cameraLayers.renderBackground) {
			this.activeCamera.layers.disable(this.cameraLayers.renderBackground)
			this.activeCamera.layers.enable(0)
			this.activeCamera.layers.enable(this.cameraLayers.particles)
		}
	}

	upateLightUniforms() {
		this.globalUniforms.sun.uLightPosition.value.copy(this.sun.position)

		this.globalUniforms.sun.uLightDirection.value.copy(this.sun.position)
		this.globalUniforms.sun.uLightDirection.value.sub(this.sun.target.position)
		this.globalUniforms.sun.uLightDirection.value.transformDirection(this.activeCamera.matrixWorldInverse)
	}

	onSceneResize() {
		this.backgroundRenderTarget?.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
	}
}
