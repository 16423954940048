import { ComponentManager } from "_utils"

export default class ComponentManagerProxy {
	_store = {}

	add(...components) {
		for (let i = 0; i < components.length; i++) {
			if (components[i].negationSelector !== undefined) {
				this._store[components[i].Component.name] = components[i].init()
				continue
			}

			if (components[i].selector) {
				this._store[components[i].name] = new ComponentManager(components[i]).init()
				continue
			}

			this._store[components[i].constructor.name || components[i].name] = components[i]
		}
	}

	get(classToFind) {
		if (typeof classToFind === 'string') {
			return this._store[classToFind] ?? null
		}

		return this._store[classToFind.name] ?? null
	}

	destroy() {
		for (const component in this._store) {
			this._store[component].destroy()
		}

		this._store = {}
	}

	/**
	 * Ensure that ComponentManager can be iterated/spread.
	 * @returns {IterableIterator<Object>}
	 */
	[Symbol.iterator]() {
		return Object.values(this._store).values()
	}
}
