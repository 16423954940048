import store from "_store"
import { ComponentManagerProxy } from '_utils'

export default function populateStore() {
	// set store.mq
	Array.from(document.styleSheets)
		.filter(sheet => sheet.href === null || sheet.href.startsWith(window.location.origin) || sheet.href.match('http://127.0.0.1') || sheet.href.match('http://localhost') || sheet.href.match('http://192.168'))
		.reduce(
			(previous, current) =>
				(previous = [
					...previous,
					...Array.from(current.cssRules).reduce(
						(def, rule) =>
							(def =
								rule.selectorText === ":root"
									? [...def, ...Array.from(rule.style).filter(name => name.startsWith("--bp-"))]
									: def),
						[]
					)
				]),
			[]
		)
		.forEach(el => {
			store.mq[el.replace('--bp-', '')] = window.matchMedia(getComputedStyle(document.documentElement).getPropertyValue(el))
		})

	// populate localised WP JS data
	store.publicUrl = window.globalData.publicUrl
	store.assetsUrl = window.globalData.assetsUrl

	store.staticComponents = new ComponentManagerProxy()
	store.components = new ComponentManagerProxy()
}
