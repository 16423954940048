#include <common>
#include <packing>	

varying vec2 vUv;

struct Camera {
    vec3 position;
    mat4 projectionMatrixInverse;
    mat4 viewMatrixInverse;
};

struct Light {
    vec3 position;
    mat4 projectionMatrix;
    mat4 viewMatrix;
};

uniform sampler2D uLightDepthMap;

uniform vec2 uResolution;
uniform sampler2D tNormalDepth;
uniform Light uLight;
uniform Camera uCamera;
uniform float uBias;
uniform float uMaxSamples;

uniform float uObjectMaskStrength;
uniform float uDecayStart;
uniform float uDecayEnd;

float calcOcclusion(Light light, sampler2D depthMap, vec3 position, float bias) {
    vec4 lp = light.projectionMatrix * light.viewMatrix * vec4(position, 1.0);
    vec3 shadowCoord = lp.xyz / lp.w * 0.5 + 0.5;
    float depthPosFromLight = shadowCoord.z;
    float depthFromLight = unpackRGBAToDepth(texture2D(depthMap, shadowCoord.xy));
    // float shadow = step(depthPosFromLight - bias, depthFromLight);
    float shadow = smoothstep(depthPosFromLight - bias, depthPosFromLight, depthFromLight);
    return shadow;
}
    
vec3 hash(vec3 v) {
    uvec3 x = floatBitsToUint(v + vec3(0.1, 0.2, 0.3));
    x = (x >> 8 ^ x.yzx) * 0x456789ABu;
    x = (x >> 8 ^ x.yzx) * 0x6789AB45u;
    x = (x >> 8 ^ x.yzx) * 0x89AB4567u;
    return vec3(x) / vec3(-1u);
}

void main() {
    vec3 col = vec3(0.);

    // ---
    vec4 normalDepth = texture(tNormalDepth, vUv);
    float depth = normalDepth.w;

    // ---
    vec2 suv = vUv * 2.0 - 1.0; // Convert to normalized device coordinate system [-1, 1]
    vec3 ro = vec3(suv, -1.0); // The starting point of the normalized device coordinate system is -1
    vec3 rd = normalize(vec3(0, 0, 1)); // +1 Send a ray in the positive direction.
    
    float i = 0.0;
    float samplingCount = uMaxSamples; // max sample count
    float accum = 1.0;
    vec3 h = hash(ro) * 2.0 - 1.0;

    for (i; i < samplingCount; i++) {
        // Sampling point of the normalized device coordinate system. Add noise in the +z direction to blur it so that it looks good even with a small number of steps.
        vec3 ndcSamplingPosition = ro + rd * (2.0 * i / samplingCount) + vec3(0., 0, h.x) * 0.01;
        
        if (depth < ndcSamplingPosition.z * 0.5 + 0.5) break; // Terminate if it becomes more than the depth of the object.

        // Convert to world coordinate system
        vec4 target = uCamera.viewMatrixInverse * uCamera.projectionMatrixInverse * vec4(ndcSamplingPosition, 1.0);
        vec3 worldSamplingPosition = target.xyz / target.w;

        float decay = smoothstep(uDecayStart, uDecayEnd, i / samplingCount);
        accum -= calcOcclusion(uLight, uLightDepthMap, worldSamplingPosition, uBias) * decay;
    }

    float lightShaft = accum / samplingCount;
    // lightShaft = 1.0 - (1.0 - lightShaft) * 2.0; // Invert the value and increase the contrast. ([0, 1] -> [1, 0] -> [1, -1])
    // lightShaft = 1. - (1. - lightShaft) * 0.7;

    // If the ray is colliding with an object (on the surface of the object), lower the color for additive blending.
    if (i / samplingCount < 1.0) {
        lightShaft *= uObjectMaskStrength;
    }

    col -= lightShaft;

    gl_FragColor = vec4(vec3(col), 1.0);

    #include <colorspace_fragment>
}