import store from '_store'
import studio from '@theatre/studio'
import * as d from '@theatre/dataverse'
import { getProject, createRafDriver } from '@theatre/core'
const projectStates = import.meta.glob('/resources/assets/theatre/**/state.json', { eager: true, import: 'default' })

export default class TheatreManager {
	initStudio() {
		if (store.env === 'production' || import.meta.env.MODE === 'browsersync-no-theatre') return

		store.theatre.studio = studio
		store.theatre.studioProject = store.theatre.studio.getStudioProject()
		store.theatre.persistenceKey = import.meta.env.VITE_APP_NAME + '-theatre'
		store.theatre.studio.initialize({
			persistenceKey: store.theatre.persistenceKey
		})

		if (!store.urlParams.has('gui')) {
			store.theatre.studio.ui.hide()
		}

		if (store.urlParams.has('gui')) {
			// switch scenes when selecting a scene sheet in the studio
			store.theatre.studio.onSelectionChange(selection => {
				if (store.WebGL?.isBuilt && selection.length && (
					(selection[0]._webglScene && store.WebGL?.activeScene !== selection[0]._webglScene) ||
					(selection[0].sheet && selection[0].sheet._webglScene && store.WebGL?.activeScene !== selection[0].sheet._webglScene)
				)) {
					if (store.WebGL.activeScene.theatreIsPlaying) {
						store.WebGL.activeScene.runNextTheatreSequence = false
						store.WebGL.activeScene.pauseTheatreSequence()
					}

					store.WebGL.activeScene.stop()

					if (selection[0].sheet?._webglScene) {
						selection[0].sheet._webglScene.start()
					} else {
						selection[0]._webglScene.start()
					}
				}
			})
		}

		// override the play/pause keyboard shortcut to allow for autoplay functionality whilst the gui is visible
		if (store.urlParams.has('gui') && store.urlParams.has('autoplay')) {
			store.theatre.studio.__experimental.__experimental_disblePlayPauseKeyboardShortcut()

			window.addEventListener('keydown', e => {
				if (e.code === 'Space') {
					if (store.WebGL.activeScene.theatreIsPlaying) {
						store.WebGL.activeScene.runNextTheatreSequence = false
						store.WebGL.activeScene.pauseTheatreSequence()
					} else {
						store.WebGL.activeScene.playTheatreSequence()
					}
				}
			})
		}

		// widen the object details panel
		const styleTag = document.createElement('style')
		styleTag.innerHTML = `
			[data-testid="DetailPanel-Object"] {
				width: 340px;
			}

			.kXqnKI {
				--step: 13px;
				--left-pad: 5px;
				--right-width: 40%;
			}

			.brRnNE {
				--right-width: 40%;
			}
		`

		// hack to make sure the style tag is appended after the studio has loaded
		setTimeout(() => {
			document.querySelector('#theatrejs-studio-root').shadowRoot.appendChild(styleTag)
		}, 500)
	}

	async loadProjectState(projectId, projectFolder) {
		store.theatre.rafDriver = createRafDriver()

		let projectState

		if (store.urlParams.has('version')) {
			const file = store.urlParams.get('version')

			const url = store.env === 'development' ? `//${import.meta.env.VITE_SERVER_HOST}/${import.meta.env.VITE_THEATRE_LOCATION}` : `//${import.meta.env.VITE_THEATRE_STAGING_URL}/${import.meta.env.VITE_THEATRE_LOCATION}`
			const response = await fetch(url + '?download', {
				method: 'POST',
				body: JSON.stringify({ filename: file, projectId: projectFolder })
			})
			projectState = await response.json()
			projectState = JSON.parse(projectState)
		} else {
			projectState = projectStates[`/resources/assets/theatre/${projectFolder}/state.json`]
		}

		store.theatre.project = getProject(projectId, { state: projectState, assets: { baseUrl: '/public' } })

		if (store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') {
			store.theatre.project.ready.then(() => {
				// clear any browser-saved state if the gui is not active (helpful for forcing the latest version when looking at staging)
				if (!store.urlParams.has('gui') && window.__TheatreJS_StudioBundle._studio._store._atom._currentState.ephemeral.coreByProject[projectId].loadingState.type === 'browserStateIsNotBasedOnDiskState') {
					studio.__experimental.__experimental_clearPersistentStorage(store.theatre.persistenceKey)
					localStorage.removeItem(store.theatre.persistenceKey + '.persistent')
					window.location.reload()
				}
			})
		}

		return store.theatre.project.ready
	}

	unloadProjectState(projectId) {
		if (store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') {
			const studioPrivate = window.__TheatreJS_StudioBundle._studio

			const coreAtom = d.getPointerParts(studioPrivate._coreBits.projectsP).root

			const newProjects = { ...coreAtom.get().projects }
			delete newProjects[projectId]

			coreAtom.set({ projects: newProjects })
		}
	}

	enableRAF() {
		store.RAFCollection.add(store.theatre.rafDriver.tick, -1) // make sure the Theatre is the first to run so it sets properties
	}

	disableRAF() {
		store.RAFCollection.remove(store.theatre.rafDriver.tick)
	}
}