import store from "_store"
import { E, qs } from "_utils"
import DefaultRenderer from './DefaultRenderer'
import TheatreExtension from '_utils/TheatreExtension'
import ChapterTitleCard from '_components/ChapterTitleCard'
import AWorldApartWebGL from '_webgl/AWorldApartWebGL'
import ChapterSelector from "_components/ChapterSelector"
import gsap from 'gsap'
import AudioButton from "_components/AudioButton"
import WorldApartIntro from "_components/WorldApartIntro"
import WorldApartOutro from "_components/WorldApartOutro"

export default class AWorldApartRenderer extends DefaultRenderer {
	async initialLoad() {
		// load Theatre project state and wait so properties can be set when components are initialised
		await store.theatre.manager.loadProjectState('A World Apart', 'a-world-apart')

		this.onEnter()

		store.AssetLoader.loaded.then(() => {
			this.onEnterCompleted()
		})
	}

	async onEnter() {
		if (!store.isInitialLoad) {
			// load Theatre project state and wait so properties can be set when components are initialised
			await store.theatre.manager.loadProjectState('A World Apart', 'a-world-apart')
		}

		store.WebGL = new AWorldApartWebGL(document.getElementById('a-world-apart'))

		// add callback to be called first when all assets are loaded since this is run before the check in super.onEnter
		store.AssetLoader.loaded.then(() => {
			store.WebGL?.build()
			for (const key in store.WebGL?.scenes) {
				store.WebGL.scenes[key]?.build && store.WebGL.scenes[key]?.build() // build all available scenes
			}
			store.WebGL?.preRender()
			store.WebGL?.calculateChapterLengths()
		})

		super.onEnter()

		// initialise the check to see if all asset promises have resolved
		store.AssetLoader.loaded.then(() => {
			// Init components
			store.components.add(
				ChapterTitleCard,
				ChapterSelector,
				AudioButton,
				WorldApartIntro,
				WorldApartOutro
			)

			store.WebGL?.start()
			store.WebGL?.scenes[store.sceneName].start() // Start the selected or first scene of the loaded scenes if it exists or BaseScene

			store.theatre.manager.enableRAF()

			if ((store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') && !window.__TheatreJS_StudioBundle._studio._store._atom._currentState.ephemeral.extensions.byId["unseen-extension"]) {
				// initilaise our custom Theatre extension when WebGL is ready
				store.theatre.studio.extend(TheatreExtension('a-world-apart', '/a-world-apart'))
			}
		})
	}

	onEnterCompleted() {
		super.onEnterCompleted()

		this.uponEnteringWithConsent(() => {
			store.components.get(WorldApartIntro).get(0).introTl.play()
		})

		if (store.urlParams.has('gui')) {
			qs('.js-world-apart-intro').classList.add('visibility-hidden')
		} else {
			E.on('click', '.js-enter-experience', this.startExperience)
		}

		E.emit('CheckFPS')
	}

	startExperience() {
		qs('.js-enter-experience').classList.remove('is-visible')
		gsap.to('.js-world-apart-intro', {
			autoAlpha: 0
		})

		// if a scene is specified in the URL, play that scene
		if (store.urlParams.has('scene')) {
			const scene = store.urlParams.get('scene')
			if (store.WebGL.scenes[scene]) {
				store.WebGL.scenes[scene].playTheatreSequence(false)
				E.emit('CheckFPS')
				return
			}
		}

		// otherwise play the first scene
		const sceneKeys = Object.keys(store.WebGL.scenes)
		store.WebGL.scenes[sceneKeys[0]].playTheatreSequence()

		store.Audio.play({ key: 'sfx.bg' })
	}

	onLeaveCompleted() {
		super.onLeaveCompleted()

		E.off('click', '.js-enter-experience', this.startExperience)

		for (const key in store.WebGL?.scenes) {
			store.WebGL.scenes[key]?.destroy() // destroy all available scenes
		}

		store.WebGL?.destroy()
		store.WebGL = null

		store.theatre.manager.disableRAF()
		store.theatre.manager.unloadProjectState('A World Apart')
	}
}
