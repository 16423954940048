import { qs, qsa } from "_utils"

export default class ComponentManager {
	/**
	 * Array of currently active components
	 * @type {[]}
	 */
	components = []

	/**
	 * Component instance arguments
	 * @type {[]}
	 */
	args = []

	negationSelector = null

	/**
	 * Set the Component
	 * @param {import('../components/unseen/Component').default|Object|function} Component
	 */
	constructor(Component) {
		if (Component.selector === undefined) {
			throw new Error(`The component "${Component.name}" does not implement the selector property, or it is not available statically`)
		}

		this.Component = Component
		this.inited = false
	}

	/**
	 * Boot up the Component instances in the current document
	 * Optionally, a different context can be provided if working with fragments or fetch API
	 * @param context
	 * @returns {ComponentManager}
	 */
	init(context = document) {
		if (this.inited) {
			return this
		}

		this.inited = true

		if (this.Component.isSingleton) {
			const el = qs(this.Component.selector, context)
			if (el) {
				this.components = [new this.Component(el, ...this.args)]
			}
			return this
		}

		const componentEls = qsa(this.Component.selector, context)
		this.components = []

		for (let i = 0; i < componentEls.length; i++) {
			if (this.negationSelector === null || componentEls[i].matches(this.negationSelector) === false) {
				this.components.push(new this.Component(componentEls[i], ...this.args))
			}
		}

		return this
	}

	/**
	 * Provide arguments to the created Component instances
	 * @param args
	 * @returns {ComponentManager}
	 */
	with(...args) {
		this.args = [...args]
		return this
	}

	/**
	 * Provide a selector sting here to test possible components against, and bypass initialization
	 * @param selector
	 * @returns {ComponentManager}
	 */
	not(selector) {
		this.negationSelector = selector
		return this
	}

	/**
	 * Manually create a new instance by directly passing the target element
	 * @param {Element} element
	 * @param args
	 */
	make(element, ...args) {
		const c = new this.Component(element, ...args)
		this.components.push(c)
		return c
	}

	/**
	 * Get a specific component from the stack
	 * @param {?number} index
	 * @return {*[]|*|null}
	 */
	get(index = null) {
		return index !== null ? this.components[index] || null : this.components
	}

	/**
	 * Get a specific component from the stack
	 * @param {HTMLElement} element
	 * @return {*[]|*|null}
	 */
	getForElement(element) {
		return this.components.find(component => component.el === element)
	}

	/**
	 * Shorthand to get the first active component
	 * @returns {*|null}
	 */
	first() {
		return this.get(0)
	}

	/**
	 * Iterate all instances and pass each to a callback
	 * @param {function} callback
	 */
	forEach(callback) {
		for (let i = 0; i < this.components.length; i++) {
			callback(this.components[i])
		}

		return this
	}

	/**
	 * Call a provided method on each instance, optionally passing args
	 * @param {string} method
	 * @param {...any} [args]
	 */
	callAll(method, ...args) {
		for (let i = 0; i < this.components.length; i++) {
			this.components[i][method](...args)
		}

		return this
	}

	/**
	 * Shorthand for callAll('destroy')
	 */
	destroy() {
		this.callAll('destroy')
		return this
	}

	/**
	 * Sometimes make isn't good enough as you have to discard a load of old instances (say on an ajax search page).
	 * This method destroys all old instances and starts again.
	 */
	reInit() {
		this.destroy()
		this.inited = false
		this.init()
	}

	/**
	 * Ensure that ComponentManager can be iterated/spread.
	 * @returns {IterableIterator<Object>}
	 */
	[Symbol.iterator]() {
		return this.components.values()
	}
}
