varying vec2 vUv;

uniform sampler2D uBaseTexture; // original
uniform sampler2D uTexture; // updating
uniform sampler2D tNoise;
uniform float uTime;
uniform float uDelta;
uniform float uDecay;
uniform float uSpeed;
uniform bool uEnablePointer;
uniform vec2 uPointer;
uniform float uPointerRadius;
uniform vec3 uCameraPosition;
uniform vec3 uStartOffset;
uniform bool uReset;

void main() {
    vec4 newPosition = texture2D(uTexture, vUv);
    vec4 originalPosition = texture2D(uBaseTexture, vUv);

    originalPosition.xyz += uStartOffset;

    // skip rendering if the pixel has no position
    if (originalPosition.xyzw == vec4(0.)) {
        return;
    }

    if (uReset) {
        newPosition = originalPosition;
        newPosition.xyz += uStartOffset;
    } else {
        vec2 remappedPointer = (uPointer - 0.5) * 2. * uCameraPosition.z + uCameraPosition.xy;

        vec3 cursorMagnetPos = vec3(0.);

        if (originalPosition.z > 999. && newPosition.w <= 0.) {
            cursorMagnetPos.xy = remappedPointer;
            cursorMagnetPos.xy += normalize(originalPosition.xy) * uPointerRadius;

            newPosition.xy = cursorMagnetPos.xy;

            if (!uEnablePointer) {
                newPosition.xyz = vec3(1000., 1000., 1000.);
            }

            newPosition.w = 1.;
        } else if (originalPosition.z > 999.) {
            vec3 noise = texture2D(tNoise, newPosition.xy * (1. / uCameraPosition.z / 2.) + 0.5).xyz;
            newPosition.xy += noise.xy * uDelta * uSpeed * (originalPosition.z - 1000.);

            newPosition.w -= uDelta * uDecay * 15.;
        } else {
            vec3 noise = texture2D(tNoise, newPosition.xy * (1. / uCameraPosition.z / 2.) + 0.5).xyz;
            newPosition.xy += noise.xy * uDelta * uSpeed * originalPosition.z;
            // newPosition.xy += 1000.;

            newPosition.w -= uDelta * uDecay;

            if (newPosition.w <= 0.) {
                newPosition.xyz = originalPosition.xyz;
                newPosition.w = 1.;
            }
        }

        newPosition.z = originalPosition.z;
    }

    gl_FragColor = newPosition;
}