import store from "_store"
import {
	E,
	FocusManager,
	// WebGLAssetLoader,
	GlobalEvents,
	SmoothScroll,
	// DarkMode,
	populateStore,
	populateVars,
	ScrollAnimations,
	WebGLAssetLoader
} from "_utils"

import Taxi from "_taxi"
// import WebGL from '_webgl/WebGL'
import parseWebGLScenes from "_webgl/utils/parseWebGLScenes"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import CustomEase from "gsap/CustomEase"

import SplitText from "_utils/gsap/SplitText"
import LangToggle from '_components/LangToggle'
import Menu from '_components/Menu'
import TheatreManager from '_utils/TheatreManager'
import FPSChecker from '_utils/FPSChecker'
import Loader from "_components/Loader.js"
import Audio from "_components/Audio"

// import all SVGs from svg-sprite folder
import.meta.glob('../svg-sprite/**/*.svg', { eager: true })

parseWebGLScenes()

store.theatre.manager = new TheatreManager()
store.theatre.manager.initStudio()

if (store.env !== 'production') {
	window.store = store
}

E.on('DOMContentLoaded', window, function() {
	/*
	 * -----------------------------------------------------------------------------------------------------------------
	 * 1. Optionally enable global events
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	GlobalEvents.enablePointerEvents()
	GlobalEvents.enableRAF(gsap)
	GlobalEvents.enableResize(100)
	// GlobalEvents.enableDrag()
	// GlobalEvents.enableScroll()

	if (window.history.scrollRestoration) {
		window.history.scrollRestoration = 'manual'
	}

	/*
	 * -----------------------------------------------------------------------------------------------------------------
	 * 2. Optionally register GSAP plugins
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	gsap.registerPlugin(ScrollTrigger)
	gsap.registerPlugin(SplitText)
	gsap.registerPlugin(CustomEase)
	CustomEase.create("joe.in", "M0,0 C0.8,0 0.8,0.5 1,1")
	CustomEase.create("joe.out", "M0,0 C0.2,0 0.1,1 1,1")
	CustomEase.create("joe.inOut", "M0,0 C0.333,0 0,1 1,1")

	/*
	 * -----------------------------------------------------------------------------------------------------------------
	 * 3. Optionally enable common features
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	populateStore() // populate store.mq values, store.urlParams
	store.isSafariMobile = store.mq.touch.matches && store.isSafari
	populateVars() // populate CSS vars
	// store.AssetLoader = new AssetLoader()
	store.AssetLoader = new WebGLAssetLoader()
	store.FPSChecker = new FPSChecker()
	store.ScrollAnimations = new ScrollAnimations()
	SmoothScroll.init()
	store.Audio = new Audio()
	store.Taxi = new Taxi()
	// DarkMode.init()

	/*
	 * -----------------------------------------------------------------------------------------------------------------
	 * 4. Global components for Taxi projects, or all components if not using Taxi
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	store.staticComponents.add(
		Loader,
		LangToggle,
		Menu
	)

	/*
	 * -----------------------------------------------------------------------------------------------------------------
	 * 5. Fix some focus issues on mobile to help A11Y
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	if (store.mq.touch.matches) {
		FocusManager.init()
	}
})
