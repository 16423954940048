import { Toggle } from '_components/unseen'

import { attr, GlobalEvents, qs, qsa } from '_utils'
import store from '_store'

import gsap from 'gsap'

const DURATION = 0.5

export default class Menu extends Toggle {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-menu'

	isContactOpen = false

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.dom = {
			menuEl: qs('.js-menu-el'),
			socials: qsa('.js-menu-socials'),
			links: qsa('.js-menu-link'),
			activeLinks: qsa('.js-header-link'),
			smallLinks1: qsa('.js-menu-small-link'),
			smallLinks2: qsa('.js-menu-small-link-2'),
			menuIcon: qs('.js-menu-icon'),
			menuIconTop: qsa('.js-menu-icon-top'),
			menuIconBottom: qsa('.js-menu-icon-bottom'),
			contactBtn: qs('.js-menu-contact-btn'),
			contact: qs('.js-menu-contact'),
			contactBackBtn: qs('.js-menu-contact-back'),
			contactBlocks: qsa('.js-menu-contact-block'),
			menuContainer: qs('.js-menu-container')
		}

		gsap.set(this.dom.contact, { autoAlpha: 0 })

		// Get color from CSS variable for menu borders
		const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--menu-accent')
		this.borderColor = this.hexToRgb(borderColor)

		const { r, g, b } = this.borderColor
		gsap.set(this.dom.menuEl, { borderColor: `rgba(${r}, ${g}, ${b}, 0)` })

		this.buildTl()
		this.buildButtonTl()
		this.buildContactTl()

		this.addEvents()
	}

	buildTl() {
		this.gsap.context.add(() => {
			const { r, g, b } = this.borderColor

			this.tl = gsap.timeline({
				paused: true,
				defaults: {
					ease: 'power2.out',
					duration: DURATION
				}
			})

			this.tl
				.to(this.dom.menuContainer, {
					'--left': '0%',
					'--bottom': '0%'
				}, 0)
				.from(this.dom.links, {
					scale: 0.9,
					autoAlpha: 0,
					stagger: 0.1,
					transformOrigin: '0% 50%'
				}, '-=0.4')
				.from(this.dom.smallLinks1, {
					scale: 0.9,
					autoAlpha: 0,
					stagger: 0.05,
					transformOrigin: '0% 50%',
					duration: DURATION * 0.5
				}, '-=0.38')
				.from(this.dom.smallLinks2, {
					scale: 0.9,
					autoAlpha: 0,
					stagger: 0.05,
					transformOrigin: '0% 50%',
					duration: DURATION * 0.5
				}, '-=0.36')

			if (this.dom.socials.length) {
				this.tl.from(this.dom.socials, {
					scale: 0,
					stagger: 0.1,
					duration: DURATION * 0.5
				}, '-=0.36')
			}

			this.tl
				.fromTo(this.dom.menuEl, {
					borderColor: `rgba(${r}, ${g}, ${b}, 0)`
				}, {
					borderColor: `rgba(${r}, ${g}, ${b}, 0.3)`
				}, '-=0.2')
		})
	}

	buildButtonTl() {
		this.buttonTl = gsap.timeline({
			paused: true,
			defaults: {
				ease: 'power2.in',
				duration: 0.2
			}
		})

		this.buttonTl
			.to(this.dom.menuIconBottom, {
				rotate: 0,
				transformOrigin: 'center center'
			}, 0)
			.to(this.dom.menuIconTop, {
				rotate: 0,
				transformOrigin: 'center center'
			}, 0)
			.addLabel('menu-step1', '>')
			.to(this.dom.menuIconTop, {
				y: '-=3.36'
			}, 'menu-step1')
			.to(this.dom.menuIconBottom, {
				y: '+=1.64'
			}, 'menu-step1')
			.to(this.dom.menuIconBottom, {
				scaleX: 0.63,
				x: '+=3'
			}, '>')

		this.buttonTl.pause(1)
	}

	buildContactTl() {
		this.gsap.context.add(() => {
			this.contactTl = gsap.timeline({
				paused: true,
				onComplete: () => {
					this.isContactOpen = true
				},
				onReverseComplete: () => {
					this.isContactOpen = false
				},
				defaults: {
					duration: 0.2
				}
			})
				.to(this.dom.contact, {
					autoAlpha: 1
				})
				.from([this.dom.contactBackBtn, ...this.dom.contactBlocks], {
					scale: 0.9,
					autoAlpha: 0,
					stagger: 0.05,
					transformOrigin: '0% 50%',
					ease: 'power2.out'
				})
		})
	}

	animButtonToCloseIcon() {
		this.buttonTl.reverse()
	}

	animButtonToMenuIcon() {
		this.buttonTl.play()
	}

	// On page transition, update the active menu link
	updateActive = () => {
		const currentHref = store.Taxi.currentLocation.href
		const currentHrefWithoutParams = currentHref.split('?')[0]

		for (let i = 0; i < this.dom.activeLinks.length; i++) {
			if (currentHrefWithoutParams === this.dom.activeLinks[i].href) {
				this.dom.activeLinks[i].classList.add('is-active')
			}
		}
	}

	/**
	 * Utils.
	 */

	hexToRgb(hex) {
		// Remove the hash symbol (#) if present
		hex = hex.replace(/^#/, '')

		// Parse the hex string into the red, green, and blue components
		const r = parseInt(hex.substring(0, 2), 16)
		const g = parseInt(hex.substring(2, 4), 16)
		const b = parseInt(hex.substring(4, 6), 16)

		// Return the RGB values as an object
		return { r, g, b }
	}

	/**
	 * Events.
	 */

	addEvents() {
		this.on(GlobalEvents.RESIZE, this.onResize)
		this.on('transition:enter', () => {
			this.updateActive()

			this.target.style.display = 'none'
			this.openProp = 'false'
			this.buttonTl.pause(1) // 0: close icon, 1: menu icon
			attr(this.el, 'aria-expanded', 'false')
			this.tl.pause(0)
		})

		this.on('click', this.dom.contactBtn, this.onContactBtnClick)
		this.on('click', this.dom.contactBackBtn, this.onContactBackBtnClick)

		// force the menu to close on a transition
		store.Taxi.on('NAVIGATE_OUT', () => {
			this.dom.menuIcon.classList.remove('t-header-light')
			this.close()
		})
	}

	onOpen(resolve) {
		this.target.style.display = 'block'

		const menuHeight = this.dom.menuContainer
		const contactHeight = this.dom.contact.offsetHeight

		if (menuHeight < contactHeight && contactHeight > 0) {
			gsap.set(this.dom.menuContainer, { height: contactHeight })
		}

		this.dom.menuIcon.classList.add('t-header-light')
		this.animButtonToCloseIcon()

		this.tl
			.eventCallback('onComplete', () => {
				resolve()
				this.tl.eventCallback('onComplete', null)

				this.addClickOutsideMenuListener()
			})
			.play()
	}

	addClickOutsideMenuListener() {
		this.on('click', document, this.onDocumentClick)
	}

	removeClickOutsideMenuListener() {
		this.off('click', document, this.onDocumentClick)
	}

	onDocumentClick = (e) => {
		if (!this.dom.menuContainer.contains(e.target)) {
			this.isOpen() && this.close()
		}
	}

	onClose(resolve) {
		this.animButtonToMenuIcon()

		this.tl
			.eventCallback('onReverseComplete', () => {
				if (this.isContactOpen) this.contactTl.pause(0)

				this.target.style.display = 'none'
				this.dom.menuIcon.classList.remove('t-header-light')
				resolve()
				this.tl.eventCallback('onReverseComplete', null)

				this.removeClickOutsideMenuListener()
			})
			.reverse()
	}

	onContactBtnClick = () => {
		this.contactTl.play()
	}

	onContactBackBtnClick = () => {
		this.contactTl.reverse()
	}

	onResize = () => {
		this.target.style.display = 'none'
		this.openProp = 'false'
		this.buttonTl.pause(1) // 0: close icon, 1: menu icon
		attr(this.el, 'aria-expanded', 'false')
		gsap.set(this.dom.menuContainer, { clearProps: 'height' })
		this.dom.menuIcon.classList.remove('t-header-light')

		// Only this.tl and this.contactTl in the gsap.context
		this.gsap.context.kill(true)
		this.buildTl()
		this.buildContactTl()
	}
}
