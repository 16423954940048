attribute vec2 aFboUv;
attribute float aPathOffset;
attribute float aSize;

varying vec2 vUv;
varying float vSize;
varying float vPathOffset;
varying float vSpeed;

uniform sampler2D tPosition;
uniform sampler2D tPrevPosition;
uniform float uPathSpread;
uniform float uLineLength;
uniform float uLineWidth;

void main() {
    vUv = uv;
    vPathOffset = aPathOffset;

    vec4 transformedPosition = vec4(position, 1.0);

    vec4 particlePosition = texture2D(tPosition, aFboUv);
    vec4 prevParticlePosition = texture2D( tPrevPosition, aFboUv );

    vSpeed = particlePosition.z;

    vec3 particleVelocity = vec3(particlePosition.xy, 0.) - vec3(prevParticlePosition.xy, 0.);

    /* rotate particle towards its velocity */
    vec3 targetPos = particleVelocity;
    vec3 xDir = normalize(targetPos); // x now points at target, unit length

    vec3 yDir = cross(vec3(0.0, 0.0, 1.0), xDir); // y is perpendicular to x, unit length
    yDir = normalize(yDir);
    vec3 zDir = cross(xDir, yDir); // z is now perpendicular to both x and y, and unit length

    mat4 rotationMatrix = mat4(
        vec4(xDir, 0.0),
        vec4(yDir, 0.0),
        vec4(zDir, 0.0),
        vec4(0.0, 0.0, 0.0, 1.0)
    );

    transformedPosition.x *= uLineLength;
    transformedPosition.y *= uLineWidth * (0.2 + smoothstep(0., 0.3, vSpeed) * 3.);

    transformedPosition = instanceMatrix * transformedPosition;
    transformedPosition = rotationMatrix * transformedPosition;

    // billboard (face camera)
	vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
	vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
	// transformedPosition.xyz = right * transformedPosition.x + up * transformedPosition.y;

    // transformedPosition = instanceMatrix * transformedPosition;

    float size = smoothstep(0.05, 0.1, particlePosition.w); // scale down when life starts
    size *= smoothstep(0.9, 0.85, particlePosition.w); // scale up when life ends
    size *= aSize;
    size = clamp(size, 0., 1.);
    vSize = size;
    transformedPosition.xyz *= size;

    transformedPosition.xy += particlePosition.xy;

    // spread particle sideways along its path
    transformedPosition.xy += yDir.xy * aPathOffset * uPathSpread;

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;
}