import { BufferAttribute, Camera, ClampToEdgeWrapping, DataTexture, HalfFloatType, InstancedBufferAttribute, Mesh, MeshBasicMaterial, PlaneGeometry, RGBAFormat, Scene, ShaderMaterial, Vector2, WebGLRenderTarget, NearestFilter, FloatType, LinearFilter } from 'three'
import store from '_store'
import { closestPowerOf2 } from '_utils'

import vertexShader from '_glsl/default.vert'

export default class FBOHelper {
	constructor({
		fragmentShader,
		uniforms = {},
		defines = {},
		width = 32,
		height = 32,
		data = false,
		count,
		filter,
		wrap,
		type,
		createTexture = true,
		pingPong = true,
		autoSwap = true,
		renderTargets = false,
		createDebugPlane = false,
		transparent = false,
		rtOptions = {}
	}) {
		if (count) {
			this.width = closestPowerOf2(count)
			this.height = this.width
			this.count = count
		} else {
			this.width = width
			this.height = height
			this.count = width * height
		}

		this.uniforms = uniforms
		this.defines = defines

		this.renderTargets = {}

		if (renderTargets) {
			this.renderTargets.a = renderTargets.a
			this.renderTargets.b = renderTargets.b
		}

		this.texture = null
		this.alternateTexture = null

		this.createTexture = createTexture
		this.createDebugPlane = createDebugPlane
		this.pingPong = pingPong
		this.autoSwap = autoSwap

		this.filter = filter || NearestFilter
		this.wrap = wrap || ClampToEdgeWrapping
		this.transparent = transparent

		// Make compatible with iOS devices
		if (this.createTexture && this.filter === LinearFilter) {
			console.warn("You're trying to create a base texture with Linear filtering. This won't work on iOS devices. Change the filter to Nearest.")
		}

		// if type exists -> check if it's float type -> if so, if we're on ios and not creating a texture change it to half float, otherwise keep it at float type -> otherwise keep it at half float
		this.type = type ? ((type === FloatType) ? ((store.mq.touch.matches && !this.createTexture) ? HalfFloatType : type) : type) : HalfFloatType

		this.rtOptions = rtOptions

		this.scene = new Scene()
		this.camera = new Camera()
		this.camera.position.z = 1

		this.buildRenderTargets()
		this.buildPlane(fragmentShader)

		if (this.createTexture) {
			this.buildBaseTexture(data)
		}

		if (this.createDebugPlane) {
			this.buildDebugPlane()
		}

		this.setFboUv()
		this.render()
	}

	buildBaseTexture(customData) {
		const count = this.count * 4
		const data = new Float32Array(this.width * this.height * 4)

		if (customData) {
			for (let i = 0; i < count; i += 4) {
				data[i + 0] = customData[i + 0]
				data[i + 1] = customData[i + 1]
				data[i + 2] = customData[i + 2]
				data[i + 3] = customData[i + 3]
			}
		} else {
			for (let i = 0; i < count; i += 4) {
				data[i + 0] = 0
				data[i + 1] = 0
				data[i + 2] = 0
				data[i + 3] = 1
			}
		}

		this.baseTexture = new DataTexture(
			data,
			this.width,
			this.height,
			RGBAFormat,
			this.type
		)
		this.baseTexture.minFilter = this.filter
		this.baseTexture.magFilter = this.filter
		this.baseTexture.needsUpdate = true

		this.uniforms.uBaseTexture.value = this.baseTexture
		this.uniforms.uTexture.value = this.baseTexture
	}

	buildRenderTargets() {
		if (!this.renderTargets.a) {
			this.renderTargets.a = new WebGLRenderTarget(
				this.width,
				this.height,
				{
					minFilter: this.filter,
					magFilter: this.filter,
					wrapS: this.wrap,
					wrapT: this.wrap,
					generateMipmaps: true,
					format: RGBAFormat,
					type: this.type,
					depthBuffer: false,
					stencilBuffer: false,
					...this.rtOptions
				}
			)
		}

		this.renderTargets.write = this.renderTargets.a

		if (this.pingPong) {
			if (!this.renderTargets.b) {
				this.renderTargets.b = this.renderTargets.a.clone()
			}
			this.renderTargets.read = this.renderTargets.b
		}
	}

	buildPlane(fragmentShader) {
		Object.assign(this.uniforms, {
			uBaseTexture: { value: null }, // original
			uTexture: { value: null }, // updating
			uTime: store.WebGL.globalUniforms.uTime,
			uDelta: store.WebGL.globalUniforms.uDelta,
			uNormalizedDelta: store.WebGL.globalUniforms.uNormalizedDelta,
			uResolution: { value: new Vector2(this.width, this.height) },
			uTexelSize: { value: new Vector2(1 / this.width, 1 / this.height) }
		})

		Object.assign(this.defines, {})

		this.plane = new Mesh(
			new PlaneGeometry(2, 2),
			new ShaderMaterial({
				vertexShader,
				fragmentShader,
				uniforms: this.uniforms,
				defines: this.defines,
				transparent: this.transparent
			})
		)
		this.material = this.plane.material
		this.scene.add(this.plane)
	}

	buildDebugPlane() {
		this.debugPlane = new Mesh(
			new PlaneGeometry(),
			new MeshBasicMaterial()
		)
	}

	setFboUv() {
		this.fboUv = {}

		this.fboUv.data = new Float32Array(this.count * 2)

		const halfExtentX = 1 / this.width / 2
		const halfExtentY = 1 / this.height / 2

		for (let i = 0; i < this.count; i++) {
			const x = (i % this.width) / this.width + halfExtentX
			const y = Math.floor(i / this.width) / this.height + halfExtentY

			this.fboUv.data[i * 2 + 0] = x
			this.fboUv.data[i * 2 + 1] = y
		}

		this.fboUv.attribute = new BufferAttribute(this.fboUv.data, 2)
		this.fboUv.attributeInstanced = new InstancedBufferAttribute(this.fboUv.data, 2)
	}

	render() {
		// Render
		const currentRenderTarget = store.WebGL.renderer.getRenderTarget()
		store.WebGL.renderer.setRenderTarget(this.renderTargets.write)
		store.WebGL.renderer.render(this.scene, this.camera)
		store.WebGL.renderer.setRenderTarget(currentRenderTarget)

		if (this.pingPong && this.autoSwap) {
			this.swap()
		} else {
			this.texture = this.renderTargets.write.texture
		}

		if (this.debugPlane) {
			this.debugPlane.material.map = this.texture
		}
	}

	swap() {
		// Swap
		const prev = this.renderTargets.write
		this.renderTargets.write = this.renderTargets.read
		this.renderTargets.read = prev
		this.texture = this.renderTargets.read.texture
		this.alternateTexture = this.renderTargets.write.texture
	}

	update(setTexture = true) {
		const prevAutoClear = store.WebGL.renderer.autoClear
		store.WebGL.renderer.autoClear = false

		if (setTexture) {
			if (this.pingPong) {
				this.uniforms.uTexture.value = this.renderTargets.read.texture
			} else {
				this.uniforms.uTexture.value = this.renderTargets.write.texture
			}
		}

		this.render()

		store.WebGL.renderer.autoClear = prevAutoClear
	}

	setSize(width, height) {
		this.renderTargets.a.setSize(width, height)
		this.renderTargets.b && this.renderTargets.b.setSize(width, height)
		this.uniforms.uResolution.value.set(width, height)
		this.uniforms.uTexelSize.value.set(1 / width, 1 / height)
	}

	destroy() {
		this.renderTargets.a.dispose()
		this.renderTargets.b && this.renderTargets.b.dispose()
		this.baseTexture && this.baseTexture.dispose()
		this.uniforms.uTexture.value?.dispose()
		this.plane.geometry.dispose()
		this.plane.material.dispose()
	}
}