import { types } from '@theatre/core'
import store from '_store'
import { E } from '_utils/index'
import { Box3, Box3Helper, BoxGeometry, CameraHelper, GridHelper, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, Raycaster } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { ViewHelper } from 'three/examples/jsm/helpers/ViewHelper'

export default class SceneDevTools {
	constructor(scene) {
		this.scene = scene

		this.enabled = false

		this.options = {
			helpers: true
		}

		this.cameraHelper = new CameraHelper(this.scene.activeCamera)
		this.cameraHelper.visible = false
		this.cameraHelper.enabled = true

		this.cameraHelperRaycastMesh = new Mesh(new BoxGeometry(), new MeshBasicMaterial({ visible: false }))
		this.cameraHelperRaycastMesh.name = 'CameraHelperRaycastMesh'
		this.cameraHelper.add(this.cameraHelperRaycastMesh)

		this.devCamera = new PerspectiveCamera(45, store.window.w / store.window.h, 0.1, 10000)
		this.devCamera.position.z = 20
		this.devCamera.position.y = 3

		for (const key in this.scene.cameraLayers) {
			this.devCamera.layers.enable(this.scene.cameraLayers[key])
		}

		this.controls = new OrbitControls(this.devCamera, store.WebGL.renderer.domElement)
		this.controls.target.set(0, 0, 0)
		this.controls.enabled = true
		this.controls.zoomToCursor = true

		this.selectedObject = null

		this.transformControls = new TransformControls(this.devCamera, store.WebGL.renderer.domElement)
		this.transformControls.enabled = false
		this.transformControls.visible = false

		this.box3 = new Box3()
		this.boxHelper = new Box3Helper(this.box3)
		this.boxHelper.enabled = false
		this.boxHelper.visible = false
		this.scene.add(this.boxHelper)

		this.raycaster = new Raycaster()

		this.pointerDown = false
		this.dragging = false
		this.mouse = { x: 0, y: 0 }
		this.mouseDelta = { x: 0, y: 0 }

		this.transformableObjects = []

		// add invisible mesh for cameras to mirror the transforms
		const cameraTransformMesh = new Mesh(new BoxGeometry(), new MeshBasicMaterial({ visible: false }))
		cameraTransformMesh.name = 'CameraTransformMesh'

		for (const key in this.scene.cameras) {
			const camera = this.scene.cameras[key]

			camera._transformMesh = cameraTransformMesh.clone()
			// override the Theatre camera object with the transform mesh for selecting via the Theatre UI
			camera._theatreObject._webglObject = camera._transformMesh
			camera._transformMesh._theatreObject = camera._theatreObject
			this.scene.add(camera._transformMesh)

			this.transformableObjects.push(camera._transformMesh)
		}

		// add any components that have Theatre objects attached to them with transforms available
		for (const key in this.scene.components) {
			if (this.scene.components[key]._theatreObject && (this.scene.components[key]._theatreObject.value.transforms || this.scene.components[key]._theatreObject.value.position)) {
				this.transformableObjects.push(this.scene.components[key])
			}
		}

		this.viewHelperElement = document.createElement('div')
		Object.assign(this.viewHelperElement.style, {
			width: '128px',
			height: '128px',
			position: 'fixed',
			bottom: 0,
			right: 0,
			zIndex: 99,
			pointerEvents: 'none'
		})
		document.body.appendChild(this.viewHelperElement)
		this.viewHelper = new ViewHelper(this.devCamera, store.WebGL.renderer.domElement)

		this.buildGrid()
		this.addHelpers()

		this.onSelectionChangeUnsubcribe = store.theatre.studio.onSelectionChange(this.onStudioSelectionChange)
	}

	enable() {
		if (this.enabled) return

		this.enabled = true

		this.toggleDevCamera(true)
		this.addEvents()

		this.viewHelperElement.style.pointerEvents = 'auto'

		store.theatre.updateExtensionToolbar && store.theatre?.updateExtensionToolbar()
	}

	disable() {
		if (!this.enabled) return

		this.enabled = false

		this.toggleDevCamera(false)
		this.removeEvents()

		this.viewHelperElement.style.pointerEvents = 'none'

		store.theatre.updateExtensionToolbar && store.theatre?.updateExtensionToolbar()
	}

	createTheatreObject() {
		if (store.theatre.studioSheets[this.scene.prettyName]) {
			this.theatreObject = store.theatre.helper.addStudioSheetObject(this.scene.prettyName, 'Dev Tools', {
				enabled: types.boolean(this.enabled),
				position: types.compound({
					x: this.controls.object.position.x,
					y: this.controls.object.position.y,
					z: this.controls.object.position.z
				}),
				target: {
					x: this.controls.target.x,
					y: this.controls.target.y,
					z: this.controls.target.z
				},
				helpers: types.boolean(this.options.helpers),
				grid: types.boolean(this.grid.enabled)
			})

			this.unsubscribeFromTheatreObject = this.theatreObject.onValuesChange(values => {
				values.enabled ? this.enable() : this.disable()

				this.controls.object.position.copy(values.position)
				this.controls.target.copy(values.target)

				this.toggleGrid(values.grid)
				this.toggleHelpers(values.helpers)
			})

			this.controls.addEventListener('end', this.updateTheatreCameraProps)

			store.theatre.updateExtensionToolbar && store.theatre.updateExtensionToolbar()
		}
	}

	detachTheatreObject() {
		store.theatre.studioSheets[this.scene.prettyName]?.detachObject('Dev Tools')
		delete store.theatre.studioObjects[this.scene.prettyName]?.['Dev Tools']
		this.unsubscribeFromTheatreObject?.()
		this.controls.removeEventListener('end', this.updateTheatreCameraProps)
	}

	updateTheatreCameraProps = () => {
		if (!this.enabled) return

		store.theatre.studio.transaction(({ set }) => {
			set(this.theatreObject.props.position.x, this.controls.object.position.x)
			set(this.theatreObject.props.position.y, this.controls.object.position.y)
			set(this.theatreObject.props.position.z, this.controls.object.position.z)
			set(this.theatreObject.props.target.x, this.controls.target.x)
			set(this.theatreObject.props.target.y, this.controls.target.y)
			set(this.theatreObject.props.target.z, this.controls.target.z)
		})
	}

	buildGrid() {
		this.grid = new GridHelper(200, 50)
		this.grid.material.opacity = 0.2
		this.grid.material.transparent = true
		this.grid.visible = false
		this.grid.enabled = false
	}

	toggleDevCamera(enabled) {
		if (enabled === null) {
			enabled = !this.controls.enabled // toggle if no value is passed
		}

		this.controls.enabled = enabled

		if (this.prevCanvasPointerEvents && !enabled) {
			store.WebGL.renderer.domElement.classList.add('pointer-events-none')
		}
		this.prevCanvasPointerEvents = store.WebGL.renderer.domElement.classList.contains('pointer-events-none')
		if (enabled && this.prevCanvasPointerEvents) {
			store.WebGL.renderer.domElement.classList.remove('pointer-events-none')
		}

		// toggle zIndex to allow for pointer events on the canvas
		if (enabled) {
			store.WebGL.renderer.domElement.style.zIndex = 0
			this.prevZIndex = store.WebGL.renderer.domElement.style.zIndex
		} else {
			store.WebGL.renderer.domElement.style.zIndex = this.prevZIndex || -1
		}

		return enabled
	}

	addHelpers() {
		this.scene.add(this.cameraHelper)
		this.scene.add(this.transformControls)
		this.scene.add(this.grid)
	}

	toggleGrid(enabled) {
		const prevValue = this.grid.enabled
		this.grid.enabled = enabled

		if (prevValue !== enabled) {
			store.theatre.updateExtensionToolbar && store.theatre?.updateExtensionToolbar()
		}
	}

	toggleHelpers(enabled) {
		const prevValue = this.options.helpers
		this.options.helpers = enabled
		this.cameraHelper.enabled = enabled

		if (this.transformControls.object) {
			this.transformControls.enabled = enabled
		}

		if (prevValue !== enabled) {
			store.theatre.updateExtensionToolbar && store.theatre?.updateExtensionToolbar()
		}
	}

	update = () => {
		if (!this.enabled) return

		this.controls.update()
		this.cameraHelper.camera = this.scene.activeCamera
		this.cameraHelper.matrix = this.scene.activeCamera.matrixWorld

		for (const key in this.scene.cameras) {
			const camera = this.scene.cameras[key]

			// mirror the transforms of the cameras
			if (!this.transformControls.dragging) {
				camera._transformMesh.position.copy(camera._position)

				if (camera.enableLookAt) {
					camera._transformMesh.lookAt(camera._lookAt)
				} else {
					camera._transformMesh.rotation.copy(camera.rotation)
				}
			} else {
				camera._position.copy(camera._transformMesh.position)

				if (camera.enableLookAt) {
					camera._lookAt.copy(camera._transformMesh.getWorldDirection(camera._lookAt))
				} else {
					camera.rotation.copy(camera._transformMesh.rotation)
				}
			}
		}

		// toggle visibility of helpers
		if (this.options.helpers) {
			this.transformControls.visible = this.transformControls.enabled
			this.boxHelper.visible = this.boxHelper.enabled
			this.cameraHelper.visible = this.cameraHelper.enabled
		}

		this.grid.visible = this.grid.enabled

		if (this.boxHelper.enabled) {
			this.box3.setFromObject(this.selectedObject)
		}

		store.WebGL.renderer.render(this.scene, this.devCamera)

		// render bottom right camera direction gizmo on top of everything else
		const prevAutoClear = store.WebGL.renderer.autoClear
		store.WebGL.renderer.autoClear = false
		if (this.viewHelper.animating) this.viewHelper.update(store.WebGL.clockDelta)
		this.viewHelper.render(store.WebGL.renderer)
		store.WebGL.renderer.autoClear = prevAutoClear

		// hide all helpers so they don't appear in the regular render loop
		this.transformControls.visible = false
		this.boxHelper.visible = false
		this.cameraHelper.visible = false
		this.grid.visible = false
	}

	selectObject(object, selectInTheatre = false) {
		if (object instanceof Object3D) {
			this.selectedObject = object

			if (object._theatreObject.value.transforms || object._theatreObject.value.position) {
				this.transformControls.attach(this.selectedObject)
				this.transformControls.enabled = true
			} else {
				this.transformControls.detach()
				this.transformControls.enabled = false
			}

			this.box3.setFromObject(this.selectedObject)
			this.boxHelper.enabled = true
		}

		// select object in Theatre UI
		if (selectInTheatre && object._theatreObject) {
			store.theatre.studio.setSelection([object._theatreObject.sheet, object._theatreObject])
		}

		store.theatre.updateExtensionToolbar && store.theatre.updateExtensionToolbar()
	}

	deselectObject() {
		this.transformControls.detach()
		this.transformControls.enabled = false
		this.boxHelper.enabled = false

		this.selectedObject = null

		store.theatre.updateExtensionToolbar && store.theatre.updateExtensionToolbar()
	}

	addEvents() {
		store.RAFCollection.add(this.update, 150) // render after main WebGL but before any RTViewer instances
		E.on('pointermove', store.WebGL.renderer.domElement, this.onPointerMove)
		E.on('pointerdown', store.WebGL.renderer.domElement, this.onPointerDown)
		E.on('pointerup', store.WebGL.renderer.domElement, this.onPointerUp)
		this.transformControls.addEventListener('dragging-changed', this.onTransformControlsDraggingChanged)
		this.transformControls.addEventListener('mouseUp', this.onTransformControlsMouseUp)
		this.viewHelperElement.addEventListener('pointerup', this.onViewHelperClick)
	}

	removeEvents() {
		store.RAFCollection.remove(this.update)
		E.off('pointermove', store.WebGL.renderer.domElement, this.onPointerMove)
		E.off('pointerdown', store.WebGL.renderer.domElement, this.onPointerDown)
		E.off('pointerup', store.WebGL.renderer.domElement, this.onPointerUp)
		this.transformControls.removeEventListener('dragging-changed', this.onTransformControlsDraggingChanged)
		this.transformControls.removeEventListener('mouseUp', this.onTransformControlsMouseUp)
		this.viewHelperElement.removeEventListener('pointerup', this.onViewHelperClick)
	}

	onStudioSelectionChange = selection => {
		if (!this.enabled) return

		if (selection.length) {
			if (selection[0]._webglObject) {
				this.selectObject(selection[0]._webglObject)
			} else if (selection[1]?._webglObject) {
				// sometimes the selection includes the sheet first, so try and select the object in the second slot instead
				this.selectObject(selection[1]._webglObject)
			} else {
				this.deselectObject()
			}
		}
	}

	onViewHelperClick = event => {
		this.viewHelper.handleClick(event)
	}

	onTransformControlsDraggingChanged = event => {
		this.controls.enabled = !event.value
	}

	onTransformControlsMouseUp = event => {
		const mode = event.mode
		const object = event.target.object

		if (object._theatreObject) {
			const theatreObject = object._theatreObject

			switch (mode) {
				case 'translate':
					if (theatreObject.value.transforms?.position) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.transforms.position.x, object.position.x)
							set(theatreObject.props.transforms.position.y, object.position.y)
							set(theatreObject.props.transforms.position.z, object.position.z)
						})
					} else if (theatreObject.value.position) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.position.x, object.position.x)
							set(theatreObject.props.position.y, object.position.y)
							set(theatreObject.props.position.z, object.position.z)
						})
					}
					break
				case 'rotate':
					if (theatreObject.value.transforms?.position) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.transforms.rotation.x, object.rotation.x)
							set(theatreObject.props.transforms.rotation.y, object.rotation.y)
							set(theatreObject.props.transforms.rotation.z, object.rotation.z)
						})
					} else if (theatreObject.value.rotation) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.rotation.x, object.rotation.x)
							set(theatreObject.props.rotation.y, object.rotation.y)
							set(theatreObject.props.rotation.z, object.rotation.z)
						})
					}
					break
				case 'scale':
					if (theatreObject.value.transforms?.scale) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.transforms.scale.x, object.scale.x)
							set(theatreObject.props.transforms.scale.y, object.scale.y)
							set(theatreObject.props.transforms.scale.z, object.scale.z)
						})
					} else if (theatreObject.value.scale) {
						store.theatre.studio.transaction(({ set }) => {
							set(theatreObject.props.scale.x, object.scale.x)
							set(theatreObject.props.scale.y, object.scale.y)
							set(theatreObject.props.scale.z, object.scale.z)
						})
					}
					break
				default:
					break
			}
		}
	}

	onPointerMove = () => {
		this.draggingTransformControls = this.transformControls.dragging

		if (this.pointerDown) {
			this.mouseDelta.x += this.mouse.x - store.pointer.x
			this.mouseDelta.y += this.mouse.y - store.pointer.y

			if (Math.abs(this.mouseDelta.x) > 1 || Math.abs(this.mouseDelta.y) > 1) {
				this.dragging = true
			}
		}
	}

	onPointerDown = () => {
		this.pointerDown = true

		this.mouse.x = store.pointer.x
		this.mouse.y = store.pointer.y
		this.mouseDelta.x = 0
		this.mouseDelta.y = 0
	}

	onPointerUp = () => {
		if (!this.draggingTransformControls && !this.dragging) {
			const visibleObjects = this.transformableObjects.filter(object => object.visible)

			// check what the ray intersects
			this.raycaster.setFromCamera(store.pointer.glNormalized, this.devCamera)
			const intersects = this.raycaster.intersectObjects(visibleObjects, false)

			if (intersects.length > 0) {
				this.selectObject(intersects[0].object, true)
			} else {
				this.deselectObject()
			}
		}

		this.pointerDown = false
		this.dragging = false
	}

	onResize() {
		this.devCamera.aspect = store.window.w / store.window.h
		this.devCamera.updateProjectionMatrix()
	}

	destroy() {
		this.disable()
		this.detachTheatreObject()
		this.onSelectionChangeUnsubcribe()
	}
}