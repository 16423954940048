import { FloatType, Group, InstancedBufferAttribute, InstancedMesh, LinearSRGBColorSpace, MathUtils, Object3D, PlaneGeometry, RGBADepthPacking, ShaderMaterial } from "three"
import createComponent from './unseen/Component'
import RockImposterMaterial from '_webgl/materials/rockImposter/RockImposterMaterial'
import store from '_store'
import particlePositionFrag from '_webgl/materials/rockImposter/position.glsl'
import FBOHelper from '_webgl/utils/FBOHelper'

export default class RockImposters extends createComponent(Group) {
	constructor() {
		super({
			name: 'Rock Imposters'
		})
	}

	build() {
		const amount = 40
		const spread = 10
		const count = Math.pow(amount, 3)

		this.instancedMesh = new InstancedMesh(
			new PlaneGeometry(),
			new RockImposterMaterial({
				uniforms: {
					tPosition: { value: null },
					tNormals: { value: this.assets.textures.normals }
				},
				globalUniforms: this.parent.globalUniforms
			}),
			count
		)

		this.originalCount = count

		this.instancedMesh.name = 'Rock Imposters'
		this.instancedMesh.frustumCulled = false
		this.instancedMesh.receiveShadow = true
		this.instancedMesh.castShadow = true

		const aRandom = []

		const dummy = new Object3D()

		const positionData = []

		for (let i = 0; i < this.instancedMesh.count; i++) {
			dummy.scale.setScalar(MathUtils.randFloat(0.02, 0.035))
			dummy.position.y = dummy.scale.x / 2 - dummy.scale.x * 0.15

			positionData.push(
				MathUtils.randFloatSpread(-spread, spread),
				0,
				MathUtils.randFloatSpread(-spread, spread),
				Math.random()
			)

			dummy.updateMatrix()

			aRandom.push(
				Math.random(),
				Math.random(),
				Math.random()
			)

			this.instancedMesh.setMatrixAt(i, dummy.matrix)
		}

		this.particlePositionSim = new FBOHelper({
			fragmentShader: particlePositionFrag,
			data: positionData,
			count,
			type: FloatType,
			uniforms: {
				uDecay: { value: 0.1, gui: { min: 0, max: 2, step: 0.01 } },
				tFluid: { value: store.WebGL.fluidSim?.advectionSimVelocity.texture },
				tFluidMask: { value: store.WebGL.fluidSim?.addForceSimMouse.texture },
				uModelMatrix: { value: this.instancedMesh.matrixWorld },
				uViewMatrix: { value: this.parent.activeCamera.matrixWorldInverse },
				uProjectionMatrix: { value: this.parent.activeCamera.projectionMatrix },
				uFluidEnabled: { value: true },
				uFluidMaskEnabled: { value: true },
				uFluidStrength: { value: 0.1, gui: { min: 0, max: 0.3, step: 0.0001 } },
				uFluidLerpSpeed: { value: 0.014, gui: { min: 0, max: 1.0, step: 0.001 } },
				...this.parent.globalUniforms
			}
		})

		this.instancedMesh.material.uniforms.tPosition.value = this.particlePositionSim.texture

		this.instancedMesh.geometry.setAttribute('aFboUv', this.particlePositionSim.fboUv.attributeInstanced)
		this.instancedMesh.geometry.setAttribute('aRandom', new InstancedBufferAttribute(new Float32Array(aRandom), 3))

		this.instancedMesh.customDepthMaterial = new ShaderMaterial({
			vertexShader: this.instancedMesh.material.vertexShader,
			fragmentShader: this.instancedMesh.material.fragmentShader,
			uniforms: this.instancedMesh.material.uniforms,
			defines: {
				DEPTH_PACKING: RGBADepthPacking,
				GRID_SIZE: 14
			}
		})

		this.add(this.instancedMesh)
	}

	animate() {
		this.particlePositionSim.update()
		this.instancedMesh.material.uniforms.tPosition.value = this.particlePositionSim.texture

		if (store.WebGL.fluidSim) {
			this.particlePositionSim.uniforms.tFluid.value = store.WebGL.fluidSim.advectionSimVelocity.texture
			this.particlePositionSim.uniforms.tFluidMask.value = store.WebGL.fluidSim.addForceSimMouse.texture
		}

		this.particlePositionSim.uniforms.uViewMatrix.value = this.parent.activeCamera.matrixWorldInverse
		this.particlePositionSim.uniforms.uProjectionMatrix.value = this.parent.activeCamera.projectionMatrix
	}

	load() {
		const basePath = store.publicUrl + 'webgl'

		store.AssetLoader.loadTexture(`${basePath}/textures/normals/rock-01/atlas.png`, { colorSpace: LinearSRGBColorSpace }).then(texture => {
			this.assets.textures.normals = texture
		})
	}

	addGui() {
		const particleConfig = {}

		const particleCallbacks = store.theatre.helper.autoAddUniforms(particleConfig, this.particlePositionSim.uniforms, ['uLightPosition', 'uLightDirection', 'uLightColor', 'uNoiseSeed', 'uNoiseHeight', 'uNoiseScale', 'uNoiseTimeScale'])

		const theatreObject = store.theatre.helper.autoAddObject(this.instancedMesh, this.parent.prettyName, {
			exclude: ['uLightPosition', 'uLightDirection', 'uLightColor', 'uNoiseSeed', 'uNoiseHeight', 'uNoiseScale', 'uNoiseTimeScale'],
			additionalConfig: particleConfig
		})

		theatreObject.onValuesChange(values => {
			particleCallbacks.forEach(callback => callback(null, values))
		}, store.theatre.rafDriver)
	}

	destroy() {
		this.particlePositionSim.destroy()
		this.instancedMesh.geometry.dispose()
		this.instancedMesh.material.dispose()
		this.instancedMesh.customDepthMaterial.dispose()
		this.instancedMesh.dispose()
	}
}