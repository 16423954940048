#include <default_pars>

float test;

varying vec2 vUv;

uniform sampler2D uBaseTexture;
uniform sampler2D uTexture;

uniform sampler2D tSpline;
uniform sampler2D tAttributes;

uniform bool uReversed;
uniform float uLerpSpeed;
uniform float uTravelSpeed;

uniform float uLowestYPoint;
uniform float uHighestYPoint;

uniform float uMainSplineTravelSpeedSlowFactor;
uniform float uMidSplineTravelSpeedSlowFactor;
uniform float uAmbientSplineTravelSpeedSlowFactor;

uniform float uAnimationProgress;
uniform float uAfterGrowTravelSpeed;

uniform float uTime;
uniform float uDelta;
uniform float uNormalizedDelta;

uniform float uRadius;
uniform bool uCurl;
uniform float uCurlSize;
uniform float uCurlNoiseSpeed;
uniform float uCurlStrength;

uniform float uSetup;

uniform float uSplineGroupIndex;
uniform float uSplineCount;
uniform float uSplineTexSize;
uniform float uPixelsPerSpline;
uniform float uSplineThickness;

uniform float uAmbientSplineThickness;

uniform vec2 uSplineTaper;
uniform vec2 uFadeOut;

// uniform vec2 uMainSplineTaper;
// uniform vec2 uMidSplineTaper;
// uniform vec2 uSmallSplineTaper;
// uniform vec2 uAmbientSplineTaper;

// uniform float uMainSplineNoiseReduction;
// uniform float uMidSplineNoiseReduction;
// uniform float uSmallSplineNoiseReduction;

uniform float uRandomThicknessMax;
uniform float uRandomThicknessStrength;
uniform float uRandomThicknessFrequency;
uniform float uRandomThicknessThreshold;

#ifdef USE_FLUID
    uniform sampler2D tFluid;
    uniform sampler2D tFluidMask;
    uniform bool uFluidEnabled;
    uniform bool uFluidMaskEnabled;
    uniform float uFluidStrength;

    uniform float uFluidLerpSpeed;

    uniform mat4 uModelMatrix;
    uniform mat4 uViewMatrix;
    uniform mat4 uProjectionMatrix;
#endif

vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

float mod289(float x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
    return mod289(((x*34.0)+1.0)*x);
}

float permute(float x) {
    return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

float taylorInvSqrt(float r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

vec4 grad4(float j, vec4 ip) {
    const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
    vec4 p,s;

    p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
    p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
    s = vec4(lessThan(p, vec4(0.0)));
    p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;

    return p;
}

#define F4 0.309016994374947451

vec4 snoise4 (vec4 v) {
    const vec4  C = vec4( 0.138196601125011,0.276393202250021,0.414589803375032,-0.447213595499958);

    vec4 i  = floor(v + dot(v, vec4(F4)) );
    vec4 x0 = v -   i + dot(i, C.xxxx);

    vec4 i0;
    vec3 isX = step( x0.yzw, x0.xxx );
    vec3 isYZ = step( x0.zww, x0.yyz );
    i0.x = isX.x + isX.y + isX.z;
    i0.yzw = 1.0 - isX;
    i0.y += isYZ.x + isYZ.y;
    i0.zw += 1.0 - isYZ.xy;
    i0.z += isYZ.z;
    i0.w += 1.0 - isYZ.z;

    vec4 i3 = clamp( i0, 0.0, 1.0 );
    vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
    vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

    vec4 x1 = x0 - i1 + C.xxxx;
    vec4 x2 = x0 - i2 + C.yyyy;
    vec4 x3 = x0 - i3 + C.zzzz;
    vec4 x4 = x0 + C.wwww;

    i = mod289(i);
    float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
    vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));


    vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

    vec4 p0 = grad4(j0,   ip);
    vec4 p1 = grad4(j1.x, ip);
    vec4 p2 = grad4(j1.y, ip);
    vec4 p3 = grad4(j1.z, ip);
    vec4 p4 = grad4(j1.w, ip);

    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
    p4 *= taylorInvSqrt(dot(p4,p4));

    vec3 values0 = vec3(dot(p0, x0), dot(p1, x1), dot(p2, x2)); //value of contributions from each corner at point
    vec2 values1 = vec2(dot(p3, x3), dot(p4, x4));

    vec3 m0 = max(0.5 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0); //(0.5 - x^2) where x is the distance
    vec2 m1 = max(0.5 - vec2(dot(x3,x3), dot(x4,x4)), 0.0);

    vec3 temp0 = -6.0 * m0 * m0 * values0;
    vec2 temp1 = -6.0 * m1 * m1 * values1;

    vec3 mmm0 = m0 * m0 * m0;
    vec2 mmm1 = m1 * m1 * m1;

    float dx = temp0[0] * x0.x + temp0[1] * x1.x + temp0[2] * x2.x + temp1[0] * x3.x + temp1[1] * x4.x + mmm0[0] * p0.x + mmm0[1] * p1.x + mmm0[2] * p2.x + mmm1[0] * p3.x + mmm1[1] * p4.x;
    float dy = temp0[0] * x0.y + temp0[1] * x1.y + temp0[2] * x2.y + temp1[0] * x3.y + temp1[1] * x4.y + mmm0[0] * p0.y + mmm0[1] * p1.y + mmm0[2] * p2.y + mmm1[0] * p3.y + mmm1[1] * p4.y;
    float dz = temp0[0] * x0.z + temp0[1] * x1.z + temp0[2] * x2.z + temp1[0] * x3.z + temp1[1] * x4.z + mmm0[0] * p0.z + mmm0[1] * p1.z + mmm0[2] * p2.z + mmm1[0] * p3.z + mmm1[1] * p4.z;
    float dw = temp0[0] * x0.w + temp0[1] * x1.w + temp0[2] * x2.w + temp1[0] * x3.w + temp1[1] * x4.w + mmm0[0] * p0.w + mmm0[1] * p1.w + mmm0[2] * p2.w + mmm1[0] * p3.w + mmm1[1] * p4.w;

    return vec4(dx, dy, dz, dw) * 49.0;
}

vec3 curl( in vec3 p, in float noiseTime, in float persistence ) {

    vec4 xNoisePotentialDerivatives = vec4(0.0);
    vec4 yNoisePotentialDerivatives = vec4(0.0);
    vec4 zNoisePotentialDerivatives = vec4(0.0);

    for (int i = 0; i < 3; ++i) {

        float twoPowI = pow(2.0, float(i));
        float scale = 0.5 * twoPowI * pow(persistence, float(i));

        xNoisePotentialDerivatives += snoise4(vec4(p * twoPowI, noiseTime)) * scale;
        yNoisePotentialDerivatives += snoise4(vec4((p + vec3(123.4, 129845.6, -1239.1)) * twoPowI, noiseTime)) * scale;
        zNoisePotentialDerivatives += snoise4(vec4((p + vec3(-9519.0, 9051.0, -123.0)) * twoPowI, noiseTime)) * scale;
    }

    return vec3(
        zNoisePotentialDerivatives[1] - yNoisePotentialDerivatives[2],
        xNoisePotentialDerivatives[2] - zNoisePotentialDerivatives[0],
        yNoisePotentialDerivatives[0] - xNoisePotentialDerivatives[1]
    );

}

float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    float oldRange = oldMax - oldMin;
    float newRange = newMax - newMin;
    return (((oldValue - oldMin) * newRange) / oldRange) + newMin;
}

vec2 range(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    vec2 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    return v;
}

vec3 range(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    vec3 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    v.z = range(oldValue.z, oldMin.z, oldMax.z, newMin.z, newMax.z);
    return v;
}

float scnoise(vec3 v) {
    float t = v.z * 0.3;
    v.y *= 0.8;
    float noise = 0.0;
    float s = 0.5;
    noise += range(sin(v.x * 0.9 / s + t * 10.0) + sin(v.x * 2.4 / s + t * 15.0) + sin(v.x * -3.5 / s + t * 4.0) + sin(v.x * -2.5 / s + t * 7.1), -1.0, 1.0, -0.3, 0.3);
    noise += range(sin(v.y * -0.3 / s + t * 18.0) + sin(v.y * 1.6 / s + t * 18.0) + sin(v.y * 2.6 / s + t * 8.0) + sin(v.y * -2.6 / s + t * 4.5), -1.0, 1.0, -0.3, 0.3);
    return noise;
}

float splineIndex;

vec2 getSplineLookupUV(float t) {

    float pixel = uPixelsPerSpline * t;

    float size = uSplineTexSize;
    float p0 = pixel / size;
    float y = floor(p0);
    float x = p0 - y;
    vec2 uv = vec2(0.0);
    uv.x = x;
    uv.y = y / size;
    return uv;
}

vec2 getSplineLookupUV(float index, float time) {

    float splineStep = 1.0 / uPixelsPerSpline;

    // float pixel = (index * uPixelsPerSpline) + (time * uPixelsPerSpline) - 1.; // this should be the correct one
    float pixel = max((index * uPixelsPerSpline) + (time * uPixelsPerSpline) - 1., index * uPixelsPerSpline); // this should be the correct one - limit so to never go below the start of the spline

    float size = uSplineTexSize;
    float p0 = pixel / size;
    float y = floor(p0);
    float x = p0 - y;
    vec2 uv = vec2(0.0);
    uv.x = x;
    uv.y = y / size;
    return uv;
}

vec3 getSplinePosition(float t) {

    float splineStep = 1.0 / uPixelsPerSpline;

    vec2 uvNow = getSplineLookupUV(splineIndex, t);
    vec2 uvNext = getSplineLookupUV(splineIndex, min(1.0, t + splineStep));

    float interpolate = mod(t, splineStep) * uPixelsPerSpline;

    vec3 currentPos = texture2D(tSpline, uvNow).xyz;
    vec3 nextPos = texture2D(tSpline, uvNext).xyz;

    vec3 pos = mix(currentPos, nextPos, interpolate);

    return pos;

}

float linearStep(float edge0, float edge1, float x) {
    return clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);

    // https://www.artstation.com/blogs/briz/beeN/linear-smooth-step-material-niagara-functions
    // return max(0., min(1., (x - edge0) / (edge1 - edge0)))
}

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

void main() {

	vec2 uv = vUv;

    vec4 originalPosFetch = texture2D(uBaseTexture, uv); // Fetch original position (aka the offsets around the source)
	vec3 offsets = originalPosFetch.xyz;
	float originalLife = originalPosFetch.w;

	vec4 position = texture2D(uTexture, uv); 
	vec3 currentPos = position.xyz;

    vec4 attributes = texture2D(tAttributes, uv);

    splineIndex = map(attributes.x, 0., 1., 0., uSplineCount-1.);
    float splineType = attributes.y;

    float yPos = attributes.z;

    float randomUV = rand(vUv);
    float randomData = attributes.w;

    float mapRandomUV = map(randomUV, 0.0, 1.0, 0.7, 1.0);
    float mapRandomData = map(randomData, 0., 1., 0.8, 1.25);
    
	float dieSpeed = uTravelSpeed * 0.001 * uNormalizedDelta * mapRandomData;

    float lerpSpeed = uLerpSpeed;

    float life = position.a;

    /**
        * Growth animation on a selected main spline (or a group of them)
    */
    float staggerY = map(yPos, uLowestYPoint+15., uHighestYPoint, 0.0, 1.0); // map the y position to a 0-1 range

    // float travel_progress_stagger = smoothstep(staggerY, 0.0, 1.0 - uAnimationProgress ); // stagger the particles so they start growing from top to bottom
    float travel_progress_stagger = linearStep(0.0, mapRandomUV, uAnimationProgress ); // stagger the particles based on their random value and life, starting all approx at the same time (v0), but finishing at different times
    // travel_progress_stagger = smoothstep(0.0, originalLife, uAnimationProgress ); // stagger the particles based on their life, starting all approx at the same time (v0)

    if (travel_progress_stagger >= 1.0) { // after the animation has finished
        life = position.a; // Move them based on the previous position after the animation has completed
    } else {
        life = 1.0 - mix(0.0, originalLife, travel_progress_stagger); // position them to their original position with staggering
    }

    lerpSpeed = 1.0; // override the lerp speed to 1.0 for the growing main spline(s)

    dieSpeed = uAfterGrowTravelSpeed * 0.001 * uNormalizedDelta * mapRandomData; // override the travel speed so the particles move at desired speed even after their animation is done


    if(splineType == 0.0 ) { // main additional more spread out spline (ambient)
        dieSpeed *= uAmbientSplineTravelSpeedSlowFactor; // make it way slower
    }
    // } else if(splineType > 0.0 && splineType < 0.4) { // main spline
    //     dieSpeed *= uMainSplineTravelSpeedSlowFactor; // make it way slower

    // } else if (splineType == 0.5) { // mid splines
    //     dieSpeed *= uMidSplineTravelSpeedSlowFactor; // make it a bit slower
    // }

    life -= dieSpeed; // get new life by subtracting the die speed

    if(life <= 0.0) { // Reset life if needed
        life = 1.0;
    }

    // Spline thickness
    float noise = map(scnoise(vec3(uv, 0.0) * uRandomThicknessFrequency), -1.0, 1.0, 0., uRandomThicknessMax) * uRandomThicknessStrength;
    float radius;


    if(splineType == 0.0 ) { // ambient spline
        radius = uAmbientSplineThickness + noise;
    } else {
        radius = mix(uSplineThickness, uSplineThickness + noise, step(uRandomThicknessThreshold, randomUV)); // select some of the particles and add some noise to their positions
        radius = mix(radius * uSplineTaper.x, radius * uSplineTaper.y, smoothstep(uFadeOut.x, uFadeOut.y, life)); // taper out the radius
    }

    // decrease spline thickness with life based on the spline type
    // if(splineType == 0.0 ) { // main additional more spread out spline

    //     // reduce noise across the spline
    //     radius = uAmbientSplineThickness + noise;
    //     radius = mix(radius * uAmbientSplineTaper.x, radius * uAmbientSplineTaper.y, life); // taper out the radius

    // } else if(splineType < 0.4) { // main spline

    //     // reduce noise across the spline
    //     noise = mix(noise * uMainSplineNoiseReduction, noise, smoothstep(0.0, 0.65, life));
    //     radius = mix(uSplineThickness, uSplineThickness + noise, step(uRandomThicknessThreshold, randomUV)); // select some of the particles and add some noise to their positions
    //     radius = mix(radius * uMainSplineTaper.x, radius * uMainSplineTaper.y, smoothstep(uDeathMainScaleEnd, uDeathMainScaleStart, life)); // taper out the radius

    // } else if (splineType == 0.5) { // mid splines
    //     // reduce noise across the spline
    //     noise = mix(noise * uMidSplineNoiseReduction, noise, smoothstep(0.0, 0.65, life));
    //     radius = mix(uSplineThickness, uSplineThickness + noise, step(uRandomThicknessThreshold, randomUV)); // select some of the particles and add some noise to their positions
    //     radius = mix(radius * uMidSplineTaper.x, radius * uMidSplineTaper.y, smoothstep(uDeathMidScaleEnd, uDeathMidScaleStart, life)); // taper out the radius

    // } else { // small splines
    //     // reduce noise across the spline
    //     noise = mix(noise * uSmallSplineNoiseReduction, noise, smoothstep(0.0, 0.65, life));
    //     radius = mix(uSplineThickness, uSplineThickness + noise, step(uRandomThicknessThreshold, randomUV)); // select some of the particles and add some noise to their positions
    //     radius = mix(radius * uSmallSplineTaper.x, radius * uSmallSplineTaper.y, smoothstep(uDeathSmallScaleEnd, uDeathSmallScaleStart, life)); // taper out the radius
    // }

    // get the new position to move towards based on the new life
    vec3 target;
    if(uReversed) {
        target = getSplinePosition(1.0 - life);
    } else {
        target = getSplinePosition(life);
    }
    target += offsets * radius;

    if (uCurl) {
        target += curl(currentPos * uCurlSize, uTime * uCurlNoiseSpeed, 0.1 + (1.0 - life) * 0.1) * uCurlStrength;
    }

    #ifdef USE_FLUID
        vec4 worldPos = uModelMatrix * vec4(currentPos.xyz, 1.0);
        vec4 earlyProjection = uProjectionMatrix * uViewMatrix * worldPos;
        vec2 screenSpace = earlyProjection.xy / earlyProjection.w * 0.5 + vec2(0.5);

        vec3 fluidTarget = vec3(0.);
        vec3 fluidPosition = vec3(0.);

        if (uFluidEnabled) {
            fluidPosition = texture2D(tFluid, screenSpace).rgb;
            fluidPosition.z *= -1. + 1.;

            if(uFluidMaskEnabled) fluidPosition *= clamp(texture2D(tFluidMask, screenSpace).r, 0.0, 1.0); // optional masking out the fluid where the mouse affects it // needs clamping ? todo ?
        }

        fluidTarget += fluidPosition.xyz * uFluidStrength;
    #endif

    if (uSetup > 0.5 || life == 1.0) {
        currentPos = target;
    }

    currentPos += (target - currentPos) * lerpSpeed * uNormalizedDelta;

    #ifdef USE_FLUID
        currentPos += (fluidTarget) * uFluidLerpSpeed * uNormalizedDelta; // add fluid separately
    #endif

	gl_FragColor = vec4(currentPos, life);

}