import { AdditiveBlending, CustomBlending, FloatType, InstancedBufferAttribute, InstancedMesh, MathUtils, MultiplyBlending, NoBlending, NormalBlending, Object3D, PlaneGeometry, RepeatWrapping, SubtractiveBlending } from "three"
import createComponent from "./unseen/Component"
import { mergeDeep } from "_utils/index"
import FBOHelper from "_webgl/utils/FBOHelper"
import CursorParticlesMaterial from "_webgl/materials/cursorParticlesMaterial/CursorParticlesMaterial"
import positionSimFrag from "_webgl/materials/cursorParticlesMaterial/positionSimulationFrag.glsl"
import store from "_store"
import { types } from "@theatre/core"

export default class CursorParticles extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			scene: null,
			name: 'CursorParticles',
			particlePlaneScale: 1,
			textureSize: store.isLowTierGPU ? 32 : 64,
			maxCount: store.isLowTierGPU ? 2000 : 4096,
			materialOpts: {
				uniforms: {}
			},
			simulationOpts: {
				numberOfLines: 16,
				uniforms: {
					uDieSpeed: { value: 0.036, gui: { min: 0.000, step: 0.001 } },

					uCurl: { value: true },
					uCurlSize: { value: 0.48, gui: { min: 0.00, step: 0.001 } },
					uCurlNoiseSpeed: { value: 0.04, gui: { min: 0.001, step: 0.001 } },
					uCurlStrength: { value: 0.005, gui: { min: 0.00, step: 0.01 } },
					// uCurlTailLength: { value: 0.76, gui: { min: 0.00, max: 1.0, step: 0.01 } },
					// uCurlTailSmoothness: { value: 0.4, gui: { min: 0, max: 1.0, step: 0.01 } },

					uSpawnRadius: { value: 1, gui: { min: 0.001, step: 0.001 } },
					uSpreadRadius: { value: 1, gui: { min: 0.00, step: 0.1 } },

					uLerpSpeed: { value: 0.015, gui: { min: 0.001, step: 0.001 } }
				}
			}
		}, options)

		super(options)

		/* particle simulation */
		this._textureWidth = this.options.textureSize
		this.PARTICLE_COUNT = this._textureWidth * this._textureWidth

		// set correct count
		this.ACTIVE_PARTICLE_COUNT = this.PARTICLE_COUNT > this.options.maxCount ? this.options.maxCount : this.PARTICLE_COUNT

		this.sizeRange = [0.5, 1.2]

		this._instanceDummy = new Object3D()
		this._debugMeshes = {}
	}

	build() {
		/* build the particles */
		this.mesh = new InstancedMesh(new PlaneGeometry(this.options.particlePlaneScale, this.options.particlePlaneScale), new CursorParticlesMaterial(this.options.materialOpts, this.options.scene), this.ACTIVE_PARTICLE_COUNT)
		this.mesh.frustumCulled = false
		this.mesh.name = this.options.name
		this.add(this.mesh)

		this.initFBOHelper()
		this.initParticlePositions()
	}

	initFBOHelper() {
		this.positionSim = new FBOHelper({
			fragmentShader: positionSimFrag,
			width: this._textureWidth,
			height: this._textureWidth,
			uniforms: {

				uDieSpeed: this.options.simulationOpts.uniforms.uDieSpeed,
				uLerpSpeed: this.options.simulationOpts.uniforms.uLerpSpeed,

				uCurl: this.options.simulationOpts.uniforms.uCurl,
				uCurlSize: this.options.simulationOpts.uniforms.uCurlSize,
				uCurlNoiseSpeed: this.options.simulationOpts.uniforms.uCurlNoiseSpeed,
				uCurlStrength: this.options.simulationOpts.uniforms.uCurlStrength,
				// uCurlTailLength: this.options.simulationOpts.uniforms.uCurlTailLength,
				// uCurlTailSmoothness: this.options.simulationOpts.uniforms.uCurlTailSmoothness,

				uSpawnRadius: this.options.simulationOpts.uniforms.uSpawnRadius,
				uSpreadRadius: this.options.simulationOpts.uniforms.uSpreadRadius,
				...this.options.scene.globalUniforms.mouse
			},
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: true,
			data: this.createPositionData()
		})

		// addTextureDebug(this.options.scene, this._debugMeshes, 'positionSim', this.positionSim.texture, false, true, new Vector3(-1.3, -0.8, -5.5))
	}

	reduceCount() { // reduce the particle count by half
		this.ACTIVE_PARTICLE_COUNT = Math.floor(this.ACTIVE_PARTICLE_COUNT / 2)
		this.mesh.count = this.ACTIVE_PARTICLE_COUNT
	}

	/**
	 * Creates the initial position data for the particles aka their offsets around a point in a sphere
	 * The alpha channel is used for life data
	 * @returns {Float32Array}
	 */
	createPositionData() {
		const data = new Float32Array(this.PARTICLE_COUNT * 4)

		const radius = 1

		let r, phi, theta

		for (let i = 0; i < this.PARTICLE_COUNT; i++) {
			const index = i * 4

			const lineIndex = i % this.options.simulationOpts.numberOfLines

			r = (0.5 + MathUtils.seededRandom(lineIndex) * 0.5) * radius
			phi = (MathUtils.seededRandom(lineIndex + 1) - 0.5) * Math.PI
			theta = MathUtils.seededRandom(lineIndex + 2) * Math.PI * 2

			data[index + 0] = r * Math.cos(theta) * Math.cos(phi)
			data[index + 1] = r * Math.sin(phi)
			data[index + 2] = r * Math.sin(theta) * Math.cos(phi)

			data[index + 3] = MathUtils.seededRandom(lineIndex + 3 + i) // Initial lifespan
		}

		return data
	}

	initParticlePositions() {
		const references = []
		const aRandomArray = []
		const scaleArray = new Float32Array(this.ACTIVE_PARTICLE_COUNT)

		for (let i = 0; i < this.ACTIVE_PARTICLE_COUNT; i++) {
			this._instanceDummy.updateMatrix()
			this.mesh.setMatrixAt(i, this._instanceDummy.matrix)

			const x = (i % this._textureWidth) / this._textureWidth
			const y = ~~(i / this._textureWidth) / this._textureWidth
			references.push(x, y)

			aRandomArray.push(Math.random(), Math.random(), Math.random())

			scaleArray[i] = MathUtils.randFloat(this.sizeRange[0], this.sizeRange[1])
		}

		this.mesh.geometry.setAttribute('reference', new InstancedBufferAttribute(new Float32Array(references), 2))
		this.mesh.geometry.setAttribute('aRandom', new InstancedBufferAttribute(new Float32Array(aRandomArray), 3))
		this.mesh.geometry.setAttribute('aScale', new InstancedBufferAttribute(scaleArray, 1))

		this.mesh.matrixAutoUpdate = false
		this.mesh.updateMatrix()

		// Expand the boundix box to capture the whole spline group
		this.mesh.frustumCulled = false
	}

	animate() {
		/* update the particles simulation */
		this.positionSim?.update()
		this.mesh.material.uniforms.tPosition.value = this.positionSim.texture
	}

	addGui() {
		const config = {
			visible: this.mesh.visible,
			count: types.number(this.mesh.count, { label: 'Particle Count', nudgeMultiplier: 1, range: [0, this.ACTIVE_PARTICLE_COUNT] }),
			material: {
				blending: types.stringLiteral(this.mesh.material.blending, {
					[NoBlending]: 'NoBlending',
					[NormalBlending]: 'NormalBlending',
					[AdditiveBlending]: 'AdditiveBlending',
					[SubtractiveBlending]: 'SubtractiveBlending',
					[MultiplyBlending]: 'MultiplyBlending',
					[CustomBlending]: 'CustomBlending'
				}, { as: 'menu' })
			},
			simulation: {}
		}

		const updateUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.material, this.mesh.material.uniforms)
		const updateSimulationUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.simulation, this.positionSim.uniforms, ['uMouseRadius', 'uMaxParticleCount', 'uPrevMouse', 'uTargetMouse', 'uMouse', 'uSmoothMouse', 'uMouseVelocity', 'uForce', 'uTexelSize'])

		const sheetObject = store.theatre.helper.addSheetObject(this.options.scene.prettyName, 'Cursor Particles', config)

		sheetObject.onValuesChange(values => {
			this.mesh.visible = values.visible
			this.mesh.count = values.count
			this.mesh.material.blending = parseInt(values.material.blending)
			for (let i = 0; i < updateUniformsCallbacks.length; i++) {
				updateUniformsCallbacks[i](this, values.material)
			}
			for (let i = 0; i < updateSimulationUniformsCallbacks.length; i++) {
				updateSimulationUniformsCallbacks[i](this, values.simulation)
			}
		}, store.theatre.rafDriver)
	}

	destroy() {
		super.destroy()
		// console.log(`Destroying CursorParticles`)

		this.positionSim?.destroy()
		this.mesh.geometry.dispose()
		this.mesh.material.dispose()
		this.parent.remove(this)
	}
}