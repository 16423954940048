varying vec2 vUv;

uniform sampler2D tDiffuse; // Rendered screen

uniform float u_LuminosityThreshold;
uniform float uSmoothThreshold;

// Three.js
float luminance( const in vec3 rgb ) {
	// assumes rgb is in linear color space with sRGB primaries and D65 white point
	const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
	return dot( weights, rgb );
}

void main() {
    
    // Thresholing and Downsampling
    // float uBias = -0.8; // Threshold
    // float uScale = 1.;
    // vec4 finalColor = max(vec4(0.0), texel + uBias) * uScale; // Linear thresholding
    // gl_FragColor = finalColor;


    // Smooth thresholing based on luminance
    vec4 texel = texture2D(tDiffuse, vUv);
    float texelLuminance = luminance(texel.rgb); // Calculate luminance of the pixel
    float alpha = smoothstep(u_LuminosityThreshold, u_LuminosityThreshold+uSmoothThreshold, texelLuminance);
    
    vec4 outputColor = vec4(0.0, 0.0, 0.0, 1.0);
    outputColor = mix(outputColor, texel, alpha); // Only let the brightest pixels through

    gl_FragColor = vec4(outputColor.rgb, 1.0);

}