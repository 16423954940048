varying vec2 vUv;

void main()	{
    vUv = uv;

    vec4 transformedPosition = vec4( position, 1.0 );

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;
}