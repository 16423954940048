import gsap from 'gsap'
import { qsa } from "_utils"

export default {
	name: 'storyYear',
	extendTimeline: true,
	onResize: () => {
		// Leave this empty as the splittexts are handled elsewhere, but this animation still needs to be rebuilt each resize
	},
	effect: (targets, config) => {
		let numbers = qsa('.js-year-wrap', targets[0])

		numbers = numbers.reduce((all, one, i) => {
			const ch = Math.floor(i / 3)
			all[ch] = [].concat((all[ch] || []), one)
			return all
		}, [])

		return gsap.timeline({ defaults: config })
			.from(numbers[0], { yPercent: -100, opacity: 0 })
			.from(numbers[1], { yPercent: -100, opacity: 0 }, 0)
			.from(targets[0], { opacity: 0 }, 0)
	},
	defaults: {
		stagger: 0.1,
		duration: 1.2,
		ease: 'joe.inOut'
	}
}
