varying vec2 vUv;

varying vec2 vL;
varying vec2 vR;
varying vec2 vT;
varying vec2 vB;

uniform vec2 uTexelSize;

uniform sampler2D uTexture;
uniform sampler2D tPressure;
uniform sampler2D tDivergence;

vec2 boundary (vec2 uv) {
    return uv;
    // uncomment if you use wrap or repeat texture mode
    
    // uv = min(max(uv, 0.0), 1.0);
    // return uv;
}

void main () {

    float L = texture2D(uTexture, boundary(vL)).x;
    float R = texture2D(uTexture, boundary(vR)).x;
    float T = texture2D(uTexture, boundary(vT)).x;
    float B = texture2D(uTexture, boundary(vB)).x;

    float C = texture2D(uTexture, vUv).x;

    float divergence = texture2D(tDivergence, vUv).x;
    
    float pressure = (L + R + B + T - divergence) * 0.25;

    gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);
}

// // Jacobi
// // coords = grid coordinates
// // rBeta = 1.0 / beta (beta is the diagonal of the matrix ?? ) reciprocal of beta
// // pressureT vector (Ax = b) 
// // b vector (Ax = b)
// vec4 jacobi (vec2 coords, uniform float alpha, uniform float rBeta, sampler2D pressureT, sampler2D divergenceT) {   
//     // left, right, bottom, and top pressure samples    
//     vec4 xL = sampler2D(pressureT, coords - vec2(1, 0));   
//     vec4 xR = sampler2D(pressureT, coords + vec2(1, 0));   
//     vec4 xB = sampler2D(pressureT, coords - vec2(0, 1));   
//     vec4 xT = sampler2D(pressureT, coords + vec2(0, 1)); 
//     // divergenceT sample, from center     
//     vec4 bC = sampler2D(divergenceT, coords); 
//     // evaluate Jacobi iteration   
//     return (xL + xR + xB + xT + alpha * bC) * rBeta; 
// } 