struct DynamicMaterial {
	vec3 diffuseColor;
	float roughness;
	vec3 specularColor;
	float specularF90;
    float ior;
};

struct IncidentLight {
	vec3 color;
	vec3 direction;
	bool visible;
};

struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};

struct GeometricContext {
	vec3 position;
	vec3 normal;
	vec3 viewDir;
};

#include <lights_pars_begin>

varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vTangent;
varying vec3 vBitangent;

uniform float uOpacity;
uniform float uMetalness;
uniform float uSpecularIntensity;
uniform vec3 uEmissiveColor;
uniform vec3 uSpecularColor;

uniform float uDiffuseMatcapBlend;

uniform vec3 uColor;
uniform int uMatcapBlendMode;

#ifdef USE_MATCAP
    uniform sampler2D tMatcap;
#endif

#ifdef USE_DIFFUSE
    uniform float uDiffuseScale;
    uniform sampler2D tDiffuse;
#endif

#ifdef USE_NORMAL
    uniform sampler2D tNormal;
    uniform float uNormalScale;
    uniform float uNormalStrength;
#endif

#ifdef USE_ROUGH
    uniform sampler2D tRoughness;
    uniform float uRoughnessScale;
#endif

#ifdef USE_METALNESS
	uniform sampler2D tMetalness;
    uniform float uMetalnessScale;
#endif

#ifdef USE_EMISSIVEMAP
    uniform sampler2D tEmissiveMap;
#endif

#ifdef USE_LIGHTMAP
    uniform sampler2D tLightMap;
	uniform float uLightMapIntensity;
#endif

varying vec4 vWorldPosition;
uniform float uRoughness;
uniform float uIOR;


#define MAX_UDIMS 2 // Anything more than this hits the limit of the earth textures

#ifdef USE_UDIMS
	uniform sampler2D tDiffuseArray[MAX_UDIMS];
	uniform sampler2D tRoughnessArray[MAX_UDIMS];
	uniform sampler2D tNormalArray[MAX_UDIMS];

	vec4 getUDIMcolor(sampler2D textureArray[MAX_UDIMS], float textureIndx, vec2 udimuv, float textureScale) {
		switch (int(textureIndx)) {
		case 0:
			return texture2D( textureArray[0], udimuv * textureScale );
			break;
		case 1:
			return texture2D( textureArray[1], udimuv * textureScale );
			break;
		// case 2:
		// 	return texture2D( textureArray[2], udimuv * textureScale );
		// 	break;
		// case 3:
		// 	return texture2D( textureArray[3], udimuv * textureScale );
		// 	break;
		}
	}

#endif

#ifdef USE_ENV

    uniform sampler2D tEnvMap;
    uniform float uEnvMapStrength;
    
	vec3 getIBLRadiance( const in vec3 viewDir, const in vec3 normal, const in float roughness ) {
        vec3 reflectVec = reflect( - viewDir, normal );
        // Mixing the reflection with the normal is more accurate and keeps rough objects from gathering light from behind their tangent plane.
        reflectVec = normalize( mix( reflectVec, normal, roughness * roughness) );
        reflectVec = inverseTransformDirection( reflectVec, viewMatrix );
        vec4 envMapColor = textureCubeUV( tEnvMap, reflectVec, roughness ); // Sample the mipmapped image
        return envMapColor.rgb * uEnvMapStrength;
	}

    vec3 getIBLIrradiance( const in vec3 normal ) {

        vec3 worldNormal = inverseTransformDirection( normal, viewMatrix );

        vec4 envMapColor = textureCubeUV( tEnvMap, worldNormal, 1.0 );

        return PI * envMapColor.rgb * uEnvMapStrength;

    }

#endif

vec3 BRDF_Lambert( const in vec3 diffuseColor ) {

	return RECIPROCAL_PI * diffuseColor;

}

void RE_IndirectDiffuse_Physical( const in vec3 irradiance, const in GeometricContext geometry, const in DynamicMaterial material, inout ReflectedLight reflectedLight ) {

	reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );

}

vec2 DFGApprox( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {

	float dotNV = saturate( dot( normal, viewDir ) );

	const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );

	const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );

	vec4 r = roughness * c0 + c1;

	float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;

	vec2 fab = vec2( - 1.04, 1.04 ) * a004 + r.zw;

	return fab;

}

void computeMultiscattering( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {

	vec2 fab = DFGApprox( normal, viewDir, roughness );

	// #ifdef USE_IRIDESCENCE

	// 	vec3 Fr = mix( specularColor, iridescenceF0, iridescence );

	// #else

	vec3 Fr = specularColor;

	// #endif

	vec3 FssEss = Fr * fab.x + specularF90 * fab.y;

	float Ess = fab.x + fab.y;
	float Ems = 1.0 - Ess;

	vec3 Favg = Fr + ( 1.0 - Fr ) * 0.047619; // 1/21
	vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );

	singleScatter += FssEss;
	multiScatter += Fms * Ems;

}

void RE_IndirectSpecular_Physical( const in vec3 radiance, const in vec3 irradiance, const in vec3 clearcoatRadiance, const in GeometricContext geometry, const in DynamicMaterial material, inout ReflectedLight reflectedLight) {

	// Both indirect specular and indirect diffuse light accumulate here

	vec3 singleScattering = vec3( 0.0 );
	vec3 multiScattering = vec3( 0.0 );
	vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;


    computeMultiscattering( geometry.normal, geometry.viewDir, material.specularColor, material.specularF90, material.roughness, singleScattering, multiScattering );

	vec3 totalScattering = singleScattering + multiScattering;
	vec3 diffuse = material.diffuseColor * ( 1.0 - max( max( totalScattering.r, totalScattering.g ), totalScattering.b ) );

	reflectedLight.indirectSpecular += radiance * singleScattering;
	reflectedLight.indirectSpecular += multiScattering * cosineWeightedIrradiance;

	reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;
}


