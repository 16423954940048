varying vec2 vUv;

uniform bool uEnabled;

uniform float uBlurAmount;

uniform sampler2D tDiffuse;
uniform sampler2D tMotionMap;

vec2 rotateUV(vec2 uv, float r, vec2 origin) {
    float c = cos(r);
    float s = sin(r);
    mat2 m = mat2(c, -s, s, c);
    vec2 st = uv - origin;
    st = m * st;
    return st + origin;
}

vec2 scaleUV(vec2 uv, vec2 scale, vec2 origin) {
    vec2 st = uv - origin;
    st /= scale;
    return st + origin;
}

vec2 rotateUV(vec2 uv, float r) {
    return rotateUV(uv, r, vec2(0.5));
}

vec4 motionBlur(vec4 color, vec2 uv) {
    vec2 motionVec = texture2D(tMotionMap, uv).xy * 1.;
    
    // if (length(motionVec) > .5) {
    //     return color;
    // }
    if (length(motionVec) < .03) {
    //   color += vec4(1., 0., 0., 0.);
      return color;
    }

    const int nSamples = 10;
    for (int i = 1; i < nSamples; ++i) {
        // get offset in range [-0.5, 0.5]:
        vec2 offset = motionVec * (float(i) / float(nSamples - 1) - .5);
        
        // sample & add to result:
        color += texture2D(tDiffuse, uv + offset);
    }
    color /= float(nSamples);
    return color;
}

void main() {
    
    vec4 color = texture2D( tDiffuse, vUv );

    if (uEnabled) {

        vec4 texel = texture2D(tMotionMap, vUv);
        texel.xy = rotateUV(texel.xy, radians(0.0));
        vec2 velocity = texel.xy;

        int nSamples = 10;

        // if(length(velocity) > 0.034 && length(velocity) < 0.5) {

            for (int i = 1; i < nSamples; i++) {
                vec2 offset = velocity * (float(i) / float(nSamples-1) - 0.5) * uBlurAmount;
                color += texture2D(tDiffuse, vUv + offset);

            }

            color = color / float(nSamples);

        // }

        // color = motionBlur(color, vUv);
    }
    
    gl_FragColor = color;

}