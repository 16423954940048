#include <default_pars>
#include <default_frag_pars>

uniform sampler2D tDiffuse;
uniform sampler2D tGodrays;
uniform vec3 uColor;
uniform float uIntensity;
uniform float uContrast;

varying vec2 vUv;

void main() {
    vec4 color = texture2D(tDiffuse, vUv);
    vec4 godrays = texture2D(tGodrays, vUv);

    vec3 blend = color.rgb + uColor * godrays.r * uIntensity;
    blend = clamp(blend, 0.0, 1.0);

    gl_FragColor = vec4(blend.rgb, color.a);

    #include <colorspace_fragment>
}