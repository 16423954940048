import WebGL from './WebGL'
import ANatureUntamedScene from './scenes/ANatureUntamedScene'

export default class ANatureUntamedWebGL extends WebGL {
	constructor(el) {
		super(el, {
			renderer: {
				alpha: true
			},
			scenes: {
				'a-nature-untamed': ANatureUntamedScene
			},
			composerPassOrder: {
				'a-nature-untamed': 0
			}
		})
	}

	buildFluidSim() {} // empty to prevent fluid sim from being built
}
