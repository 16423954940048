import store from "_store"
import { E, GlobalEvents, mergeDeep } from "_utils/index"
import { BufferGeometry, CatmullRomCurve3, Color, FloatType, HalfFloatType, InstancedBufferAttribute, InstancedMesh, Line, LineBasicMaterial, MathUtils, Matrix4, Object3D, PlaneGeometry, RGBADepthPacking, RGBAFormat, RepeatWrapping, ShaderMaterial, Sphere, UniformsLib, Vector2, Vector3, WebGLRenderTarget } from "three"
import positionSimulationFrag from "_webgl/materials/vineyardParticlesMaterial/positionSimulationFrag.glsl"
import FBOHelper from "_webgl/utils/FBOHelper"
import { types } from "@theatre/core"
import VineyardParticlesMaterial from "_webgl/materials/vineyardParticlesMaterial/VineyardParticlesMaterial"
import createComponent from "./unseen/Component"

/**
 * A component that creates a particle mesh (InstancedMesh) that follows a spline or a group of splines. Includes self-shadowing and optional per-particle motion blur (only on this component).
 */
export default class VineyardParticleSpline extends createComponent() {
	_debugColors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff]

	constructor(options = {}) {
		super()

		this.options = mergeDeep({
			reversed: false,
			scene: undefined,
			data: {},
			numberOfSplines: 1, // arbitrary fallback, if no data is provided
			name: 'Particle Spline',
			planeScale: 1,
			sizeRange: new Vector2(0.8, 1.25),
			textureWidth: 64,
			maxParticleCount: 5000,
			curveTension: 0.5,
			splineGroupIndex: 0, // Optional index for this group of splines (if using manager with multiple spline groups/instances of this component)
			materialOpts: {
				uniforms: {
					uParticleSize: { value: 1 }
				}
			},
			simulationOpts: {
				uniforms: {
					uShapeThreshold: { value: 0.8 },
					uDecay: { value: 0.1 },
					uCurl: { value: false },
					uCurlSize: { value: 0.2 },
					uCurlNoiseSpeed: { value: 0.5 },
					uCurlStrength: { value: 1.0 },
					uLerpSpeed: { value: 0.07 },
					uSetup: { value: 1.0 },
					uSplineThickness: { value: 1.25 }
				}
			}
		}, options)

		this.options.materialOpts.globalUniforms = options.globalMaterialUniforms
		Object.assign(this.options.simulationOpts.uniforms, options.globalSimulationUniforms)

		this.globalMaterialUniforms = options.globalMaterialUniforms // For GUI exclusion
		this.globalSimulationUniforms = options.globalSimulationUniforms // For GUI exclusion

		this.name = this.options.name

		this._instanceDummy = new Object3D()

		this._textureWidth = this.options.textureWidth
		this.PARTICLE_COUNT = this._textureWidth * this._textureWidth

		// set correct count
		this.ACTIVE_PARTICLE_COUNT = this.PARTICLE_COUNT > this.options.maxParticleCount ? this.options.maxParticleCount : this.PARTICLE_COUNT

		this.sizeRange = [this.options.sizeRange.x, this.options.sizeRange.y]

		this.layers.set(this.options.scene.cameraLayers.particles)

		E.on(GlobalEvents.RESIZE, this.onResize)

		this._debugMeshes = {}
		this._debugSplines = {}

		// Rendering variables for local motion blur
		this._clearColor = new Color()
		this._background = new Color(0x000000)
	}

	build(objectData) {
		this.numberOfSplines = Object.keys(this.options.data).length > 0 ? Object.keys(this.options.data).length : this.options.numberOfSplines
		this.particlesPerSpline = Math.floor(this.PARTICLE_COUNT / this.numberOfSplines)

		this.splineObjects = []

		for (const key in this.options.data) {
			const index = Object.keys(this.options.data).indexOf(key)
			this.buildSplineFromData({ key, index, splineData: this.options.data[key][0], targetArray: this.splineObjects, addHelper: true })
		}

		Object.assign(this.options.materialOpts.uniforms, {
			// for motion blur
			uPrevModelViewMatrix: { value: new Matrix4() },
			tPrevPosition: { value: null }
		})

		this.particleMaterial = new VineyardParticlesMaterial(this.options.materialOpts, this.options.scene)

		this.customDepthMaterial = new ShaderMaterial({
			vertexShader: this.particleMaterial.vertexShader,
			fragmentShader: this.particleMaterial.fragmentShader,
			uniforms: this.particleMaterial.uniforms,
			defines: {
				DEPTH_PACKING: RGBADepthPacking
			}
		})

		this.motionMaterial = new ShaderMaterial({
			vertexShader: this.particleMaterial.vertexShader,
			fragmentShader: this.particleMaterial.fragmentShader,
			uniforms: this.particleMaterial.uniforms,
			defines: {
				MOTION_BLUR: true
			}
		})

		this.mesh = new InstancedMesh(new PlaneGeometry(this.options.planeScale, this.options.planeScale), this.particleMaterial, this.ACTIVE_PARTICLE_COUNT)
		this.mesh.name = this.options.name

		this.mesh.layers.set(this.options.scene.cameraLayers.particles)

		this.mesh.customDepthMaterial = this.customDepthMaterial // Needed for directional light shadows
		// this.mesh.receiveShadow = true
		this.mesh.castShadow = true

		this.initFBOHelper()
		this.initParticlePositions()

		this.particleMaterial.uniforms.tOriginalPosition.value = this.positionSim.baseTexture

		this.preRender()

		this.addGui()
	}

	preRender() {
		this.animate()

		this.positionSim.uniforms.uSetup.value = 0.0
	}

	buildSplineFromData({ key, index, splineData, targetArray, addHelper = false }) {
		const splinePoints = []
		const _v = new Vector3()

		for (let i = 0; i < splineData.length; i++) { // Iterate through all points
			splinePoints.push(_v.fromArray(splineData[i]).clone())
		}

		// Create a curve from the points
		const curve = new CatmullRomCurve3(splinePoints, false, 'catmullrom', this.options.curveTension)

		targetArray.push({ curve, points: splinePoints })

		if (addHelper) this.addSplineHelper(curve, index)
	}

	/**
	 * Creates the initial position data for the particles - extracting the data from the spline objects
     * It also clusters 20% of the particles at the beginning of the spline
	 * The alpha channel is used for a random value between 0 and 1 for each particle
	 * @returns {Float32Array}
	 */
	buildBaseTextureData() {
		const dataArray = new Float32Array(this.PARTICLE_COUNT * 4) // Number of pixels * 4 (RGBA)

		const splinePositions = []
		this.splineObjects.forEach((spline, i) => { // save spline positions
			const points = spline.curve.getSpacedPoints(this.particlesPerSpline - 1)

			// if this.options.reversed is true, copy the points in reverse order
			if (this.options.reversed) {
				points.reverse()
			}

			splinePositions.push(...points)
		})

		for (let i = 0; i < this.PARTICLE_COUNT; i++) {
			const pixelIndex = i * 4 // index in array

			const splineIndex = Math.floor(i / this.particlesPerSpline)

			const clusterSelect = MathUtils.seededRandom(i * 2 * (i + 2)) > 0.8 // Random selection of 20% of the points that will be clustered at the beginning of the spline
			const cluster = MathUtils.lerp(0.0, 0.2, MathUtils.seededRandom(i * 4 * (i + 7))) // cluster particles in the first 20% of the spline

			let position = splinePositions[i]

			if (clusterSelect) {
				const clusterPosition = splinePositions[splineIndex * this.particlesPerSpline + Math.floor(cluster * this.particlesPerSpline)]
				if (clusterPosition) position = clusterPosition
			}

			if (position) {
				dataArray[pixelIndex + 0] = position.x
				dataArray[pixelIndex + 1] = position.y
				dataArray[pixelIndex + 2] = position.z
				// dataArray[pixelIndex + 3] = MathUtils.seededRandom(i * pixelIndex + 56) // Random value between 0 and 1 for each particle
				dataArray[pixelIndex + 3] = MathUtils.seededRandom(i * 3 * (i + 25)) // Random value between 0 and 1 for each particle
			} else {
				dataArray[pixelIndex + 0] = 0
				dataArray[pixelIndex + 1] = 0
				dataArray[pixelIndex + 2] = 0
				dataArray[pixelIndex + 3] = 0
			}
		}

		return dataArray
	}

	addSplineHelper(curve, index) {
		const points = curve.getPoints(50)
		const geometry = new BufferGeometry().setFromPoints(points)

		const splineHelper = new Line(geometry, new LineBasicMaterial({ color: this._debugColors[this.options.splineGroupIndex] }))
		splineHelper.name = `splineHelper-${index}`
		splineHelper.visible = false
		this._debugSplines[index] = splineHelper
		this.add(splineHelper)
	}

	toggleSplineHelpers(visible) {
		for (const key in this._debugSplines) {
			this._debugSplines[key].visible = visible
		}
	}

	onResize = () => {
		if (this.motionRT) {
			this.motionRT.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
			this.motionRT.needsUpdate = true
		}
	}

	buildMotionRT() {
		// Build a render target for the motion blur pass
		this.motionRT = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio(), {
			format: RGBAFormat,
			type: HalfFloatType
		})

		// addTextureDebug(this.options.scene, this._debugMeshes, 'motion', this.motionRT.texture, false, false, new Vector3(0.2, -0.2, -2)) // Optional debug
	}

	initParticlePositions() {
		const references = []
		const aRandomArray = []
		const scaleArray = new Float32Array(this.ACTIVE_PARTICLE_COUNT)

		for (let i = 0; i < this.ACTIVE_PARTICLE_COUNT; i++) {
			this._instanceDummy.updateMatrix()
			this.mesh.setMatrixAt(i, this._instanceDummy.matrix)

			const x = (i % this._textureWidth) / this._textureWidth
			const y = ~~(i / this._textureWidth) / this._textureWidth
			references.push(x, y)

			aRandomArray.push(Math.random(), Math.random(), Math.random())

			scaleArray[i] = MathUtils.randFloat(this.sizeRange[0], this.sizeRange[1])
		}

		this.mesh.geometry.setAttribute('aFboUv', this.positionSim.fboUv.attributeInstanced)
		this.mesh.geometry.setAttribute('reference', new InstancedBufferAttribute(new Float32Array(references), 2))
		this.mesh.geometry.setAttribute('aRandom', new InstancedBufferAttribute(new Float32Array(aRandomArray), 3))
		this.mesh.geometry.setAttribute('aScale', new InstancedBufferAttribute(scaleArray, 1))

		this.mesh.matrixAutoUpdate = false
		this.mesh.updateMatrix()

		// Redo the boundix box to capture the whole spline group
		this.mesh.geometry.computeBoundingBox()
		this.mesh.geometry.computeBoundingSphere()

		this.mesh.geometry.boundingBox.makeEmpty() // Clear the bounding box

		// Combine all spline's points into one bounding box
		const boundingPoints = []
		this.splineObjects.forEach(spline => {
			boundingPoints.push(...spline.points)
		})

		this.mesh.geometry.boundingBox.setFromPoints(boundingPoints) // Set it from points, so it's huge and covers the whole path

		this.mesh.geometry.boundingSphere = new Sphere()
		this.mesh.geometry.boundingBox.getBoundingSphere(this.mesh.geometry.boundingSphere)
		this.mesh.geometry.boundingSphere.radius *= 1.5

		this.add(this.mesh)
	}

	initFBOHelper() {
		this.positionSim = new FBOHelper({
			fragmentShader: positionSimulationFrag,
			uniforms: {
				...this.options.simulationOpts.uniforms
			},
			width: this._textureWidth,
			height: this._textureWidth,
			wrap: RepeatWrapping,
			createTexture: true,
			type: FloatType,
			data: this.buildBaseTextureData()
		})

		this.addExtraSimulationUniforms()
	}

	/*
	** Add extra uniforms to the positionSim
	*/
	addExtraSimulationUniforms() {
		this.positionSim.uniforms.uFluidEnabled = this.options.simulationOpts.uniforms.uFluidEnabled
		this.positionSim.uniforms.uFluidMaskEnabled = this.options.simulationOpts.uniforms.uFluidMaskEnabled
		this.positionSim.uniforms.uModelMatrix = { value: this.mesh.matrixWorld }
		this.positionSim.uniforms.uViewMatrix = { value: this.options.scene.activeCamera.matrixWorldInverse }
		this.positionSim.uniforms.uProjectionMatrix = { value: this.options.scene.activeCamera.projectionMatrix }
		this.positionSim.uniforms.uFluidStrength = this.options.simulationOpts.uniforms.uFluidStrength
		this.positionSim.uniforms.uFluidLerpSpeed = this.options.simulationOpts.uniforms.uFluidLerpSpeed

		if (store.WebGL.fluidSim) {
			this.positionSim.uniforms.tFluid = { value: store.WebGL.fluidSim.advectionSimVelocity.texture }
			this.positionSim.uniforms.tFluidMask = { value: store.WebGL.fluidSim.addForceSimMouse.texture }
		}

		this.positionSim.material.defines.USE_FLUID = true
		this.positionSim.material.needsUpdate = true

		this.positionSim.uniforms.uRandomThicknessStrength = this.options.simulationOpts.uniforms.uRandomThicknessStrength
		this.positionSim.uniforms.uRandomThicknessMax = this.options.simulationOpts.uniforms.uRandomThicknessMax
		this.positionSim.uniforms.uRandomThicknessFrequency = this.options.simulationOpts.uniforms.uRandomThicknessFrequency
		this.positionSim.uniforms.uRandomThicknessThreshold = this.options.simulationOpts.uniforms.uRandomThicknessThreshold

		this.positionSim.uniforms.uSkewThickness = this.options.simulationOpts.uniforms.uSkewThickness || { value: new Vector3(1., 1., 1.) }
	}

	animate() {
		if (!this.mesh.visible) return

		if (store.WebGL.fluidSim && store.WebGL.fluidSim?.enabled) {
			this.positionSim.uniforms.tFluid.value = store.WebGL.fluidSim.advectionSimVelocity.texture
			this.positionSim.uniforms.tFluidMask.value = store.WebGL.fluidSim.addForceSimMouse.texture
		}

		this.positionSim.uniforms.uViewMatrix.value.copy(this.options.scene.activeCamera.matrixWorldInverse)
		this.positionSim.uniforms.uProjectionMatrix.value.copy(this.options.scene.activeCamera.projectionMatrix)

		this.positionSim.update()

		this.particleMaterial.uniforms.tPosition.value = this.positionSim.texture
		this.particleMaterial.uniforms.tPreviousPosition.value = this.positionSim.alternateTexture

		this.customDepthMaterial.uniforms.tPosition.value = this.positionSim.texture

		// Render the particles to the motionRT with the motionMaterial
		this.motionMaterial.uniforms.tPosition.value = this.positionSim.texture
		this.motionMaterial.uniforms.tPrevPosition.value = this.positionSim.alternateTexture
	}

	setMotionMaterial() {
		this.mesh.material = this.motionMaterial
	}

	setParticleMaterial() {
		this.mesh.material = this.particleMaterial
	}

	updateMotionMaterialPrevModelMatrix() {
		this.motionMaterial.uniforms.uPrevModelViewMatrix.value = this.mesh.modelViewMatrix
	}

	addGui() {
		const config = {
			visible: this.mesh.visible,
			count: types.number(this.mesh.count, { label: 'Particle Count', nudgeMultiplier: 1, range: [0, this.ACTIVE_PARTICLE_COUNT] }),
			material: {},
			simulation: {}
		}

		const materialExcludes = [...Object.keys(this.globalMaterialUniforms), ...Object.keys(this.options.scene.globalUniforms.sun), ...Object.keys(this.options.scene.globalUniforms.shadow), ...Object.keys(UniformsLib.lights)]
		const simulationExcludes = ['uTexelSize', ...Object.keys(this.globalSimulationUniforms)]

		const updateUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.material, this.particleMaterial.uniforms, materialExcludes)
		const updateSimUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.simulation, this.positionSim.uniforms, simulationExcludes)
		const sheetObject = store.theatre.helper.addSheetObject(this.options.scene.prettyName, `ParticleSpline: ${this.options.name}`, config, this)

		sheetObject.onValuesChange(values => {
			for (let i = 0; i < updateUniformsCallbacks.length; i++) {
				updateUniformsCallbacks[i](this, values.material)
			}
			for (let i = 0; i < updateSimUniformsCallbacks.length; i++) {
				updateSimUniformsCallbacks[i](this, values.simulation)
			}
			this.mesh.visible = values.visible
			this.mesh.count = values.count
		}, store.theatre.rafDriver)
	}

	destroy() {
		super.destroy()
		// console.log(`Destroying ${this.constructor.name}`)

		E.off(GlobalEvents.RESIZE, this.onResize)

		this.motionMaterial.dispose()
		this.particleMaterial.dispose()
		this.particleMaterial?.uniforms.tOriginalPosition?.value?.dispose()
		this.customDepthMaterial?.uniforms.tPosition?.value?.dispose()
		this.motionMaterial?.uniforms.tPosition?.value?.dispose()
		this.motionMaterial?.uniforms.tPrevPosition?.value?.dispose()
		this.customDepthMaterial.dispose()
		this.positionSim?.destroy()

		for (const key in this._debugMeshes) {
			this._debugMeshes[key].geometry.dispose()
			this._debugMeshes[key].material.dispose()
			this._debugMeshes[key].material?.uniforms.tTexture.value?.dispose()
			this._debugMeshes[key].parent.remove(this._debugMeshes[key])
		}

		for (const key in this._debugSplines) {
			this._debugSplines[key].geometry.dispose()
			this._debugSplines[key].material.dispose()
			this._debugSplines[key].parent.remove(this._debugSplines[key])
		}

		if (this.motionRT) this.motionRT.dispose()
		store.WebGL.motionBlurPass?.material?.uniforms.tMotionMap?.value?.dispose()
	}
}