import { Component } from '_components/unseen'
import { GlobalEvents, qs } from '_utils'

import gsap from 'gsap'

export default class VintageDetails extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-vintage-details'

	lines = {
		poor: [],
		medium: [],
		excellent: []
	}

	lifeStages = []

	activeLifeStagePoint = null

	lineProgress = 0

	isDetailsPageVisible = false

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.circlesGroup = qs('.js-circles-group')
		this.textGroup = qs('.js-line-text-container')

		this.createLinesData()
		this.addEvents()
	}

	createLinesData() {
		if (this.dom.lineGroupArray.length) {
			for (let i = 0; i < this.dom.lineGroupArray.length; i++) {
				const quality = this.dom.lineGroupArray[i].dataset.quality
				const fgLine = qs('.js-line-top', this.dom.lineGroupArray[i])

				this.lines[quality].push({
					group: this.dom.lineGroupArray[i],
					fgLine,
					bgLine: qs('.js-line-bottom', this.dom.lineGroupArray[i]),
					length: fgLine.getTotalLength(),
					stopPercent: JSON.parse(this.dom.lineGroupArray[i].dataset.stopPercent || '[]')
				})
			}
		}
	}

	/**
	 * Timelines.
	 */

	buildEnterTl() {
		this.enterTl = gsap.timeline({
			defaults: {
				ease: 'joe.out',
				duration: 0.5
			},
			onComplete: () => {
				this.emit('VintageSelector:showVintage')
			}
		})
			.fromTo(this.dom.detailsYear, { opacity: 0 }, { opacity: 1 }, 0.2)
			.title(this.dom.dataTitle, {}, 0.3)
			.fromTo(this.dom.backBtnArray, { opacity: 0 }, { opacity: 1 }, 0.8)

		const contentLines = [this.dom.contentLine1Array, this.dom.contentLine2Array, this.dom.contentLine3Array]

		for (let i = 0; i < contentLines.length; i++) {
			this.enterTl.fadeUp(contentLines[i], { duration: 0.5 }, 0.85 + i * 0.1)
		}

		this.enterTl.call(() => {
			this.lineTl.play()
		}, [], '>-0.5')
	}

	buildLineTl() {
		const duration = 1.5
		const activeCircleBtn = this.dom.activeCircleBtn
		const activeCircleMobile = this.dom.activeCircleMobile

		this.gsap.mm((context) => {
			const lifeStages = this.lifeStages.slice()
			activeCircleMobile.classList.remove('is-visible')
			activeCircleBtn.classList.remove('is-visible')

			for (let i = 0; i < lifeStages.length; i++) {
				gsap.set([lifeStages[i].textContainer, lifeStages[i].circle], { autoAlpha: 0 })
			}

			const { sm, md } = context.conditions

			this.lineTl = gsap.timeline({
				paused: true,
				defaults: {
					ease: 'linear',
					duration
				}
			})

			let bottomLine, topLine

			if (md) {
				bottomLine = this.activeLine.bgLine
				topLine = this.activeLine.fgLine
			} else if (sm) {
				bottomLine = this.dom.tabletBottom
				topLine = this.dom.tabletTop
			} else {
				bottomLine = this.dom.mobileBottom
				topLine = this.dom.mobileTop
			}

			const length = topLine.getTotalLength()

			let strokeDashoffsetBegin, strokeDashoffsetEnd, lineDuration

			gsap.set(topLine, { clearProps: 'all' })

			if (md) {
				strokeDashoffsetBegin = length
				strokeDashoffsetEnd = length - this.activeLifeStagePercent * length
				lineDuration = this.activeLifeStagePercent * duration
			} else {
				strokeDashoffsetBegin = length * 1.5
				strokeDashoffsetEnd = length * 2
				lineDuration = duration

				gsap.set(this.dom.mobileLineContainer, { '--opacity': 0 })
			}

			gsap.set(topLine, {
				'stroke-dashoffset': strokeDashoffsetBegin,
				'stroke-dasharray': length
			})

			this.lineTl
				.fromTo(bottomLine, {
					opacity: 0
				}, {
					opacity: 0.3,
					ease: 'joe.out',
					duration: 0.4
				}, 0)
				.addLabel('lineStart')
				.fromTo(topLine, {
					'stroke-dashoffset': strokeDashoffsetBegin
				}, {
					'stroke-dashoffset': strokeDashoffsetEnd,
					duration: lineDuration
				}, 'lineStart')
				.fromTo(this, {
					lineProgress: 0
				}, {
					lineProgress: 1,
					onUpdate: function() {
						const progress = this.progress()

						for (let i = 0; i < lifeStages.length; i++) {
							const lifeStage = lifeStages[i]

							if (progress > lifeStage.percent) {
								gsap.to([lifeStages[i].textContainer, lifeStages[i].circle], { autoAlpha: 1 })
								lifeStages.splice(i, 1)

								if (lifeStage.isActive) {
									activeCircleBtn.classList.add('is-visible')
								}
							}
						}
					}
				}, 'lineStart')

			if (!md) {
				this.lineTl
					.addLabel('lineEnd', '>')
					.fromTo(
						[this.dom.currentLifeStageWording, this.dom.mobileActiveStage],
						{ opacity: 0 },
						{
							opacity: 1,
							duration: 0.3
						},
						'lineEnd'
					)
					.fromTo(
						this.dom.mobileLineContainer,
						{ '--opacity': 0 },
						{
							'--opacity': 1,
							duration: 0.3
						},
						'lineEnd'
					)
					.call(() => {
						activeCircleMobile.classList.add('is-visible')
					}, [], 'lineEnd')
			}
		})
	}

	/**
	 * Utils.
	 */

	fillDetails = (vintage) => {
		if (vintage.life_stage_name) {
			this.dom.dataTitle.innerHTML = vintage.life_stage_name
		}

		if (vintage.year) {
			this.dom.dataYear.innerHTML = vintage.year
		}

		if (vintage.blend) {
			this.dom.dataBlend.innerHTML = vintage.blend
		}

		if (vintage.service_temperature.min && vintage.service_temperature.max) {
			this.dom.dataTemperature.innerHTML = `${vintage.service_temperature.min} - ${vintage.service_temperature.max} °c`
		}

		if (vintage.service_temperature.min && !vintage.service_temperature.max) {
			this.dom.dataTemperature.innerHTML = `${vintage.service_temperature.min} °c`
		}

		if (vintage.harvest_period) {
			this.dom.dataHarvest.innerHTML = vintage.harvest_period
		}

		if (vintage.decanting) {
			this.dom.dataDecanting.innerHTML = vintage.decanting
		}

		if (vintage.life_stage_name_raw) {
			qs('#a-nature-untamed').setAttribute('data-preset', vintage.life_stage_name_raw)
		}

		this.dom.descriptionArray.forEach((el) => {
			if (el.dataset.name === vintage.life_stage_name_raw) {
				el.classList.remove('d-none')
			} else {
				el.classList.add('d-none')
			}
		})

		this.emit('VintageSelector:vintageSelected')
	}

	addLifeStages(vintage) {
		this.activeLine = this.lines[vintage.quality][parseInt(vintage.line_number) - 1]
		this.activeLine.group.removeAttribute('display')

		const length = this.activeLine.length
		this.circlesGroup.innerHTML = ''
		this.textGroup.innerHTML = ''
		this.lifeStages = []

		for (let i = 0; i < vintage.life_stages.length; i++) {
			const lifeStage = vintage.life_stages[i]

			// Because CDM has only 4 life stages, jump the 3rd one
			const stopIndex = (window.is_clos_du_marquis && i >= 2) ? i + 1 : i
			const progress = this.activeLine.stopPercent[stopIndex] / 100

			const point = this.activeLine.fgLine.getPointAtLength(progress * length)
			const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
			circle.setAttribute('class', `vintage__line__circle ${lifeStage.is_active ? 'active' : ''}`)
			circle.setAttribute('cx', point.x)
			circle.setAttribute('cy', point.y)
			this.circlesGroup.appendChild(circle)

			if (lifeStage.is_active) {
				this.activeLifeStagePoint = circle
				this.activeLifeStagePercent = progress
				this.dom.mobileActiveStage.innerHTML = lifeStage.life_stage_name
			}

			const textContainer = document.createElement('div')
			textContainer.setAttribute('class', `vintage__line__text-container ${lifeStage.is_active ? 'active' : ''}`)

			const lifeStageName = document.createElement('div')
			lifeStageName.setAttribute('class', `vintage__line__text | ${lifeStage.is_active ? 'active' : ''}`)
			lifeStageName.innerHTML = lifeStage.life_stage_name

			if (lifeStage.is_active) {
				const currentLifeStageWording = this.dom.currentLifeStageWording.cloneNode(true)
				textContainer.appendChild(currentLifeStageWording)
			}

			textContainer.appendChild(lifeStageName)
			this.textGroup.appendChild(textContainer)

			this.lifeStages.push({ circle, textContainer, percent: progress, isActive: lifeStage.is_active })
		}
	}

	updateTextPosition = () => {
		const { top: svgTop, left: svgLeft } = this.dom.lineSvg.getBoundingClientRect()

		this.lifeStages.forEach(({ circle, textContainer, isActive }) => {
			const { top, left, height, width } = circle.getBoundingClientRect()

			textContainer.style.top = `${top - svgTop + height / 2}px`
			textContainer.style.left = `${left + width / 2 - svgLeft}px`

			if (isActive) {
				const activeCircleHeight = this.dom.activeCircleBtn.offsetHeight
				const activeCircleWidth = this.dom.activeCircleBtn.offsetWidth

				this.dom.activeCircleBtn.style.top = `${top + height / 2 - svgTop - activeCircleHeight / 2}px`
				this.dom.activeCircleBtn.style.left = `${left + width / 2 - svgLeft - activeCircleWidth / 2}px`
			}
		})
	}

	/**
	 * Events.
	 */

	addEvents() {
		this.on('click', this.dom.backBtnArray, this.onBackBtnClick)

		this.on('Vintage:updateDetailsPage', (vintage) => {
			if (this.activeLine) {
				this.activeLine.group.setAttribute('display', 'none')
				this.activeLine = null
			}

			this.activeLifeStagePoint = null
			this.fillDetails(vintage)
			this.addLifeStages(vintage)
			this.updateTextPosition()

			this.buildLineTl()
		})

		this.on('VintageDetails:showDetails', () => {
			this.isDetailsPageVisible = true
			this.buildEnterTl()

			gsap.to(this.el, {
				autoAlpha: 1,
				delay: 0.2,
				onComplete: () => {
					this.enterTl.play()
				}
			})
		})

		this.on(GlobalEvents.RESIZE, () => {
			this.updateTextPosition()
			gsap.set(this.dom.lineSvg, { clearProps: 'x' })

			if (this.isDetailsPageVisible) {
				this.lineTl.pause().progress(1)

				for (let i = 0; i < this.lifeStages.length; i++) {
					gsap.to([this.lifeStages[i].textContainer, this.lifeStages[i].circle], { autoAlpha: 1 })

					if (this.lifeStages[i].isActive) {
						this.dom.activeCircleBtn.classList.add('is-visible')
					}
				}
			}
		})
	}

	onBackBtnClick = () => {
		this.isDetailsPageVisible = false

		gsap.to(this.el, {
			autoAlpha: 0,
			onComplete: () => {
				this.enterTl.pause(0)
				this.dom.activeCircleBtn.classList.remove('is-visible')
				this.dom.activeCircleMobile.classList.remove('is-visible')
			}
		})

		this.emit('VintageSelector:goBackToIntro')
	}
}
