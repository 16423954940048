#include <fog_frag_pars>

varying vec2 vUv;
varying vec4 vWorldPosition;

uniform sampler2D tDiffuse;
uniform vec2 uResolution;

uniform bool uPerspective;

uniform float uFadeEdge;

void main() {

	vec2 uv = vUv;

	vec4 color = texture2D(tDiffuse, uv);

	// fade out colour around edges of UVs
	float edge = uFadeEdge;
	float mask = smoothstep(0.0, edge, uv.x) * smoothstep(1.0, 1.0 - edge, uv.x) * smoothstep(0.0, edge, uv.y) * smoothstep(1.0, 1.0 - edge, uv.y);

	gl_FragColor = vec4(color.rgb, color.a * mask);

	#include <fog_frag>

	#include <colorspace_fragment>
}