import { Mesh, RGBADepthPacking, RepeatWrapping, ShaderMaterial } from 'three'
import createComponent from './unseen/Component'
import store from '_store'
import { mergeDeep } from '_utils/index'
import OutroTerrainMaterial from '_webgl/materials/outroTerrain/OutroTerrainMaterial'

export default class OutroTerrain extends createComponent(Mesh) {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Outro Terrain',
			globalUniforms: {}
		}, options)

		super(options)
	}

	build() {
		this.geometry = this.parent.assets.models.floor.geometry
		this.material = new OutroTerrainMaterial({
			uniforms: {
				tNormal: { value: this.assets.textures.normal }
			},
			globalUniforms: Object.assign({}, { ...this.parent.globalUniforms.sun }, { ...this.parent.globalUniforms.fog }, { ...this.parent.globalUniforms.shadow })
		})

		this.customDepthMaterial = new ShaderMaterial({
			vertexShader: this.material.vertexShader,
			fragmentShader: this.material.fragmentShader,
			uniforms: this.material.uniforms,
			defines: {
				DEPTH_PACKING: RGBADepthPacking
			}
		})

		this.castShadow = true
		this.receiveShadow = true
	}

	load() {
		store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/terrain-normal-01.jpg`, { wrapping: RepeatWrapping }).then(texture => {
			this.assets.textures.normal = texture
		})
	}

	addGui() {
		this.sheetObject = store.theatre.helper.autoAddObject(this, this.parent.prettyName, {
			exclude: [...Object.keys(this.parent.globalUniforms.sun), ...Object.keys(this.parent.globalUniforms.fog), ...Object.keys(this.parent.globalUniforms.shadow)]
		})
	}

	destroy() {
		super.destroy()
		// console.log('Destroying OutroTerrain')
		this.geometry.dispose()
		this.material.dispose()
		this.customDepthMaterial.dispose()

		this.parent.remove(this)
		for (const key in this.assets.textures) {
			this.assets.textures[key].dispose()
		}
	}
}