import { Transition } from '@unseenco/taxi'
import { E, qs, qsa } from '_utils'
import store from '_store'
import gsap from 'gsap'

export default class DefaultTransition extends Transition {
	constructor({ wrapper }) {
		super({ wrapper })

		this.el = qs('.js-transition')
		this.sprite = qs('.js-transition\\:sprite')
		this.headerlinks = qsa('.js-header-link')
	}

	onLeave({ from, trigger, done }) {
		if (this.sprite) {
			this.sprite.classList.add('is-active')
		}

		gsap.to(this.el, {
			autoAlpha: 1,
			duration: 0.8
		}).then(() => {
			done()
		})
	}

	onEnter({ to, trigger, done }) {
		// remove active class from all links while content is still hidden
		this.headerlinks.forEach(link => link.classList.remove('is-active'))

		console.log('DefaultTransition.onEnter')

		store.AssetLoader.loaded.then(() => {
			gsap.timeline()
				.to(this.el, {
					autoAlpha: 0,
					duration: 0.8
				}).call(() => {
					E.emit('transition:enter')
					if (this.sprite) {
						this.sprite.classList.remove('is-active')
					}
					done()
				}, [], .2)
		})
	}
}
