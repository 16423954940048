import store from "_store"
import { CubeReflectionMapping, CubeTexture, EquirectangularReflectionMapping, HalfFloatType, LinearSRGBColorSpace, PMREMGenerator, RGBAFormat, SRGBColorSpace, WebGLCubeRenderTarget } from "three"

export default class PMREMHandler {
	constructor(renderer) {
		this.pmremGenerator = new PMREMGenerator(renderer)
		this.pmremGenerator.compileCubemapShader()
		this.pmremGenerator.compileEquirectangularShader()

		this.textures = []
	}

	/**
	 * Prepares environment maps to be used by Dynamic material, i.e. makes a
	 * PMREM and (optionally) a Cube texture from an equirectangular one. (in this case the texture is stored in the scene render target "envrionmentRT")
	 * @param {Scene} scene
	 * @param {Texture|CubeTexture} texture
	 */
	buildEnvironmentTexture(scene, texture) {
		for (let i = 0; i < this.textures.length; i++) {
			if (this.textures[i].texture === texture) {
				return this.textures[i].pmrem
			}
		}

		const pmrem = this.buildPMREM(scene, texture, false, 'environment')

		this.textures.push({ texture, pmrem })

		return pmrem
	}

	buildFogTexture(texture) {
		for (let i = 0; i < this.textures.length; i++) {
			if (this.textures[i].texture === texture) {
				return this.textures[i].pmrem
			}
		}

		const pmrem = this.createPMREM(texture)

		this.textures.push({ texture, pmrem })

		return pmrem
	}

	buildPMREM(scene, texture, globalRT = true, rtKey) {
		if (texture.isHDR === undefined || texture.isHDR === null) {
			console.error(`Environment texture ${texture.name} is missing .isHDR parameter`)
		}

		let textureToPMREM = texture // Texture to be converted to a PMREM

		if (!(texture instanceof CubeTexture)) {
			// Handle Equirectangular texture first -> Render it to a Cube render target
			textureToPMREM = this.equiToCube(scene, texture, globalRT, rtKey)
		}

		textureToPMREM.name = texture.name

		// Convert the selected texture and return it
		return this.createPMREM(textureToPMREM)
	}

	equiToCube(scene, texture, useGlobalRT = true, rtKey) {
		if (useGlobalRT) {
			if (!scene.cubeRenderTarget) {
				// Create a global Cube RT to be reused if it doesn't exist
				scene.cubeRenderTarget = new WebGLCubeRenderTarget(texture.image.height / 2, { type: HalfFloatType, format: RGBAFormat, colorSpace: texture.isHDR ? LinearSRGBColorSpace : SRGBColorSpace })
			}

			// Render the env map to the target
			scene.cubeRenderTarget.fromEquirectangularTexture(store.WebGL.renderer, texture) // Create the cube texture
			return scene.cubeRenderTarget.texture
		}

		scene[`${rtKey}RT`] = new WebGLCubeRenderTarget(texture.image.height / 2, { type: HalfFloatType, format: RGBAFormat, colorSpace: texture.isHDR ? LinearSRGBColorSpace : SRGBColorSpace })
		scene[`${rtKey}RT`].fromEquirectangularTexture(store.WebGL.renderer, texture) // Create the cube texture
		return scene[`${rtKey}RT`].texture
	}

	/**
	 * Creates a PMREM from a cube map or an equirectangular texture
	 * @param {Texture|CubeTexture} texture
	 */
	createPMREM(texture) {
		if (texture instanceof CubeTexture) {
			const texturePMREM = this.cubeUVfromCubemap(texture)
			texturePMREM.name = `${texture.name} PMREM`
			return texturePMREM
		}
		// Create a PMREM directly from the Equirectangular tex
		const texturePMREM = this.fromEquirectangular(texture)
		texturePMREM.name = `${texture.name} PMREM`
		return texturePMREM
	}

	cubeUVfromCubemap(cubetexture) {
		cubetexture.mapping = CubeReflectionMapping
		const cubeUV = this.pmremGenerator.fromCubemap(cubetexture).texture

		return cubeUV
	}

	fromEquirectangular(texture) {
		texture.mapping = EquirectangularReflectionMapping
		const cubeUV = this.pmremGenerator.fromEquirectangular(texture).texture

		return cubeUV
	}

	generateCubeUVSize(height) {
		const imageHeight = height

		if (imageHeight === null) return null

		const maxMip = Math.log2(height) - 2

		const texelHeight = 1.0 / imageHeight

		const texelWidth = 1.0 / (3 * Math.max(Math.pow(2, maxMip), 7 * 16))

		return { texelWidth, texelHeight, maxMip }
	}

	generateShaderDefines(texture) {
		if (!texture.image) {
			return {
				// Return some default numbers
				CUBEUV_TEXEL_WIDTH: 0.0006510416666666666,
				CUBEUV_TEXEL_HEIGHT: 0.00048828125,
				CUBEUV_MAX_MIP: 9
			}
		}
		const params = this.generateCubeUVSize(texture.image.height)

		return {
			CUBEUV_TEXEL_WIDTH: params.texelWidth,
			CUBEUV_TEXEL_HEIGHT: params.texelHeight,
			CUBEUV_MAX_MIP: params.maxMip
		}
	}
}