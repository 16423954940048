#include <default_pars>
#include <default_frag_pars>
#include <fog_frag_pars>

varying vec2 vUv;
varying vec2 vPosUv;
varying vec3 vNormal;
varying vec3 vViewPosition;
varying vec4 vWorldPosition;

uniform sampler2D tNormal;
uniform sampler2D tPointer;
uniform float uNormalMapRepeat;
uniform float uNormalMapStrength;
uniform float uLowerNormalMapRepeat;
uniform float uLowerNormalMapStrength;
uniform vec3 uColor;
uniform vec3 uAmbientColor;
uniform vec3 uEmissiveColor;
uniform vec3 uSpecularColor;
uniform float uSpecularShininess;
uniform float uSpecularStrength;
uniform vec3 uSunColor;
uniform vec3 uSunDirection;
uniform float uSecondaryLineOffset;
uniform float uSecondaryLineScale;
uniform vec3 uSecondaryLineColor;
uniform vec3 uPrimaryLineColor;
uniform float uPrimaryLineOffset;
uniform float uPrimaryLineScale;

struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};

struct BlinnPhongMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularShininess;
    float specularStrength;
};

struct DirectionalLight {
    vec3 direction;
    vec3 color;
};

void getDirectionalLightInfo( const in DirectionalLight directionalLight, out IncidentLight light ) {
    light.color = directionalLight.color;
    light.direction = directionalLight.direction;
    light.visible = true;
}

vec3 getAmbientLightIrradiance( const in vec3 ambientLightColor ) {
    vec3 irradiance = ambientLightColor;
    return irradiance;
}

vec3 BRDF_Lambert( const in vec3 diffuseColor ) {
    return RECIPROCAL_PI * diffuseColor;
}

vec3 F_Schlick( const in vec3 f0, const in float f90, const in float dotVH ) {
    float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
    return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
}
float F_Schlick( const in float f0, const in float f90, const in float dotVH ) {
    float fresnel = exp2( ( - 5.55473 * dotVH - 6.98316 ) * dotVH );
    return f0 * ( 1.0 - fresnel ) + ( f90 * fresnel );
}

float G_BlinnPhong_Implicit( ) {
    return 0.25;
}
float D_BlinnPhong( const in float shininess, const in float dotNH ) {
    return RECIPROCAL_PI * ( shininess * 0.5 + 1.0 ) * pow( dotNH, shininess );
}
vec3 BRDF_BlinnPhong( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float shininess ) {
    vec3 halfDir = normalize( lightDir + viewDir );
    float dotNH = saturate( dot( normal, halfDir ) );
    float dotVH = saturate( dot( viewDir, halfDir ) );
    vec3 F = F_Schlick( specularColor, 1.0, dotVH );
    float G = G_BlinnPhong_Implicit( );
    float D = D_BlinnPhong( shininess, dotNH );
    return F * ( G * D );
}

void RE_Direct_BlinnPhong( const in IncidentLight directLight, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    float dotNL = saturate( dot( geometryNormal, directLight.direction ) );
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
    reflectedLight.directSpecular += irradiance * BRDF_BlinnPhong( directLight.direction, geometryViewDir, geometryNormal, material.specularColor, material.specularShininess ) * material.specularStrength;
}

void RE_IndirectDiffuse_BlinnPhong( const in vec3 irradiance, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
}

float rand(float n){return fract(sin(n) * 43758.5453123);}

float noise(float p){
	float fl = floor(p);
  float fc = fract(p);
	return mix(rand(fl), rand(fl + 1.0), fc);
}

void main() {
    vec3 normal = normalize(vNormal);

    vec4 pointer = texture2D(tPointer, vPosUv);

    vec4 diffuseColor = vec4( uColor, 1. );

    // triplanar normal matrix
	vec3 T = vec3(0.,1.,0.);
  	vec3 BT = normalize( cross( vNormal, T ) * 1.0 );
  	mat3 tsb = mat3( normalize( T ), normalize( BT ), normalize( vNormal ) );

    // sample normal map
	vec3 normalTexture = texture2D(tNormal, vUv * uNormalMapRepeat).rgb;
	normalTexture = normalTexture * 2.0 - 1.0;
	normalTexture.xy *= uNormalMapStrength;
	normalTexture = normalize( normalTexture );

    vec3 normalTextureLarge = texture2D(tNormal, vUv * uLowerNormalMapRepeat).rgb;
	normalTextureLarge = normalTextureLarge * 2.0 - 1.0;
	normalTextureLarge.xy *= uLowerNormalMapStrength + pointer.r * 0.5;
	normalTextureLarge = normalize( normalTextureLarge );

  	normal = tsb * mix(normalTexture, normalTextureLarge, smoothstep(0.2, 0.3, pointer.r));

    // grey lines
    float lineNoise = noise(max(0., (vWorldPosition.y - uSecondaryLineOffset + 1.) * uSecondaryLineScale) * length(normal));
    lineNoise = lineNoise - noise(max(0., vWorldPosition.y * 1.543));
    diffuseColor.rgb = mix(diffuseColor.rgb, uSecondaryLineColor, lineNoise);
 
    // red line
    lineNoise = smoothstep(0.1, 0., noise( max(-1., (vWorldPosition.y - uPrimaryLineOffset) * uPrimaryLineScale * length(normal))));
    diffuseColor.rgb = mix(diffuseColor.rgb, uPrimaryLineColor, lineNoise);

    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = uEmissiveColor;
    vec3 irradiance = getAmbientLightIrradiance( uAmbientColor );

    IncidentLight directLight;
    DirectionalLight directionalLight;
    directionalLight.direction = normalize( uSunDirection );
    directionalLight.color = uSunColor;
    getDirectionalLightInfo( directionalLight, directLight );

    BlinnPhongMaterial material;
    material.diffuseColor = diffuseColor.rgb;
    material.specularColor = uSpecularColor;
    material.specularShininess = uSpecularShininess;
    material.specularStrength = uSpecularStrength;
    vec3 geometryPosition = - vViewPosition;
    vec3 geometryNormal = normal;
    vec3 geometryViewDir = normalize( vViewPosition );

    RE_Direct_BlinnPhong( directLight, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );
    RE_IndirectDiffuse_BlinnPhong( irradiance, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );

    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

    gl_FragColor = vec4(outgoingLight, 1.);

    // gl_FragColor = vec4(uColor * lightIntensity, 1.);

    // gl_FragColor = vec4(normal, 1.);

    #include <fog_frag>

    #include <colorspace_fragment>
}