attribute vec3 aPosition;
attribute vec3 aRandom;

varying vec2 vUv;
varying float vRandom;
varying vec3 vWorldPosition;
varying float vOpacity;

uniform float uTime;

uniform vec2 uScaleRange;

uniform vec3 uBounds;
uniform float uBoundsFadeThreshold;
uniform float uCameraFadeThresholdNear;
uniform float uCameraFadeThresholdFar;

uniform float uMovementAmplitude;
uniform float uMovementSpeed;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 ambientMovement(vec3 position, vec3 offset, float amplitude, float speed) {
    return vec3(
        sin(position.x + offset.x + uTime * speed * 0.7) * amplitude * 0.7 + cos(position.y + offset.y + uTime * speed * 0.3) * amplitude * 0.2,
        cos(position.y + offset.y + uTime * speed) * amplitude + sin(position.x + offset.x + uTime * speed * 0.5) * amplitude * 0.2,
        sin(position.z + offset.z + uTime * speed * 0.3) * amplitude * 0.5
    );
}

void main()	{
    vUv = uv;
    vRandom = aRandom.x;

    vec4 newPos = vec4(position, 1.);

    // billboard (face camera)
	vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
	vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
	newPos.xyz = right * newPos.x + up * newPos.y;

    // scale variance
    newPos.xyz *= 0.5 * mix(uScaleRange.x, uScaleRange.y, aRandom.y);

    newPos = instanceMatrix * newPos;

    // ambient movement
    newPos.xyz += ambientMovement(aPosition.xyz, aRandom, uMovementAmplitude, map(aRandom.x, 0.0, 1.0, 0.5, 1.0) * uMovementSpeed);

    // loop position inside bounds
    newPos.xyz += mod(aPosition.xyz - cameraPosition, uBounds);
    
    vec4 mvPosition = modelMatrix * newPos;

    // fade opacity around edges of bounds
    vec3 opacity = smoothstep(vec3(0.), vec3(uBoundsFadeThreshold), mvPosition.xyz);
    opacity -= smoothstep(uBounds - uBoundsFadeThreshold, uBounds, mvPosition.xyz);
    vOpacity = opacity.x * opacity.y * opacity.z;

    // pin particles to camera
    // (set Z multiplier to 1 to put in front of the camera if always facing forward in the scene to show more particles at a time)
    mvPosition.xyz += cameraPosition - uBounds * vec3(0.5, 0.5, 0.5);

    // fade around camera
    float distFromCamera = distance(mvPosition.xyz, cameraPosition);
    vOpacity *= smoothstep(uCameraFadeThresholdNear, uCameraFadeThresholdFar, distFromCamera);

    mvPosition = viewMatrix * mvPosition;

 	gl_Position = projectionMatrix * mvPosition;

}