import { notify } from '@theatre/core'
import store from '_store'
import GlobalEvents from './GlobalEvents'
import { E } from '.'

const fetchUrl = store.env === 'development' ? `//${import.meta.env.VITE_SERVER_HOST}/${import.meta.env.VITE_THEATRE_LOCATION}` : `https://${import.meta.env.VITE_THEATRE_STAGING_URL}/${import.meta.env.VITE_THEATRE_LOCATION}`

export default function createExtension(projectId, url) {
	const getToolsetConfig = studio => {
		const toolbarConfig = [
			{
				type: 'Icon',
				title: 'Reset State',
				svgSource: '<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>',
				onClick: a => {
					if (confirm('Revert all changes?')) {
						studio.__experimental.__experimental_clearPersistentStorage(store.theatre.persistenceKey)
						localStorage.removeItem(store.theatre.persistenceKey + '.persistent')
						localStorage.removeItem(store.theatre.persistenceKey + '.openRTViewerPanes')
						window.location.reload()
					}
				}
			},
			{
				type: 'Icon',
				title: 'Save',
				svgSource: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>',
				onClick: a => {
					const name = window.prompt('Save as: ', 'state')

					if (!name) return

					const data = store.theatre.studio.createContentOfSaveFile(store.theatre.project.address.projectId)

					fetch(`${fetchUrl}?save`, {
						method: 'POST',
						body: JSON.stringify({
							name,
							projectId,
							data
						})
					}).then(response => {
						response.json().then(result => {
							if (result) {
								notify.success('Saved Project', 'Project has been saved!')
							}
						})
					}, error => {
						console.error(error)
					})
				}
			},
			{
				type: 'Icon',
				title: 'Versions',
				svgSource: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>',
				onClick: a => {
					studio.createPane('unseen-versions')
				}
			}
		]

		if (store.env === 'development' || store.env === 'browsersync') {
			// only add the publish option when in development mode
			toolbarConfig.push({
				type: 'Icon',
				title: 'Publish Current Version',
				svgSource: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>',
				onClick: a => {
					const data = store.theatre.studio.createContentOfSaveFile(store.theatre.project.address.projectId)

					if (confirm('This will overwrite the published version. Are you sure?')) {
						fetch(`${fetchUrl}?publish`, {
							method: 'POST',
							body: JSON.stringify({
								projectId,
								data
							})
						}).then(response => {
							response.json().then(result => {
								console.log(result)
								if (result) {
									notify.success('Published Project', 'Project has been published!')
								}
							})
						}, error => {
							console.error(error)
						})
					}
				}
			})
		}

		if (store.WebGL?.activeScene?.devTools) {
			// orbit cam toggle
			toolbarConfig.push({
				type: 'Switch',
				value: store.WebGL.activeScene.devTools.enabled,
				onChange: value => {
					store.theatre.studio.transaction(({ set }) => {
						set(store.theatre.studioObjects[store.WebGL.activeScene.prettyName]['Dev Tools'].props.enabled, !store.WebGL.activeScene.devTools.enabled)
					})
				},
				options: [
					{
						value: true,
						label: 'Toggle Orbit Cam',
						svgSource: `<svg fill="currentColor" viewBox="0 -4 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M1450,258l-5-2v2a2,2,0,0,1-2,2h-13a2,2,0,0,1-2-2V246a2,2,0,0,1,2-2h13a2,2,0,0,1,2,2v2l5-2a2,2,0,0,1,2,2v8A2,2,0,0,1,1450,258Zm-7-4v-8h-13v12h13v-4Zm7-6-5,2v4l5,2v-8Z" transform="translate(-1428 -244)"/>
					</svg>`
					}
				]
			})
		}

		toolbarConfig.push({
			type: 'Switch',
			value: store.theatre.previewCamOpen,
			onChange: value => {
				if (store.theatre.previewCamOpen) return
				studio.createPane('unseen-preview-cam')
			},
			options: [
				{
					value: true,
					label: 'Show Preview Cam',
					svgSource: `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 40.3901C11 44.1666 14.0779 47.2445 17.8544 47.2445H44.2269C48.0034 47.2445 51.0813 44.1666 51.0813 40.3901L51.0838 24.6006C51.0838 20.824 48.0059 17.7461 44.2294 17.7461H40.5617L39.4201 15.9642C38.6355 14.734 37.2939 14 35.8435 14H26.0732C24.6152 14 23.2813 14.7315 22.4966 15.9642L21.355 17.7461H17.862C14.0855 17.7461 11.0076 20.8241 11.0076 24.6006V40.3901H11ZM13.592 24.6006C13.592 22.2491 15.503 20.338 17.8545 20.338H22.765L24.671 17.3563C24.9747 16.8779 25.5012 16.5918 26.0631 16.5918H35.8335C36.403 16.5918 36.9219 16.8778 37.2256 17.3563L39.1316 20.338H44.2194C46.5708 20.338 48.4819 22.2491 48.4819 24.6006V40.3849C48.4819 42.7364 46.5708 44.6474 44.2194 44.6474H17.8469C15.4954 44.6474 13.5844 42.7364 13.5844 40.3849L13.5869 24.6006H13.592Z" fill="currentColor" stroke="currentColor"/>
					<path d="M30.8314 40.6629C35.6989 40.6629 39.6629 36.704 39.6629 31.8314C39.6629 26.9639 35.704 23 30.8314 23C25.9589 23 22 26.9588 22 31.8314C21.9975 36.704 25.9563 40.6629 30.8314 40.6629ZM30.8314 25.5971C34.2713 25.5971 37.0708 28.3966 37.0708 31.8365C37.0708 35.2765 34.2713 38.076 30.8314 38.076C27.3915 38.076 24.592 35.2765 24.592 31.8365C24.5895 28.3966 27.389 25.5971 30.8314 25.5971Z" fill="currentColor" stroke="currentColor"/>
					<path d="M2.67939 23.1374C3.51909 23.1374 4.28245 22.4504 4.28245 21.5344V4.20611H21.6107C22.4504 4.20611 23.2137 3.51908 23.2137 2.60305C23.2137 1.68702 22.5267 1 21.6107 1H1.07634V21.458C1.07634 22.374 1.76336 23.1374 2.67939 23.1374Z" fill="currentColor" stroke="currentColor"/>
					<path d="M40.8474 1.07633C40.0077 1.07633 39.2443 1.76336 39.2443 2.67939C39.2443 3.59542 39.9313 4.28244 40.8474 4.28244H58.2519V21.6107C58.2519 22.4504 58.939 23.2137 59.855 23.2137C60.771 23.2137 61.458 22.5267 61.458 21.6107V1.07633H40.8474Z" fill="currentColor" stroke="currentColor"/>
					<path d="M21.6107 57.7939H4.20611V40.4656C4.20611 39.626 3.51908 38.8626 2.60305 38.8626C1.68702 38.8626 1 39.5496 1 40.4656V60.9237H21.6107C22.4504 60.9237 23.2137 60.2366 23.2137 59.3206C23.2137 58.4046 22.4504 57.7939 21.6107 57.7939Z" fill="currentColor" stroke="currentColor"/>
					<path d="M59.7787 38.8626C58.939 38.8626 58.1756 39.5496 58.1756 40.4656V57.7939H40.8474C40.0077 57.7939 39.2443 58.4809 39.2443 59.3969C39.2443 60.313 39.9313 61 40.8474 61H61.3054V40.4656C61.3817 39.626 60.6947 38.8626 59.7787 38.8626Z" fill="currentColor" stroke="currentColor"/>
					</svg>`
				}
			]
		})

		toolbarConfig.push({
			type: 'Icon',
			title: 'Preview Render Target',
			svgSource: `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M42.2293 0H1.77073C0.79293 0 0 0.79293 0 1.77073V42.2288C0 43.2071 0.79293 43.9995 1.77073 43.9995H42.2293C43.2076 43.9995 44 43.2066 44 42.2288V1.77073C44 0.79293 43.2071 0 42.2293 0ZM40.4585 40.4581H3.54146V3.54195H40.4581L40.4585 40.4581Z" fill="currentColor"/>
			<path d="M17.0296 7.07653H7.08046V17.0257H17.0296V7.07653Z" fill="currentColor"/>
			<path d="M26.9787 17.0257H17.0296V26.9748H26.9787V17.0257Z" fill="currentColor"/>
			<path d="M36.9279 26.9743H26.9787V36.9235H36.9279V26.9743Z" fill="currentColor"/>
			<path d="M36.9279 7.07653H26.9787V17.0257H36.9279V7.07653Z" fill="currentColor"/>
			<path d="M17.0296 26.9743H7.08046V36.9235H17.0296V26.9743Z" fill="currentColor"/>
			</svg>`,
			onClick: a => {
				studio.createPane('unseen-preview-render-target')
			}
		})

		toolbarConfig.push({
			type: 'Switch',
			value: store.WebGL.stats.visible,
			onChange: value => {
				store.theatre.studio.transaction(({ set }) => {
					set(store.theatre.studioObjects.Unseen.Settings.props.showStats, !store.WebGL.stats.visible)
				})
			},
			options: [
				{
					value: true,
					label: 'Toggle Stats',
					svgSource: `<svg width="80" height="50" viewBox="0 0 80 50" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M44.2427 42.6127C39.8757 46.1878 33.4079 42.4536 34.3206 36.8842C34.532 35.5937 35.1015 34.3885 35.9641 33.4056L56.3606 10.167L46.4335 39.4501C46.0136 40.6886 45.2546 41.7843 44.2427 42.6127ZM29.3864 36.0757C27.7285 46.1926 39.4774 52.9758 47.41 46.4816C49.1461 45.0603 50.4484 43.1803 51.1687 41.0554L63.5119 4.64551C64.741 1.01991 60.092 -1.66419 57.5667 1.21301L32.2063 30.1074C30.7262 31.7937 29.7492 33.8615 29.3864 36.0757ZM40 8.59171C20.67 8.59171 5 24.2618 5 43.5917C5 44.9724 3.8807 46.0917 2.5 46.0917C1.11929 46.0917 0 44.9724 0 43.5917C0 21.5003 17.9086 3.59171 40 3.59171C41.6223 3.59171 43.2232 3.68841 44.7969 3.87661C46.1678 4.04061 47.1463 5.28491 46.9823 6.65581C46.8184 8.02681 45.5741 9.00521 44.2031 8.84131C42.826 8.67661 41.4235 8.59171 40 8.59171ZM63.6624 14.3916C64.6014 13.3793 66.1832 13.3199 67.1954 14.2588C75.0679 21.5608 80 32.0022 80 43.5917C80 44.9724 78.8807 46.0917 77.5 46.0917C76.1193 46.0917 75 44.9724 75 43.5917C75 33.4507 70.6903 24.3201 63.7952 17.9247C62.7829 16.9857 62.7235 15.4039 63.6624 14.3916Z" fill="currentColor"/>
					</svg>`
				}
			]
		})

		if (store.WebGL?.activeScene?.devTools?.enabled) {
			toolbarConfig.push({
				type: 'Switch',
				value: store.WebGL.activeScene.devTools.grid.enabled,
				onChange: value => {
					store.theatre.studio.transaction(({ set }) => {
						set(store.theatre.studioObjects[store.WebGL.activeScene.prettyName]['Dev Tools'].props.grid, !store.WebGL.activeScene.devTools.grid.enabled)
					})
				},
				options: [
					{
						value: true,
						label: 'Toggle Grid',
						svgSource: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M1.00285 19.9985L19.9985 19.9971L19.9971 1.00147L1.00147 1.00285L1.00285 19.9985ZM0.998746 0H20.0013C20.5528 0 21 0.438615 21 0.998746V20.0013C21 20.5528 20.5614 21 20.0013 21H0.998746C0.447154 21 0 20.5614 0 20.0013V0.998746C0 0.447154 0.438615 0 0.998746 0Z" fill="currentColor"/>
						<path d="M6 0H5V21H6V0Z" fill="currentColor"/>
						<path d="M11 0H10V21H11V0Z" fill="currentColor"/>
						<path d="M16 0H15V21H16V0Z" fill="currentColor"/>
						<path d="M21 10H0V11H21V10Z" fill="currentColor"/>
						<path d="M21 5H0V6H21V5Z" fill="currentColor"/>
						<path d="M21 15H0V16H21V15Z" fill="currentColor"/>
						</svg>`
					}
				]
			})

			toolbarConfig.push({
				type: 'Switch',
				value: store.WebGL.activeScene.devTools.options.helpers,
				onChange: value => {
					store.theatre.studio.transaction(({ set }) => {
						set(store.theatre.studioObjects[store.WebGL.activeScene.prettyName]['Dev Tools'].props.helpers, !store.WebGL.activeScene.devTools.options.helpers)
					})
				},
				options: [
					{
						value: true,
						label: 'Toggle Helpers',
						svgSource: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2 3C2 2.44772 2.44772 2 3 2H8.5C9.05229 2 9.5 1.55228 9.5 1C9.5 0.447715 9.05229 0 8.5 0H3C1.34315 0 0 1.34315 0 3V8.5C0 9.05229 0.447715 9.5 1 9.5C1.55228 9.5 2 9.05229 2 8.5V3Z" fill="currentColor"/>
						<path d="M23.5 0C22.9477 0 22.5 0.447715 22.5 1C22.5 1.55228 22.9477 2 23.5 2H29C29.5523 2 30 2.44772 30 3V8.5C30 9.05229 30.4477 9.5 31 9.5C31.5523 9.5 32 9.05229 32 8.5V3C32 1.34315 30.6569 0 29 0H23.5Z" fill="currentColor"/>
						<path d="M2 23.5C2 22.9477 1.55228 22.5 1 22.5C0.447715 22.5 0 22.9477 0 23.5V29C0 30.6569 1.34315 32 3 32H8.5C9.05229 32 9.5 31.5523 9.5 31C9.5 30.4477 9.05229 30 8.5 30H3C2.44772 30 2 29.5523 2 29V23.5Z" fill="currentColor"/>
						<path d="M32 23.5C32 22.9477 31.5523 22.5 31 22.5C30.4477 22.5 30 22.9477 30 23.5V29C30 29.5523 29.5523 30 29 30H23.5C22.9477 30 22.5 30.4477 22.5 31C22.5 31.5523 22.9477 32 23.5 32H29C30.6569 32 32 30.6569 32 29V23.5Z" fill="currentColor"/>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M4.65826 21.0602L10.3238 19L4.65826 16.9398C4.26307 16.7961 4 16.4205 4 16C4 15.5795 4.26307 15.2039 4.65826 15.0602L10.3238 13L4.65826 10.9398C4.26307 10.7961 4 10.4205 4 10C4 9.57949 4.26307 9.20391 4.65826 9.06021L15.6583 5.06021C15.879 4.97993 16.121 4.97993 16.3417 5.06021L27.3417 9.06021C27.7369 9.20391 28 9.57949 28 10C28 10.4205 27.7369 10.7961 27.3417 10.9398L21.6762 13L27.3417 15.0602C27.7369 15.2039 28 15.5795 28 16C28 16.4205 27.7369 16.7961 27.3417 16.9398L21.6762 19L27.3417 21.0602C27.7369 21.2039 28 21.5795 28 22C28 22.4205 27.7369 22.7961 27.3417 22.9398L16.3417 26.9398C16.121 27.0201 15.879 27.0201 15.6583 26.9398L4.65826 22.9398C4.26307 22.7961 4 22.4205 4 22C4 21.5795 4.26307 21.2039 4.65826 21.0602ZM18.75 14.0641L16.3417 14.9398C16.121 15.0201 15.879 15.0201 15.6583 14.9398L13.25 14.0641L7.92618 16L16 18.9359L24.0738 16L18.75 14.0641ZM7.92618 22L13.25 20.0641L15.6583 20.9398C15.879 21.0201 16.121 21.0201 16.3417 20.9398L18.75 20.0641L24.0738 22L16 24.9359L7.92618 22ZM16 12.9359L7.92618 10L16 7.06406L24.0738 10L16 12.9359Z" fill="currentColor"/>
						</svg>`
					}
				]
			})
		}

		if (store.WebGL?.activeScene?.devTools?.enabled && store.WebGL?.activeScene?.devTools?.transformControls.enabled) {
			toolbarConfig.push({
				type: 'Switch',
				value: store.WebGL.activeScene.devTools.transformControls.mode,
				onChange: value => {
					store.WebGL.activeScene.devTools.transformControls.setMode(value)
					store.theatre.updateExtensionToolbar()
				},
				options: [
					{
						value: 'translate',
						label: 'Translate',
						svgSource: '<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"/></svg>'
					},
					{
						value: 'rotate',
						label: 'Rotate',
						svgSource: '<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/></svg>'
					},
					{
						value: 'scale',
						label: 'Scale',
						svgSource: '<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg>'
					}
				]
			})

			toolbarConfig.push({
				type: 'Switch',
				value: store.WebGL.activeScene.devTools.transformControls.space,
				onChange: value => {
					store.WebGL.activeScene.devTools.transformControls.setSpace(value)
					store.theatre.updateExtensionToolbar()
				},
				options: [
					{
						value: 'world',
						label: 'World Transform',
						svgSource: '<svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M90 44.7C89.9 33.3 85.6 21.9 76.8 13.2C68.3 4.7 57 0 45 0C33 0 21.7 4.7 13.2 13.2C4.5 21.9 0.1 33.3 0 44.7C0 44.8 0 44.9 0 45C0 45.1 0 45.2 0 45.3C0.1 56.7 4.4 68.1 13.2 76.8C21.7 85.3 33 90 45 90C57 90 68.3 85.3 76.8 76.8C85.5 68.1 89.9 56.7 90 45.3C90 45.2 90 45.1 90 45C90 44.9 90 44.8 90 44.7ZM74.9 71.5C72.2 69.1 69.3 67.1 66.2 65.4C67.6 60 68.5 53.9 68.7 47.5H84.9C84.4 56.1 81 64.6 74.9 71.5ZM5.1 47.5H21.3C21.5 54 22.4 60 23.8 65.4C20.7 67 17.8 69.1 15.1 71.5C9 64.6 5.6 56.1 5.1 47.5ZM15.1 18.5C17.8 20.9 20.7 22.9 23.8 24.6C22.3 30 21.5 36 21.3 42.5H5.1C5.6 33.9 9 25.4 15.1 18.5ZM42.5 24.8C38.2 24.5 33.9 23.6 29.8 21.9C32.8 13.1 37.3 6.9 42.5 5.4V24.8ZM42.5 29.8V42.5H26.3C26.5 36.9 27.2 31.5 28.4 26.7C32.9 28.5 37.7 29.5 42.5 29.8ZM42.5 47.5V60.2C37.7 60.5 32.9 61.5 28.4 63.3C27.2 58.5 26.5 53.1 26.3 47.5H42.5ZM42.5 65.2V84.6C37.3 83.1 32.8 76.8 29.8 68.1C33.9 66.4 38.2 65.5 42.5 65.2ZM47.5 65.2C51.8 65.5 56.1 66.4 60.2 68.1C57.2 76.9 52.7 83.1 47.5 84.6V65.2ZM47.5 60.2V47.5H63.7C63.5 53.1 62.8 58.5 61.6 63.3C57.1 61.5 52.3 60.5 47.5 60.2ZM47.5 42.5V29.8C52.3 29.5 57.1 28.5 61.6 26.7C62.8 31.5 63.5 36.9 63.7 42.5H47.5ZM47.5 24.8V5.4C52.7 6.9 57.2 13.2 60.2 21.9C56.1 23.6 51.8 24.5 47.5 24.8ZM58 7.2C62.9 8.9 67.4 11.5 71.4 15C69.3 16.8 67.1 18.4 64.8 19.7C63 14.7 60.7 10.4 58 7.2ZM25.3 19.7C23 18.4 20.7 16.8 18.7 15C22.7 11.5 27.2 8.9 32.1 7.2C29.3 10.4 27 14.7 25.3 19.7ZM25.3 70.3C27.1 75.3 29.4 79.5 32 82.8C27.1 81.1 22.6 78.5 18.6 75C20.7 73.2 22.9 71.6 25.3 70.3ZM64.7 70.3C67 71.6 69.3 73.2 71.3 75C67.3 78.5 62.8 81.1 57.9 82.8C60.7 79.6 63 75.3 64.7 70.3ZM68.7 42.5C68.5 36 67.7 30 66.2 24.6C69.3 23 72.2 20.9 74.9 18.5C81 25.4 84.3 33.9 84.9 42.5H68.7Z" fill="currentColor"/></svg>'
					},
					{
						value: 'local',
						label: 'Local Transform',
						svgSource: '<svg viewBox="0 0 100 92" xmlns="http://www.w3.org/2000/svg"><path d="M99.867 85.986C99.969 85.685 100.015 85.364 99.996 85.037C99.986 84.871 99.96 84.703 99.915 84.536L99.914 84.534L96.015 69.978C95.658 68.643 94.28 67.857 92.953 68.209C91.619 68.566 90.827 69.938 91.184 71.271L93.539 80.063L52.416 56.321V8.95L58.807 15.341C59.295 15.829 59.935 16.073 60.575 16.073C61.215 16.073 61.854 15.829 62.343 15.341C63.32 14.365 63.32 12.782 62.343 11.806L51.684 1.147C50.707 0.169996 49.124 0.169996 48.149 1.147L37.49 11.806C36.513 12.782 36.513 14.365 37.49 15.341C37.978 15.829 38.618 16.073 39.258 16.073C39.898 16.073 40.538 15.829 41.026 15.341L47.417 8.95V56.321L6.49499 79.946L8.83799 71.203C9.19499 69.869 8.40399 68.498 7.06999 68.14C5.73599 67.786 4.36499 68.575 4.00799 69.908L0.136993 84.354C-0.0800068 85.008 -0.0370068 85.746 0.334993 86.389C0.720993 87.057 1.37199 87.462 2.07799 87.584L16.434 91.43C16.651 91.488 16.869 91.516 17.082 91.516C18.186 91.516 19.196 90.779 19.496 89.662C19.853 88.329 19.061 86.958 17.728 86.6L9.01299 84.265L49.916 60.651L90.96 84.348L82.294 86.67C80.961 87.027 80.169 88.399 80.526 89.733C80.826 90.849 81.836 91.587 82.939 91.587C83.154 91.587 83.372 91.559 83.588 91.501L98.147 87.6C98.908 87.396 99.483 86.857 99.778 86.187C99.806 86.124 99.841 86.064 99.864 85.999C99.865 85.994 99.865 85.99 99.867 85.986Z" fill="currentColor"/></svg>'
					}
				]
			})
		}

		return toolbarConfig
	}

	const config = {
		id: 'unseen-extension',
		toolbars: {
			global(set, studio) {
				store.theatre.studioSheets.Unseen = store.theatre.studioProject.sheet('Unseen')

				const sheetObject = store.theatre.helper.addStudioSheetObject('Unseen', 'Settings', {
					showStats: store.WebGL.stats.visible
				})

				store.theatre.updateExtensionToolbar = () => {
					set(getToolsetConfig(studio))
				}
				store.theatre.updateExtensionToolbar()

				sheetObject.onValuesChange(values => {
					if (store.WebGL.stats) {
						values.showStats ? store.WebGL.showStats() : store.WebGL.hideStats()
					}

					store.theatre.updateExtensionToolbar()
				})
			},
			previewCamToolbar(set, studio) {
				set([
					{
						type: 'Icon',
						title: 'Toggle Wireframe',
						svgSource: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z"></path></svg>',
						onClick: () => { }
					}
				])
				return () => { }
			}
		},
		panes: [
			{
				class: 'unseen-versions',
				mount: async ({ paneId, node }) => {
					node.style.overflowY = 'auto'

					const className = 'unseen-versions-wrapper'
					const styleTag = document.createElement('style')

					const style = `
						.${className} {
							padding: 0.3rem;
							font-size: 0.7rem;
						}
	
						.${className} li {
							margin-bottom: 0.5rem;
						}
	
						.${className} a {
							color: white;
						}
	
						.${className} .list-wrap {
							display: flex;
							flex-direction: column;
							width: 100%;
						}
	
						.${className} .list-wrap > div {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0.2rem 0.4rem;
						}
	
						.${className} .list-wrap > div.published {
							padding: 0.4rem 0.4rem;
							border-bottom: 1px solid #888;
						}
	
						.${className} .list-wrap > div.active {
							background-color: rgb(85, 167, 113, 0.2);
						}
	
						.${className} .list-wrap > div:hover {
							background-color: rgba(255, 255, 255, 0.2);
						}
	
						.${className} .list-wrap small {
							font-size: 0.6rem;
							color: #aaa;
						}
	
						.${className} .list-wrap .btn {
							display: inline-block;
							appearance: none;
							padding: 0.3rem 0.4rem;
							background: #676767;
							border: 1px solid rgba(0, 0, 0, 0.5);
							font-size: 0.5rem;
							text-transform: uppercase;
							font-weight: 600;
							cursor: pointer;
							color: #fff;
							text-decoration: none;
						}
	
						.${className} .list-wrap .btn:hover {
							opacity: 0.7;
						}
	
						.${className} .list-wrap .btn--download {
							background: #286a2e;
						}
	
						.${className} .list-wrap .btn--delete {
							background: #6a2835;
						}
	
						.${className} .list-wrap .btn--rename {
							background: #276b81;
						}
					`

					styleTag.appendChild(document.createTextNode(style))
					node.appendChild(styleTag)

					const wrapperEl = document.createElement('div')
					wrapperEl.classList.add(className)
					node.appendChild(wrapperEl)

					const onViewClick = e => {
						// prevent Theatre from overwriting the state when reloading the page
						window.addEventListener('beforeunload', () => {
							store.theatre.studio.__experimental.__experimental_clearPersistentStorage(store.theatre.persistenceKey)
							localStorage.removeItem(store.theatre.persistenceKey + '.persistent')
						})
					}

					const onDownloadClick = e => {
						const filename = e.target.dataset.file

						// fetch the data from the staging server
						fetch(`https://${import.meta.env.VITE_THEATRE_STAGING_URL}/${import.meta.env.VITE_THEATRE_LOCATION}?download`, {
							method: 'POST',
							body: JSON.stringify({
								filename,
								projectId
							})
						}).then(response => {
							response.json().then(result => {
								if (result) {
									// save the data locally
									fetch(`${fetchUrl}?save`, {
										method: 'POST',
										body: JSON.stringify({
											name: filename.replace('.json', ''),
											projectId,
											data: JSON.parse(result)
										})
									}).then(response => {
										response.json().then(result => {
											if (result) {
												notify.success('Downloaded Project', 'Project has been downloaded!')
												fetchData()
											}
										})
									}, error => {
										console.error(error)
									})
								}
							})
						}, error => {
							console.error(error)
						})
					}

					const onDeleteClick = e => {
						if (confirm('Are you sure?')) {
							fetch(`${fetchUrl}?delete`, {
								method: 'POST',
								body: JSON.stringify({
									file: e.target.dataset.file,
									projectId
								})
							}).then(response => {
								response.json().then(result => {
									console.log(result)
									if (result) {
										notify.success('Deleted Project', 'Project has been deleted!')
										fetchData()
									}
								})
							}, error => {
								console.error(error)
							})
						}
					}

					const onRenameClick = e => {
						const prevName = e.target.dataset.filename.replace('.json', '')
						const newName = window.prompt('Rename to:', prevName)

						// abort if the name is the same or empty
						if (newName === prevName || !newName) return

						fetch(`${fetchUrl}?rename`, {
							method: 'POST',
							body: JSON.stringify({
								file: e.target.dataset.file,
								projectId,
								newName
							})
						}).then(response => {
							response.json().then(result => {
								if (result) {
									notify.success('Renamed Version', `Version has been renamed to "${result}"`)
									fetchData()
								}
							})
						}, error => {
							console.error(error)
						})
					}

					const fetchData = () => {
						wrapperEl.innerHTML = 'Loading...'

						removeEvents()

						const fetchPromises = []
						let stagingData = []
						let localData = []
						let finalData = []

						// fetch the staging data only in development mode
						if (store.env === 'development') {
							fetchPromises.push(
								new Promise(resolve => {
									fetch(`https://${import.meta.env.VITE_THEATRE_STAGING_URL}/${import.meta.env.VITE_THEATRE_LOCATION}?list=${projectId}`).then(response => {
										response.json().then(json => {
											stagingData = json
											resolve()
										})
									}, error => {
										console.error(error)
									})
								})
							)
						}

						// fetch the local data
						fetchPromises.push(
							new Promise(resolve => {
								fetch(`${fetchUrl}?list=${projectId}`).then(response => {
									response.json().then(json => {
										localData = json
										resolve()
									})
								}, error => {
									console.error(error)
								})
							})
						)

						Promise.all(fetchPromises).then(() => {
							// find the entries from the staging data that are not in the local data
							const stagingOnlyData = stagingData.filter(stagingEntry => {
								const isStagingOnly = !localData.find(localEntry => localEntry[0] === stagingEntry[0])
								if (isStagingOnly) {
									stagingEntry.push(true)
								}
								return isStagingOnly
							})

							// merge the local and staging data
							finalData = [...localData, ...stagingOnlyData]

							// sort the data by date
							finalData.sort((a, b) => {
								return b[3] - a[3]
							})

							const currentLoadedJson = store.urlParams.get('version')

							const currentScene = store.urlParams.has('scene') ? '&scene=' + store.urlParams.get('scene') : ''

							let html = `<div class="list-wrap">`

							finalData.forEach(data => {
								if (data[0] === 'state.json') {
									// published version
									html += `
										<div class="${data[0] === 'state.json' ? 'published' : ''} ${data[0] === currentLoadedJson ? 'active' : ''}">
											<span>Published Version <small>(${data[2]})</small></span>
											<div>
												<a href="${url}/?gui&version=${encodeURI(data[0])}${currentScene}" class="btn js-view">View</a>
											</div>
										</div>`
								}
							})

							finalData.forEach(data => {
								if (data[4]) {
									// staging version
									html += `
										<div class="${data[0] === currentLoadedJson ? 'active' : ''}">
											<span>${data[1] === 'state' ? data[2] : data[1] + ' <small>(' + data[2] + ')</small>'}</span>
											<div>
												<button class="btn btn--download js-download" data-file="${data[0]}">Download</button>
											</div>
										</div>`
								} else if (data[0] !== 'state.json') {
									// local version
									html += `
										<div class="${data[0] === currentLoadedJson ? 'active' : ''}">
											<span>${data[1] === 'state' ? data[2] : data[1] + ' <small>(' + data[2] + ')</small>'}</span>
											<div>
												<a href="${url}/?gui&version=${encodeURI(data[0])}${currentScene}" class="btn js-view">View</a>
												<button class="btn btn--delete js-delete" data-file="${data[0]}">Delete</button>
												<button class="btn btn--rename js-rename" data-file="${data[0]}" data-filename="${data[1]}">Rename</button>
											</div>
										</div>`
								}
							})

							html += `</div>`

							wrapperEl.innerHTML = html

							wrapperEl.querySelectorAll('.js-view').forEach(el => {
								el.addEventListener('click', onViewClick)
							})

							wrapperEl.querySelectorAll('.js-download').forEach(el => {
								el.addEventListener('click', onDownloadClick)
							})

							wrapperEl.querySelectorAll('.js-delete').forEach(el => {
								el.addEventListener('click', onDeleteClick)
							})

							wrapperEl.querySelectorAll('.js-rename').forEach(el => {
								el.addEventListener('click', onRenameClick)
							})
						})
					}

					const removeEvents = () => {
						wrapperEl.querySelectorAll('.js-view').forEach(el => {
							el.removeEventListener('click', onViewClick)
						})

						wrapperEl.querySelectorAll('.js-download').forEach(el => {
							el.removeEventListener('click', onDownloadClick)
						})

						wrapperEl.querySelectorAll('.js-delete').forEach(el => {
							el.removeEventListener('click', onDeleteClick)
						})

						wrapperEl.querySelectorAll('.js-rename').forEach(el => {
							el.removeEventListener('click', onRenameClick)
						})
					}

					fetchData()

					// return function that's called when the pane is closed
					return () => {
						removeEvents()
					}
				}
			},
			{
				class: 'unseen-preview-cam',
				mount({ paneId, node }) {
					node.parentElement.style.boxShadow = 'none'
					// const unmountPaneToolbar = store.theatre.studio.ui.renderToolset('previewCamToolbar', node)

					const rtViewerPane = new RTViewerPane(node, 'Composer Result')

					// update toolbar button state
					store.theatre.previewCamOpen = true
					store.theatre.updateExtensionToolbar()

					return () => {
						rtViewerPane.destroy()

						// unmountPaneToolbar()

						// update toolbar button state
						store.theatre.previewCamOpen = false
						store.theatre.updateExtensionToolbar()
					}
				}
			},
			{
				class: 'unseen-preview-render-target',
				mount({ paneId, node }) {
					node.style.overflowY = 'auto'
					node.parentElement.style.boxShadow = 'none'

					const openRTViewerPanes = JSON.parse(localStorage.getItem(store.theatre.persistenceKey + '.openRTViewerPanes'))

					let rtViewerPane
					let onClick

					if (openRTViewerPanes?.[paneId] && store.WebGL.rtViewer.renderTargets[openRTViewerPanes[paneId]]) {
						// this pane was open before, so open it again

						const rtName = openRTViewerPanes[paneId]

						rtViewerPane = new RTViewerPane(node, rtName)
					} else {
						// this pane was not open before, so show the list of render targets to choose from
						let html = `<ul style="padding: 1rem">`

						for (const key in store.WebGL.rtViewer.renderTargets) {
							html += `<li style="margin-bottom: 0.5rem"><button style="padding: 0.25rem" data-rtname="${key}">Open ${key}</button></li>`
						}

						html += `</ul>`

						node.innerHTML = html

						onClick = e => {
							const rtName = e.target.dataset.rtname

							rtViewerPane = new RTViewerPane(node, rtName)

							// save the opened RTViewerPane
							const openRTViewerPanes = JSON.parse(localStorage.getItem(store.theatre.persistenceKey + '.openRTViewerPanes')) || {}
							openRTViewerPanes[paneId] = rtName
							localStorage.setItem(store.theatre.persistenceKey + '.openRTViewerPanes', JSON.stringify(openRTViewerPanes))

							node.innerHTML = ''
						}

						node.querySelectorAll('button').forEach(el => {
							el.addEventListener('click', onClick)
						})
					}

					return () => {
						rtViewerPane?.destroy()

						const openRTViewerPanes = JSON.parse(localStorage.getItem(store.theatre.persistenceKey + '.openRTViewerPanes'))

						if (openRTViewerPanes?.[paneId]) {
							// remove the opened RTViewerPane
							delete openRTViewerPanes[paneId]
							localStorage.setItem(store.theatre.persistenceKey + '.openRTViewerPanes', JSON.stringify(openRTViewerPanes))
						} else {
							node.querySelectorAll('button').forEach(el => {
								el.removeEventListener('click', onClick)
							})
						}

						// delete from object stringLiteral
					}
				}
			}
		]
	}

	return config
}

class RTViewerPane {
	constructor(paneElement, rtName, options = {}) {
		this.paneElement = paneElement
		this.rtName = rtName

		// make the pane transparent
		this.paneElement.style.background = 'none'

		store.WebGL.rtViewer.createRTView(this.rtName, options)

		// initial update
		this.updateLocation()

		// listen for changes to position and dimensions
		this.observer = new MutationObserver(this.updateLocation)
		this.observer.observe(this.paneElement.parentElement, { attributes: true, attributeFilter: ['style'] })

		// update the location on window resize
		E.on(GlobalEvents.RESIZE, this.onResize)
	}

	updateLocation = () => {
		const x = this.paneElement.parentElement.offsetLeft
		const y = this.paneElement.parentElement.offsetTop + this.paneElement.offsetTop // add the offset of the top bar
		const width = this.paneElement.clientWidth
		const height = this.paneElement.clientHeight

		store.WebGL.rtViewer.rtViews[this.rtName].setLocation(x, y, width, height)
	}

	onResize = () => {
		this.updateLocation()
	}

	destroy() {
		this.observer.disconnect()
		store.WebGL.rtViewer.destroyRTView(this.rtName)
		E.off(GlobalEvents.RESIZE, this.onResize)
	}
}