import { BackSide, Color, Euler, Mesh, RepeatWrapping, ShaderMaterial, SphereGeometry, Vector3 } from "three"
import createComponent from "./unseen/Component"
import store from "_store"
import { mergeDeep } from "_utils/index"

export default class BackgroundSphere extends createComponent(Mesh) {
	constructor(options = {}) {
		options = mergeDeep({
			visible: true,
			scene: null,
			name: 'Background Sphere',
			scale: new Vector3(120, 120, 120),
			position: new Vector3(0, 0, 0),
			rotation: new Euler(0, 0, 0),
			materialOpts: {
				uniforms: {
					uBaseColor1: { value: new Color(0x0c0805) },
					uBaseColor2: { value: new Color(0x010101) },
					uBaseColor3: { value: new Color(0x010101) },
					uStep1: { value: 0.16, gui: { min: 0, max: 1, step: 0.001 } },
					uStep2: { value: 0.58, gui: { min: 0, max: 1, step: 0.001 } },
					uStep3: { value: 0.98, gui: { min: 0, max: 1, step: 0.001 } },

					uTriPlanar: { value: !store.isLowTierGPU },

					uBrushSpeeed: { value: 1.01 },
					uBrushScale: { value: 4.4, gui: { min: 0, max: 10, step: 0.001 } },
					uBrushMinEdge: { value: 0.5, gui: { min: 0, max: 1, step: 0.001 } },
					uBrushMaxEdge: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
					uBrushBlend: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
					uBrushOffset: { value: new Vector3(0.0, 0.0, 0.0), gui: { min: 0, max: 1, step: 0.001 } },
					uBrushRotation: { value: 0.0, gui: { min: 0, max: Math.PI * 2, step: 0.001 } }
				}
			}
		}, options)

		super(options)

		this.position.copy(this.options.position)
		this.scale.copy(this.options.scale)
		this.rotation.copy(this.options.rotation)
	}

	build() {
		this.geometry = new SphereGeometry(1, 16, 16)

		const vertexShader = `
            varying vec2 vUv;

			varying vec3 vWorldPosition;
			varying vec3 vWorldNormal;

			uniform float uScale;

            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

				vec3 worldPosition = (modelMatrix * vec4(position, 1.0)).xyz;

				vWorldPosition.x = smoothstep(-uScale, uScale, worldPosition.x);
				vWorldPosition.y = smoothstep(-uScale, uScale, worldPosition.y);
				vWorldPosition.z = smoothstep(-uScale, uScale, worldPosition.z);

				vec3 objectNormal = vec3( normal );
				vWorldNormal = (modelMatrix * vec4(objectNormal, 0.)).xyz;
            }
        `

		const fragmentShader = `

			#include <default_pars>
			#include <default_frag_pars>

			varying vec3 vWorldPosition;
			varying vec3 vWorldNormal;

			uniform sampler2D tBrush;

			uniform float uBrushRotation;
			uniform vec3 uBrushOffset;

			uniform float uBrushScale;
			uniform float uBrushMinEdge;
			uniform float uBrushMaxEdge;
			uniform float uBrushBlend;

			uniform bool uTriPlanar;

            uniform vec3 uBaseColor1; // top
            uniform vec3 uBaseColor2; // middle 
            uniform vec3 uBaseColor3; // bottom

            uniform float uStep1;
            uniform float uStep2;
            uniform float uStep3;

			uniform float uTime;
			uniform float uBrushSpeeed;

            varying vec2 vUv;

			vec2 rotate2d(vec2 v, float a) {
				float s = sin(a);
				float c = cos(a);
				mat2 m = mat2(c, s, -s, c);
				return m * v;
			}

			vec3 getTriPlanarBlend(vec3 _wNorm){
				// in wNorm is the world-space normal of the fragment
				vec3 blending = abs( _wNorm );
				blending = normalize(max(blending, 0.00001)); // Force weights to sum to 1.0
				float b = (blending.x + blending.y + blending.z);
				blending /= vec3(b, b, b);
				return blending;
			}

			vec4 sampleTriPlanarTexture(sampler2D tex, vec3 coord, float repeat, float angle, vec3 blend){
				vec4 xaxis = texture2D( tex, rotate2d(coord.yz, angle) * repeat );
				vec4 yaxis = texture2D( tex, rotate2d(coord.xz, angle) * repeat );
				vec4 zaxis = texture2D( tex, rotate2d(coord.xy, angle) * repeat );
				return xaxis * blend.x + yaxis * blend.y + zaxis * blend.z;
			}

            void main () {

				float brushSpeed = uBrushSpeeed * uTime;

				vec3 brush1 = vec3(0.0);
				vec3 brush2 = vec3(0.0);

				if(uTriPlanar) {
					// calculate triplanar blend
    				vec3 triPlanarBlend = getTriPlanarBlend(vWorldNormal);

					// sample brush displacement texture
					vec3 brushCoords = vWorldPosition + uBrushOffset;

					brush1 = sampleTriPlanarTexture(tBrush, brushCoords + vec3(brushSpeed * 0.0016, brushSpeed * 0.0000 + 0.02, brushSpeed * 0.003 + 0.02), uBrushScale, uBrushRotation, triPlanarBlend).rgb;
					brush2 = sampleTriPlanarTexture(tBrush, brushCoords * 0.9 + vec3(-0.123 - brushSpeed * 0.0025, 0.867 + brushSpeed * 0.001, brushSpeed * 0.002 + 0.763), uBrushScale, uBrushRotation + 0.5, triPlanarBlend).rgb;
				} else {
					brush1 = texture2D( tBrush, rotate2d(vUv + uBrushOffset.xy + vec2(brushSpeed * 0.0016, brushSpeed * 0.0000 + 0.02), uBrushRotation) * uBrushScale * 2.0).rgb;
					brush2 = texture2D( tBrush, rotate2d((vUv + uBrushOffset.xy) * 0.9 + vec2(-0.123 - brushSpeed * 0.0025, 0.867 + brushSpeed * 0.001), uBrushRotation + 0.5) * uBrushScale * 2.0).rgb;
				}
				
				brush1 = smoothstep(uBrushMinEdge, uBrushMaxEdge, brush1);
				brush2 = smoothstep(uBrushMinEdge, uBrushMaxEdge, brush2);

				vec3 brush = ( clamp( min(brush2, brush1), vec3(0.), vec3(1.0)) * 1.0);

				vec2 displaceUV = blendSoftLight(vWorldPosition, brush, uBrushBlend).xy;

				// Three step gradient
                vec3 gradientColor = vec3(0.);
                gradientColor += mix(uBaseColor3, uBaseColor2, smoothstep(uStep1, uStep2, displaceUV.y));
                gradientColor = mix(gradientColor, uBaseColor1, smoothstep(uStep2, uStep3, displaceUV.y));

                gl_FragColor = vec4(gradientColor, 1.0);
                gl_FragColor = clamp(gl_FragColor, vec4(0.0), vec4(1.0));

                #include <colorspace_fragment>
            }

        `

		this.material = new ShaderMaterial({
			vertexShader,
			fragmentShader,
			uniforms: {
				uScale: { value: this.scale.y },
				tBrush: { value: this.assets.textures.brush },
				...this.options.materialOpts.uniforms,
				...store.WebGL.globalUniforms
			},
			side: BackSide,
			depthWrite: false
		})

		if (this.parent.cameraLayers.renderBackground) {
			this.layers.set(this.parent.cameraLayers.renderBackground)
		}
	}

	load() {
		store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/brush-01.jpg`, { wrapping: RepeatWrapping }).then(texture => {
			this.assets.textures.brush = texture
		})
	}

	addGui() {
		store.theatre.helper.autoAddObject(this, this.parent.prettyName, {})
	}

	destory() {
		super.destory()
		// console.log('Destroying BackgroundSphere')

		this.options.scene.backgroundRenderTarget?.dispose()
		this.options.scene.globalUniforms.fog?.tBackground?.value?.dispose()

		this.material.dispose()
		this.geometry.dispose()
		for (const key in this.assets.textures) {
			this.assets.textures[key].dispose()
		}
	}
}