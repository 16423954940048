varying vec2 vUv;

varying vec2 vL;
varying vec2 vR;
varying vec2 vT;
varying vec2 vB;

uniform sampler2D uTexture;
uniform sampler2D tVelocity;
uniform sampler2D tCurl;

uniform float uCurlStrength;
uniform float uNormalizedDelta; // time step

void main() {
    // curl values at neighbouring pixels
    float L = texture(tCurl, vL).x;
    float R = texture(tCurl, vR).x;
    float T = texture(tCurl, vT).x;
    float B = texture(tCurl, vB).x;

    float C = texture(tCurl, vUv).x; // curl at current point

    vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
    force /= length(force) + 0.0001;
    force *= uCurlStrength * C;
    force.y *= -1.0;
    // force.y += 400.3; // ? idk man
        
    vec2 vel = texture(tVelocity, vUv).xy;

    gl_FragColor = vec4(vel + force * uNormalizedDelta, 0.0, 1.0); // dt is delta time
}