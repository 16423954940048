import store from '_store'
import { E, GlobalEvents } from '_utils/index'
import BaseMaterial from '_webgl/materials/unseen/base/BaseMaterial'
import { Mesh, OrthographicCamera, PlaneGeometry, Scene, Vector2 } from 'three'
import fragmentShader from '_glsl/uvBackgroundCover/frag.glsl'

export default class RTViewer {
	constructor() {
		this.enabled = false

		this.renderTargets = {}
		this.rtViews = {}

		this.scene = new Scene()
		this.camera = new OrthographicCamera(-1, 1, 1, -1, 0.000001, 1000)

		this.setWindowSize(store.window.w, store.window.h)
	}

	enable() {
		if (this.enabled) return

		this.enabled = true
		store.RAFCollection.add(this.render, 200) // after final composer and any dev tools passes so it's always on top
		E.on(GlobalEvents.RESIZE, this.onResize)
	}

	disable() {
		if (!this.enabled) return

		this.enabled = false
		store.RAFCollection.remove(this.render)
		E.off(GlobalEvents.RESIZE, this.onResize)
	}

	registerRT(renderTarget, name) {
		this.renderTargets[name] = renderTarget
	}

	unregisterRT(name) {
		if (this.renderTargets[name]) {
			delete this.renderTargets[name]
		}
	}

	createRTView(key, options = {}) {
		if (!this.renderTargets[key]) throw new Error(`RTViewer: render target ${key} not found`)

		this.rtViews[key] = new RTView({ renderTarget: this.renderTargets[key], ...options })
		this.scene.add(this.rtViews[key].mesh)
	}

	destroyRTView(key) {
		this.scene.remove(this.rtViews[key].mesh)
		this.rtViews[key].destroy()
		delete this.rtViews[key]
	}

	setWindowSize(width, height) {
		// resize camera to match pixel unit size
		this.camera.left = -width / 2
		this.camera.right = width / 2
		this.camera.top = height / 2
		this.camera.bottom = -height / 2
		this.camera.updateProjectionMatrix()
	}

	onResize = () => {
		this.setWindowSize(store.window.w, store.window.h)
	}

	render = () => {
		if (!this.enabled || !this.scene.children.length) return

		const prevAutoClear = store.WebGL.renderer.autoClear

		store.WebGL.renderer.autoClear = false

		for (const key in this.rtViews) {
			this.rtViews[key].updateTexture()
		}

		store.WebGL.renderer.render(this.scene, this.camera)

		store.WebGL.renderer.autoClear = prevAutoClear
	}

	destroy() {
		this.disable()
		this.camera = null
		this.scene = null
	}
}

class RTView {
	constructor({ renderTarget, x = 0, y = 0, width = 400, crop = false }) {
		this.crop = crop
		this.renderTarget = renderTarget

		// TODO: don't allow stretching
		this.material = new BaseMaterial({
			fragmentShader,
			uniforms: {
				tTexture: { value: this.renderTarget.texture },
				uScreenSize: { value: new Vector2() },
				uImageSize: { value: new Vector2() }
			},
			defines: {
				CROP: this.crop
			}
		})
		this.geometry = new PlaneGeometry(1, 1)
		this.mesh = new Mesh(this.geometry, this.material)

		const height = this.renderTarget.height * width / this.renderTarget.width

		this.mesh.scale.set(width, height, 1)
		this.mesh.position.x = (-store.window.w / 2 + x) + width / 2
		this.mesh.position.y = (store.window.h / 2 - y) - height / 2

		this.material.uniforms.uScreenSize.value.set(width, height)
		this.material.uniforms.uImageSize.value.set(this.renderTarget.width, this.renderTarget.height)
	}

	setLocation(x, y, width, height) {
		this.mesh.scale.set(width, height, 1)
		this.mesh.position.x = (-store.window.w / 2 + x) + width / 2
		this.mesh.position.y = (store.window.h / 2 - y) - height / 2

		this.material.uniforms.uScreenSize.value.set(width, height)
		this.material.uniforms.uImageSize.value.set(this.renderTarget.width, this.renderTarget.height)
	}

	updateTexture() {
		this.material.uniforms.tTexture.value = this.renderTarget.texture
	}

	// TODO: pause

	destroy() {
		this.mesh.geometry.dispose()
		this.mesh.material.dispose()
		this.mesh = null
		this.geometry.dispose()
		this.material.dispose()
		this.renderTarget = null
	}
}