import store from "_store"
import { E, GlobalEvents, mergeDeep } from "_utils/index"
import { BufferGeometry, CatmullRomCurve3, Color, DataTexture, FloatType, HalfFloatType, InstancedBufferAttribute, InstancedMesh, Line, LineBasicMaterial, LinearSRGBColorSpace, MathUtils, Matrix4, NearestFilter, Object3D, PlaneGeometry, RGBADepthPacking, RGBAFormat, RepeatWrapping, ShaderMaterial, Sphere, UniformsLib, Vector2, Vector3, WebGLRenderTarget } from "three"
import positionSimulationFrag from "_webgl/materials/revealRootMaterial/positionSimulationFrag.glsl"
import FBOHelper from "_webgl/utils/FBOHelper"
import roundToDecimal from "_utils/functions/roundToDecimal"
import fract from "_utils/functions/fract"
import { types } from "@theatre/core"
import createComponent from "./unseen/Component"
import RevealRootMaterial from "_webgl/materials/revealRootMaterial/RevealRootMaterial"

/**
 * A component that creates a particle mesh (InstancedMesh) that follows a spline or a group of splines. Includes self-shadowing and optional per-particle motion blur (only on this component).
 */
export default class RevealRootsParticleSpline extends createComponent() {
	_debugColors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff]

	constructor(options = {}) {
		super()

		this.options = mergeDeep({
			scene: undefined,
			data: {},
			numberOfSplines: 1, // arbitrary fallback, if no data is provided
			name: 'reveal-root-0',
			planeScale: 1,
			sizeRange: new Vector2(0.8, 1.25),
			textureWidth: 256,
			splineTextureWidth: 64,
			maxParticleCount: 40000,
			curveTension: 0.5,
			renderLocalMotionBlur: false, // If true, the particles will be rendered to a separate render target with the motionMaterial
			splineGroupIndex: 0, // Optional index for this group of splines (if using manager with multiple spline groups/instances of this component)
			materialOpts: {
				uniforms: {
					uFadeOut: { value: new Vector2(0.0, 0.2), gui: { step: 0.01 } }
				},
				defines: {
					GRID_SIZE: 14
				}
			},
			simulationOpts: {
				uniforms: {
					uAnimationProgress: { value: 0.0, gui: { min: 0, max: 1, step: 0.01 } },
					uReversed: { value: true },

					uTravelSpeed: { value: 0.2 },
					uAfterGrowTravelSpeed: { value: 1 },

					uCurl: { value: false },
					uCurlSize: { value: 0.2 },
					uCurlNoiseSpeed: { value: 0.5 },
					uCurlStrength: { value: 1.0 },
					uLerpSpeed: { value: 0.07 },
					uSetup: { value: 1.0 },
					uSplineThickness: { value: 0.2, gui: { min: 0, max: 5, step: 0.01 } },
					uAmbientSplineThickness: { value: 3.8, gui: { min: 0, max: 10, step: 0.01 } },

					// uMainSplineTravelSpeedSlowFactor: { value: 0.5 },
					// uMidSplineTravelSpeedSlowFactor: { value: 0.8 },
					uAmbientSplineTravelSpeedSlowFactor: { value: 0.5 },

					uSplineTaper: { value: new Vector2(0.0, 1.0), gui: { step: 0.01 } },

					// uMainSplineTaper: { value: new Vector2(0.5, 1.0) },
					// uMidSplineTaper: { value: new Vector2(0.3, 0.6) },
					// uSmallSplineTaper: { value: new Vector2(0.18, 0.3) },
					// uAmbientSplineTaper: { value: new Vector2(0.5, 1.0) },

					uFluidEnabled: { value: false },
					uFluidMaskEnabled: { value: true },
					uFluidStrength: { value: 8 },
					uFluidLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

					uRandomThicknessStrength: { value: 1.17, gui: { min: 0, step: 0.01 } },
					uRandomThicknessMax: { value: 1.0, gui: { min: 0, step: 0.01 } },
					uRandomThicknessFrequency: { value: 2., gui: { min: 0, step: 0.01 } },
					uRandomThicknessThreshold: { value: 0.6, gui: { min: 0, max: 1, step: 0.01 } }

					// uMainSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } },
					// uMidSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } },
					// uSmallSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } }

					// uLowestYPoint: { value: 0.0 },
					// uHighestYPoint: { value: 0.0 }
				}
			}
		}, options)

		Object.assign(this.options.simulationOpts.uniforms, {
			uFadeOut: this.options.materialOpts.uniforms.uFadeOut
		})

		this.name = this.options.name

		this.options.data = this.getFilteredSplines(this.options.data, this.name)

		this._instanceDummy = new Object3D()

		this._textureWidth = this.options.textureWidth
		this.PARTICLE_COUNT = this._textureWidth * this._textureWidth

		// set correct count
		this.ACTIVE_PARTICLE_COUNT = this.PARTICLE_COUNT > this.options.maxParticleCount ? this.options.maxParticleCount : this.PARTICLE_COUNT

		this._splineTextureSize = this.options.splineTextureWidth

		this.sizeRange = [this.options.sizeRange.x, this.options.sizeRange.y]

		this.layers.set(this.options.scene.cameraLayers.particles)

		E.on(GlobalEvents.RESIZE, this.onResize)

		this._debugMeshes = {}
		this._debugSplines = {}

		// Rendering variables for local motion blur
		this._clearColor = new Color()
		this._background = new Color(0x000000)
	}

	getFilteredSplines(data, splineKey) {
		return Object.keys(data).filter(key =>
			key.substring(0, key.length - 4) === splineKey // Trim the last 3 characters and the dash and compare with the splineKey
		).reduce((obj, key) => {
			obj[key] = data[key]
			return obj
		}, {})
	}

	build(objectData) {
		this.numberOfSplines = Object.keys(this.options.data).length > 0 ? Object.keys(this.options.data).length : this.options.numberOfSplines
		this.pixelsPerSpline = Math.floor((this._splineTextureSize * this._splineTextureSize) / this.numberOfSplines)
		this.particlesPerSpline = Math.floor(this.PARTICLE_COUNT / this.numberOfSplines)

		this.splineObjects = []

		for (const key in this.options.data) {
			const index = Object.keys(this.options.data).indexOf(key)
			this.buildSplineFromData({ key, index, splineData: this.options.data[key], targetArray: this.splineObjects, addHelper: true })
		}

		this.buildSplineTexture()
		this.buildAttributesTexture()

		this.addSplineDataToTextures()

		/*
		** Optional debug textures
		*/
		// addTextureDebug(this.options.scene, this._debugMeshes, 'spline', this.splineTexture, false, false, new Vector3(-1.16, -0.85, -5))
		// addTextureDebug(this.options.scene, this._debugMeshes, 'attributes', this.attributeTexture, false, false, new Vector3(-1.16, 0.85, -5))

		if (this.options.renderLocalMotionBlur) this.buildMotionRT()

		Object.assign(this.options.materialOpts.uniforms, {
			uSplineCount: { value: this.numberOfSplines },
			tAttributes: { value: this.attributeTexture },

			tNormals: { value: this.assets.textures.normals },

			// for motion blur
			uPrevModelViewMatrix: { value: new Matrix4() },
			tPrevPosition: { value: null }
		})

		this.particleMaterial = new RevealRootMaterial(this.options.materialOpts, this.options.scene)

		this.customDepthMaterial = new ShaderMaterial({
			vertexShader: this.particleMaterial.vertexShader,
			fragmentShader: this.particleMaterial.fragmentShader,
			uniforms: this.particleMaterial.uniforms,
			defines: {
				DEPTH_PACKING: RGBADepthPacking,
				...this.options.materialOpts.defines
			}
		})

		this.motionMaterial = new ShaderMaterial({
			vertexShader: this.particleMaterial.vertexShader,
			fragmentShader: this.particleMaterial.fragmentShader,
			uniforms: this.particleMaterial.uniforms,
			defines: {
				MOTION_BLUR: true,
				...this.options.materialOpts.defines
			}
		})

		this.mesh = new InstancedMesh(new PlaneGeometry(this.options.planeScale, this.options.planeScale), this.particleMaterial, this.ACTIVE_PARTICLE_COUNT)
		this.mesh.name = this.options.name

		this.mesh.layers.set(this.options.scene.cameraLayers.particles)

		this.mesh.customDepthMaterial = this.customDepthMaterial // Needed for directional light shadows
		this.mesh.receiveShadow = true
		this.mesh.castShadow = true

		this.initFBOHelper()
		this.initParticlePositions()

		this.preRender()
	}

	preRender() {
		this.animate()

		this.positionSim.uniforms.uSetup.value = 0.0
	}

	buildSplineFromData({ key, index, splineData, targetArray, addHelper = false }) {
		const splinePoints = []
		const _v = new Vector3()

		for (let i = 0; i < splineData[0].length; i++) { // Iterate through all points
			splinePoints.push(_v.fromArray(splineData[0][i]).clone())
		}

		// Add custom attribute of root type
		let attribute
		if (splineData[1].attributes.rootType || splineData[1].attributes.rootType === 0) {
			attribute = splineData[1].attributes.rootType
		}

		// Create a curve from the points
		const curve = new CatmullRomCurve3(splinePoints, false, 'catmullrom', this.options.curveTension)

		targetArray.push({ curve, points: splinePoints, rootType: attribute })

		if (addHelper) this.addSplineHelper(curve, index)
	}

	buildSplineTexture() {
		const dataArray = new Float32Array(this._splineTextureSize * this._splineTextureSize * 4) // Number of pixels * 4 (RGBA)
		const arrayLength = this._splineTextureSize * this._splineTextureSize * 4

		for (let k = 0, kl = arrayLength; k < kl; k += 4) {
			dataArray[k + 0] = 0
			dataArray[k + 1] = 0
			dataArray[k + 2] = 0
			dataArray[k + 3] = 1
		}

		this.splineTexture = new DataTexture(
			dataArray,
			this._splineTextureSize,
			this._splineTextureSize,
			RGBAFormat,
			FloatType
		)
		this.splineTexture.minFilter = NearestFilter
		this.splineTexture.magFilter = NearestFilter
		this.splineTexture.needsUpdate = true
	}

	buildAttributesTexture() {
		const dataArray = new Float32Array(this._textureWidth * this._textureWidth * 4) // Number of pixels * 4 (RGBA)
		const arrayLength = this._textureWidth * this._textureWidth * 4

		// let lowestYPoint = 1000
		// let highestYPoint = -1000

		for (let k = 0, kl = arrayLength; k < kl; k += 4) {
			const i = k / 4 // Particle index
			// Spline index on [0, 1] - last spline might have one extra particle
			const splineIndex = Math.min(Math.floor(i / this.particlesPerSpline), this.numberOfSplines - 1) / (Math.max(1, this.numberOfSplines - 1))
			// const splineIndex = Math.min(Math.floor(i / this.pixelsPerSpline), this.numberOfSplines - 1) / (Math.max(1, this.numberOfSplines - 1)) // only works if textures are the same size

			const splineIndexInteger = Math.min(Math.floor(i / this.particlesPerSpline), this.numberOfSplines - 1)

			dataArray[k + 0] = splineIndex
			dataArray[k + 1] = this.splineObjects[splineIndexInteger].rootType // Get the root type from the spline object
			dataArray[k + 2] = this.splineObjects[splineIndexInteger].points[0].y // Get the Y position of the first point in the spline
			dataArray[k + 3] = MathUtils.seededRandom(i * (splineIndex + i)) // Random value between 0 and 1 for each particle

			// if (this.splineObjects[splineIndexInteger].points[this.splineObjects[splineIndexInteger].points.length - 1].y < lowestYPoint) { // Lowest point will be the last point of the spline
			// 	lowestYPoint = this.splineObjects[splineIndexInteger].points[this.splineObjects[splineIndexInteger].points.length - 1].y
			// }

			// if (this.splineObjects[splineIndexInteger].points[0].y > highestYPoint) { // Highest point will be the first point of the spline
			// 	highestYPoint = this.splineObjects[splineIndexInteger].points[0].y
			// }
		}

		// Set the lowest Y point to the simulation uniforms
		// this.options.simulationOpts.uniforms.uLowestYPoint.value = lowestYPoint
		// this.options.simulationOpts.uniforms.uHighestYPoint.value = highestYPoint

		this.attributeTexture = new DataTexture(
			dataArray,
			this._textureWidth,
			this._textureWidth,
			RGBAFormat,
			FloatType
		)
		this.attributeTexture.minFilter = NearestFilter
		this.attributeTexture.magFilter = NearestFilter
		this.attributeTexture.needsUpdate = true
	}

	addSplineDataToTextures() {
		// Extract each spline's points and fill the texture with them
		this.splineObjects.forEach((spline, i) => {
			this.addSplineDataToSplineTexture(spline, i)
		})
	}

	addSplineDataToSplineTexture(splineObject, splineIndex) {
		const theArray = this.splineTexture.image.data

		const data = splineObject
		const curve = data.curve
		const points = curve.getSpacedPoints(this.pixelsPerSpline - 1)

		for (let i = 0; i < points.length; i++) {
			const indexInImageArray = (splineIndex * this.pixelsPerSpline + i) * 4

			const point = points[i]

			theArray[indexInImageArray] = point.x
			theArray[indexInImageArray + 1] = point.y
			theArray[indexInImageArray + 2] = point.z
			// theArray[indexInImageArray + 3] = MathUtils.mapLinear(splineIndex, 0., this.numberOfSplines - 1, 0.01, 1.) // Spline index on [0.5, 1]
			theArray[indexInImageArray + 3] = 0 // unnecessary
		}

		this.splineTexture.needsUpdate = true
	}

	addSplineHelper(curve, index) {
		const points = curve.getPoints(50)
		const geometry = new BufferGeometry().setFromPoints(points)

		const splineHelper = new Line(geometry, new LineBasicMaterial({ color: this._debugColors[this.options.splineGroupIndex] }))
		splineHelper.name = `splineHelper-${index}`
		splineHelper.visible = false
		this._debugSplines[index] = splineHelper
		this.add(splineHelper)
	}

	toggleSplineHelpers(visible) {
		for (const key in this._debugSplines) {
			this._debugSplines[key].visible = visible
		}
	}

	onResize = () => {
		if (this.motionRT) {
			this.motionRT.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
			this.motionRT.needsUpdate = true
		}
	}

	buildMotionRT() {
		// Build a render target for the motion blur pass
		this.motionRT = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio(), {
			format: RGBAFormat,
			type: HalfFloatType
		})

		// addTextureDebug(this.options.scene, this._debugMeshes, 'motion', this.motionRT.texture, false, false, new Vector3(0.2, -0.2, -2)) // Optional debug
	}

	initParticlePositions() {
		const references = []
		const aRandomArray = []
		const scaleArray = new Float32Array(this.ACTIVE_PARTICLE_COUNT)

		for (let i = 0; i < this.ACTIVE_PARTICLE_COUNT; i++) {
			this._instanceDummy.updateMatrix()
			this.mesh.setMatrixAt(i, this._instanceDummy.matrix)

			const x = (i % this._textureWidth) / this._textureWidth
			const y = ~~(i / this._textureWidth) / this._textureWidth
			references.push(x, y)

			aRandomArray.push(Math.random(), Math.random(), Math.random())

			scaleArray[i] = MathUtils.randFloat(this.sizeRange[0], this.sizeRange[1])
		}

		this.mesh.geometry.setAttribute('reference', new InstancedBufferAttribute(new Float32Array(references), 2))
		this.mesh.geometry.setAttribute('aRandom', new InstancedBufferAttribute(new Float32Array(aRandomArray), 3))
		this.mesh.geometry.setAttribute('aScale', new InstancedBufferAttribute(scaleArray, 1))

		this.mesh.matrixAutoUpdate = false
		this.mesh.updateMatrix()

		// Redo the boundix box to capture the whole spline group
		this.mesh.geometry.computeBoundingBox()
		this.mesh.geometry.computeBoundingSphere()

		this.mesh.geometry.boundingBox.makeEmpty() // Clear the bounding box

		// Combine all spline's points into one bounding box
		const boundingPoints = []
		this.splineObjects.forEach(spline => {
			boundingPoints.push(...spline.points)
		})

		this.mesh.geometry.boundingBox.setFromPoints(boundingPoints) // Set it from points, so it's huge and covers the whole path

		this.mesh.geometry.boundingSphere = new Sphere()
		this.mesh.geometry.boundingBox.getBoundingSphere(this.mesh.geometry.boundingSphere)
		this.mesh.geometry.boundingSphere.radius *= 1.5

		this.add(this.mesh)
	}

	/**
	 * Creates the initial position data for the particles aka their offsets around a point in a sphere
	 * The alpha channel is used for life data
	 * @returns {Float32Array}
	 */
	createPositionData() {
		const data = new Float32Array(this.PARTICLE_COUNT * 4)

		const radius = 3

		let r, phi, theta

		for (let i = 0; i < this.PARTICLE_COUNT; i++) {
			const index = i * 4
			r = (0.5 + MathUtils.seededRandom(i) * 0.5) * radius
			phi = (MathUtils.seededRandom(i + 1) - 0.5) * Math.PI
			theta = MathUtils.seededRandom(i + 2) * Math.PI * 2

			data[index + 0] = r * Math.cos(theta) * Math.cos(phi)
			data[index + 1] = r * Math.sin(phi)
			data[index + 2] = r * Math.sin(theta) * Math.cos(phi)
			data[index + 3] = roundToDecimal(fract(i / (this.pixelsPerSpline)), 3) // Initial lifespan to be used to position the particle on the spline
		}

		return data
	}

	initFBOHelper() {
		this.positionSim = new FBOHelper({
			fragmentShader: positionSimulationFrag,
			uniforms: {
				...this.options.simulationOpts.uniforms,

				tAttributes: { value: this.attributeTexture },
				tSpline: { value: this.splineTexture },
				uPixelsPerSpline: { value: this.pixelsPerSpline },
				uSplineTexSize: { value: this._splineTextureSize },
				uSplineCount: { value: this.numberOfSplines },
				uSplineGroupIndex: { value: this.options.splineGroupIndex },

				tFluid: { value: store.WebGL.fluidSim.advectionSimVelocity.texture },
				tFluidMask: { value: store.WebGL.fluidSim.addForceSimMouse.texture },
				uModelMatrix: { value: this.mesh.matrixWorld },
				uViewMatrix: { value: this.options.scene.activeCamera.matrixWorldInverse },
				uProjectionMatrix: { value: this.options.scene.activeCamera.projectionMatrix }
			},
			width: this._textureWidth,
			height: this._textureWidth,
			wrap: RepeatWrapping,
			createTexture: true,
			type: FloatType,
			data: this.createPositionData()
		})

		this.positionSim.material.defines.USE_FLUID = true
		this.positionSim.material.needsUpdate = true
	}

	animate() {
		if (!this.mesh.visible) return

		if (store.WebGL.fluidSim && store.WebGL.fluidSim?.enabled) {
			this.positionSim.uniforms.tFluid.value = store.WebGL.fluidSim.advectionSimVelocity.texture
			this.positionSim.uniforms.tFluidMask.value = store.WebGL.fluidSim.addForceSimMouse.texture
		}

		this.positionSim.uniforms.uViewMatrix.value.copy(this.options.scene.activeCamera.matrixWorldInverse)
		this.positionSim.uniforms.uProjectionMatrix.value.copy(this.options.scene.activeCamera.projectionMatrix)

		this.positionSim.update()

		this.particleMaterial.uniforms.tPosition.value = this.positionSim.texture
		this.particleMaterial.uniforms.tPreviousPosition.value = this.positionSim.alternateTexture

		this.customDepthMaterial.uniforms.tPosition.value = this.positionSim.texture

		// Render the particles to the motionRT with the motionMaterial
		this.motionMaterial.uniforms.tPosition.value = this.positionSim.texture
		this.motionMaterial.uniforms.tPrevPosition.value = this.positionSim.alternateTexture

		// this.renderLocalMotionBlur()
	}

	setMotionMaterial() {
		this.mesh.material = this.motionMaterial
	}

	setParticleMaterial() {
		this.mesh.material = this.particleMaterial
	}

	updateMotionMaterialPrevModelMatrix() {
		this.motionMaterial.uniforms.uPrevModelViewMatrix.value = this.mesh.modelViewMatrix
	}

	/**
	 * Renders the particles with the motionMaterial to the motionRT only on this specific component
	 */
	renderLocalMotionBlur() {
		if (!this.options.renderLocalMotionBlur || !this.motionRT) return

		this.setMotionMaterial()

		// Disable all layers except the particles layer
		this.options.scene.activeCamera.layers.disableAll()
		this.options.scene.activeCamera.layers.enable(this.layers.mask) // Only render the layer of this component

		this._background = this.options.scene.background
		this.options.scene.background = null
		store.WebGL.renderer.getClearColor(this._clearColor)

		// Render the particles to the motionRT with the motionMaterial
		store.WebGL.renderer.setRenderTarget(this.motionRT)
		store.WebGL.renderer.setClearColor(0xffffff, 0) // Set alpha to 0 to get a transparent background
		store.WebGL.renderer.clear()
		store.WebGL.renderer.render(this.options.scene, this.options.scene.activeCamera)

		// Reset the renderer
		store.WebGL.renderer.setRenderTarget(null)
		store.WebGL.renderer.setClearColor(this._clearColor)
		this.options.scene.background = this._background
		this.setParticleMaterial()

		// Update the motion blur pass uniforms
		store.WebGL.motionBlurPass && (store.WebGL.motionBlurPass.material.uniforms.tMotionMap.value = this.motionRT.texture)

		this.updateMotionMaterialPrevModelMatrix()

		// Enable all layers
		this.options.scene.activeCamera.layers.enableAll()
	}

	addGui() {
		const config = {
			visible: this.mesh.visible,
			count: types.number(this.mesh.count, { label: 'Particle Count', nudgeMultiplier: 1, range: [0, this.ACTIVE_PARTICLE_COUNT] }),
			material: {},
			simulation: {}
		}

		const materialExcludes = ['uSplineCount', 'uFadeOut', ...Object.keys(this.options.scene.globalUniforms.sun), ...Object.keys(this.options.scene.globalUniforms.shadow), ...Object.keys(UniformsLib.lights)]
		const simulationExcludes = ['uSetup', 'uTexelSize', 'uSplineCount', 'uPixelsPerSpline', 'uSplineTexSize']

		const updateUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.material, this.particleMaterial.uniforms, materialExcludes)
		const updateSimUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.simulation, this.positionSim.uniforms, simulationExcludes)
		const sheetObject = store.theatre.helper.addSheetObject(this.options.scene.prettyName, `ParticleSpline: ${this.options.name}`, config, this)

		sheetObject.onValuesChange(values => {
			for (let i = 0; i < updateUniformsCallbacks.length; i++) {
				updateUniformsCallbacks[i](this, values.material)
			}
			for (let i = 0; i < updateSimUniformsCallbacks.length; i++) {
				updateSimUniformsCallbacks[i](this, values.simulation)
			}
			this.mesh.visible = values.visible
			this.mesh.count = values.count
		}, store.theatre.rafDriver)
	}

	destroy() {
		super.destroy()
		// console.log(`Destroying ${this.constructor.name}`)

		E.off(GlobalEvents.RESIZE, this.onResize)

		this.motionMaterial.dispose()
		this.particleMaterial.dispose()
		this.customDepthMaterial.dispose()
		this.positionSim?.destroy()

		this.particleMaterial?.uniforms.tOriginalPosition?.value?.dispose()
		this.customDepthMaterial?.uniforms.tPosition?.value?.dispose()
		this.motionMaterial?.uniforms.tPosition?.value?.dispose()
		this.motionMaterial?.uniforms.tPrevPosition?.value?.dispose()

		for (const key in this._debugMeshes) {
			this._debugMeshes[key].geometry.dispose()
			this._debugMeshes[key].material.dispose()
			this._debugMeshes[key].material?.uniforms.tTexture.value?.dispose()
			this._debugMeshes[key].parent.remove(this._debugMeshes[key])
		}

		for (const key in this._debugSplines) {
			this._debugSplines[key].geometry.dispose()
			this._debugSplines[key].material.dispose()
			this._debugSplines[key].parent.remove(this._debugSplines[key])
		}

		this.splineTexture?.dispose()
		this.attributeTexture?.dispose()

		if (this.motionRT) this.motionRT.dispose()
		store.WebGL.motionBlurPass?.material?.uniforms.tMotionMap?.value?.dispose()
	}

	load() {
		this.assets = {
			models: {},
			textures: {}
		}

		const basePath = store.publicUrl + 'webgl'

		store.AssetLoader.loadTexture(`${basePath}/textures/normals/rock-01/atlas.png`, { colorSpace: LinearSRGBColorSpace }).then(texture => {
			this.assets.textures.normals = texture
		})
	}
}