import { ClampToEdgeWrapping, Clock, Color, LinearFilter, LinearMipmapLinearFilter, LinearSRGBColorSpace, MathUtils, Scene, Texture, UVMapping, Vector2, WebGLRenderer } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

import store from '_store'
import { setupShaderChunks } from '_materials'
import { GlobalEvents, mergeDeep } from '_utils'
import { Component } from '_components/unseen'
import ArrayOrderController from '_utils/ArrayOrderController'
import PMREMHandler from './utils/PMREMHandler'
import defaultVert from '_glsl/default.vert'
import fxaaFrag from '_glsl/fxaa/frag.glsl'
import fxaaVert from '_glsl/fxaa/vert.glsl'
import motionBlurFrag from '_glsl/motionBlur/frag.glsl'
import compositeFrag from '_glsl/composite/frag.glsl'
import BaseMaterial from './materials/unseen/base/BaseMaterial'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import BaseScene from './scenes/BaseScene'
import RTViewer from './utils/RTViewer'
import FluidSim from './components/unseen/FluidSim'
import Stats from 'stats-gl'
import LensFlareEffect from './passes/LensFlareEffect'

export default class WebGL extends Component {
	constructor(el, options = {}) {
		options = mergeDeep({
			renderer: {
				alpha: false,
				antialias: false,
				canvas: el,
				powerPreference: 'high-performance',
				stencil: false,
				shadowMap: false
			},
			scenes: {},
			composerPassOrder: {}
		}, options)

		super(el)

		this.options = options

		this.isBuilt = false

		this.renderer = new WebGLRenderer(this.options.renderer)
		store.AssetLoader.ktxLoader.detectSupport(this.renderer)

		const dbgRenderInfo = this.renderer.getContext().getExtension("WEBGL_debug_renderer_info")
		if (dbgRenderInfo != null) {
			const gpuName = this.renderer.getContext().getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL)
			// detect low tier gpu
			if (gpuName.includes('Intel') && (gpuName.includes('Plus') || gpuName.includes('HD'))) {
				store.isLowTierGPU = true
			}
		}

		if (store.isLowTierGPU) {
			this.renderer.setPixelRatio(store.window.dpr >= 2 ? 1.25 : store.window.dpr)
		} else {
			this.renderer.setPixelRatio(store.window.dpr >= 2 ? 2 : store.window.dpr)
		}

		this.renderer.setSize(store.window.w, store.window.h)
		this.renderer.info.autoReset = false

		if (this.options.renderer.shadowMap) {
			this.renderer.shadowMap.enabled = true
			this.renderer.shadowMap.type = this.options.renderer.shadowMap
		}

		this.composer = new EffectComposer(this.renderer)
		this.composer.enabled = true
		this.composerPasses = new ArrayOrderController(this.composer.passes)

		this.composerPassOrder = {
			...this.options.composerPassOrder,
			postfx: 200
		}

		this.pmremHandler = new PMREMHandler(this.renderer)

		this.clock = new Clock()
		this.clockDelta = 0
		this.normalizedDelta = 0

		if (store.env !== 'production') {
			this.rtViewer = new RTViewer()
			this.rtViewer?.registerRT(this.composer.renderTarget1, 'Composer Result')
		}

		this.buildStats()

		this.globalUniforms = {
			uDelta: { value: 0 },
			uNormalizedDelta: { value: 0 },
			uTime: { value: 0 },
			uResolution: { value: new Vector2(store.window.w * this.renderer.getPixelRatio(), store.window.h * this.renderer.getPixelRatio()) }
		}

		this.scenes = { ...this.options.scenes }

		/** @type { import("./scenes/BaseScene").default } */
		this.activeScene = null

		// only build specified scene otherwise build first that appears in the object
		if (store.urlParams.has('scene')) {
			const sceneName = store.urlParams.get('scene')

			if (this.scenes[sceneName]) {
				this.scenes[sceneName] = this.scenes[sceneName] ? new this.scenes[sceneName](sceneName, {}, this) : new BaseScene(sceneName) // get scene class if it exists, otherwise use BaseScene
				store.sceneName = sceneName
			} else {
				alert('No scene found called: ' + sceneName)

				for (const key in this.scenes) {
					if (this.scenes[key] !== null) {
						this.scenes[key] = new this.scenes[key](key, {}, this)
					}
				}

				store.sceneName = Object.keys(this.scenes)[0]
			}
		} else {
			for (const key in this.scenes) {
				if (this.scenes[key] !== null) {
					this.scenes[key] = new this.scenes[key](key, {}, this)
				}
			}

			// set the first scene as the active scene
			store.sceneName = Object.keys(this.scenes)[0]
		}

		setupShaderChunks()
	}

	build() {
		if (this.isBuilt) return

		this.isBuilt = true
		this.buildFluidSim()
		this.buildPasses()
	}

	buildFluidSim() {
		this.fluidSim = new FluidSim({
			simulationOpts: {
				uniforms: {
					uMouseRadius: { value: 0.2, gui: { min: 0, max: 1.0, step: 0.01 } },
					uPressure: { value: 0.999199, gui: { min: 0.0, max: 1, step: 0.001 } },
					uDissipation: { value: 0.0011, gui: { min: 0.0, max: 1, step: 0.001 } },
					uViscosity: { value: 0.001100, gui: { min: 0, max: 0.1, step: 0.001 } },
					uCurlStrength: { value: 0.243, gui: { min: 0, step: 0.01 } }
				}
			},
			fluid: {
				force: 20,
				forceClamp: 50
			}
		})
	}

	buildPasses() {
		this.bloomPass = new UnrealBloomPass(new Vector2(store.window.w, store.window.h))
		this.bloomPass.threshold = 0.21
		this.bloomPass.strength = 0.24
		this.bloomPass.radius = 0.04
		this.bloomPass.name = 'Bloom'
		this.bloomPass.enabled = false

		this.fxaaPass = new ShaderPass(new BaseMaterial({
			vertexShader: fxaaVert,
			fragmentShader: fxaaFrag,
			uniforms: {
				tDiffuse: { value: null }
			},
			defines: {
				EDGE_THRESHOLD_MIN: 0.009,
				EDGE_THRESHOLD_MAX: 0.125,
				SUBPIXEL_QUALITY: 0.85,
				SAMPLES: 6
			},
			name: 'FXAAMaterial'
		}))
		this.fxaaPass.name = 'FXAA'
		this.fxaaPass.material.name = 'FXAA'

		this.motionBlurPass = new ShaderPass(new BaseMaterial({
			vertexShader: defaultVert,
			fragmentShader: motionBlurFrag,
			uniforms: {
				tDiffuse: { value: null },
				tMotionMap: { value: null },
				uEnabled: { value: false },
				uBlurAmount: { value: 0.15 }
			},
			name: 'MotionBlur'
		}))
		this.motionBlurPass.name = 'Motion Blur'

		this.lensFlareFX = new LensFlareEffect({ passOrder: this.composerPassOrder })
		this.lensFlareFX?.build({ passIndex: 3 })

		this.compositePass = new ShaderPass(new BaseMaterial({
			vertexShader: defaultVert,
			fragmentShader: compositeFrag,
			uniforms: {
				tDiffuse: { value: null },
				tOriginal: { value: this.lensFlareFX?.savePass.renderTarget.texture },
				uEnabled: { value: true },
				uLensHalo: { value: true },
				uLensHaloOpacity: { value: 1 },
				uChromaticAberration: { value: true },
				uVignette: { value: true },
				uNoise: { value: true },
				uMaxDistort: { value: 1.460 },
				uBendAmount: { value: -0.032 },
				uVignetteStrength: { value: 0.922 },
				uVignetteInnerStrength: { value: 0.48 },
				uVignetteOuterStrength: { value: 0.28 },
				uVignetteColor: { value: new Color(0x000000) },
				uNoiseStrength: { value: 0.052 }
			},
			name: 'FinalComposite'
		}))
		this.compositePass.name = 'Final Composite'

		// output pass only needed for viewing preview of final render in dev mode
		if (store.env !== 'production' && store.env !== 'browsersync-no-theatre') {
			this.outputPass = new OutputPass()
		}

		this.composerPasses.add(this.fxaaPass, this.composerPassOrder.postfx)
		this.composerPasses.add(this.motionBlurPass, this.composerPassOrder.postfx + 1)
		if (!store.isLowTierGPU) {
			this.composerPasses.add(this.bloomPass, this.composerPassOrder.postfx + 2)
		}
		this.composerPasses.add(this.compositePass, this.composerPassOrder.postfx + 10)

		if (this.outputPass) {
			this.composerPasses.add(this.outputPass, this.composerPassOrder.postfx + 11)
		}
	}

	buildStats() {
		if (store.env !== 'production') {
			this.stats = new Stats({
				samplesLog: 200,
				samplesGraph: 10
			})

			this.stats.visible = false

			this.stats.init(this.renderer)

			this.stats.dom.classList.add('js-stats')
			Object.assign(this.stats.dom.style, {
				display: 'none',
				width: '100%',
				justifyContent: 'center',
				pointerEvents: 'none'
			})
			const style = `
						.js-stats > * {
							position: static !important;
						}
					`
			const styleEl = document.createElement('style')
			styleEl.innerHTML = style
			this.stats.dom.appendChild(styleEl)
			document.body.appendChild(this.stats.dom)
		}
	}

	showStats() {
		this.stats.dom.style.display = 'flex'
		this.stats.visible = true
	}

	hideStats() {
		this.stats.dom.style.display = 'none'
		this.stats.visible = false
	}

	preRender() {
		for (const key in this.scenes) {
			if (this.scenes[key] instanceof Scene) {
				this.scenes[key].renderPass.enabled = true
				this.scenes[key].onRaf()
			}
		}

		store.WebGL.composer.render()

		for (const key in this.scenes) {
			if (this.scenes[key] instanceof Scene) {
				this.scenes[key].renderPass.enabled = false
			}
		}
	}

	start() {
		this.addEvents()
		this.rtViewer?.enable()
	}

	stop() {
		this.removeEvents()
		this.rtViewer?.disable()
	}

	addEvents() {
		this.on(GlobalEvents.RESIZE, this.onResize)
		store.RAFCollection.add(this.beforeRender, 0)
		store.RAFCollection.add(this.render, 100)
		this.on('FPSChecked', this.onFPSChecked)
	}

	removeEvents() {
		this.off(GlobalEvents.RESIZE, this.onResize)
		store.RAFCollection.remove(this.beforeRender)
		store.RAFCollection.remove(this.render)
		this.off('FPSChecked', this.onFPSChecked)
	}

	beforeRender = (time) => {
		this.clockDelta = this.clock.getDelta()
		this.globalUniforms.uDelta.value = this.clockDelta > 0.016 ? 0.016 : this.clockDelta
		this.globalUniforms.uTime.value = time
		this.clockDelta = Math.round(this.clockDelta * 1000) / 1000 // remove all extra decimal places
		this.clockDelta = MathUtils.clamp(this.clockDelta, 0, 0.016) // don't let delta be slower than 60fps
		this.normalizedDelta = this.clockDelta / 0.016 // multiply any RAF animations by this value to normalise its speed to 60fps
		this.globalUniforms.uNormalizedDelta.value = this.normalizedDelta
		this.renderer.info.reset()
	}

	render = () => {
		if (this.composer.enabled) {
			this.composer.render()
		}

		this.stats?.update()
		store.Gui && store.Gui.refresh(false)
	}

	onResize = () => {
		this.renderer.setSize(store.window.w, store.window.h)
		this.composer.setSize(store.window.w, store.window.h)
		this.globalUniforms.uResolution.value.set(store.window.w * this.renderer.getPixelRatio(), store.window.h * this.renderer.getPixelRatio())

		this.lensFlareFX?.setSize(store.window.w, store.window.h)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			// this.bloomPass.enabled = false
			// this.motionBlurPass.enabled = false // ? maybe

			if (this.renderer.getPixelRatio() <= 2 && this.renderer.getPixelRatio() > 1.5) {
				this.renderer.setPixelRatio(1.5)
				this.composer.setPixelRatio(1.5)
			}

			if (this.renderer.getPixelRatio() <= 1.5 && this.renderer.getPixelRatio() > 1.25) {
				this.renderer.setPixelRatio(1.25)
				this.composer.setPixelRatio(1.25)
			}

			this.globalUniforms.uResolution.value.set(store.window.w * this.renderer.getPixelRatio(), store.window.h * this.renderer.getPixelRatio())
		}
	}

	generateTexture(texture, options = {}) {
		if (texture instanceof HTMLImageElement) {
			texture = new Texture(texture)
		}
		texture.minFilter = options.minFilter || LinearMipmapLinearFilter
		texture.magFilter = options.magFilter || LinearFilter
		texture.wrapS = texture.wrapT = options.wrapping || ClampToEdgeWrapping
		texture.flipY = options.flipY !== undefined ? options.flipY : true
		texture.colorSpace = options.colorSpace || LinearSRGBColorSpace
		texture.mapping = options.mapping || UVMapping
		this.renderer.initTexture(texture)
		return texture
	}

	destroy() {
		super.destroy()

		this.stop()
		this.bloomPass.dispose()
		this.fxaaPass.dispose()
		this.motionBlurPass.dispose()
		this.motionBlurPass?.material?.uniforms.tMotionMap?.value?.dispose()
		this.motionBlurPass?.material?.uniforms.tDiffuse?.value?.dispose()
		this.compositePass.dispose()
		this.outputPass?.dispose()
		this.lensFlareFX?.dispose()
		this.fluidSim?.destroy()
		this.composer.dispose()
		this.renderer.dispose()
	}
}
