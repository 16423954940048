// #include <default_pars>
// #include <default_frag_pars>
#include <fog_frag_pars>

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vViewPosition;
varying vec4 vWorldPosition;

uniform sampler2D tNormal;
uniform float uNormalMapRepeat;
uniform float uNormalMapStrength;
uniform vec3 uColor;
uniform vec3 uAmbientColor;
uniform vec3 uEmissiveColor;
uniform vec3 uSpecularColor;
uniform float uSpecularShininess;
uniform float uSpecularStrength;
uniform vec3 uLightColor;
uniform vec3 uLightDirection;
uniform sampler2D tFluid;
uniform sampler2D tFluidMask;
uniform bool uFluidEnabled;
uniform bool uFluidMaskEnabled;
uniform float uFluidStrength;
uniform float uFluidLerpSpeed;
uniform vec2 uResolution;
uniform float uSecondaryLineOffset;
uniform float uSecondaryLineScale;
uniform vec3 uSecondaryLineColor;
uniform vec3 uPrimaryLineColor;
uniform float uPrimaryLineOffset;

#include <common>
#include <packing>
#include <lights_pars_begin>
#include <shadowmap_pars_fragment>
#include <shadowmask_pars_fragment>
vec3 uShadowColor = vec3(0.0);
uniform float uShadowIntensity;

#ifdef DEPTH_PACKING
varying vec2 vHighPrecisionZW;
#endif

struct BlinnPhongMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularShininess;
    float specularStrength;
};

float G_BlinnPhong_Implicit( ) {
    return 0.25;
}
float D_BlinnPhong( const in float shininess, const in float dotNH ) {
    return RECIPROCAL_PI * ( shininess * 0.5 + 1.0 ) * pow( dotNH, shininess );
}
vec3 BRDF_BlinnPhong( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float shininess ) {
    vec3 halfDir = normalize( lightDir + viewDir );
    float dotNH = saturate( dot( normal, halfDir ) );
    float dotVH = saturate( dot( viewDir, halfDir ) );
    vec3 F = F_Schlick( specularColor, 1.0, dotVH );
    float G = G_BlinnPhong_Implicit( );
    float D = D_BlinnPhong( shininess, dotNH );
    return F * ( G * D );
}

void RE_Direct_BlinnPhong( const in IncidentLight directLight, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    float dotNL = saturate( dot( geometryNormal, directLight.direction ) );
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
    reflectedLight.directSpecular += irradiance * BRDF_BlinnPhong( directLight.direction, geometryViewDir, geometryNormal, material.specularColor, material.specularShininess ) * material.specularStrength;
}

void RE_IndirectDiffuse_BlinnPhong( const in vec3 irradiance, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
}

float rand(float n){return fract(sin(n) * 43758.5453123);}

float noise(float p){
	float fl = floor(p);
  float fc = fract(p);
	return mix(rand(fl), rand(fl + 1.0), fc);
}

void main() {
    #ifdef DEPTH_PACKING

        gl_FragColor = vec4(1.);

    #else
        // vec3 fluid = texture2D(tFluid, gl_FragCoord.xy / uResolution).rgb;

        vec3 fluid = vec3(0.0);

        vec3 normal = normalize(vNormal);

        vec4 diffuseColor = vec4( uColor, 1. );

        // triplanar normal matrix
        vec3 T = vec3(0.,1.,0.);
        vec3 BT = normalize( cross( vNormal, T ) * 1.0 );
        mat3 tsb = mat3( normalize( T ), normalize( BT ), normalize( vNormal ) );

        // sample normal map
        vec3 normalTexture = texture2D(tNormal, vUv * uNormalMapRepeat).rgb;
        normalTexture = normalTexture * 2.0 - 1.0;
        normalTexture.xy *= uNormalMapStrength - abs(length(fluid.xy)) * uFluidStrength * 10.;
        normalTexture = normalize( normalTexture );

        normal = tsb * normalTexture;

        // grey lines
        float lineNoise = noise(max(-0.5, (vWorldPosition.y - uSecondaryLineOffset - length(fluid.xy) * uFluidStrength) * uSecondaryLineScale) * length(normal));
        // lineNoise = lineNoise - noise(max(0., vWorldPosition.y * 1.543));
        diffuseColor.rgb = mix(diffuseColor.rgb, uSecondaryLineColor, lineNoise);
    
        // red line
        lineNoise = smoothstep(0.1, 0., noise( max(-1., (vWorldPosition.y - uPrimaryLineOffset - length(fluid.xy) * uFluidStrength) * 30. * length(normal))));
        diffuseColor.rgb = mix(diffuseColor.rgb, uPrimaryLineColor, lineNoise);

        // normal.xy *= (1. - lineNoise) * 0.9;

        ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
        vec3 totalEmissiveRadiance = uEmissiveColor;
        vec3 irradiance = getAmbientLightIrradiance( uAmbientColor );

        IncidentLight directLight;
        DirectionalLight directionalLight;
        directionalLight.direction = normalize( uLightDirection );
        directionalLight.color = uLightColor;
        getDirectionalLightInfo( directionalLight, directLight );

        BlinnPhongMaterial material;
        material.diffuseColor = diffuseColor.rgb;
        material.specularColor = uSpecularColor;
        material.specularShininess = uSpecularShininess;
        material.specularStrength = uSpecularStrength;
        vec3 geometryPosition = - vViewPosition;
        vec3 geometryNormal = normal;
        vec3 geometryViewDir = normalize( vViewPosition );

        RE_Direct_BlinnPhong( directLight, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );
        RE_IndirectDiffuse_BlinnPhong( irradiance, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );

        vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

        gl_FragColor = vec4(outgoingLight, 1.);

        // gl_FragColor = vec4(uColor * lightIntensity, 1.);

        // gl_FragColor = vec4(normal, 1.);

    #endif

    #ifdef DEPTH_PACKING
		// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
        float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        gl_FragColor = packDepthToRGBA(fragCoordZ);
    #else
        gl_FragColor.rgb = mix(gl_FragColor.rgb, uShadowColor, (1.0 - getShadowMask() ) * uShadowIntensity);
	#endif

    #include <colorspace_fragment>

    #include <fog_frag>
}