import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color, UniformsLib } from 'three'

export default class RockImposterMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				uColor: { value: new Color(0x494949) },
				uAmbientColor: { value: new Color(0x000000) },
				uEmissiveColor: { value: new Color(0x000000) },
				uSpecularColor: { value: new Color(0xFFFFFF) },
				uSpecularStrength: { value: 0.22, gui: { min: 0, max: 1, step: 0.01 } },
				uSpecularShininess: { value: 27.2, gui: { min: 0.001, max: 100, step: 0.1 } },
				uShadowIntensity: { value: 0.9, gui: { min: 0, max: 1, step: 0.01 } },
				...UniformsLib.lights
			},
			defines: {
				USE_FOG: true,
				SOLID_FOG: true,
				GRID_SIZE: 14
			},
			lights: true,
			transparent: true
		}, options)

		super(options)
	}
}