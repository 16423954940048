varying vec2 vUv;
varying vec2 vL;
varying vec2 vR;
varying vec2 vT;
varying vec2 vB;

uniform sampler2D tVelocity;

void main() {
    float L = texture(tVelocity, vL).y;
    float R = texture(tVelocity, vR).y;
    float T = texture(tVelocity, vT).x;
    float B = texture(tVelocity, vB).x;

    float vorticity = R - L - T + B;

    gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
}