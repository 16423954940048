#include <default_pars>
#include <default_frag_pars>

varying vec2 vUv;

uniform sampler2D tDiffuse; // Rendered screen
uniform vec2 uScreenSize;
uniform float uHaloRadius;
uniform float uHaloSmooth;
uniform float uHaloRGBShift;
uniform float uHaloStrength;
uniform vec2 uTexelSize;

uniform float uHaloMaskMax;
// uniform float uHaloMaskMin;

// Three.js
float luminance( const in vec3 rgb ) {
	// assumes rgb is in linear color space with sRGB primaries and D65 white point
	const vec3 weights = vec3( 0.2126729, 0.7151522, 0.0721750 );
	return dot( weights, rgb );
}

/* Based on http://john-chapman-graphics.blogspot.com/2013/02/pseudo-lens-flare.html */

void main()
{	
   vec4 texel = texture2D(tDiffuse, vUv); // Thresholded image

   // float screenRes = uScreenSize.y / uScreenSize.x;
   float screenRes = uScreenSize.x / uScreenSize.y;
   if (uScreenSize.x / uScreenSize.y > 1.) {
      screenRes = 1.;
   } // 1> if horizontal, <1 if vertical

   // Prepare UVs for ghost spots and halo generation
   vec2 centerUV = (vUv-0.5); // Make origin in center of screen
   centerUV.x *= uScreenSize.x / uScreenSize.y; // Take into account screen res, so it's always circular
   vec2 ghostUV = 1.0 - (centerUV+0.5); // Bring the UV back to the corner and turn around
   vec2 ghostVec = (vec2(0.5) - ghostUV); // Ghost vector to image centre

   gl_FragColor = texel; // Include the thresholded image too
   gl_FragColor.rgb = vec3(0.); // Get only the rim

   // float uGhostDispersal = 0.05;
   // vec2 dispersedGhostVec = ghostVec * uGhostDispersal;
   // Ghost spots
   // int uGhostNumber = 2; // How intense they are basically
   // for (int i = 0; i < uGhostNumber; ++i) { 
   //    vec2 offset = fract(ghostUV + dispersedGhostVec * float(i));
   // // allow only bright spots at the centre of the source image to generate ghosts
   //    float weight = length(vec2(0.5) - offset) / length(vec2(0.5));
   //    weight = pow(1.0 - weight, 5.0);

   //    gl_FragColor.rgb += (texture2D(tDiffuse, offset) * weight).rgb;
   // }

   // Halo
   vec2 direction = normalize(ghostVec);
   // float uHaloRadius = 0.45; // More like a radius
   vec2 haloVec = direction * uHaloRadius * screenRes;
   float weight = length(vec2(0.5) - fract(ghostUV + haloVec)) / length(vec2(0.5)); 
   // float weight = length(vec2(0.5) - fract(ghostUV + haloVec)); 
   weight = pow(1.0 - weight, uHaloSmooth); // Power to how steep the halo is

   // Add Chromatic aberration to the halo
   // vec2 uTexelSize = 1.0 / vec2(textureSize(tDiffuse, 0)); // ?

   vec3 distortion = vec3(-uTexelSize.x, 0.0, uTexelSize.x)*uHaloRGBShift;
   // Kinda the same as above
   distortion = vec3(-uTexelSize.x * uHaloRGBShift, 0.0, uTexelSize.x * uHaloRGBShift);

   // Some weird Blurring steps ?
   // float zoomBlurRatio = fract(atan(centerUV.y, centerUV.x)*40.0)*0.05+0.95;
   // vec2 uv = ghostUV*zoomBlurRatio+haloVec;
   // gl_FragColor.rgb += vec3(texture2D(tDiffuse, uv+direction*distortion.r).r, 
   //                         texture2D(tDiffuse, uv+direction*distortion.g).g, 
   //                         texture2D(tDiffuse, uv+direction*distortion.b).b) * 
   //                         uHaloStrength*smoothstep(0.0, 1., length(centerUV));

   vec2 uv = ghostUV + haloVec;
   gl_FragColor.r += (texture2D(tDiffuse, uv + direction * distortion.r) * weight).r;
   gl_FragColor.g += (texture2D(tDiffuse, uv + direction * distortion.g) * weight).g;
   gl_FragColor.b += (texture2D(tDiffuse, uv + direction * distortion.b) * weight).b;
   gl_FragColor.rgb *= clamp(uHaloStrength * smoothstep(0.0, uHaloMaskMax, length(centerUV)), 0.0, 1.0);

//    gl_FragColor.rgb = (texture2D(tDiffuse, vUv)).rgb;
//    gl_FragColor.r += 0.2;
}