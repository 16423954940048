import Component from "./Component"
import store from "_store"

export default class CookieNotice extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-cookie-notice'

	/**
	 * @param {HTMLElement|Element} el
	 * @param {{}} [options]
	 * @param {string|null} [options.hostname=null] Optionally override hostname
	 * @param {boolean} [options.includeSubDomains=false] Whether the cookie acceptance should carry onto subdomains as well
	 */
	constructor(el, options = {}) {
		super(el)

		this.populateDom()

		this.hostname = options.hostname || null
		this.includeSubDomains = options.includeSubDomains || false

		if (this.hostname === null) {
			this.hostname = window.location.hostname
		}

		this.updateStore()

		if (!document.cookie.match(/cookie_notice_accepted/)) {
			this.on('click', this.dom.accept, this.onAccept)
			this.dom.close && this.on('click', this.dom.close, this.onClose)
			this.dom.reject && this.on('click', this.dom.reject, this.onReject)
			this.show()
		} else {
			this.hide()
		}
	}

	/**
	 * Public hide method
	 */
	hide() {
		this.el.classList.remove('is-open')
		this.el.style.display = 'none'
	}

	/**
	 * Public show method
	 */
	show() {
		this.el.classList.add('is-open')
		this.el.classList.remove('d-none')
	}

	/**
	 * .js-cookie-notice:accept click handler
	 * @private
	 */
	onAccept = () => {
		this.setCookie('true')
		this.enableAnalytics()
		this.updateStore()
		this.hide()
	}

	/**
	 * .js-cookie-notice:close click handler
	 * @private
	 */
	onClose = () => {
		this.hide()
	}

	/**
	 * .js-cookie-notice:reject click handler
	 * @private
	 */
	onReject = () => {
		this.setCookie('false')
		this.updateStore()
		this.hide()
	}

	/**
	 * @param {boolean} value
	 * @private
	 */
	setCookie(value) {
		const expiryDate = new Date()
		expiryDate.setMonth(expiryDate.getMonth() + 1)

		let cookie = `cookie_notice_accepted=${value};expires=${expiryDate.toUTCString()};path=/`

		if (this.includeSubDomains) {
			cookie += `;domain=${this.hostname}`
		}

		document.cookie = cookie
	}

	/**
	 * @private
	 */
	enableAnalytics() {
		if (window.gtag) {
			window.gtag('consent', 'default', {
				ad_storage: 'granted',
				analytics_storage: 'granted'
			})
		}
	}

	/**
	 * Update store.cookieNoticeAccepted value
	 * @private
	 */
	updateStore() {
		store.cookieNoticeAccepted = document.cookie
			.split(';')
			.some((item) => item.includes('cookie_notice_accepted=true'))

		if (store.cookieNoticeAccepted === true) {
			this.emit('cookienotice:accepted')
		}
	}
}
