varying vec2 vUv;

uniform sampler2D tTexture;
uniform vec2 uScreenSize;
uniform vec2 uImageSize;

void main() {
	vec2 uv = vUv;

	vec2 s = uScreenSize;
	vec2 i = uImageSize;
	float rs = s.x / s.y;
	float ri = i.x / i.y;

	#ifdef CROP
		vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
		vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
	#else
		vec2 new = rs > ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
		vec2 offset = (rs > ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
	#endif

	uv = vUv * s / new + offset;

	// make out-of-bounds pixels black
	if (uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0 || uv.y > 1.0) {
		gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
		return;
	}

	gl_FragColor = texture2D(tTexture, uv);

	#include <colorspace_fragment>
}