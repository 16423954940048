import { Euler, LinearSRGBColorSpace, Mesh, MeshPhysicalMaterial, SRGBColorSpace, ShaderMaterial, Vector3 } from "three"
import createComponent from "./unseen/Component"
import { mergeDeep } from "_utils/index"
import store from "_store"
import copyObjectDataTransforms from "_utils/functions/copyObjectDataTransforms"
import { types } from "@theatre/core"

export default class IntroArch extends createComponent(Mesh) {
	constructor(options = {}) {
		options = mergeDeep({
			visible: true,
			scene: null,
			name: 'Intro Arch',
			scale: new Vector3(1, 1, 1),
			position: new Vector3(0, 0, 0),
			rotation: new Euler(0, 0, 0),
			objectData: {},
			enableFog: false
		}, options)

		super(options)

		if (Object.keys(this.options.objectData).length) {
			copyObjectDataTransforms(this, this.options.objectData)
		} else {
			this.position.copy(this.options.position)
			this.scale.copy(this.options.scale)
			this.rotation.copy(this.options.rotation)
		}

		this.frustumCulled = false
	}

	build() {
		this.options.scene = this.parent

		this.geometry = this.assets.models.arch.geometry
		this.defaultMaterial = new MeshPhysicalMaterial({
			color: 0xffffff,
			normalMap: this.assets.textures.arch.normal,
			normalScale: new Vector3(1, -1), // flip on y
			roughnessMap: this.assets.textures.arch.roughness,
			map: this.options.name === 'Intro Arch' ? this.assets.textures.arch['base-color'] : null,
			roughness: 0.9
		})

		this.material = this.defaultMaterial

		if (this.options.enableFog) {
			this.defaultMaterial.onBeforeCompile = this.onBeforeCompile.bind(this)
		}

		this.depthMaterial = new ShaderMaterial({
			vertexShader: `
				#include <common>
				varying float vDepth;

				varying vec2 vHighPrecisionZW;

				void main() {
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
					vDepth = (gl_Position.z / gl_Position.w) * 0.5 + 0.5;

					vHighPrecisionZW = gl_Position.zw;
				}
			`,
			fragmentShader: `
				#include <common>
				#include <packing>
				varying float vDepth;

				varying vec2 vHighPrecisionZW;

				void main() {

					float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        			gl_FragColor = packDepthToRGBA(fragCoordZ);	 // higher precision

					// gl_FragColor = packDepthToRGBA(vDepth);
				}
			`
		})

		this.godraysMaterial = new ShaderMaterial({
			vertexShader: `
				#include <common>

				varying vec2 vUv;

				varying vec3 vNormal;
				varying float vDepth;
				varying vec2 vHighPrecisionZW;

				void main() {
					vUv = uv;
					
					vNormal = normalize(normalMatrix * normal);
					
					gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);

					vDepth = (gl_Position.z / gl_Position.w) * 0.5 + 0.5;
					vHighPrecisionZW = gl_Position.zw;
				}
			`,
			fragmentShader: `
				#include <common>
				varying vec2 vUv;
				
				varying vec3 vNormal;
				varying float vDepth;
				varying vec2 vHighPrecisionZW;

				void main() {
					float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        			pc_fragColor = vec4(vNormal, fragCoordZ); // pack normal depth with higher precision

					// pc_fragColor = vec4(vNormal, vDepth); // pack normal depth
				}
			`,
			uniforms: {}
		})

		this.castShadow = true
		this.receiveShadow = true

		if (this.options.scene.cameraLayers.godrays) this.layers.set(this.options.scene.cameraLayers.godrays)
	}

	onBeforeCompile = (shader) => {
		shader.defines.USE_FOG = true
		shader.defines.SOLID_FOG = false

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_pars_vertex>',
			`
            varying vec3 vWorldPosition;
            #include <fog_vert_pars>
            `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_vertex>',
			`
            vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            #include <fog_vert>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_pars_fragment>',
			`
			uniform vec2 uResolution;
            varying vec3 vWorldPosition;
            #include <fog_frag_pars>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_fragment>',
			`
            #include <fog_frag>
            `
		)
	}

	setDepthMaterial() {
		this.material = this.depthMaterial
	}

	setDefaultMaterial() {
		this.material = this.defaultMaterial
	}

	setGodraysMaterial() {
		this.material = this.godraysMaterial
	}

	destroy() {
		super.destroy()
		// console.log('Destroying IntroArch')
		this.geometry.dispose()
		this.defaultMaterial.dispose()
		this.depthMaterial.dispose()
		this.godraysMaterial.dispose()

		this.parent.remove(this)
		for (const key in this.assets.textures.arch) {
			this.assets.textures.arch[key].dispose()
		}
		for (const key in this.assets.models) {
			this.assets.models[key].geometry?.dispose()
			this.assets.models[key].material?.dispose()
		}
	}

	load() {
		this.assets.textures.arch = {}

		const basePath = store.publicUrl + 'webgl'

		const textures = ['base-color', 'normal', 'roughness']

		textures.forEach(textureName => {
			store.AssetLoader.loadTexture(`${basePath}/textures/arch-${textureName}.png`, { colorSpace: textureName === 'base-color' ? SRGBColorSpace : LinearSRGBColorSpace, flipY: false }).then(texture => {
				this.assets.textures.arch[textureName] = texture
			})
		})

		store.AssetLoader.loadGltf(`${basePath}/models/arch.glb`).then(gltf => {
			this.assets.models.arch = gltf.scene.children[0]
		})
	}

	addGui() {
		const sheetObject = store.theatre.helper.autoAddObject(this, this.parent.prettyName, {
			additionalConfig: {
				color: store.theatre.helper.parseColor(this.material.color),
				roughness: types.number(this.material.roughness, { range: [0, 1], nudgeMultiplier: 0.01 }),
				specularIntensity: this.material.specularIntensity,
				specularColor: store.theatre.helper.parseColor(this.material.specularColor)
			}
		})

		sheetObject.onValuesChange(values => {
			this.material.color.copy(values.color)
			this.material.roughness = values.roughness
			this.material.specularIntensity = values.specularIntensity
			this.material.specularColor.copy(values.specularColor)
		})
	}
}