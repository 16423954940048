import { Howl, Howler } from 'howler'
import spriteJson from '../../audio/sfx.json'

import { E } from '_utils'
import store from '_store'

const data = {
	sfx: spriteJson.sprite
}

function getDotAccessParts(string) {
	return string.split('.')
}

/**
 * Audio sprite manager, powered by Howler.js
 *
 * @example store.Audio.play({ key: 'click' })
 * @example store.Audio.stop('click')
 * @example store.Audio.stopAll()
 * @example store.Audio.mute('click', true)
 * @example store.Audio.muteAll(true)
 */
export default class Audio {
	constructor() {
		this.sprites = {
			sfx: null
		}

		this.isUnlocked = false
		this.playQueue = []
		Howler.mute(false)

		this.html5 = false
		const ua = navigator ? navigator.userAgent : ''
		const isMacOSCatalina = ua.match(/Mac OS X 10_15/)
		const isSafari = ua.indexOf('Safari') !== -1 && ua.indexOf('Chrome') === -1
		const safariVersion = ua.match(/Version\/(.*?) /)
		if (isMacOSCatalina && isSafari && parseInt(safariVersion[1], 10) === 15) {
			this.html5 = true
		}

		for (const pool in this.sprites) {
			this.loadSprite(pool)
		}

		this.addDomEvents()

		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				Howler.mute(true)
			} else {
				if (!store.audioMuted) {
					Howler.mute(false)
				}
			}
		})

		this.activeSounds = {}
	}

	loadSprite(pool) {
		store.AssetLoader.add(new Promise((resolve, reject) => {
			this.sprites[pool] = new Howl({
				src: [`${store.assetsUrl}audio/${pool}.webm`, `${store.assetsUrl}audio/${pool}.mp3`],
				sprite: data[pool],
				html5: this.html5,
				onload: () => {
					resolve()
				},
				onloaderror: (a, b) => {
					console.error(a, b, pool)
					// eslint-disable-next-line prefer-promise-reject-errors
					reject()
				}
			})

			// unlock event is triggered only on interaction
			this.sprites[pool].on('unlock', this.unlockAudio)

			// if there's no interaction but the browser is allowed to play sound, we need to check the context state
			const AudioContext = window.AudioContext || window.webkitAudioContext
			const audioContext = new AudioContext()

			audioContext.onstatechange = () => {
				if (audioContext.state === 'running') {
					this.unlockAudio()
				}
			}
		}))
	}

	unlockAudio = () => {
		if (this.isUnlocked) return

		this.isUnlocked = true

		this.playQueue.forEach((item) => {
			this.play(item)
		})

		store.audioMuted = false
		store.audioUnlocked = true
		E.emit('audio:unlock')
	}

	unloadSprite(pool) {
		this.sprites[pool].unload()
	}

	addDomEvents() {
		E.delegate('mouseenter', '[data-audio-enter]', (e) => {
			if (!store.mq.touch.matches) {
				this.play({ key: e.currentTarget.dataset.audioEnter, isInteraction: true })
			}
		})

		E.delegate('mouseleave', '[data-audio-leave]', (e) => {
			if (!store.mq.touch.matches) {
				this.play({ key: e.currentTarget.dataset.audioLeave, isInteraction: true })
			}
		})

		E.delegate('click', '[data-audio-click]', (e) => {
			this.play({ key: e.currentTarget.dataset.audioClick, isInteraction: true })
		})

		E.delegate('click', '[data-audio-mute]', (e) => {
			if (e.currentTarget.dataset.audioMute && e.currentTarget.dataset.audioMute !== '') {
				this.mute(e.currentTarget.dataset.audioMute, true)
			} else {
				this.muteAll(true)
			}
		})

		E.delegate('click', '[data-audio-unmute]', (e) => {
			if (e.currentTarget.dataset.audioUnmute && e.currentTarget.dataset.audioUnmute !== '') {
				this.mute(e.currentTarget.dataset.audioUnmute, false)
			} else {
				this.muteAll(false)
			}
		})

		E.delegate('click', '[data-audio-stop]', (e) => {
			if (e.currentTarget.dataset.audioStop && e.currentTarget.dataset.audioStop !== '') {
				this.stop(e.currentTarget.dataset.audioStop)
			} else {
				this.stopAll()
			}
		})
	}

	addToPlayQueue({ key, fade, volume, speed, isInteraction, callback, on = {}, once = {} }) {
		this.playQueue.push({ key, fade, volume, speed, isInteraction, callback, on, once })
	}

	removeFromPlayQueue(key) {
		this.playQueue = this.playQueue.filter((item) => item.key !== key)
	}

	play({ key, fade, volume, speed, isInteraction, callback, on = {}, once = {} }) {
		const [pool, sprite] = getDotAccessParts(key)

		if (!this.sprites[pool]) {
			console.error(`Sound not found - ${pool}.${sprite}`)
			return
		}

		const isLoop = data[pool][sprite][2]

		// If a non-looping sprite, play freely
		if (!isLoop) {
			this.activeSounds[key] = this.sprites[pool].play(sprite, false)
		} else {
			// as this is a looping sprite, we don't want to play multiple times
			if (!this.activeSounds[key]) {
				this.activeSounds[key] = this.sprites[pool].play(sprite, false)
			}
		}

		if (this.activeSounds[key]) {
			// processing
			if (fade) {
				this.sprites[pool].fade(fade.from, fade.to, fade.duration * 1000, this.activeSounds[key])
			}

			if (volume) {
				this.sprites[pool].volume(volume, this.activeSounds[key])
			}

			if (speed && !this.html5) {
				for (let i = 0; i < this.sprites[pool]._sounds.length; i++) {
					if (this.sprites[pool]._sounds[i]._id === this.activeSounds[key]) {
						if (this.sprites[pool]._sounds[i]._node.bufferSource.playbackRate) {
							this.sprites[pool]._sounds[i]._node.bufferSource.playbackRate.value = speed
						}
					}
				}
			}
		}

		// Interactions are one-shots, so auto-remove from activeSounds
		if (isInteraction && !isLoop) {
			delete this.activeSounds[key]
		}

		if (!isInteraction) {
			this.sprites[pool].once('stop', () => {
				if (this.activeSounds[key]) {
					delete this.activeSounds[key]
				}
			}, this.activeSounds[key])

			if (!isLoop) {
				this.sprites[pool].once('end', () => {
					if (this.activeSounds[key]) {
						delete this.activeSounds[key]
					}

					if (callback) {
						callback()
					}
				}, this.activeSounds[key])
			}
		}

		for (const onKey in on) {
			this.sprites[pool].on(onKey, on[onKey], this.activeSounds[key])
		}

		for (const onceKey in once) {
			this.sprites[pool].once(onceKey, once[onceKey], this.activeSounds[key])
		}
	}

	isPlaying(key) {
		return !!this.activeSounds[key]
	}

	lerpSpeed(key, target, duration) {
		const [pool, sprite] = getDotAccessParts(key)

		if (this.sprites[pool]._sprite[sprite]) {
			for (let i = 0; i < this.sprites[pool]._sounds.length; i++) {
				const sound = this.sprites[pool]._sounds[i]

				if (sound._sprite === sprite) {
					const initial = sound?._node?.bufferSource?.playbackRate.value
					if (!initial) {
						return
					}

					const step = (initial > target ? initial - target : target - initial) / (duration / 50)

					const interval = setInterval(() => {
						const current = sound?._node?.bufferSource?.playbackRate?.value

						if (!current) {
							window.clearInterval(interval)
							return
						}

						if (initial > target) {
							if (current <= target) {
								window.clearInterval(interval)
								return
							}

							sound._node.bufferSource.playbackRate.value = current - step
						} else {
							if (current >= target) {
								window.clearInterval(interval)
								return
							}

							sound._node.bufferSource.playbackRate.value = current + step
						}
					}, 50)

					break
				}
			}
		}
	}

	filterTo({ key, type, duration, from = {}, to = {} }) {
		if (this.html5) {
			return
		}

		const [pool, sprite] = getDotAccessParts(key)
		const f = Object.assign({ frequency: 350, q: 1, gain: 0, detune: 0 }, from)
		const t = Object.assign({ frequency: 350, q: 1, gain: 0, detune: 0 }, to)

		if (this.sprites[pool]._sprite[sprite]) {
			for (let i = 0; i < this.sprites[pool]._sounds.length; i++) {
				const sound = this.sprites[pool]._sounds[i]

				if (sound._sprite === sprite) {
					const filter = Howler.ctx.createBiquadFilter()
					filter.type = type
					filter.frequency.value = f.frequency
					filter.Q.value = f.q
					filter.gain.value = f.gain
					filter.detune.value = f.detune

					sound._node.bufferSource.disconnect()

					if (sound.filter) {
						sound.filter.disconnect()
					}

					sound._node.bufferSource.connect(filter)
					sound.filter = filter

					filter.connect(sound._node)

					filter.frequency.exponentialRampToValueAtTime(t.frequency, sound._node.context.currentTime + (duration / 1000))

					if (f.detune !== t.detune) {
						filter.detune.exponentialRampToValueAtTime(t.detune, sound._node.context.currentTime + (duration / 1000))
					}

					if (f.q !== t.q) {
						filter.q.exponentialRampToValueAtTime(t.q, sound._node.context.currentTime + (duration / 1000))
					}

					break
				}
			}
		}
	}

	fadeTo({ key, to, duration, callback }) {
		if (!this.activeSounds[key]) {
			return
		}

		const cb = () => {
			if (callback) {
				callback()
			}
		}

		if (key) {
			const [pool] = getDotAccessParts(key)

			if (this.activeSounds[key]) {
				this.sprites[pool].once('fade', cb, this.activeSounds[key])
				this.sprites[pool].fade(this.sprites[pool].volume(this.activeSounds[key]), to, duration * 1000, this.activeSounds[key])
			}
		} else {
			let eventAttached = null

			for (const loopKey in this.activeSounds) {
				const soundId = this.activeSounds[loopKey]
				const [pool] = getDotAccessParts(loopKey)

				if (!eventAttached) {
					this.sprites[pool].once('fade', cb, soundId)
					eventAttached = true
				}

				this.sprites[pool].fade(this.sprites[pool].volume(soundId), to, duration * 1000, soundId)
			}
		}
	}

	fadeToStop({ key, to = 0, duration }) {
		if (!this.activeSounds[key]) {
			return
		}

		const callback = () => this.stop(key)
		this.fadeTo({ key, to, duration, callback })
	}

	fadeAllToStop({ to = 0, duration }) {
		for (const key in store.Audio.activeSounds) {
			store.Audio.fadeToStop({ key, to, duration })
		}
	}

	stop(key) {
		if (this.activeSounds[key]) {
			const [pool] = getDotAccessParts(key)
			this.sprites[pool].stop(this.activeSounds[key], false)
			delete this.activeSounds[key]
		}
	}

	stopAll() {
		Howler.stop()
		this.activeSounds = {}
	}

	setVolume({ key, volume }) {
		if (this.activeSounds[key]) {
			const [pool] = getDotAccessParts(key)
			this.sprites[pool].volume(volume, this.activeSounds[key])
		}
	}

	getVolume(key) {
		if (this.activeSounds[key]) {
			const [pool] = getDotAccessParts(key)
			return this.sprites[pool].volume(this.activeSounds[key])
		}
	}

	mute(key, state) {
		if (this.activeSounds[key]) {
			const [pool] = getDotAccessParts(key)

			this.sprites[pool].mute(state, this.activeSounds[key])
		}
	}

	muteAll(state) {
		Howler.mute(state)
		E.emit('AudioMute', state)
	}

	duration(key) {
		const [pool, sprite] = getDotAccessParts(key)

		if (this.sprites[pool]._sprite[sprite]) {
			return this.sprites[pool]._sprite[sprite][1]
		}

		return null
	}

	lerp(value1, value2, amount) {
		amount = amount < 0 ? 0 : amount
		amount = amount > 1 ? 1 : amount
		return value1 + (value2 - value1) * amount
	}
}
