import { Color, PerspectiveCamera, Scene, EquirectangularReflectionMapping, SRGBColorSpace, RepeatWrapping, MeshPhysicalMaterial, CatmullRomCurve3, Vector3, InstancedMesh, Object3D, Vector2, Euler, Quaternion, Texture } from 'three'
import store from '_store'
import { E, GlobalEvents, mergeDeep } from '_utils'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import copyObjectDataTransforms from '_utils/functions/copyObjectDataTransforms'
import SceneDevTools from '_webgl/utils/SceneDevTools'
import { onChange, types, val } from '@theatre/core'
import BrownianMotion from '_webgl/utils/BrownianMotion'
import ResourceTracker from '_webgl/utils/ResourceTracker'

export default class BaseScene extends Scene {
	constructor(name, options = {}, webgl) {
		super()

		this.postFxTheatreOverrides = {
			motionBlur: {},
			chromaticAberration: {},
			bloom: {},
			lensFlare: {}
		}

		this.shadowTheatreOverrides = {}

		this.enabled = false
		this.theatreIsPlaying = false
		this.runNextTheatreSequence = true
		this.isSwitchingChapter = false
		this.nextChapterTitleVisible = false
		this.chapterProgress = 0
		this.chapterSequenceLength = 0

		this.webgl = webgl

		this.name = name
		this.prettyName = name.charAt(0).toUpperCase() + name.slice(1).replace('-', ' ')

		// add theatre sheet for dev-related options
		store.theatre.studioSheets[this.prettyName] = store.theatre.studioProject?.sheet(this.prettyName)

		// add theatre sheet for scene-related options
		store.theatre.sheets[this.prettyName] = store.theatre.project.sheet(this.prettyName)
		store.theatre.sheets[this.prettyName]._webglScene = this

		this.objectData = {}
		if (store.objectDatas?.[this.name]) {
			this.objectData = JSON.parse(store.objectDatas[this.name])
		}

		this.meshes = {} // Collection of all meshes
		this.hierarchy = {} // Meshes and Objects organised in a Scene graph
		this.paths = {}

		this.options = mergeDeep({
			autoBuildObjects: true,
			fogEnabled: false,
			fluidEnabled: false,
			currentChapterTitleHideDelay: 0,
			nextChapterTitleShowPosition: 1,

			mouseMoveAngle: new Vector2(0.024, 0.024),
			mouseRollAmount: 0,
			cameraMotionSpeed: 0.8,
			cameraMotionPosAmplitude: 0.026,
			cameraMotionRotAmplitude: 0.007,
			cameraMotionPosFrequency: 0.29,
			cameraMotionRotFrequency: 0.1,
			cameraXTranslate: 0,
			cameraYTranslate: 0,
			cameraZTranslate: 0,
			cameraZOffset: 5,
			pointerLerpSpeed1: 0.032,
			pointerLerpSpeed2: 0.025,
			audioFiles: false
		}, options)

		this.origOptions = { ...this.options }

		this.cameraLayers = {
			all: 0
		}

		this.cameras = {
			camera1: this.createCamera('Camera 1'),
			camera2: this.createCamera('Camera 2')
		}

		const theatreCameraObject = {}

		for (const key in this.cameras) {
			theatreCameraObject[key] = this.cameras[key].name
		}

		store.theatre.helper.addSheetObject(this.prettyName, 'Cameras / Settings', {
			activeCamera: types.stringLiteral('camera1', theatreCameraObject),

			// pivot movement
			cameraXTranslate: types.number(this.options.cameraXTranslate, { nudgeMultiplier: 0.01 }),
			cameraYTranslate: types.number(this.options.cameraYTranslate, { nudgeMultiplier: 0.01 }),
			cameraZTranslate: types.number(this.options.cameraZTranslate, { nudgeMultiplier: 0.01 }),
			cameraZOffset: types.number(this.options.cameraZOffset, { nudgeMultiplier: 0.01 }),
			pointerLerpSpeed1: types.number(this.options.pointerLerpSpeed1, { nudgeMultiplier: 0.001 }),
			pointerLerpSpeed2: types.number(this.options.pointerLerpSpeed2, { nudgeMultiplier: 0.001 }),
			mouseMoveAngle: types.compound({
				x: types.number(this.options.mouseMoveAngle.x, { nudgeMultiplier: 0.001 }),
				y: types.number(this.options.mouseMoveAngle.y, { nudgeMultiplier: 0.001 })
			}),
			mouseRollAmount: types.number(this.options.mouseRollAmount, { nudgeMultiplier: 0.001 }),

			// brownian motion
			cameraMotionSpeed: types.number(this.options.cameraMotionSpeed, { nudgeMultiplier: 0.001 }),
			cameraMotionPosAmplitude: types.number(this.options.cameraMotionPosAmplitude, { range: [0, 1], nudgeMultiplier: 0.001 }),
			cameraMotionRotAmplitude: types.number(this.options.cameraMotionRotAmplitude, { range: [0, 1], nudgeMultiplier: 0.001 }),
			cameraMotionPosFrequency: types.number(this.options.cameraMotionPosFrequency, { range: [0, 1], nudgeMultiplier: 0.001 }),
			cameraMotionRotFrequency: types.number(this.options.cameraMotionRotFrequency, { range: [0, 1], nudgeMultiplier: 0.001 })
		}).onValuesChange(values => {
			this.activeCamera = this.cameras[values.activeCamera]

			this.options.mouseMoveAngle.x = values.mouseMoveAngle.x
			this.options.mouseMoveAngle.y = values.mouseMoveAngle.y
			this.options.mouseRollAmount = values.mouseRollAmount
			this.options.cameraMotionSpeed = values.cameraMotionSpeed
			this.options.cameraMotionPosAmplitude = values.cameraMotionPosAmplitude
			this.options.cameraMotionRotAmplitude = values.cameraMotionRotAmplitude
			this.options.cameraMotionPosFrequency = values.cameraMotionPosFrequency
			this.options.cameraMotionRotFrequency = values.cameraMotionRotFrequency
			this.options.cameraXTranslate = values.cameraXTranslate
			this.options.cameraYTranslate = values.cameraYTranslate
			this.options.cameraZTranslate = values.cameraZTranslate
			this.options.cameraZOffset = values.cameraZOffset
			this.options.pointerLerpSpeed1 = values.pointerLerpSpeed1
			this.options.pointerLerpSpeed2 = values.pointerLerpSpeed2

			// update brownian motion
			if (this.brownianMotion) {
				this.brownianMotion.positionAmplitude = this.options.cameraMotionPosAmplitude
				this.brownianMotion.rotationAmplitude = this.options.cameraMotionRotAmplitude
				this.brownianMotion.positionFrequency = this.options.cameraMotionPosFrequency
				this.brownianMotion.rotationFrequency = this.options.cameraMotionRotFrequency
			}
		}, store.theatre.rafDriver)

		this.activeCamera = this.cameras.camera1

		this._euler = new Euler()
		this._quaternion = new Quaternion()
		this.smoothMouse = [new Vector2(), new Vector2()]

		this.brownianMotion = new BrownianMotion()
		this.brownianMotion.positionAmplitude = this.options.cameraMotionPosAmplitude
		this.brownianMotion.rotationAmplitude = this.options.cameraMotionRotAmplitude
		this.brownianMotion.positionFrequency = this.options.cameraMotionPosFrequency
		this.brownianMotion.rotationFrequency = this.options.cameraMotionRotFrequency

		this.resourceTracker = new ResourceTracker()

		this.background = new Color(0x222222)

		this._instanceDummy = new Object3D()

		this.globalUniforms = {}
		this.globalDefines = {}

		// add keys of objects that exist in the data that you don't want automatically added
		this.ignoreDataObjects = []

		/* Add scene components */
		this.components = {}

		this.onTheatreSequenceUpdate = this.onTheatreSequenceUpdate.bind(this)

		this.load()
	}

	start() {
		if (this.enabled) return

		this.enabled = true
		store.WebGL.activeScene = this
		this.renderPass.enabled = true

		for (const key in this.components) {
			this.components[key].start && this.components[key].start()
		}

		// update post fx values for this scene using the stored values
		if (store.theatre.objects[this.prettyName]['Post FX']) {
			this.updatePostFXValuesFromTheatre(store.theatre.objects[this.prettyName]['Post FX'].value)
		}

		// update fluid sim values for this scene using the stored values
		if (store.theatre.objects[this.prettyName]['Fluid Sim']) {
			this.updateFluidSimValuesFromTheatre(store.theatre.objects[this.prettyName]['Fluid Sim'].value)
		}

		this.addEvents()

		if (this.options.fluidEnabled && store.WebGL.fluidSim) store.WebGL.fluidSim.addEvents()

		this.devTools?.createTheatreObject()

		store.WebGL.renderer.compile(this, this.activeCamera)
	}

	stop() {
		if (!this.enabled) return

		store.theatre.sheets[this.prettyName].sequence.position = 0

		this.enabled = false
		this.renderPass.enabled = false
		this.nextChapterTitleVisible = false
		this.isSwitchingChapter = false

		for (const key in this.components) {
			this.components[key].stop && this.components[key].stop()
		}

		this.removeEvents()

		this.pauseTheatreSequence()

		if (this.options.fluidEnabled && store.WebGL.fluidSim) store.WebGL.fluidSim.removeEvents()

		this.devTools?.disable()
		this.devTools?.detachTheatreObject()
	}

	build() {
		for (const key in this.cameraLayers) {
			for (const camKey in this.cameras) {
				this.cameras[camKey].layers.enable(this.cameraLayers[key])
			}
		}

		if (this.assets.textures.envmap) { // Build the PMREM map
			this.assets.textures[`${this.assets.textures.envmap.name}PMREM`] = store.WebGL.pmremHandler.buildEnvironmentTexture(this, this.assets.textures.envmap)
		}

		// Only add Dummy if we have no data
		// if (Object.keys(this.components).length === 0) {
		// 	this.components.dummy = new DummyComponent()
		// }

		this.buildEnvironment()
		this.buildObjectsFromData()
		this.buildPaths()
		this.buildPasses()
		this.addPostFXToTheatre()
		this.addFluidSimToTheatre()
		this.addAudioToTheatre()

		// Build components and add to scene
		for (const key in this.components) {
			if (this.components[key] instanceof Object3D) {
				this.add(this.components[key])
			}
			this.components[key].build && this.components[key].build(this.objectData)
			this.components[key].addGui && this.components[key].addGui()
		}

		E.on(GlobalEvents.RESIZE, this.onResize) // always leave resize enabled so things are correct if resizing on another page
		E.on('chapterSelect', this.onChapterSelect) // listen for chapter select events even when scene isn't active

		if (store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') {
			this.devTools = new SceneDevTools(this)
		}

		this.resourceTracker.track(this.children)
	}

	buildObjectsFromData() {
		if (!this.options.autoBuildObjects) return

		for (const key in this.objectData.objects) {
			if (key in this.components || this.ignoreDataObjects.includes(key)) {
				continue // Skip the already existing components
			}
			this.hierarchy[key] = this.assets.models[key]
			this.meshes[key] = this.hierarchy[key]
			copyObjectDataTransforms(this.hierarchy[key], this.objectData.objects[key])
			this.applyMaterialProperties(this.hierarchy[key], key, this.objectData.objects[key][3])
			this.add(this.hierarchy[key])
		}

		for (const key in this.objectData.instances) {
			if (key in this.components || this.ignoreDataObjects.includes(key)) {
				continue // Skip the already existing components
			}
			this.applyMaterialProperties(this.assets.models[key], key, this.objectData.instances[key][1])
			this.buildInstancedMesh(key, this.assets.models[key].geometry, this.assets.models[key].material)
		}
	}

	buildPasses() {
		// Render the scene
		this.renderPass = new RenderPass(this, this.activeCamera)
		this.renderPass.name = `${this.name} Render`
		this.renderPass.enabled = false

		store.WebGL.composerPasses.add(this.renderPass, store.WebGL.composerPassOrder[this.name] || 0)
	}

	addPostFXToTheatre() {
		const options = {
			bloom: {
				enabled: types.boolean(false),
				strength: types.number(0.24, { range: [0, 2], nudgeMultiplier: 0.01 }),
				threshold: types.number(0.21, { range: [0, 2], nudgeMultiplier: 0.01 }),
				radius: types.number(0.04, { range: [0, 2], nudgeMultiplier: 0.01 })
			},
			motionBlur: {
				enabled: types.boolean(false),
				blurAmount: types.number(0.15, { range: [0, 0.2], step: 0.001 })
			},
			chromaticAberration: {
				enabled: types.boolean(true),
				maxDistort: types.number(1.460, { range: [0, 4], nudgeMultiplier: 0.001 }),
				bendAmount: types.number(-0.032, { range: [-1, 1], nudgeMultiplier: 0.001 })
			},
			vignette: {
				enabled: types.boolean(true),
				strength: types.number(0.052, { range: [0, 1], nudgeMultiplier: 0.001 }),
				outerStrength: types.number(20, { range: [0, 2], nudgeMultiplier: 0.01 }),
				innerStrength: types.number(20, { range: [0, 5], nudgeMultiplier: 0.01 }),
				color: store.theatre.helper.parseColor(new Color(0x000000))
			},
			noise: {
				enabled: types.boolean(true),
				strength: types.number(0.052, { range: [0, 1], nudgeMultiplier: 0.001 })
			},
			lensFlare: {
				enabled: types.boolean(!store.isLowTierGPU), // disable by default on low tier GPUs
				blurEnabled: types.boolean(false),
				threshold: types.number(0.45, { range: [0, 1], nudgeMultiplier: 0.001 }),
				texelSize: types.compound({
					x: types.number(0.01, { range: [0, 1], nudgeMultiplier: 0.001 }),
					y: types.number(0.01, { range: [0, 1], nudgeMultiplier: 0.001 })
				}),
				smoothThreshold: types.number(0.4, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloRadius: types.number(0.45, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloStrength: types.number(0.5, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloSmoothness: types.number(0.5, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloRGBShift: types.number(0.5, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloMaskMax: types.number(0.5, { range: [0, 1], nudgeMultiplier: 0.001 }),
				haloOpacity: types.number(1.0, { range: [0, 1], nudgeMultiplier: 0.001 }),
				blurSampleCount: types.number(10, { range: [0, 20], nudgeMultiplier: 1 }),
				blurAmount: types.number(0.015, { range: [0, 0.5], nudgeMultiplier: 0.001 }),
				blurFallOff: types.number(0.8, { range: [0, 1], nudgeMultiplier: 0.001 })
			}
		}

		const postFXTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Post FX', options)

		postFXTheatreObject.onValuesChange(this.updatePostFXValuesFromTheatre, store.theatre.rafDriver)

		return postFXTheatreObject
	}

	updatePostFXValuesFromTheatre = (values) => {
		if (!this.enabled) return

		store.WebGL.bloomPass.enabled = this.postFxTheatreOverrides.bloom.enabled ?? values.bloom.enabled
		store.WebGL.bloomPass.strength = values.bloom.strength
		store.WebGL.bloomPass.threshold = values.bloom.threshold
		store.WebGL.bloomPass.radius = values.bloom.radius

		store.WebGL.motionBlurPass.enabled = this.postFxTheatreOverrides.motionBlur.enabled ?? values.motionBlur.enabled
		store.WebGL.motionBlurPass.material.uniforms.uEnabled.value = values.motionBlur.enabled
		store.WebGL.motionBlurPass.material.uniforms.uBlurAmount.value = values.motionBlur.blurAmount

		store.WebGL.compositePass.material.uniforms.uChromaticAberration.value = values.chromaticAberration.enabled
		store.WebGL.compositePass.material.uniforms.uMaxDistort.value = values.chromaticAberration.maxDistort
		store.WebGL.compositePass.material.uniforms.uBendAmount.value = values.chromaticAberration.bendAmount

		store.WebGL.compositePass.material.uniforms.uVignette.value = values.vignette.enabled
		store.WebGL.compositePass.material.uniforms.uVignetteStrength.value = values.vignette.strength
		store.WebGL.compositePass.material.uniforms.uVignetteInnerStrength.value = values.vignette.innerStrength
		store.WebGL.compositePass.material.uniforms.uVignetteOuterStrength.value = values.vignette.outerStrength
		store.WebGL.compositePass.material.uniforms.uVignetteColor.value.copy(values.vignette.color)

		store.WebGL.compositePass.material.uniforms.uNoise.value = values.noise.enabled
		store.WebGL.compositePass.material.uniforms.uNoiseStrength.value = values.noise.strength

		store.WebGL.compositePass.material.uniforms.uLensHalo.value = this.postFxTheatreOverrides.lensFlare.enabled ?? (store.isLowTierGPU ? false : values.lensFlare.enabled)
		store.WebGL.compositePass.material.uniforms.uLensHaloOpacity.value = values.lensFlare.haloOpacity

		if (store.WebGL.lensFlareFX) {
			store.WebGL.lensFlareFX.uniforms.uTexelSize.value.set(values.lensFlare.texelSize.x, values.lensFlare.texelSize.y)

			store.WebGL.lensFlareFX.uniforms.uHaloRadius.value = values.lensFlare.haloRadius
			store.WebGL.lensFlareFX.uniforms.uHaloStrength.value = values.lensFlare.haloStrength
			store.WebGL.lensFlareFX.uniforms.uHaloSmooth.value = values.lensFlare.haloSmoothness
			store.WebGL.lensFlareFX.uniforms.uHaloRGBShift.value = values.lensFlare.haloRGBShift
			store.WebGL.lensFlareFX.uniforms.uHaloMaskMax.value = values.lensFlare.haloMaskMax

			store.WebGL.lensFlareFX.uniforms.u_LuminosityThreshold.value = values.lensFlare.threshold
			store.WebGL.lensFlareFX.uniforms.uSmoothThreshold.value = values.lensFlare.smoothThreshold

			store.WebGL.lensFlareFX.uniforms.uSampleCount.value = values.lensFlare.blurSampleCount
			store.WebGL.lensFlareFX.uniforms.uBlur.value = values.lensFlare.blurAmount
			store.WebGL.lensFlareFX.uniforms.uFallOff.value = values.lensFlare.blurFallOff

			store.WebGL.lensFlareFX.toggle(this.postFxTheatreOverrides.lensFlare.enabled ?? (store.isLowTierGPU ? false : values.lensFlare.enabled))
			store.WebGL.lensFlareFX.toggleBlur(this.postFxTheatreOverrides.lensFlare.enabled ?? (values.lensFlare.enabled ? (store.isLowTierGPU ? false : values.lensFlare.blurEnabled) : false))
		}
	}

	addFluidSimToTheatre() {
		const options = {
			enabled: types.boolean(this.options.fluidEnabled),
			fluid: {
				force: types.number(20, { range: [0, Infinity], nudgeMultiplier: 0.1 }),
				forceClamp: types.number(100, { range: [0, Infinity], nudgeMultiplier: 0.1 }),
				iterations: types.number(3, { range: [0, 12], nudgeMultiplier: 1 })
			},
			simulation: {
				mouseRadius: types.number(0.25, { range: [0, 1], nudgeMultiplier: 0.01 }),
				pressure: types.number(0.98, { range: [0.55, 0.99], nudgeMultiplier: 0.0001 }), // how fast the mouse trail fades out
				curlStrength: types.number(0.25, { range: [0, 2.0], nudgeMultiplier: 0.0001 }),
				dissipation: types.number(0.1, { range: [0, 0.99], nudgeMultiplier: 0.0001 }),
				clearValue: types.number(0.1, { range: [0, 0.99], nudgeMultiplier: 0.0001 }),
				viscosity: types.number(0.08, { range: [0, 1.0], nudgeMultiplierp: 0.0001 })
			}
		}

		const fluidSimTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Fluid Sim', options)

		fluidSimTheatreObject.onValuesChange(this.updateFluidSimValuesFromTheatre, store.theatre.rafDriver)

		return fluidSimTheatreObject
	}

	updateFluidSimValuesFromTheatre = (values) => {
		if (!this.enabled || !store.WebGL.fluidSim) return

		store.WebGL.fluidSim.enabled = values.enabled

		store.WebGL.fluidSim.options.fluid.force = values.fluid.force
		store.WebGL.fluidSim.options.fluid.forceClamp = values.fluid.forceClamp
		store.WebGL.fluidSim.options.fluid.iterations = values.fluid.iterations

		store.WebGL.fluidSim.options.simulationOpts.uniforms.uMouseRadius.value = values.simulation.mouseRadius
		store.WebGL.fluidSim.options.simulationOpts.uniforms.uPressure.value = values.simulation.pressure
		store.WebGL.fluidSim.options.simulationOpts.uniforms.uCurlStrength.value = values.simulation.curlStrength
		store.WebGL.fluidSim.options.simulationOpts.uniforms.uDissipation.value = values.simulation.dissipation
		store.WebGL.fluidSim.options.simulationOpts.uniforms.uClearValue.value = values.simulation.clearValue
		store.WebGL.fluidSim.options.simulationOpts.uniforms.uViscosity.value = values.simulation.viscosity
	}

	addAudioToTheatre() {
		if (!this.options.audioFiles) return

		const audio = {
			none: 'None'
		}

		this.options.audioFiles.forEach(id => {
			audio[id] = id
		})

		store.theatre.helper.addSheetObject(this.prettyName, 'Audio', {
			file: types.stringLiteral('none', audio)
		}).onValuesChange(values => {
			E.emit('AWorldApart:playAudio', values.file)
		}, store.theatre.rafDriver)
	}

	get theatreSequenceLength() {
		return val(store.theatre.sheets[this.prettyName].sequence.pointer.length)
	}

	playTheatreSequence(runNextSequence = true, delay = 1) {
		this.theatreIsPlaying = true
		this.runNextTheatreSequence = runNextSequence

		store.theatre.studio?.setSelection([store.theatre.sheets[this.prettyName]])
		// store.theatre.sheets[this.prettyName].sequence.position = 0

		this.playTheatreSequenceTimeout = setTimeout(() => {
			E.emit('chapterTitle:hide', this.name)

			store.theatre.sheets[this.prettyName].sequence.play().then(() => {
				if (this.runNextTheatreSequence) {
					this.onTheatreSequenceEnd()
				}
			})
		}, this.options.currentChapterTitleHideDelay * 1000 * delay)
	}

	pauseTheatreSequence() {
		this.theatreIsPlaying = false

		clearTimeout(this.playTheatreSequenceTimeout)
		store.theatre.sheets[this.prettyName].sequence.pause()
	}

	onTheatreSequenceEnd() {
		if (!this.theatreIsPlaying || this.isSwitchingChapter) return

		this.theatreIsPlaying = false

		const keys = Object.keys(store.WebGL.scenes)
		const nextScene = keys.at(keys.indexOf(this.name) + 1)

		if (nextScene) {
			this.stop()
			store.WebGL.scenes[nextScene].start()
			store.WebGL.scenes[nextScene].playTheatreSequence()
		}
	}

	onTheatreSequenceUpdate(position) {
		const sequenceLength = this.theatreSequenceLength

		// emit event with progress through sequence
		E.emit('chapter:progress', (this.chapterProgress + position) / this.chapterSequenceLength, this.prettyName)

		// check if we need to show the next chapter title
		if (!this.nextChapterTitleVisible && position >= sequenceLength - this.options.nextChapterTitleShowPosition) {
			const keys = Object.keys(store.WebGL.scenes)
			const nextScene = keys.at(keys.indexOf(this.name) + 1)
			E.emit('chapterTitle:show', nextScene)

			this.nextChapterTitleVisible = true
		}
	}

	onChapterSelect = (sceneName) => {
		if (this.name === sceneName) {
			// add event to wait for chapter title to be visible before switching scenes
			E.on('chapterTitle:visible', this.onChapterTitleCardVisible)

			if (store.WebGL.activeScene.theatreIsPlaying) {
				store.WebGL.activeScene.isSwitchingChapter = true
			}

			E.emit('chapterTitle:show', this.name)
		}
	}

	onChapterTitleCardVisible = (sceneName) => {
		const keys = Object.keys(store.WebGL.scenes)
		const isLastScene = keys.indexOf(this.name) === keys.length - 1

		if (store.WebGL.activeScene.theatreIsPlaying || (isLastScene && store.WebGL.activeScene.enabled)) {
			store.WebGL.activeScene.stop()
		}

		if (sceneName === this.name) {
			this.start()
			this.playTheatreSequence()
		}

		E.off('chapterTitle:visible', this.onChapterTitleCardVisible)
	}

	buildEnvironment() {
		if (!this.objectData.scene || Object.values(this.objectData.scene).length === 0) return

		this.background = this.assets.textures.bgmap ? this.assets.textures.bgmap : this.assets.textures.envmap
		this.backgroundBlurriness = 0.1
		this.environment = this.assets.textures.envmap
		this.environmentIntensity = this.objectData.scene.material.strength
	}

	buildInstancedMesh(name, geometry, material) {
		const objectData = this.objectData.instances[name]
		const count = Object.keys(objectData[0]).length

		this.hierarchy[name] = new InstancedMesh(geometry, material, count)
		this.hierarchy[name].name = name
		this.meshes[name] = this.hierarchy[name]

		for (let i = 0; i < count; i++) {
			copyObjectDataTransforms(this._instanceDummy, objectData[0][i])
			this._instanceDummy.updateMatrix()
			this.hierarchy[name].setMatrixAt(i, this._instanceDummy.matrix)
		}

		this.add(this.hierarchy[name])
	}

	buildPath(pathPoints) {
		const points = []

		for (let i = 0; i < pathPoints.length; i++) {
			points.push(new Vector3(pathPoints[i][0], pathPoints[i][1], pathPoints[i][2]))
		}

		return new CatmullRomCurve3(points)
	}

	buildPaths() {
		for (const key in this.objectData.paths) {
			this.paths[key] = this.buildPath(this.objectData.paths[key])
		}
	}

	createCamera(name) {
		const camera = new PerspectiveCamera(45, store.window.w / store.window.h, 1.0, 2000)
		camera.name = name
		camera._position = new Vector3(0, 0, 20)
		camera._rotation = new Euler()
		camera._lookAt = new Vector3()
		camera.enableLookAt = false

		camera.position.copy(camera._position)
		camera.rotation.copy(camera._rotation)
		camera.lookAt(camera._lookAt)

		const camTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Cameras / ' + name, {
			transforms: {
				position: types.compound({
					x: types.number(camera._position.x, { nudgeMultiplier: 0.1 }),
					y: types.number(camera._position.y, { nudgeMultiplier: 0.1 }),
					z: types.number(camera._position.z, { nudgeMultiplier: 0.1 })
				}),
				rotation: types.compound({
					x: types.number(camera._rotation.x, { nudgeMultiplier: 0.01 }),
					y: types.number(camera._rotation.y, { nudgeMultiplier: 0.01 }),
					z: types.number(camera._rotation.z, { nudgeMultiplier: 0.01 })
				})
			},
			enableLookAt: types.boolean(camera.enableLookAt),
			lookAtTarget: types.compound({
				x: types.number(camera._lookAt.x, { nudgeMultiplier: 0.1 }),
				y: types.number(camera._lookAt.y, { nudgeMultiplier: 0.1 }),
				z: types.number(camera._lookAt.z, { nudgeMultiplier: 0.1 })
			}),
			near: types.number(camera.near, { range: [0, Infinity] }),
			far: types.number(camera.far, { range: [0, Infinity] }),
			zoom: types.number(camera.zoom, { range: [0.01, 50] }),
			fov: types.number(camera.fov, { range: [0, 180] })
		}, camera)

		camTheatreObject.onValuesChange(values => {
			camera._position.copy(values.transforms.position)
			camera._rotation.set(values.transforms.rotation.x, values.transforms.rotation.y, values.transforms.rotation.z)
			camera.enableLookAt = values.enableLookAt
			camera._lookAt.copy(values.lookAtTarget)
			camera.near = values.near
			camera.far = values.far
			camera.zoom = values.zoom
			camera.fov = values.fov
		}, store.theatre.rafDriver)

		return camera
	}

	updateCameras() {
		for (const key in this.cameras) {
			const camera = this.cameras[key]

			camera.position.copy(camera._position)

			if (camera.enableLookAt) {
				camera.lookAt(camera._lookAt)
			} else {
				// Update rotation every frame so ambient movement doesn't get added indefinitely
				if (!this.devTools?.transformControls.dragging) {
					camera.rotation.copy(camera._rotation)
				}
			}

			camera.translateX(this.options.cameraXTranslate)
			camera.translateY(this.options.cameraYTranslate)
			camera.translateZ(this.options.cameraZTranslate)

			this.updateCameraPivotMovement(camera)

			camera.updateProjectionMatrix()
		}
	}

	updateCameraPivotMovement(camera) {
		// Camera pivot movement + brownian motion
		if (store.mq.touch.matches || this.devTool?.enabled) return

		this.brownianMotion.update(store.WebGL.clockDelta * this.options.cameraMotionSpeed * store.WebGL.normalizedDelta)
		camera.updateMatrix()
		camera.matrix.multiply(this.brownianMotion.matrix)

		camera.matrix.decompose(camera.position, camera.quaternion, camera.scale)

		camera.translateZ(-this.options.cameraZOffset)

		this._euler.set(
			this.smoothMouse[0].y * this.options.mouseMoveAngle.y,
			-this.smoothMouse[0].x * this.options.mouseMoveAngle.x,
			0.0
		)
		this._quaternion.setFromEuler(this._euler)

		camera.quaternion.multiply(this._quaternion)

		this._euler.set(
			0.0,
			0.0,
			(this.smoothMouse[0].x - this.smoothMouse[1].x) * -this.options.mouseRollAmount
		)
		this._quaternion.setFromEuler(this._euler)
		camera.quaternion.multiply(this._quaternion)

		camera.translateZ(this.options.cameraZOffset)
		camera.updateMatrixWorld()
	}

	applyMaterialProperties(object, name, data) {
		// Return if no material properties
		if (!data || !data.material || Object.values(data).length === 0) return

		// Update existing material with data properties
		if (Object.values(data.material).length > 0) {
			object.material.emissive.set(0x000000)
			object.material.color.set(0xffffff)

			if (data.material.metallic) object.material.metalness = data.material.metallic
			if (data.material.roughness) object.material.roughness = data.material.roughness
			if (data.material.ior) object.material.ior = data.material.ior
			if (data.material.specular) object.material.specularIntensity = data.material.specular
			if (data.material.alpha) object.material.opacity = data.material.alpha
			if (data.material.alpha < 1) object.material.transparent = true
			if (data.material['emission-strength']) object.material.emissiveIntensity = data.material['emission-strength']
		}

		if (Object.values(data.textures).length > 0) {
			if (data.textures['base-color'] && !(data.textures['base-color'] instanceof Array)) object.material.map = this.assets.textures[name]['base-color']
			if (data.textures.normal && !(data.textures.normal instanceof Array)) object.material.normalMap = this.assets.textures[name].normal
			if (data.textures.metallic && !(data.textures.metallic instanceof Array)) object.material.metalnessMap = this.assets.textures[name].metallic
			if (data.textures.roughness && !(data.textures.roughness instanceof Array)) {
				object.material.roughnessMap = this.assets.textures[name].roughness
				object.material.roughness = 1 // Use only map if there is one
			}
		}

		object.material.envMapIntensity = this.environmentIntensity || 0
	}

	addEvents() {
		store.RAFCollection.add(this.onRaf, 3)
		this.unsubscribeFromSequencePositionChange = onChange(store.theatre.sheets[this.prettyName].sequence.pointer.position, this.onTheatreSequenceUpdate, store.theatre.rafDriver)
		E.on('visibilitychange', document, this.onTabVisibilityChange)
	}

	removeEvents() {
		store.RAFCollection.remove(this.onRaf)
		this.unsubscribeFromSequencePositionChange()
		E.off('visibilitychange', document, this.onTabVisibilityChange)
	}

	onRaf = (time) => {
		this.renderPass.camera = this.activeCamera

		this.smoothMouse[0].lerp(store.pointer.glNormalized, this.options.pointerLerpSpeed1 * store.WebGL.normalizedDelta)
		this.smoothMouse[1].lerp(store.pointer.glNormalized, this.options.pointerLerpSpeed2 * store.WebGL.normalizedDelta)

		this.updateCameras()
		this.animate()
	}

	onTabVisibilityChange = () => {
		if (document.hidden && this.theatreIsPlaying) {
			this.previousTheatrePlayingState = this.theatreIsPlaying
			this.runNextTheatreSequence = false
			this.pauseTheatreSequence()
		} else if (this.previousTheatrePlayingState === true) {
			this.runNextTheatreSequence = true
			this.playTheatreSequence(true, 0)
		}
	}

	animate() {
		this.components.fog && this.components.fog.update()

		for (const key in this.components) {
			this.components[key].animate && this.components[key].animate()
		}
	}

	onResize = () => {
		for (const key in this.cameras) {
			this.cameras[key].aspect = store.window.w / store.window.h
			this.cameras[key].updateProjectionMatrix()
		}

		this.devTools?.onResize()

		this.onSceneResize()
	}

	onSceneResize() {}

	load() {
		this.assets = {
			textures: {},
			models: {}
		}

		const basePath = store.publicUrl + 'webgl'

		// Normal objects
		for (const key in this.objectData.objects) {
			this.loadGltf(basePath, key)
			this.loadTextures(basePath, this.objectData.objects[key][3], key)
		}

		// Instances
		for (const key in this.objectData.instances) {
			this.loadGltf(basePath, key)
			this.loadTextures(basePath, this.objectData.instances[key][1], key)
		}

		// Load scene level textures
		if (!this.objectData.scene) return
		for (const key in this.objectData.scene.textures) {
			// Check what type of map it is by getting last three characters
			switch (this.objectData.scene.textures[key].slice(-3)) {
				case 'png':
					store.AssetLoader.loadTexture(`${basePath}/textures/${this.objectData.scene.textures[key]}`, {
						mapping: EquirectangularReflectionMapping,
						colorSpace: SRGBColorSpace
					}).then(texture => {
						this.assets.textures[key] = texture
						this.assets.textures[key].name = key
						this.assets.textures[key].isHDR = false
					})
					break
				case 'hdr':
					store.AssetLoader.loadHDR(`${basePath}/textures/${this.objectData.scene.textures[key]}`).then(texture => {
						this.assets.textures[key] = texture
						this.assets.textures[key].mapping = EquirectangularReflectionMapping
						this.assets.textures[key].name = key
						this.assets.textures[key].isHDR = true
					})
					break
				default:
					console.error(`Loading of scene texture ${key}: ${this.objectData.scene.textures[key]} failed. Unsupported texture format.`)
			}
		}
	}

	loadGltf(basePath, key) {
		store.AssetLoader.loadGltf(`${basePath}/models/${key}.glb`).then(gltf => {
			this.assets.models[key] = gltf.scene.children[0]
			if (this.assets.models[key].material.name === '') {
				// Keep these for models that don't have set material properties
				this.assets.models[key].material = new MeshPhysicalMaterial()
				this.assets.models[key].material.color.set(0xff00ff)
			}
		})
	}

	loadTextures(basePath, data, key) {
		if (!data.textures || Object.keys(data.textures).length === 0) return

		this.assets.textures[key] = {}

		for (const textureKey in data.textures) {
			if (data.textures[textureKey] instanceof Array) {
				// Load array of UDIMs
				data.textures[textureKey].forEach((udim, indx) => {
					store.AssetLoader.loadTexture(`${basePath}/textures/${udim}`, { flipY: false, wrapping: RepeatWrapping }).then(texture => {
						this.assets.textures[key][`${textureKey}-100${indx + 1}`] = texture
					})
				})
				continue
			}

			store.AssetLoader.loadTexture(`${basePath}/textures/${data.textures[textureKey]}`, { flipY: false, wrapping: RepeatWrapping }).then(texture => {
				this.assets.textures[key][textureKey] = texture
			})
		}
	}

	destroy() {
		this.stop()

		E.off(GlobalEvents.RESIZE, this.onResize) // always leave resize enabled so things are correct if resizing on another page
		E.off('chapterSelect', this.onChapterSelect) // listen for chapter select events even when scene isn't active

		for (const key in this.components) {
			this.components[key].destroy && this.components[key].destroy()
		}

		this.components = {}

		this.resourceTracker.dispose()
		this.renderPass.dispose()

		store.theatre.helper.deleteSheet(this.prettyName)

		if (store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') {
			store.theatre.helper.detachAllStudioSheetObjects(this.prettyName)
		}

		// reset options
		Object.assign(this.options, this.origOptions)

		for (const key in this.assets.textures) {
			if (this.assets.textures[key] instanceof Texture) {
				this.assets.textures[key].dispose()
			} else if (Object.keys(this.assets.textures[key]).length > 0) {
				for (const subkey in this.assets.textures[key]) {
					if (this.assets.textures[key][subkey] instanceof Texture) {
						this.assets.textures[key][subkey].dispose()
					}
				}
			}
		}

		for (const key in this.assets.models) {
			this.assets.models[key].geometry?.dispose()
			this.assets.models[key].material?.dispose()
		}
	}
}
