#ifdef USE_FOG

    if (uEnableFog) {

        #ifdef SOLID_FOG
            vec3 fogSample = uSolidFogColor;
        #else
            vec3 fogSample = texture2D(tBackground, gl_FragCoord.xy / uResolution.xy).rgb;
        #endif

        vec4 debugColorOriginal = vec4(gl_FragColor.rgb, 1.0);

        // Add World Fog on top of render
        vec3 fog = mix(gl_FragColor.rgb, fogSample, uSceneFogMix);
        
        /** World fog */

        // Depth fog
        float worldDepthFogFactor = smoothstep( uFogNear_D, uFogFar_D, vFogDepth );
        // gl_FragColor.rgb = mix(gl_FragColor.rgb, fog, worldDepthFogFactor); // Uncomment this for only depth fog

        // Height fog
        // float worldHeightFogFactor = smoothstep(-uFogFar_H, -uFogNear_H, vFogHeight ); // Turned around mvPosition
        float worldHeightFogFactor = smoothstep(uFogNear_H, uFogFar_H, vWorldPosition.y - cameraPosition.y ); // based on distance from camera Y
        // gl_FragColor.rgb = mix(fog, gl_FragColor.rgb, worldDepthFogFactor); // Uncomment this for only height fog

        // Combine depth and height fog
        // gl_FragColor.rgb = mix(gl_FragColor.rgb, fog, clamp(worldHeightFogFactor*uFogStrength_H+worldDepthFogFactor*uFogStrength_D, 0.0, 1.0));
        
        gl_FragColor.rgb = mix(gl_FragColor.rgb, fog, clamp(worldDepthFogFactor*uFogStrength_D, 0.0, 1.0));
        gl_FragColor.rgb = mix(gl_FragColor.rgb, fog, clamp(worldHeightFogFactor*uFogStrength_H, 0.0, 1.0));

        if(uDebugFog) {


            vec3 debugCol = vec3(0.0, 1.0, 1.0);

            gl_FragColor.rgb = mix(gl_FragColor.rgb, debugCol, clamp(worldDepthFogFactor*uFogStrength_D, 0.0, 1.0));
            gl_FragColor.rgb = mix(gl_FragColor.rgb, debugCol, clamp(worldHeightFogFactor*uFogStrength_H, 0.0, 1.0));

            // gl_FragColor.rgb = mix(debugColorOriginal.rgb, vec3(0.0, 1.0, 1.0), clamp(worldDepthFogFactor*worldHeightFogFactor+worldHeightFogFactor*worldDepthFogFactor, 0.0, 1.0)); // debug
        }

    }

#endif