#ifdef USE_TONEMAPPING
        
    if(uToneMapping == 0) {
        gl_FragColor.rgb = LinearToneMapping( gl_FragColor.rgb );
    } else if (uToneMapping == 1) {
        gl_FragColor.rgb = ReinhardToneMapping( gl_FragColor.rgb );
    } else if (uToneMapping == 2) {
        gl_FragColor.rgb = OptimizedCineonToneMapping( gl_FragColor.rgb );
    } else {
        gl_FragColor.rgb = ACESFilmicToneMapping( gl_FragColor.rgb );
    }

#endif