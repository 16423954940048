varying vec2 vUv;

varying vec2 vL;
varying vec2 vR;
varying vec2 vT;
varying vec2 vB;

uniform vec2 uTexelSize;
uniform vec2 uResolution;

uniform sampler2D uTexture;
uniform sampler2D tPressure;
uniform sampler2D tVelocity;

vec2 boundary (vec2 uv) {
    return uv;
    // uv = min(max(uv, 0.0), 1.0);
    
    // return uv;
}

void main () {

    vec2 halfrdx = 0.5 / uResolution;

    float L = texture2D(tPressure, boundary(vL)).x;
    float R = texture2D(tPressure, boundary(vR)).x;
    float T = texture2D(tPressure, boundary(vT)).x;
    float B = texture2D(tPressure, boundary(vB)).x;

    vec2 velocity = texture2D(tVelocity, vUv).xy;

    velocity.xy -= vec2(R - L, T - B);
    // velocity.xy -= halfrdx * vec2(R - L, T - B); // NVIDIA

    gl_FragColor = vec4(velocity, 0.0, 1.0);
    
}