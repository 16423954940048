import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { AdditiveBlending, Color, FrontSide } from 'three'

export default class CursorParticlesMaterial extends BaseMaterial {
	constructor(options = {}, scene) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tPosition: { value: null },
				uBaseColor: { value: new Color(0xfec046) },
				uBirthDecayColor: { value: new Color(0x913a23) },
				uParticleSize: { value: 0.04, gui: { step: 0.01 } },
				uOpacity: { value: 1.0, gui: { step: 0.01, min: 0, max: 1 } }
			},
			defines: {
			},
			side: FrontSide,
			blending: AdditiveBlending,
			transparent: true,
			depthTest: true
		}, options)

		super(options)
	}
}