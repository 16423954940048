import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color, UniformsLib } from 'three'

export default class OutroTerrainMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				uNormalMapStrength: { value: 0.62, gui: { min: 0, max: 1, step: 0.001 } },
				uNormalMapRepeat: { value: 20, gui: { min: 1, max: 100, step: 0.1 } },
				uColor: { value: new Color(0xffd2a6) },
				uAmbientColor: { value: new Color(0xa39e95) },
				uEmissiveColor: { value: new Color(0x000000) },
				uSpecularColor: { value: new Color(0xf5d6ac) },
				uSpecularStrength: { value: 0.35, gui: { min: 0, max: 1, step: 0.01 } },
				uSpecularShininess: { value: 27.2, gui: { min: 0.001, max: 100, step: 0.1 } },
				uShadowIntensity: { value: 0.9, gui: { min: 0, max: 1, step: 0.01 } },
				uPrimaryLineColor: { value: new Color(0xf5bb79) },
				uSecondaryLineColor: { value: new Color(0x181818) },
				uPrimaryLineOffset: { value: 0.02, gui: { min: -10, max: 10, step: 0.01 } },
				uSecondaryLineOffset: { value: 0, gui: { min: -10, max: 10, step: 0.01 } },
				uSecondaryLineScale: { value: 0, gui: { min: 0, max: 500, step: 0.1 } },
				...UniformsLib.lights
			},
			defines: {
				USE_FOG: true,
				SOLID_FOG: false
			},
			lights: true
		}, options)

		super(options)
	}
}