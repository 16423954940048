varying vec2 vUv;

varying vec2 vL;
varying vec2 vR;
varying vec2 vT;
varying vec2 vB;

uniform sampler2D tVelocity;
uniform float uViscosity;

void main() {

    float L = texture(tVelocity, vL).x;
    float R = texture(tVelocity, vR).x;
    float T = texture(tVelocity, vT).y;
    float B = texture(tVelocity, vB).y;

    vec2 C = texture(tVelocity, vUv).xy;

    // idk what this section is, sometimes it is commented and sometimes not
    if (vL.x < 0.0) { L = -C.x; }
    if (vR.x > 1.0) { R = -C.x; }
    if (vT.y > 1.0) { T = -C.y; }
    if (vB.y < 0.0) { B = -C.y; }
        
    float div = 0.5 * (R - L + T - B) * uViscosity;

    gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
}