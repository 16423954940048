import { Component } from '_components/unseen'
import gsap from 'gsap'
import store from '_store'

export default class AudioButton extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-audio-button'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()
		store.audioMuted = true
		this.animation()

		this.toggle()

		this.on('click', this.el, this.toggle)
	}

	toggle = () => {
		store.audioMuted ? this.unmute() : this.mute()
	}

	animation() {
		this.tl = gsap.timeline({
			paused: true,
			repeat: -1,
			defaults: {
				ease: 'linear',
				immediateRender: false
			}
		})

			.fromTo([this.dom.lineArray[0], this.dom.lineArray[3]], {
				scaleY: 0.1
			}, {
				scaleY: 0.8,
				duration: 0.45
			}, 0)
			.to([this.dom.lineArray[0], this.dom.lineArray[3]], {
				scaleY: 0.1,
				duration: 0.45
			}, 0.45)

			.fromTo(this.dom.lineArray[1], {
				scaleY: 0.1
			}, {
				scaleY: 1,
				duration: 0.45
			}, 0 + 0.6)
			.to(this.dom.lineArray[1], {
				scaleY: 0.1,
				duration: 0.45
			}, 0.45 + 0.6)

			.fromTo([this.dom.lineArray[2], this.dom.lineArray[5]], {
				scaleY: 0.3
			}, {
				scaleY: 1,
				duration: 0.65
			}, 0)
			.to([this.dom.lineArray[2], this.dom.lineArray[5]], {
				scaleY: 0.1,
				duration: 0.65
			}, 0.65)

			.fromTo(this.dom.lineArray[4], {
				scaleY: 0.1
			}, {
				scaleY: 0.8,
				duration: 0.4
			}, 0 + 0.325)
			.to(this.dom.lineArray[4], {
				scaleY: 0.1,
				duration: 0.4
			}, 0.4 + 0.325)

			.fromTo(this.dom.lineArray[6], {
				scaleY: 0.1
			}, {
				scaleY: 0.8,
				duration: 0.2
			}, 0 + 0.325)
			.to(this.dom.lineArray[6], {
				scaleY: 0.1,
				duration: 0.2
			}, 0.2 + 0.325)
	}

	mute() {
		store.audioMuted = true
		store.Audio?.muteAll(true)
		this.tl.pause().revert()
	}

	unmute() {
		store.audioMuted = false
		store.Audio?.muteAll(false)
		this.tl.play()
	}
}
