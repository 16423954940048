vec4 diffuseColor = vec4( uColor, uOpacity );
vec3 totalEmissiveRadiance = uEmissiveColor;

ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );

#ifdef USE_UDIMS

	float textureIndex;
	for(float i = 0.; i < float(USE_UDIMS); i+=1.) {
		// determine what udim the vertex has
		textureIndex += step(i + 1., vUv.x); //udim1: 0-1, udim2: 1-2,...
	}

	vec2 udimUV = (vUv - vec2(textureIndex, 0.0));

#endif

#ifdef USE_DIFFUSE	
	#ifdef USE_UDIMS
		diffuseColor *= getUDIMcolor(tDiffuseArray, textureIndex, udimUV, uDiffuseScale);
	#else
		diffuseColor *= texture2D( tDiffuse, vUv * uDiffuseScale );
	#endif
#endif

// Roughness
float roughnessFactor = uRoughness;

#ifdef USE_ROUGH

	#ifdef USE_UDIMS
		vec4 texelRoughness = getUDIMcolor( tRoughnessArray, textureIndex, udimUV, uRoughnessScale);
	#else
		vec4 texelRoughness = texture2D( tRoughness, vUv * uRoughnessScale);
	#endif

	// reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
	roughnessFactor *= texelRoughness.g;

#endif

// Metalness
float metalnessFactor = uMetalness;

#ifdef USE_METALNESS

	vec4 texelMetalness = texture2D( tMetalness, vUv * uMetalnessScale );
	// reads channel B, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
	metalnessFactor *= texelMetalness.b;

#endif

// Normals
float faceDirection = gl_FrontFacing ? 1.0 : - 1.0;

#ifdef FLAT_SHADED
	vec3 fdx = dFdx( vViewPosition );
	vec3 fdy = dFdy( vViewPosition );
	vec3 normal = normalize( cross( fdx, fdy ) );
#else

	vec3 normal = normalize( vNormal );

	vec3 tangent = normalize( vTangent );
	vec3 bitangent = normalize( vBitangent );

	#ifdef DOUBLE_SIDED
		normal = normal * faceDirection;
		tangent = tangent * faceDirection;
		bitangent = bitangent * faceDirection;
	#endif

	mat3 vTBN = mat3( tangent, bitangent, normal );

#endif

// non perturbed normal for clearcoat among others
vec3 geometryNormal = normal;

// Normal map
#ifdef USE_NORMAL

	#ifdef OBJECTSPACE_NORMALMAP

		normal = texture2D( tNormal, vUv * uNormalScale ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals
		
		#ifdef FLIP_SIDED
			normal = - normal;
		#endif
		
		#ifdef DOUBLE_SIDED
			normal = normal * faceDirection;
		#endif
		
		normal = normalize( normalMatrix * normal );
	
	#else 
		// Tangent space normal maps

		#ifdef USE_UDIMS
			vec3 mapN = getUDIMcolor( tNormalArray, textureIndex, udimUV, uNormalScale).rgb * 2.0 - 1.0;
		#else
			vec3 mapN = texture2D( tNormal, vUv * uNormalScale ).xyz * 2.0 - 1.0;
		#endif

		mapN.xy *= uNormalStrength;
		normal = normalize( vTBN * mapN );
	
	#endif

#endif

// Emissive maps
#ifdef USE_EMISSIVEMAP

	vec4 emissiveColor = texture2D( tEmissiveMap, vUv );

	totalEmissiveRadiance *= emissiveColor.rgb;

#endif

// Matcap
#ifdef USE_MATCAP
	vec3 viewDir = normalize( vViewPosition );
	vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
	vec3 y = cross( viewDir, x );
	vec2 uv = vec2( dot( x, normal ), dot( y, normal ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

	vec4 matcapColor = texture2D( tMatcap, uv );
#else
	vec4 matcapColor = vec4(1.); // default if matcap is missing
#endif

// Lights accumulation
DynamicMaterial material;
material.diffuseColor = diffuseColor.rgb * ( 1.0 - metalnessFactor );

vec3 dxy = max( abs( dFdx( geometryNormal ) ), abs( dFdy( geometryNormal ) ) );
float geometryRoughness = max( max( dxy.x, dxy.y ), dxy.z );

material.roughness = max( roughnessFactor, 0.0525 );// 0.0525 corresponds to the base mip of a 256 cubemap.
material.roughness += geometryRoughness;
material.roughness = min( material.roughness, 1.0 );

material.ior = uIOR;

float specularIntensityFactor;
vec3 specularColorFactor;

specularIntensityFactor = uSpecularIntensity;
specularColorFactor = uSpecularColor;

material.specularF90 = mix( specularIntensityFactor, 1.0, metalnessFactor );
material.specularColor = mix( min( pow2( ( material.ior - 1.0 ) / ( material.ior + 1.0 ) ) * specularColorFactor, vec3( 1.0 ) ) * specularIntensityFactor, diffuseColor.rgb, metalnessFactor );

// More lights
GeometricContext geometry;

geometry.position = - vViewPosition;
geometry.normal = normal;
geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( vViewPosition );

// Starting ambient
vec3 ambientLightColor = vec3(0.0);
// Indirect diffuse
vec3 iblIrradiance = vec3( 0.0 );
vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );
// Indirect specular
vec3 radiance = vec3( 0.0 );
vec3 clearcoatRadiance = vec3( 0.0 );

#ifdef USE_LIGHTMAP 

	vec4 lightMapTexel = texture2D( tLightMap, vUv );
	vec3 lightMapIrradiance = lightMapTexel.rgb * uLightMapIntensity;

	irradiance += lightMapIrradiance;

#endif

#ifdef USE_ENV

	iblIrradiance += getIBLIrradiance( geometry.normal );
	radiance += getIBLRadiance( geometry.viewDir, geometry.normal, material.roughness );

#endif

RE_IndirectDiffuse_Physical( irradiance, geometry, material, reflectedLight );
RE_IndirectSpecular_Physical( radiance, iblIrradiance, clearcoatRadiance, geometry, material, reflectedLight );

vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;

vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;

// Mix between outgoing and matcap
#ifdef USE_MATCAP

	if(uMatcapBlendMode == 0) {
		outgoingLight = blendSoftLight(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	} else if(uMatcapBlendMode == 1){
		outgoingLight = blendLinearLight(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	} else if(uMatcapBlendMode == 2){
		outgoingLight = blendLighten(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	} else if(uMatcapBlendMode == 3){
		outgoingLight = blendOverlay(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	} else if(uMatcapBlendMode == 4){
		outgoingLight = blendAdd(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	} else {
		outgoingLight = blendMultiply(outgoingLight.rgb, matcapColor.rgb, uDiffuseMatcapBlend);
	}

#endif

gl_FragColor = vec4( outgoingLight, diffuseColor.a );