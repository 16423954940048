#include <common>
#include <packing>

varying vec2 vUv;

uniform sampler2D uTexture;
uniform sampler2D tBase; // texture which to write to (velocity)
uniform vec2 uTexelSize;
uniform vec2 uResolution;
uniform float uNormalizedDelta;

uniform vec3 uForce;
uniform vec2 uMouse;
uniform vec2 uPrevMouse;
uniform vec2 uMouseVelocity;
uniform float uMouseRadius;
uniform float uPressure; // how fast the mouse trail fades out

float sdLine( vec2 p, vec2 a, vec2 b ){
    float velocity = clamp(length(uMouseVelocity), 0.5, 1.5);
    vec2 pa = p - a, ba = b - a;
    float h = clamp( dot(pa, ba)/dot(ba, ba), 0.0, 1.0 );
    return length( pa - ba*h ) / velocity;
}

void main() {

    // base texture (velocity)
    vec4 color = texture2D(tBase, vUv);

    // apply mouse interaction
    float magnitude = smoothstep(1. - uMouseRadius, 1., 1.0 - min(sdLine(vUv, uPrevMouse, uMouse), 1.0)); // sd line
    // float magnitude = 1.0 - smoothstep(0.0, uMouseRadius, distance(vUv, uMouse)); // just radius

    vec3 splat = uForce * magnitude;

    color.rgb = (color.rgb + splat) * uPressure; // blend - with force

    gl_FragColor = color;

}
