import edgeFxFrag from '_glsl/edgeFx/frag.glsl'
import lensFlareFS from "_glsl/lensFlare/frag.glsl"
import lensFlareVS from "_glsl/default.vert"
import thresholdFrag from "_glsl/threshold/frag.glsl"
import store from '_store'
import { HalfFloatType, LinearFilter, ShaderMaterial, Vector2, WebGLRenderTarget } from 'three'
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'

export default class LensFlareEffect {
	constructor(options = {}) {
		this.options = options

		this.passOrder = options.passOrder

		this.enabled = true // Enable by default

		this.uniforms = {
			u_LuminosityThreshold: { value: 0.076 },
			uSmoothThreshold: { value: 0.424 },

			uScreenSize: { value: new Vector2() },
			uTexelSize: { value: new Vector2(0.01, 0.01) },

			uHaloRadius: { value: 0.5 },
			uHaloSmooth: { value: 0.5 },
			uHaloRGBShift: { value: 0.5 },
			uHaloStrength: { value: 0.5 },
			uHaloMaskMax: { value: 0.5 },

			// blur
			uSampleCount: { value: 10 },
			uBlur: { value: 0.12 },
			uFallOff: { value: 3 }

		}

		this.passes = []

		this.setSize(store.window.w, store.window.h)
	}

	build({ passIndex }) {
		/**
		 * Lens flare passes
		 */

		// Save original render to render target
		this.savePass = new SavePass(new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio(), {
			minFilter: LinearFilter,
			magFilter: LinearFilter,
			depthBuffer: false,
			type: HalfFloatType
		}))
		this.savePass.name = 'Lens Flare Save Pass'

		// Copy pass to copy the original render into the pipeline
		this.copyPass = new ShaderPass(new ShaderMaterial({
			vertexShader: CopyShader.vertexShader,
			fragmentShader: CopyShader.fragmentShader,
			uniforms: {
				tDiffuse: { value: null },
				opacity: { value: 1.0 }
			}
		}))
		this.copyPass.name = 'Lens Flare Scene Copy Pass'

		this.thresholdPass = new ShaderPass(new ShaderMaterial({
			vertexShader: lensFlareVS,
			fragmentShader: thresholdFrag,
			uniforms: {
				tDiffuse: { value: null },
				u_LuminosityThreshold: this.uniforms.u_LuminosityThreshold,
				uSmoothThreshold: this.uniforms.uSmoothThreshold
			}
		}))
		this.thresholdPass.name = "Lens Flare Threshold Pass"

		this.lensFlarePass = new ShaderPass(new ShaderMaterial({
			vertexShader: lensFlareVS,
			fragmentShader: lensFlareFS,
			uniforms: {
				tDiffuse: { value: null },
				uScreenSize: this.uniforms.uScreenSize,
				uTexelSize: this.uniforms.uTexelSize,

				uHaloRadius: this.uniforms.uHaloRadius,
				uHaloSmooth: this.uniforms.uHaloSmooth,
				uHaloRGBShift: this.uniforms.uHaloRGBShift,
				uHaloStrength: this.uniforms.uHaloStrength,
				uHaloMaskMax: this.uniforms.uHaloMaskMax
			}
		}))
		this.lensFlarePass.name = 'Lens Flare Pass'

		this.edgeFxPass = new ShaderPass(new ShaderMaterial({
			vertexShader: lensFlareVS,
			fragmentShader: edgeFxFrag,
			uniforms: {
				tDiffuse: { value: null },
				uScreenSize: this.uniforms.uScreenSize,
				uSampleCount: this.uniforms.uSampleCount,
				uBlur: this.uniforms.uBlur,
				uFallOff: this.uniforms.uFallOff
			}
		}))
		this.edgeFxPass.name = 'Lens Flare Blur Pass'
		this.edgeFxPass.enabled = false // Disable by default

		this.passes.push(this.savePass, this.copyPass, this.thresholdPass, this.lensFlarePass, this.edgeFxPass)

		// Only add the rest of the lens flare effect to desktop devices ??
		// if (!store.mq.touch.matches && store.mq.sm.matches) {
		store.WebGL.composerPasses.add(this.savePass, this.passOrder.postfx + passIndex)
		store.WebGL.composerPasses.add(this.copyPass, this.passOrder.postfx + passIndex + 1)
		store.WebGL.composerPasses.add(this.thresholdPass, this.passOrder.postfx + passIndex + 2) // Threshold image first
		store.WebGL.composerPasses.add(this.lensFlarePass, this.passOrder.postfx + passIndex + 3) // Apply lens flare
		store.WebGL.composerPasses.add(this.edgeFxPass, this.passOrder.postfx + passIndex + 4) // Blur(-ish)
		// }
	}

	setSize(width, height) {
		this.uniforms.uScreenSize.value.set(width, height)
	}

	toggle(value) {
		this.passes.forEach(pass => {
			pass.enabled = value
		})

		this.enabled = value
	}

	toggleBlur(value) {
		this.edgeFxPass.enabled = value
	}

	dispose() {
		this.passes.forEach(pass => {
			pass.dispose()
		})
	}
}