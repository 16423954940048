import Lenis from 'lenis'
import ScrollTrigger from "gsap/ScrollTrigger"
import store from '_store'
import { qs, E, Scrollbar } from '_utils'

export default class SmoothScroll {
	/**
	 * @type {import("@studio-freight/lenis").default|null}
	 */
	static Lenis = null

	static isBody = null

	/**
	 * @type {import("../utils").Scrollbar|null}
	 */
	static scrollbar = null

	/**
	 * Set up lenis smooth scroll
	 */
	static init(isBody = false, lenisParams = {}, scrollbar = true) {
		isBody ? this.scrollBody(lenisParams) : this.scrollContainer(lenisParams)

		E.on('scroll:lock', () => this.lock())
		E.on('scroll:unlock', () => this.unlock())

		store.SmoothScroll = this

		if (scrollbar) {
			this.scrollbar = new Scrollbar(this.Lenis)
		}

		store.html.classList.add('has-smoothscroll')

		// initially lock
		this.lock()
	}

	/**
	 * Proxy for lenis.scrollTo
	 * @param {any} target
	 * @param {{}} options
	 */
	static scrollTo(target, options = {}) {
		this.Lenis.scrollTo(target, options)
	}

	/**
	 * Lock scroll regardless of device
	 */
	static lock() {
		store.body.classList.remove('smoothscroll-enabled')
		this.Lenis?.stop()
	}

	/**
	 * Unlock scroll, regardless of device
	 */
	static unlock() {
		store.body.classList.add('smoothscroll-enabled')
		this.Lenis?.start()
		this.scrollbar?.onResize()
	}

	/**
	 * Convert scroll into body-based
	 * @param {{}} lenisParams
	 */
	static scrollBody(lenisParams = {}) {
		/** @type {{}} args **/
		const args = Object.assign({}, lenisParams)
		this.Lenis = new Lenis(args)

		this.isBody = true
		this.configureScrollTrigger()

		store.body.classList.add('smoothscroll-body')
		store.RAFCollection.remove(this.onRAF)
		store.RAFCollection.add(this.onRAF, 0)
	}

	/**
	 * Convert scroll into proxy-based
	 * @param {{}} lenisParams
	 */
	static scrollContainer(lenisParams = {}) {
		const args = Object.assign({
			wrapper: qs('.scroll-container'),
			content: qs('.scroll-container > div')
		}, lenisParams)
		this.Lenis = new Lenis(args)

		this.isBody = false
		this.configureScrollTrigger()

		store.body.classList.remove('smoothscroll-body')
		store.RAFCollection.remove(this.onRAF)
		store.RAFCollection.add(this.onRAF, 0)
	}

	/**
	 * @private
	 */
	static onRAF = (time) => {
		// todo - be aware of if the RAF is gsap or native
		this.Lenis.raf(time * 1000)
	}

	/**
	 * @private
	 */
	static configureScrollTrigger() {
		const scrollContainer = qs('.scroll-container')

		if (this.isBody) {
			ScrollTrigger.defaults({
				scroller: window
			})
		} else {
			ScrollTrigger.defaults({
				scroller: scrollContainer
			})
		}

		this.Lenis.on('scroll', ScrollTrigger.update)

		ScrollTrigger.scrollerProxy(scrollContainer, {
			scrollTop: (value) => {
				if (arguments.length) {
					this.isBody ? store.html.scrollTop = value : scrollContainer.scrollTop = value
				}
				return this.isBody ? store.html.scrollTop : this.Lenis.scroll
			},
			scrollLeft: (value) => {
				if (arguments.length) {
					this.isBody ? store.html.scrollLeft = value : scrollContainer.scrollLeft = value
				}
				return this.isBody ? store.html.scrollLeft : this.Lenis.scroll
			},
			getBoundingClientRect() {
				return {
					top: 0,
					left: 0,
					width: store.window.w,
					height: store.window.h
				}
			}
		})
	}

	static enableHorizontalScroll() {
		if (store.mq.touch.matches) {
			return
		}

		if (store.mq.horizontal.matches) {
			store.html.classList.add('has-horizontal-scroll')
		} else {
			store.html.classList.remove('has-horizontal-scroll')
		}

		this.Lenis.destroy()

		this.scrollbar.destroy()
		this.scrollContainer({
			orientation: store.mq.md.matches ? 'horizontal' : 'vertical',
			duration: 1.7,
			easing: (x) => {
				return 1 - Math.pow(1 - x, 4)
			}
		})
		this.scrollbar = new Scrollbar(this.Lenis)
	}

	static disableHorizontalScroll() {
		this.Lenis.destroy()
		this.scrollbar.destroy()
		this.scrollContainer({ orientation: 'vertical' })
		this.scrollbar = new Scrollbar(this.Lenis)
		store.html.classList.remove('has-horizontal-scroll')
	}

	static handleOrientationChange = (e) => {
		if (e.matches) {
			this.enableHorizontalScroll()
		} else {
			this.disableHorizontalScroll()
		}
	}
}
