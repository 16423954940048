// chunks
import { ShaderChunk } from 'three'
import { glslifyStrip } from '_utils'
import defaultPars from '_glsl/includes/default/pars.glsl'
import defaultFragPars from '_glsl/includes/default/frag.glsl'
import normalsVert from '_glsl/includes/normals/vert.glsl'
import tonemappingFrag from '_glsl/includes/tonemapping/frag.glsl'

/**
 * Dynamic material shader chunks
 */
import normalsTangentsVertPars from '_glsl/includes/normalsTangents/vertPars.glsl'
import normalsTangentsVert from '_glsl/includes/normalsTangents/vert.glsl'
import worldPositionTransformVertPars from '_glsl/includes/worldPositionTransform/vertPars.glsl'
import worldPositionTransformVert from '_glsl/includes/worldPositionTransform/vert.glsl'
import instanceOffsetTransformVert from '_glsl/includes/instanceOffsetTransform/vert.glsl'
import mvPositionTransformVert from '_glsl/includes/mvPositionTransform/vert.glsl'
import varyingsVert from '_glsl/includes/vertVaryings/vert.glsl'
import dynamicMaterialBaseFrag from '_glsl/includes/dynamicBase/frag.glsl'
import dynamicMaterialBaseFragPars from '_glsl/includes/dynamicBase/fragPars.glsl'
import dynamicHighlightMaterialFrag from '_glsl/includes/dynamicHighlight/frag.glsl'
import dynamicHighlightMaterialFragPars from '_glsl/includes/dynamicHighlight/fragPars.glsl'
import cubeUVreflectionFrag from '_glsl/includes/utils/cubeUVreflectionFrag.glsl'

/**
 * Fog shader chunks
 */
import fogVertPars from '_glsl/includes/fog/vertPars.glsl'
import fogVert from '_glsl/includes/fog/vert.glsl'
import fogFragPars from '_glsl/includes/fog/fragPars.glsl'
import fogFrag from '_glsl/includes/fog/frag.glsl'

// materials
import DynamicMaterial from './unseen/dynamic/DynamicMaterial'
import DynamicHighlightMaterial from './unseen/dynamicHighlight/DynamicHighlightMaterial'

function setupShaderChunks() {
	ShaderChunk.default_pars = glslifyStrip(defaultPars)
	ShaderChunk.default_frag_pars = glslifyStrip(defaultFragPars)
	ShaderChunk.normalsVert = glslifyStrip(normalsVert)
	ShaderChunk.tonemapping_frag = glslifyStrip(tonemappingFrag)

	ShaderChunk.cubeUVreflectionFrag = glslifyStrip(cubeUVreflectionFrag)

	/**
	 * Dynamic material shader chunks
	 */
	ShaderChunk.normals_tangents_vert_pars = glslifyStrip(normalsTangentsVertPars)
	ShaderChunk.normals_tangents_vert = glslifyStrip(normalsTangentsVert)
	ShaderChunk.world_position_transform_vert_pars = glslifyStrip(worldPositionTransformVertPars)
	ShaderChunk.world_position_transform_vert = glslifyStrip(worldPositionTransformVert)
	ShaderChunk.instance_offset_vert = glslifyStrip(instanceOffsetTransformVert)
	ShaderChunk.mv_position_transform_vert = glslifyStrip(mvPositionTransformVert)
	ShaderChunk.varyings_vert = glslifyStrip(varyingsVert)
	ShaderChunk.dynamic_frag_pars = glslifyStrip(dynamicMaterialBaseFragPars)
	ShaderChunk.dynamic_frag = glslifyStrip(dynamicMaterialBaseFrag)
	ShaderChunk.dynamic_highlight_frag_pars = glslifyStrip(dynamicHighlightMaterialFragPars)
	ShaderChunk.dynamic_highlight_frag = glslifyStrip(dynamicHighlightMaterialFrag)

	/**
	 * Fog shader chunks
	 */
	ShaderChunk.fog_vert_pars = glslifyStrip(fogVertPars)
	ShaderChunk.fog_vert = glslifyStrip(fogVert)
	ShaderChunk.fog_frag_pars = glslifyStrip(fogFragPars)
	ShaderChunk.fog_frag = glslifyStrip(fogFrag)
}

export {
	setupShaderChunks,
	DynamicMaterial,
	DynamicHighlightMaterial
}
