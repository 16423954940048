// Extra diffuse + specular
vec3 lightDirection = normalize(uLightPos - vWorldPosition.xyz);

#ifdef USE_HIGHLIGHT_DIFFUSE
    float diffuseFactor = max(dot(normalize(vNormal), lightDirection), 0.0);
    vec3 diffuse = diffuseFactor * uLightColor;
#else 
    vec3 diffuse = vec3(0.0);
#endif

vec3 viewDirExtra = normalize(cameraPosition - vWorldPosition.xyz);
vec3 reflectDir = reflect(-lightDirection, normalize(vNormal));

float specularFactor = pow(max(dot(viewDirExtra, reflectDir), 0.0), uShininess);
vec3 specular = uExtraSpecularStrength * specularFactor * uLightColor;

gl_FragColor.rgb = (uAmbient + diffuse + specular) * gl_FragColor.rgb;