import gsap from 'gsap'

import store from '_store'
import parseStrObj from '_utils/functions/parseStrObj'
import SplitTextStore from "_utils/gsap/SplitTextStore"
import { E, GlobalEvents } from '_utils'

const modules = import.meta.glob('../animations/project/**/*.js', { eager: true, import: 'default' })
const animations = {}

export default class ScrollAnimations {
	constructor() {
		this.fromAttr = 'animate-from'
		this.toAttr = 'animate-to'
		this.mqAttr = 'animate-mq'
		this.els = []
		this.resizeTweens = []

		E.on(GlobalEvents.RESIZE, this.onResize)

		for (const module in modules) {
			animations[modules[module].name] = modules[module]
		}

		this.registerGsapEffects()
	}

	build(pageEl) {
		const containerEl = pageEl || document
		const els = containerEl.querySelectorAll(`[${this.fromAttr}], [${this.toAttr}]`)
		const elsLength = els.length

		for (let i = 0; i < elsLength; i++) {
			// Skip item if it has a mq (media query) attribute and skip if it does not match
			if (
				els[i].attributes[this.mqAttr] && els[i].attributes[this.mqAttr].value !== '' &&
				!store.mq[els[i].attributes[this.mqAttr].value].matches
			) {
				continue
			}

			if (
				els[i].attributes[this.fromAttr] && els[i].attributes[this.fromAttr].value === '' &&
				els[i].attributes[this.toAttr] && els[i].attributes[this.toAttr].value === ''
			) continue

			const defaults = {
				ease: 'none',
				duration: 1.5,
				scrollTrigger: {
					trigger: els[i],
					start: 'top 80%',
					once: true
				}
			}

			let tweenEl = els[i]
			let from = false
			let to = false

			if (els[i].attributes[this.fromAttr]) {
				from = parseStrObj(`{${els[i].attributes[this.fromAttr].value}}`)
			}

			if (els[i].attributes[this.toAttr]) {
				to = {
					...defaults,
					...parseStrObj(`{${els[i].attributes[this.toAttr].value}}`)
				}
			}

			if ((to.stagger || from.stagger) && els[i].children.length) {
				tweenEl = els[i].children
			}

			defaults.scrollTrigger = Object.assign(defaults.scrollTrigger, from.scrollTrigger)
			delete from.scrollTrigger

			let tween

			if (from && from.preset) {
				const preset = from.preset
				delete from.preset
				if (animations[preset].defaults.scrollTrigger) {
					defaults.scrollTrigger = Object.assign(defaults.scrollTrigger, animations[preset].defaults.scrollTrigger)
				}
				from.scrollTrigger = defaults.scrollTrigger
				const fromCopy = { ...from }
				tween = gsap.effects[preset](tweenEl, from)

				if (animations[preset].onResize) {
					this.resizeTweens.push({ tween, preset, tweenEl, from: fromCopy, onResize: animations[preset].onResize.bind(animations[preset]) })
				}
			} else if (from && to) {
				to = Object.assign(defaults, to)
				tween = gsap.fromTo(tweenEl, from, to)
			} else if (!to && from) {
				from = Object.assign(defaults, from)
				tween = gsap.from(tweenEl, from)
			} else {
				to = Object.assign(defaults, to)
				tween = gsap.to(tweenEl, to)
			}

			if (tween.scrollTrigger) tween.scrollTrigger.disable()
			tween.pause()

			this.els.push({ el: els[i], tween })
		}
	}

	enable() {
		for (let i = 0; i < this.els.length; i++) {
			if (!this.els[i].tween.scrollTrigger) continue
			this.els[i].tween.scrollTrigger.enable()
			this.els[i].tween.scrollTrigger && this.els[i].tween.scrollTrigger.isActive && !this.els[i].tween.scrollTrigger.vars.scrub && this.els[i].tween.restart(true)
		}
	}

	registerGsapEffects() {
		for (const key in animations) {
			if (animations[key].extendTimeline) {
				gsap.registerEffect(animations[key])
			}

			if (animations[key].onResize) {
				this.resizeTweens.push({ tween: null, preset: null, tweenEl: null, from: null, onResize: animations[key].onResize.bind(animations[key]) })
			}
		}
	}

	onResize = () => {
		// revert any stored splitText instances
		SplitTextStore.revert()

		for (let i = 0; i < this.resizeTweens.length; i++) {
			const progress = this.resizeTweens[i].tween?.progress()
			if (this.resizeTweens[i].tween?.scrollTrigger) this.resizeTweens[i].tween.scrollTrigger.kill()
			this.resizeTweens[i].tween?.kill()

			this.resizeTweens[i].onResize(this.resizeTweens[i].tweenEl, this.resizeTweens[i].from, this.resizeTweens[i].tween)

			if (progress === 0) {
				this.resizeTweens[i].tween = gsap.effects[this.resizeTweens[i].preset](this.resizeTweens[i].tweenEl, { ...this.resizeTweens[i].from })
			}
		}
	}

	destroy() {
		for (let i = 0; i < this.els.length; i++) {
			if (this.els[i].tween.scrollTrigger) this.els[i].tween.scrollTrigger.kill(false)
			if (this.els[i].tween) this.els[i].tween.kill()
		}
		this.els = []
		this.resizeTweens = []
	}
}
