import CookieNotice from "./unseen/CookieNotice"

export default class Cookies extends CookieNotice {
	constructor(el) {
		super(el)
		this.on('CookieBanner:AnimateIn', this.animateIn)
	}

	animateIn = () => {
		return this.gsap.timeline({
			defaults: {
				duration: 0.6,
				ease: 'joe.out'
			}
		})
			.from(this.el, {
				y: 20
			}, 0)
			.from(this.dom.textArray, {
				y: 20,
				opacity: 0,
				stagger: 0.1
			}, 0.1)
			.fromTo(this.dom.accept, {
				opacity: 0
			}, {
				opacity: 1
			}, 0.1)
			.then(() => {
				this.dom.close.classList.add('is-visible')
			})
	}

	hide() {
		this.dom.close.classList.remove('is-visible')

		return this.gsap.timeline({
			defaults: {
				duration: 0.6,
				ease: 'joe.out'
			}
		})

			.to(this.el, {
				y: 20,
				opacity: 0
			}, 0)
			.then(() => {
				this.el.classList.remove('is-open')
				this.el.style.display = 'none'
			})
	}
}