varying vec3 toCameraVector;

varying float vFogDepth;
varying float vFogHeight;

uniform float uFogNear_D;
uniform float uFogFar_D;
uniform float uFogStrength_D;

uniform float uFogNear_H;
uniform float uFogFar_H;
uniform float uFogStrength_H;

#ifdef SOLID_FOG
    uniform vec3 uSolidFogColor; // Solid colour
#else

    // #ifdef FOG_PMREM
    //     uniform sampler2D tFogEnvMap;
    //     uniform float uBlurriness;
    // #else
    //     uniform samplerCube tFogEnvMap;
    // #endif

    uniform sampler2D tBackground;

    uniform vec3 uFogColorOverlay;
    uniform int uFogOverlayBlendMode;
    uniform float uFogOverlayAlpha;

#endif

uniform float uSceneFogMix;

uniform bool uEnableFog;
uniform bool uDebugFog;