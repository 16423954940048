import DefaultRenderer from '_taxi/renderers/DefaultRenderer'
import VintageSelector from '_components/VintageSelector'
import store from '_store'
import ANatureUntamedWebGL from '_webgl/ANatureUntamedWebGL'
import TheatreExtension from '_utils/TheatreExtension'
import { E } from '_utils/index'
import VintageDetails from '_components/VintageDetails'

export default class VintageRenderer extends DefaultRenderer {
	async onEnter() {
		// load Theatre project state and wait so properties can be set when components are initialised
		await store.theatre.manager.loadProjectState('A Nature Untamed', 'a-nature-untamed')

		store.WebGL = new ANatureUntamedWebGL(document.getElementById('a-nature-untamed'))

		// add callback to be called first when all assets are loaded since this is run before the check in super.onEnter
		store.AssetLoader.loaded.then(() => {
			store.WebGL?.build()
			for (const key in store.WebGL?.scenes) {
				store.WebGL.scenes[key]?.build && store.WebGL.scenes[key]?.build() // build all available scenes
			}
			store.WebGL?.preRender()
		})

		super.onEnter()

		store.components.add(
			VintageSelector,
			VintageDetails
		)

		// initialise the check to see if all asset promises have resolved
		store.AssetLoader.loaded.then(() => {
			store.WebGL?.start()
			store.WebGL?.scenes[store.sceneName].start() // Start the selected or first scene of the loaded scenes if it exists or BaseScene

			store.theatre.manager.enableRAF()

			if ((store.env !== 'production' && import.meta.env.MODE !== 'browsersync-no-theatre') && !window.__TheatreJS_StudioBundle._studio._store._atom._currentState.ephemeral.extensions.byId["unseen-extension"]) {
				// initilaise our custom Theatre extension when WebGL is ready
				store.theatre.studio.extend(TheatreExtension('a-nature-untamed', '/a-nature-untamed'))
			}
		})
	}

	onEnterCompleted() {
		super.onEnterCompleted()

		this.uponEnteringWithConsent(() => {
			store.components.get(VintageSelector).get(0).introTl.play()
		})

		E.emit('CheckFPS')
	}

	onLeaveCompleted() {
		super.onLeaveCompleted()

		for (const key in store.WebGL?.scenes) {
			store.WebGL.scenes[key]?.destroy() // destroy all available scenes
		}

		store.WebGL?.destroy()
		store.WebGL = null

		store.theatre.manager.disableRAF()
		store.theatre.manager.unloadProjectState('A Nature Untamed')
	}
}
