import { Euler, Quaternion, Vector3 } from 'three'

const _q = new Quaternion()
const _v = new Vector3()
const _e = new Euler()

export default function copyObjectDataTransforms(object, data) {
	if (!data) {
		console.error(`Data not found for object: ${object}`)
		return
	}

	if (object.isBufferGeometry) {
		if (data[1]) {
			let isEuler
			let isAnimated = false

			if (data[1][0].length) {
				const el = data[1][0]
				isAnimated = true
				if (el.length === 5) { // Eulers
					isEuler = true
					_e.set(el[0], el[1], el[2])
				} else { // Quaternion
					isEuler = false
					_q.set(el[0], el[1], el[2], el[3])
				}
			}

			if (!isAnimated) {
				if (data[1].length === 3) { // Euler
					isEuler = true
					_e.set(data[1][0], data[1][1], data[1][2])
				} else { // Quaternion
					_q.set(data[1][0], data[1][1], data[1][2], data[1][3])
				}
			}

			if (isEuler) {
				object.rotation.copy(_e)
			} else {
				object.applyQuaternion(_q)
			}
		}

		if (data[0]) {
			_v.set(data[0][0], data[0][1], data[0][2])

			if (data[0][0].length) { // is Array aka is animated
				const el = data[0][0]
				_v.set(el[0], el[1], el[2]) // Set the first value
			}

			object.translate(_v.x, _v.y, _v.z)
		}

		if (data[2]) {
			_v.set(data[2][0], data[2][1], data[2][2])

			if (data[2][0].length) { // is Array aka is animated
				const el = data[2][0]
				_v.set(el[0], el[1], el[2]) // Set the first value
			}

			object.scale(_v.x, _v.y, _v.z)
		}
	} else {
		if (data[0]) {
			_v.set(data[0][0], data[0][1], data[0][2])

			if (data[0][0].length) { // is Array aka is animated
				const el = data[0][0]
				_v.set(el[0], el[1], el[2]) // Set the first value
			}

			object.position.copy(_v)
		}

		if (data[1]) {
			let isEuler
			let isAnimated = false

			if (data[1][0].length) { // is Array aka is animated
				const el = data[1][0]
				isAnimated = true

				if (el.length === 5) { // Eulers
					isEuler = true
					_e.set(el[0], el[1], el[2])
				} else { // Quaternion
					isEuler = false
					_q.set(el[0], el[1], el[2], el[3])
				}
			}

			if (!isAnimated) {
				if (data[1].length === 3) { // Euler
					isEuler = true
					_e.set(data[1][0], data[1][1], data[1][2])
				} else { // Quaternion
					_q.set(data[1][0], data[1][1], data[1][2], data[1][3])
				}
			}

			if (isEuler) {
				object.rotation.copy(_e)
			} else {
				object.rotation.setFromQuaternion(_q)
			}
		}

		if (data[2]) {
			_v.set(data[2][0], data[2][1], data[2][2])

			if (data[2][0].length) { // is Array aka is animated
				const el = data[2][0]
				_v.set(el[0], el[1], el[2]) // Set the first value
			}

			object.scale.copy(_v)
		}
	}
}
