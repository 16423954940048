import store from '_store'
import { E } from '_utils'
import GlobalEvents from '_utils/GlobalEvents'

export default class FPSChecker {
	constructor() {
		this.run = false
		this.hasRun = false
		this.checkInterval = 500 // milliseconds
		this.frames = 0
		this.prevTime = 0
		this.checkCount = 0
		this.totalChecks = 0

		this.tiers = [15, 30, 40, 50]
		this.totalCheckLimit = 10
		store.gpuTier = this.tiers.length

		this.avgFps = []

		if (store.urlParams.has('forcehq')) {
			return
		}

		store.RAFCollection.add(this.check, 200)
		document.addEventListener('visibilitychange', this.onDocVisibilityChange)
		E.on(GlobalEvents.RESIZE, this.onResize)

		E.on('CheckFPS', () => {
			this.avgFps = []
			this.totalChecks = 0
			this.hasRun = true
			this.enable()
		})
	}

	enable() {
		this.checkCount = 0
		this.run = true
	}

	disable() {
		this.run = false
	}

	check = () => {
		if (!this.run) return

		this.frames++
		const time = performance.now()

		if (time >= this.prevTime + this.checkInterval) {
			const fps = (this.frames * 1000) / (time - this.prevTime)

			if (fps > 1) {
				this.avgFps.push(fps)

				this.checkCount++

				if (this.checkCount === 5) {
					this.run = false

					// remove min and max values from average calculation
					const min = Math.min(...this.avgFps)
					const max = Math.max(...this.avgFps)
					for (let i = 0; i < this.avgFps.length; i++) {
						if (this.avgFps[i] === min) {
							this.avgFps.splice(i, 1)
							break
						}
					}
					for (let i = 0; i < this.avgFps.length; i++) {
						if (this.avgFps[i] === max) {
							this.avgFps.splice(i, 1)
							break
						}
					}

					// calculate average fps
					const averageFps = Math.ceil(this.avgFps.reduce((p, c) => p + c, 0) / this.avgFps.length)
					this.avgFps = []

					store.gpuTier = this.tiers.filter(v => averageFps >= v).length

					this.totalChecks++

					// event hacking to prevent black frame on canvas resolution change
					requestAnimationFrame(() => {
						setTimeout(() => {
							E.emit('FPSChecked', store.gpuTier)
						}, 0)
					})

					if (store.gpuTier < this.totalCheckLimit && this.totalChecks < this.totalCheckLimit) {
						this.enable()
					}
				}
			}
			this.prevTime = time
			this.frames = 0
		}
	}

	onDocVisibilityChange = () => {
		if (this.run && document.visibilityState === 'visible') {
			this.prevTime = performance.now()
			this.frames = 0
		}
	}

	onResize = () => {
		if (this.run) {
			this.prevTime = performance.now()
			this.frames = 0
			this.checkCount = 0
			this.avgFps = []
		}
	}
}