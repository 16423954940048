import { E } from '_utils'

/**
 * AssetLoader is a utility class for loading and managing assets such as images, videos, and fonts.
 * It provides methods to add assets, check if they are loaded, and emit progress events.
 *
 * @class
 * @name AssetLoader
 */
export default class AssetLoader {
	constructor(progressEventName = 'AssetsProgress') {
		this.promisesToLoad = []
		this.fontsLoaded = false
		this.progressEventName = progressEventName
		this.jsons = {}

		this.createLoadedPromise()
	}

	/**
	 * Checks if the assets are loaded and emits progress if specified.
	 * @param {Object} options
	 * @param {HTMLElement} options.element - The element to check for loaded assets. Defaults to document.body.
	 * @param {boolean} options.emitProgress - Whether to emit progress while loading assets. Defaults to true.
	 * @returns {Promise} - A promise that resolves when all assets are loaded.
	 */
	checkIfLoaded({ element = document.body, emitProgress = true } = {}) {
		if (element) {
			this.element = element
			this.addFonts()
			this.addMedia()
		}

		let loadedCount = 0

		if (emitProgress) {
			for (let i = 0; i < this.promisesToLoad.length; i++) {
				this.promisesToLoad[i]
					.then(() => {
						loadedCount++
						E.emit(this.progressEventName, { percent: Math.floor((loadedCount * 100) / this.promisesToLoad.length) })
					})
			}
		}

		Promise.all(this.promisesToLoad)
			.then(() => {
				this.promisesToLoad = []
				this.loadedResolve()
				this.createLoadedPromise()
			})
			.catch(error => {
				if (error instanceof Error) {
					console.error(error.message, error.cause)
				} else {
					console.error(error)
				}
			})

		return this.loaded
	}

	/**
	 * Creates a promise that callbacks can be chained to for when all assets are ready.
	 */
	createLoadedPromise() {
		this.loaded = new Promise(resolve => {
			this.loadedResolve = resolve
		})
	}

	/**
	 * Adds a promise to the list of promises that will be checked once `AssetLoader.checkIfLoaded()` is called.
	 * @param {Promise} promise - The promise to add to the list of promises to check.
	 * @returns {Promise} - The promise that was added.
	 * @example
	 * AssetLoader.add(new Promise((resolve, reject) => {
	 *  // load asset
	 *  resolve()
	 * }))
	 */
	add(promise) {
		this.promisesToLoad.push(promise)
		return promise
	}

	/**
	 * Adds images and videos that are found in the chosen element to the AssetLoader.
	 */
	addMedia() {
		const images = this.element.querySelectorAll('img:not([loading="lazy"])')

		for (let i = 0; i < images.length; i++) {
			if (images[i].getAttribute('src')) {
				this.addImage(images[i])
			}
		}

		const videos = this.element.querySelectorAll('video[autoplay]')

		for (let i = 0; i < videos.length; i++) {
			if (videos[i].getAttribute('src') || videos[i].dataset.src) {
				this.addVideo(videos[i])
			}
		}
	}

	/**
	 * Adds an image to the AssetLoader.
	 * @param {HTMLImageElement} el - The image element to add to the AssetLoader.
	 * @returns {Promise} - The promise that was added.
	 */
	addImage(el) {
		const promise = new Promise((resolve, reject) => {
			if (el.complete) {
				if (el.naturalWidth !== 0) {
					// image already loaded so resolve
					resolve(el)
				} else {
					// image already fired error event likely due to HTTP error so manually reject
					reject(new Error('Image not loaded', { cause: el }))
				}
			} else {
				// image not loaded yet so listen for it
				el.addEventListener('load', () => {
					resolve(el)
				}, { once: true })

				el.addEventListener('error', event => {
					reject(new Error('Image not loaded', { cause: event }))
				}, { once: true })
			}
		})

		// useful for debugging
		promise._el = el

		return this.add(promise)
	}

	/**
	 * Adds a video to the AssetLoader.
	 * @param {HTMLVideoElement} el - The video element to add to the AssetLoader.
	 * @returns {Promise} - The promise that was added.
	 */
	addVideo(el) {
		const promise = new Promise((resolve, reject) => {
			el.crossOrigin = ''

			el.addEventListener('canplaythrough', () => {
				el.addEventListener('timeupdate', () => {
					// wait until the first frame has rendered before resolving
					el.currentTime = 0
					el.pause()
					resolve(el)
				}, { once: true })
			}, { once: true })

			el.addEventListener('error', event => {
				reject(new Error('Video not loaded', { cause: event }))
			}, { once: true })

			if (el.src === '' && el.dataset.src) {
				el.src = el.dataset.src
			}

			el.load()
			el.play()
		})

		// useful for debugging
		promise._el = el

		return this.add(promise)
	}

	/**
	 * Adds fonts to the AssetLoader.
	 */
	addFonts() {
		if (document.fonts) {
			this.add(document.fonts.ready)
		}

		if (!this.fontsLoaded && window.Typekit) {
			this.add(new Promise(resolve => {
				window.Typekit.load({
					active: () => {
						this.fontsLoaded = true
						resolve()
					}
				})
			}))
		}
	}

	/**
	 * Adds a JSON file to the AssetLoader.
	 * @param {string} url - The URL of the JSON file to add to the AssetLoader.
	 * @returns {Promise} - The promise that was added.
	 */
	loadJson = (url) => {
		if (!this.jsons[url]) {
			this.jsons[url] = this.add(new Promise((resolve, reject) => {
				fetch(url, {
					headers: {
						'Content-Type': 'application/json'
					}
				})
					.then(response => {
						if (!response.ok) {
							reject(new Error('Network response was not ok for request', { cause: { url, response } }))
						} else {
							resolve(response.json())
						}
					}, reject)
			}))
		}

		return this.jsons[url]
	}
}
