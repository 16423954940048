import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color } from 'three'

export default class ContourLinesParticlesMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tPosition: { value: null },
				tPrevPosition: { value: null },
				uPathSpread: { value: 1, gui: { min: 0, max: 5, step: 0.01 } },
				uColor1: { value: new Color(0xc47942) },
				uColor2: { value: new Color(0xff0000) },
				uLineLength: { value: 1, gui: { min: 0, max: 5, step: 0.01 } },
				uLineWidth: { value: 1, gui: { min: 0, max: 5, step: 0.01 } }
			},
			transparent: true
		}, options)

		super(options)
	}
}