import { Color, DoubleSide, Euler, FrontSide, Group, LinearSRGBColorSpace, Mesh, MeshPhysicalMaterial, Quaternion, SRGBColorSpace, Vector2, Vector3 } from 'three'
import createComponent from './unseen/Component'
import store from '_store'
import { mergeDeep, qs } from '_utils/index'
import { types } from '@theatre/core'
import gsap from 'gsap'

export default class WineBottle extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Wine Bottle',
			scale: new Vector3(30, 30, 30),
			position: new Vector3(0, 0, 0),
			rotation: new Euler(0.05, 0, -0.5),
			globalUniforms: {},

			pivotMovement: true,
			pointerNormalize1: 0.025,
			pointerNormalize2: 0.023,
			mouseMoveAngle: new Vector2(0.28, 0.25),
			pivotZOffset: 0
		}, options)

		super(options)

		this.smoothPos = 0
		this.scrollFactor = 1

		this.ambientGroupPos = new Vector3()
		this.ambientGroupRot = new Euler().copy(this.options.rotation)

		this.originalPosition = new Vector3().copy(this.options.position)

		this.desktopPosition = new Vector3(0, 0, 0)
		this.desktopScale = new Vector3().copy(this.options.scale)

		this.smallDesktopPosition = new Vector3(-1, 0., 0.)
		this.smallDesktopScale = new Vector3(22, 22, 22)

		// Pivot movement variables
		this.pointerPos = new Vector2()
		this._euler = new Euler()
		this._quaternion = new Quaternion()
		this.smoothMouse = [new Vector2(), new Vector2()]

		this.domSizer = qs('.js-bottle-sizer')
	}

	/**
	 * Returns the element height including margins
	 * @param element - element
	 * @returns {number}
	 */
	outerHeight(element) {
		const height = element.offsetHeight
		const style = window.getComputedStyle(element)

		return ['top', 'bottom']
			.map(side => parseInt(style[`margin-${side}`]))
			.reduce((total, side) => total + side, height)
	}

	marginTop(element) {
		const style = window.getComputedStyle(element)
		return parseInt(style[`margin-top`])
	}

	resize() {
		if (!store.mq.md.matches) { // smaller than md
			this.reposition()
		} else if (store.mq.md.matches && !store.mq.lg.matches) { // between md and lg
			// set small desktop position
			this.originalPosition.copy(this.smallDesktopPosition)
			this.position.copy(this.smallDesktopPosition)
			// this.options.position.copy(this.smallDesktopPosition)
			this.scale.copy(this.smallDesktopScale)
		} else {
			// set desktop position
			this.originalPosition.copy(this.desktopPosition)
			this.position.copy(this.desktopPosition)
			// this.options.position.copy(this.desktopPosition)
			this.scale.copy(this.desktopScale)
		}

		this.setScrollFactor()
	}

	reposition() {
		const { position, scale } = this.calculatePositionScale()

		this.position.y = position
		this.originalPosition.y = position
		this.scale.setScalar(scale)
	}

	setScrollFactor() {
		if (!store.mq.md.matches) { // smaller than md
			this.scrollFactor = 1
		} else {
			this.scrollFactor = Math.min(0.05 * (store.window.h / store.window.w), 0.030)
		}
	}

	build() {
		const geometry = this.assets.models.wineBottle.geometry
		const material = new MeshPhysicalMaterial({
			envMap: this.parent.assets.textures.envmapPMREM,
			envMapIntensity: 1.8,
			color: 0xffffff,
			normalMap: this.assets.textures.normal,
			normalScale: new Vector2(0.5, 0.5),
			roughnessMap: this.assets.textures.roughness,
			metalnessMap: this.assets.textures.metallic,
			metalness: 0.9,
			map: this.assets.textures['base-color'],
			emissive: 0xdeca74,
			emissiveIntensity: 0,
			transparent: true, // keep transparent for correct ordering
			ior: 1.6,
			specularColor: new Color(0xffd9b5),
			specularIntensity: 0.7,
			side: FrontSide
		})

		this.mesh = new Mesh(geometry, material)

		this.mesh.rotation.order = 'YXZ'

		this.ambientGroup = new Group()
		this.ambientGroup.add(this.mesh)

		this.introGroup = new Group()
		this.introGroup.add(this.ambientGroup)

		this.add(this.introGroup)

		this.resize()
	}

	playIntro() {
		const introDuration = 2

		gsap.from(this.introGroup.position, {
			duration: introDuration,
			x: -1,
			y: -0.2,
			ease: 'power3.out'
		})

		gsap.from(this.introGroup.rotation, {
			duration: introDuration,
			x: 0.3,
			z: 0.6,
			ease: 'power3.out'
		})

		gsap.from(this.mesh.rotation, {
			duration: introDuration,
			y: -3.5,
			ease: 'power3.out'
		})
	}

	/**
	 * Calculate position and scale based on the sizer element to match the bottle to the DOM
	 * @returns {{position: number, scale: number}}
	 */
	calculatePositionScale() {
		const rect = this.domSizer.getBoundingClientRect()
		const marginTop = this.marginTop(this.domSizer)

		return { position: (-rect.top - store.SmoothScroll.Lenis.scroll + marginTop - (rect.height + marginTop) * 0.5 + store.window.h * 0.5), scale: rect.height + marginTop }
	}

	pivotMovement() {
		if (this.parent.devTools?.enabled || !this.options.pivotMovement) return

		if (store.mq.touch.matches) {
			this.pointerPos.set(Math.sin(store.WebGL.clock.elapsedTime * 0.578) * 0.65, -Math.cos(store.WebGL.clock.elapsedTime * 0.523) * 0.65)
		} else {
			this.pointerPos.set(store.pointer.glNormalized.x, store.pointer.glNormalized.y)
		}

		this.smoothMouse[0].lerp(this.pointerPos, this.options.pointerNormalize1 * store.WebGL.normalizedDelta)
		this.smoothMouse[1].lerp(this.pointerPos, this.options.pointerNormalize2 * store.WebGL.normalizedDelta)

		// Animate group on mouse move
		this.translateZ(-this.options.pivotZOffset)

		this._euler.set(
			-this.smoothMouse[0].y * this.options.mouseMoveAngle.y,
			this.smoothMouse[0].x * this.options.mouseMoveAngle.x,
			0.0
		)
		this._quaternion.setFromEuler(this._euler)

		this.ambientGroup.quaternion.multiply(this._quaternion)

		this._euler.set(
			0.0,
			0.0,
			(this.smoothMouse[0].x - this.smoothMouse[1].x) * -0.05
		)
		this._quaternion.setFromEuler(this._euler)
		this.ambientGroup.quaternion.multiply(this._quaternion)

		this.ambientGroup.translateZ(this.options.pivotZOffset)
	}

	updateAmbientMovement() {
		this.ambientGroupPos.y = Math.sin(store.WebGL.clock.elapsedTime * 0.425) * 0.01
	}

	animate() {
		this.scrollTransfrom()

		// Reset rotation and position every frame so ambient movement doesn't get added indefinitely
		this.updateAmbientMovement()
		this.ambientGroup.position.copy(this.ambientGroupPos)
		this.ambientGroup.rotation.copy(this.ambientGroupRot)
		this.pivotMovement()
	}

	scrollTransfrom() {
		if (store.mq.touch.matches) {
			this.smoothPos += (store.SmoothScroll.Lenis.scroll - this.smoothPos) * 0.3
		} else { // only lerp on touch devices
			this.smoothPos = store.SmoothScroll.Lenis.scroll
		}

		this.position.y = this.originalPosition.y + this.smoothPos * this.scrollFactor
	}

	destroy() {
		super.destroy()

		this.smoothPos = 0
		this.pointerPos.set(0, 0)

		for (const key in this.assets.textures) {
			this.assets.textures[key].dispose()
		}

		for (const key in this.assets.models) {
			this.assets.models[key].geometry?.dispose()
			this.assets.models[key].material?.dispose()
		}
	}

	load() {
		const textures = ['base-color', 'normal', 'roughness', 'metallic']

		textures.forEach(textureKey => {
			store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/wine-bottle-${textureKey}.png`, { colorSpace: textureKey === 'base-color' ? SRGBColorSpace : LinearSRGBColorSpace, flipY: false }).then(texture => {
				this.assets.textures[textureKey] = texture
			})
		})

		store.AssetLoader.loadGltf(`${store.publicUrl}webgl/models/wine-bottle.glb`).then(gltf => {
			this.assets.models.wineBottle = gltf.scene.children[0]
		})
	}

	addGui() {
		const config = {
			visible: this.visible,
			// introGroupPosition: types.compound({
			// 	x: types.number(this.introGroup.position.x, { nudgeMultiplier: 0.1 }),
			// 	y: types.number(this.introGroup.position.y, { nudgeMultiplier: 0.1 }),
			// 	z: types.number(this.introGroup.position.z, { nudgeMultiplier: 0.1 })
			// }),
			// introGroupRotation: types.compound({
			// 	x: types.number(this.introGroup.rotation.x, { nudgeMultiplier: 0.1 }),
			// 	y: types.number(this.introGroup.rotation.y, { nudgeMultiplier: 0.1 }),
			// 	z: types.number(this.introGroup.rotation.z, { nudgeMultiplier: 0.1 })
			// }),
			ambientGroupRotation: types.compound({
				x: types.number(this.ambientGroupRot.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.ambientGroupRot.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.ambientGroupRot.z, { nudgeMultiplier: 0.1 })
			}),
			// meshRotationY: types.number(this.mesh.rotation.y, { nudgeMultiplier: 0.1 }), // only for intro rotation
			material: {
				envMapIntensity: types.number(this.mesh.material.envMapIntensity, { label: 'envMapIntensity', nudgeMultiplier: 0.01, range: [0, 2] }),
				color: store.theatre.helper.parseColor(this.mesh.material.color, 'color'),
				emissive: store.theatre.helper.parseColor(this.mesh.material.emissive, 'emissive color'),
				emissiveIntensity: types.number(this.mesh.material.emissiveIntensity, { label: 'emissive intensity', nudgeMultiplier: 0.01, range: [0, 1] }),
				metalness: types.number(this.mesh.material.metalness, { label: 'metalness', nudgeMultiplier: 0.01, range: [0, 1] }),
				roughness: types.number(this.mesh.material.roughness, { label: 'roughness', nudgeMultiplier: 0.01, range: [0, 1] }),
				specularIntensity: types.number(this.mesh.material.specularIntensity, { label: 'specular intensity', nudgeMultiplier: 0.01, range: [0, 1] }),
				specularColor: store.theatre.helper.parseColor(this.mesh.material.specularColor, 'specular color'),
				ior: types.number(this.mesh.material.ior, { label: 'ior', nudgeMultiplier: 0.01, range: [0, 2] }),
				transparent: this.mesh.material.transparent,
				side: types.stringLiteral(this.mesh.material.side, {
					[FrontSide]: 'FrontSide',
					[DoubleSide]: 'DoubleSide'
				}, { as: 'menu' })
			},
			pivotMovement: {
				enabled: this.options.pivotMovement,
				pointerNormalize1: types.number(this.options.pointerNormalize1, { nudgeMultiplier: 0.01 }),
				pointerNormalize2: types.number(this.options.pointerNormalize2, { nudgeMultiplier: 0.01 }),
				mouseMoveAngle: types.compound({
					x: types.number(this.options.mouseMoveAngle.x, { nudgeMultiplier: 0.01 }),
					y: types.number(this.options.mouseMoveAngle.y, { nudgeMultiplier: 0.01 })
				}),
				pivotZOffset: types.number(this.options.pivotZOffset, { nudgeMultiplier: 0.1 })
			}
		}

		const imagesCallbacks = store.theatre.helper.autoAddThreeMaterialImages(config.material, this.mesh.material)

		this.sheetObject = store.theatre.helper.addSheetObject(this.parent.prettyName, 'Wine Bottle', config)

		this.sheetObject.onValuesChange(values => {
			this.visible = values.visible

			// this.introGroup.position.set(values.introGroupPosition.x, values.introGroupPosition.y, values.introGroupPosition.z)
			// this.introGroup.rotation.set(values.introGroupRotation.x, values.introGroupRotation.y, values.introGroupRotation.z)
			this.ambientGroupRot.set(values.ambientGroupRotation.x, values.ambientGroupRotation.y, values.ambientGroupRotation.z)
			// this.mesh.rotation.y = values.meshRotationY

			for (let i = 0; i < imagesCallbacks.length; i++) {
				imagesCallbacks[i](this, values.material)
			}

			this.mesh.material.envMapIntensity = values.material.envMapIntensity
			this.mesh.material.color.copy(values.material.color)

			this.mesh.material.emissive.copy(values.material.emissive)
			this.mesh.material.emissiveIntensity = values.material.emissiveIntensity
			this.mesh.material.metalness = values.material.metalness
			this.mesh.material.roughness = values.material.roughness
			this.mesh.material.specularIntensity = values.material.specularIntensity
			this.mesh.material.specularColor.copy(values.material.specularColor)
			this.mesh.material.ior = values.material.ior

			this.mesh.material.transparent = values.material.transparent
			this.mesh.material.side = values.material.side

			this.options.pivotMovement = values.pivotMovement.enabled
			this.options.pointerNormalize1 = values.pivotMovement.pointerNormalize1
			this.options.pointerNormalize2 = values.pivotMovement.pointerNormalize2
			this.options.mouseMoveAngle.x = values.pivotMovement.mouseMoveAngle.x
			this.options.mouseMoveAngle.y = values.pivotMovement.mouseMoveAngle.y
			this.options.pivotZOffset = values.pivotMovement.pivotZOffset
		}, store.theatre.rafDriver)
	}
}