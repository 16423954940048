#include <default_pars>

varying vec2 vUv;

uniform sampler2D uBaseTexture;
uniform sampler2D uTexture;

uniform float uDieSpeed;

uniform float uTime;
uniform float uDelta;
uniform float uNormalizedDelta;

uniform float uSpawnRadius;
uniform float uSpreadRadius;
uniform float uCurlSize;
uniform float uCurlNoiseSpeed;
uniform float uCurlStrength;
uniform float uCurlTailLength;
uniform float uCurlTailSmoothness;

uniform float uLerpSpeed;

uniform bool uCurl;

uniform vec3 uMouse;
uniform vec3 uPrevMouse;
uniform vec3 uMouseVelocity;
uniform float uMouseRadius;

uniform float uSetup;

vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

float mod289(float x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
    return mod289(((x*34.0)+1.0)*x);
}

float permute(float x) {
    return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

float taylorInvSqrt(float r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

vec4 grad4(float j, vec4 ip) {
    const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
    vec4 p,s;

    p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
    p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
    s = vec4(lessThan(p, vec4(0.0)));
    p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;

    return p;
}

#define F4 0.309016994374947451

vec4 snoise4 (vec4 v) {
    const vec4  C = vec4( 0.138196601125011,0.276393202250021,0.414589803375032,-0.447213595499958);

    vec4 i  = floor(v + dot(v, vec4(F4)) );
    vec4 x0 = v -   i + dot(i, C.xxxx);

    vec4 i0;
    vec3 isX = step( x0.yzw, x0.xxx );
    vec3 isYZ = step( x0.zww, x0.yyz );
    i0.x = isX.x + isX.y + isX.z;
    i0.yzw = 1.0 - isX;
    i0.y += isYZ.x + isYZ.y;
    i0.zw += 1.0 - isYZ.xy;
    i0.z += isYZ.z;
    i0.w += 1.0 - isYZ.z;

    vec4 i3 = clamp( i0, 0.0, 1.0 );
    vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
    vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

    vec4 x1 = x0 - i1 + C.xxxx;
    vec4 x2 = x0 - i2 + C.yyyy;
    vec4 x3 = x0 - i3 + C.zzzz;
    vec4 x4 = x0 + C.wwww;

    i = mod289(i);
    float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
    vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));


    vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

    vec4 p0 = grad4(j0,   ip);
    vec4 p1 = grad4(j1.x, ip);
    vec4 p2 = grad4(j1.y, ip);
    vec4 p3 = grad4(j1.z, ip);
    vec4 p4 = grad4(j1.w, ip);

    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
    p4 *= taylorInvSqrt(dot(p4,p4));

    vec3 values0 = vec3(dot(p0, x0), dot(p1, x1), dot(p2, x2)); //value of contributions from each corner at point
    vec2 values1 = vec2(dot(p3, x3), dot(p4, x4));

    vec3 m0 = max(0.5 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0); //(0.5 - x^2) where x is the distance
    vec2 m1 = max(0.5 - vec2(dot(x3,x3), dot(x4,x4)), 0.0);

    vec3 temp0 = -6.0 * m0 * m0 * values0;
    vec2 temp1 = -6.0 * m1 * m1 * values1;

    vec3 mmm0 = m0 * m0 * m0;
    vec2 mmm1 = m1 * m1 * m1;

    float dx = temp0[0] * x0.x + temp0[1] * x1.x + temp0[2] * x2.x + temp1[0] * x3.x + temp1[1] * x4.x + mmm0[0] * p0.x + mmm0[1] * p1.x + mmm0[2] * p2.x + mmm1[0] * p3.x + mmm1[1] * p4.x;
    float dy = temp0[0] * x0.y + temp0[1] * x1.y + temp0[2] * x2.y + temp1[0] * x3.y + temp1[1] * x4.y + mmm0[0] * p0.y + mmm0[1] * p1.y + mmm0[2] * p2.y + mmm1[0] * p3.y + mmm1[1] * p4.y;
    float dz = temp0[0] * x0.z + temp0[1] * x1.z + temp0[2] * x2.z + temp1[0] * x3.z + temp1[1] * x4.z + mmm0[0] * p0.z + mmm0[1] * p1.z + mmm0[2] * p2.z + mmm1[0] * p3.z + mmm1[1] * p4.z;
    float dw = temp0[0] * x0.w + temp0[1] * x1.w + temp0[2] * x2.w + temp1[0] * x3.w + temp1[1] * x4.w + mmm0[0] * p0.w + mmm0[1] * p1.w + mmm0[2] * p2.w + mmm1[0] * p3.w + mmm1[1] * p4.w;

    return vec4(dx, dy, dz, dw) * 49.0;
}

vec3 curl( in vec3 p, in float noiseTime, in float persistence ) {

    vec4 xNoisePotentialDerivatives = vec4(0.0);
    vec4 yNoisePotentialDerivatives = vec4(0.0);
    vec4 zNoisePotentialDerivatives = vec4(0.0);

    for (int i = 0; i < 3; ++i) {

        float twoPowI = pow(2.0, float(i));
        float scale = 0.5 * twoPowI * pow(persistence, float(i));

        xNoisePotentialDerivatives += snoise4(vec4(p * twoPowI, noiseTime)) * scale;
        yNoisePotentialDerivatives += snoise4(vec4((p + vec3(123.4, 129845.6, -1239.1)) * twoPowI, noiseTime)) * scale;
        zNoisePotentialDerivatives += snoise4(vec4((p + vec3(-9519.0, 9051.0, -123.0)) * twoPowI, noiseTime)) * scale;
    }

    return vec3(
        zNoisePotentialDerivatives[1] - yNoisePotentialDerivatives[2],
        xNoisePotentialDerivatives[2] - zNoisePotentialDerivatives[0],
        yNoisePotentialDerivatives[0] - xNoisePotentialDerivatives[1]
    );

}

float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    float oldRange = oldMax - oldMin;
    float newRange = newMax - newMin;
    return (((oldValue - oldMin) * newRange) / oldRange) + newMin;
}

vec2 range(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    vec2 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    return v;
}

vec3 range(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    vec3 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    v.z = range(oldValue.z, oldMin.z, oldMax.z, newMin.z, newMax.z);
    return v;
}

float scnoise(vec3 v) {
    float t = v.z * 0.3;
    v.y *= 0.8;
    float noise = 0.0;
    float s = 0.5;
    noise += range(sin(v.x * 0.9 / s + t * 10.0) + sin(v.x * 2.4 / s + t * 15.0) + sin(v.x * -3.5 / s + t * 4.0) + sin(v.x * -2.5 / s + t * 7.1), -1.0, 1.0, -0.3, 0.3);
    noise += range(sin(v.y * -0.3 / s + t * 18.0) + sin(v.y * 1.6 / s + t * 18.0) + sin(v.y * 2.6 / s + t * 8.0) + sin(v.y * -2.6 / s + t * 4.5), -1.0, 1.0, -0.3, 0.3);
    return noise;
}

float sdLine( vec2 p, vec2 a, vec2 b ){
	float velocity = clamp(length(uMouseVelocity), 0.5, 1.5);
	vec2 pa = p - a, ba = b - a;
	float h = clamp( dot(pa, ba)/dot(ba, ba), 0.0, 1.0 );
	return length( pa - ba*h ) / velocity;
}

float sdLine( vec3 p, vec3 a, vec3 b ){
	// float velocity = clamp(length(uMouseVelocity), 0.5, 1.5);
	float velocity = max(length(uMouseVelocity), 0.5);
	vec3 pa = p - a, ba = b - a;
	float h = clamp( dot(pa, ba)/dot(ba, ba), 0.0, 1.0 );
	return length( pa - ba*h ) / velocity;
}

vec3 lerp( vec3 a, vec3 b, float t ){
    return a + (b - a) * t;
}

// function to generate a random value based on a seed
float rand(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898,78.233)))* 43758.5453123);
}


void main() {

	vec2 uv = vUv;

    vec4 originalPosFetch = texture2D(uBaseTexture, uv); // Fetch original position (aka the offsets around the source)
	vec3 offsets = originalPosFetch.xyz;
	float originalLife = originalPosFetch.w;

	vec4 position = texture2D(uTexture, uv); 
	vec3 currentPos = position.xyz;

	float dieSpeed = uDieSpeed * 0.1 * uNormalizedDelta;

    float life = position.a;

    if(life <= 0.0) {
        life = 1.0;
    }

    if (uCurl) {
        currentPos += curl(currentPos * uCurlSize, uTime * uCurlNoiseSpeed * 0.01, 0.1 + (1.0 - life) * 0.25) 
                    // * uCurlStrength * (1.0 - smoothstep(max(uCurlTailLength - uCurlTailSmoothness, 0.0), uCurlTailLength, life)) * uNormalizedDelta;
                    * uCurlStrength * uNormalizedDelta;
    }

    if(life <= 0.0 || life >= 1.0) {
        currentPos = offsets * uSpawnRadius + uMouse;
    }   

    life -= dieSpeed;

	gl_FragColor = vec4(currentPos, life);


    /* old version with particles following the mouse with a curly tail */ 

    // apply mouse interaction with SD line
    // float dir = smoothstep(1. - uMouseRadius, 1., 1.0 - min(sdLine(vec3(uv, 1.0), uPrevMouse, uMouse), 1.0));
    // vec3 followPosition = uMouse + dir * uForce;

    // // no SD line (if using lerp mouse)
    // // vec3 followPosition = uMouse;

    // /** 
	// * Lifespan of the particle
    // */

    // vec3 target;

    // if(life <= 0.0) {
    //     life = 1.0;
    //     // life = originalLife;
    // }

    // target = originalPosFetch.xyz;

    // target *= uSpawnRadius;

    // float start_spread = 1.0;
    // float finish_spread = 0.7;
    // target *= mix(uSpreadRadius, 1.0, smoothstep(finish_spread, start_spread, life)); // spread out the particles at the end of their life

    // target += followPosition;

    // // if (uCurl) {
    // //     target += curl(currentPos * uCurlSize, uTime * uCurlNoiseSpeed * 0.01, 0.1 + (1.0 - life) * 0.25) * uCurlStrength * smoothstep(0.0, 0.75, life) * uNormalizedDelta;
    // //     // target += curl(currentPos * uCurlSize, uTime * uCurlNoiseSpeed * 0.01, 0.1 + 0.5 * 0.1) * uCurlStrength * uNormalizedDelta;
    // // }

    // if(life <= 0.0 || life >= 1.0) {
    //     currentPos = target;
    // }

    // currentPos += (target - currentPos) * uLerpSpeed * uNormalizedDelta * smoothstep(0.3, 0.9, life);

    // if (uCurl) {
    //     currentPos += curl(currentPos * uCurlSize, uTime * uCurlNoiseSpeed * 0.01, 0.1 + (1.0 - life) * 0.25) 
    //                 // * uCurlStrength * (1.0 - smoothstep(uCurlTailLength - 0.2, uCurlTailLength, life)) * uNormalizedDelta;
    //                 * uCurlStrength * (1.0 - smoothstep(max(uCurlTailLength - uCurlTailSmoothness, 0.0), uCurlTailLength, life)) * uNormalizedDelta;
    // }

    // life -= dieSpeed;

	// gl_FragColor = vec4(currentPos, life);
}