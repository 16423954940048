import { mergeDeep } from "_utils/index"
import ParticleSplineManager from "./unseen/ParticleSplineManager"
import { Color, LinearSRGBColorSpace, Vector2 } from "three"
import ParticleSplineFluid from "./unseen/ParticleSplineFluid"
import store from "_store"
import SpinningParticlesMaterial from "_webgl/materials/spinningParticlesMaterial/SpinningParticlesMaterial"
import positionSimulationFrag from "_webgl/materials/spinningParticlesMaterial/positionSimulationFrag.glsl"

export default class SpinningParticles extends ParticleSplineManager {
	constructor(options = {}) {
		options = mergeDeep({
			positionSimulationFrag,
			ParticleMaterial: SpinningParticlesMaterial,
			name: 'Spinning',
			splineGroupTextureWidth: store.isLowTierGPU ? 256 : 512, // Used for the number of particles in each spline group
			splineGroupSplineTextureWidth: 64, // Spline texture width of the spline group (aka detail level of the spline)
			maxParticleCount: store.isLowTierGPU ? 45000 : 150000, // maximum particle count for each spline group
			globalMaterialUniforms: {
				uBaseColor1: { value: new Color(0x62462e) },
				uBaseColor2: { value: new Color(0x35261a) },
				uBaseColor3: { value: new Color(0x26180d) },
				uDecayColor: { value: new Color(0x56483b) },
				uAmbient: { value: new Color(0x5a3015) },
				uParticleSize: { value: 0.10 },
				uParticleSizeRange: { value: new Vector2(0.65, 1.5), gui: { step: 0.001, min: 0 } },
				uSpinAmount: { value: 1, gui: { min: 0, max: 2, step: 0.01 } },
				uUseAmbient: { value: true },

				uShininess: { value: 2.8 },
				uSpecularStrength: { value: 6.2 }
			},
			globalSimulationUniforms: {
				uFluidEnabled: { value: true },
				uFluidMaskEnabled: { value: true },
				uFluidStrength: { value: 2.8 },
				uFluidLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uCurl: { value: false },
				uCurlSize: { value: 0.26, gui: { min: 0.0, max: 1, step: 0.001 } },
				uCurlStrength: { value: 0.209, gui: { min: 0.0, max: 1.0, step: 0.01 } },
				uSplineThickness: { value: 0.3999 },
				uTravelSpeed: { value: 0.04, gui: { min: 0.000, step: 0.0001 } },
				uLerpSpeed: { value: 0.0185, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uRandomThicknessStrength: { value: 10, gui: { min: 0, step: 0.01 } },
				uRandomThicknessMax: { value: 0.15, gui: { min: 0, step: 0.01 } },
				uRandomThicknessFrequency: { value: 3.5199, gui: { min: 0, step: 0.01 } },
				uRandomThicknessThreshold: { value: 0.4, gui: { min: 0, max: 1, step: 0.01 } },

				uReversed: { value: false },
				uCurlNoiseSpeed: { value: 2 }
			},
			useMotionBlur: true
		}, options)

		super(options)

		this.load()
	}

	createSplines() {
		this.numOfGroups = this.options.numOfGroups > 0 ? this.options.numOfGroups : this.getNumberOfDistinctSplines(this.options.data, this.options.key)

		for (let i = 0; i <= this.numOfGroups - 1; i++) {
			const spline = new ParticleSplineFluid({
				positionSimulationFrag: this.options.positionSimulationFrag,
				ParticleMaterial: this.options.ParticleMaterial,
				data: this.getFilteredSplines(this.options.data, `${this.options.key}-${i}`),
				name: `${this.options.key}-${i}`,
				scene: this.options.scene,
				textureWidth: this.options.splineGroupTextureWidth,
				splineTextureWidth: this.options.splineGroupSplineTextureWidth,
				maxParticleCount: this.options.maxParticleCount,
				globalMaterialUniforms: this.options.globalMaterialUniforms,
				globalSimulationUniforms: this.options.globalSimulationUniforms,
				splineGroupIndex: i,
				materialOpts: {
					defines: {
						GRID_SIZE: 14
					}
				}
			})

			this.splines[`${this.options.key}-${i}`] = spline
		}
	}

	/**
	 * Count how many different spline groups in the data if a specific number is not specified (Distinct for this specific dataset)
	 */
	getNumberOfDistinctSplines(data, name) {
		// Filter
		const keys = Object.keys(data).filter(key => key.startsWith(name)) // Get all appropriate splines based on the input key

		const newArray = keys.map(string => {
			let trimmedKey = string.substring(name.length + 1, string.length) // get rid of the key at the beginning
			trimmedKey = Number(trimmedKey.substring(0, trimmedKey.length - 4)) // get rid of the last -001
			return trimmedKey
		})

		const counts = []
		newArray.forEach(el => (counts[el] = 1 + (counts[el] || 0))) // get counts of each unique object

		return counts.length
	}

	/**
	 * Add extra things like global textures etc. before building the splines
	 */
	preBuild() {
		Object.assign(this.options.globalMaterialUniforms, {
			tNormals: { value: this.assets.textures.normals }
		})
	}

	load() {
		this.assets = {
			models: {},
			textures: {}
		}

		const basePath = store.publicUrl + 'webgl'

		store.AssetLoader.loadTexture(`${basePath}/textures/normals/rock-01/atlas.png`, { colorSpace: LinearSRGBColorSpace }).then(texture => {
			this.assets.textures.normals = texture
		})
	}
}