import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { AdditiveBlending, Color, Vector2, Vector3 } from 'three'

export default class AmbientParticlesMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				uColor: { value: new Color(0xdb5b13) },
				uRadius: { value: 0.5, gui: { min: 0, max: 1, step: 0.001 } },
				uScaleRange: { value: new Vector2(0.05, 0.5), gui: { min: 0, step: 0.001 } },
				uBlur: { value: 1.85, gui: { min: 0, max: 2, step: 0.01 } },

				uMovementAmplitude: { value: 0.5, gui: { min: 0, step: 0.001 } },
				uMovementSpeed: { value: 1.0, gui: { min: 0, step: 0.001 } },

				uOpacity: { value: 1, gui: { min: 0, max: 1, step: 0.01 } },

				uBounds: { value: new Vector3(10, 10, 10) },
				uBoundsFadeThreshold: { value: 2, gui: { min: 0, step: 0.001 } },
				uCameraFadeThresholdNear: { value: 1, gui: { min: 0, step: 0.001 } },
				uCameraFadeThresholdFar: { value: 5, gui: { min: 0, step: 0.001 } }
			},
			defines: {
			},
			transparent: true,
			depthWrite: false,
			blending: AdditiveBlending
		}, options)

		super(options)
	}
}