import { Component } from '_components/unseen'
import store from '_store'
import { qsa } from "_utils"
import spriteJson from '../../audio/sfx.json'

export default class Subtitles extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-subtitles'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)
		this.subtitles = {}

		qsa('[data-subtitle]', this.el).forEach((subtitle) => {
			this.subtitles[subtitle.dataset.subtitle] = subtitle
		})

		this.on('AWorldApart:playAudio', this.play)
		this.on('chapterSelect', this.muteNarration)
		this.muteNarration()
	}

	muteNarration = () => {
		for (const key in spriteJson.sprite) {
			if (key !== 'bg' && store.Audio.isPlaying(`sfx.${key}`)) {
				store.Audio.fadeToStop({ key: `sfx.${key}`, duration: 0.5 })
			}
		}
	}

	play = (audio) => {
		if (audio !== 'none') {
			this.el.classList.add('is-active')
			store.Audio.play({ key: `sfx.${audio}` })

			// iterate over all subtitles and hide them
			for (const subtitle in this.subtitles) {
				this.subtitles[subtitle].classList.remove('is-active')
			}

			this.subtitles[audio].classList.add('is-active')
		} else {
			this.el.classList.remove('is-active')
		}
	}
}
