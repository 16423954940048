import { mergeDeep } from "_utils/index"
import ParticleSpline from "./ParticleSpline"
import store from "_store"
import { Vector3 } from "three"

/**
 * A component that creates a ParticleSpline instance with fluid simulation capabilities.
 */
export default class ParticleSplineFluid extends ParticleSpline {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Particle Spline Fluid',
			simulationOpts: {
				uniforms: {
					uFluidEnabled: { value: true },
					uFluidMaskEnabled: { value: true },
					uFluidStrength: { value: 20 },
					uFluidLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

					// spinning particles
					uRandomThicknessStrength: { value: 1.0, gui: { min: 0, step: 0.01 } },
					uRandomThicknessMax: { value: 1.0, gui: { min: 0, step: 0.01 } },
					uRandomThicknessFrequency: { value: 2., gui: { min: 0, step: 0.01 } },
					uRandomThicknessThreshold: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } }
				}
			}
		}, options)

		super(options)

		this.options.materialOpts.globalUniforms = options.globalMaterialUniforms
		Object.assign(this.options.simulationOpts.uniforms, options.globalSimulationUniforms)
	}

	addExtraSimulationUniforms() {
		this.positionSim.uniforms.uFluidEnabled = this.options.simulationOpts.uniforms.uFluidEnabled
		this.positionSim.uniforms.uFluidMaskEnabled = this.options.simulationOpts.uniforms.uFluidMaskEnabled
		this.positionSim.uniforms.uModelMatrix = { value: this.mesh.matrixWorld }
		this.positionSim.uniforms.uViewMatrix = { value: this.options.scene.activeCamera.matrixWorldInverse }
		this.positionSim.uniforms.uProjectionMatrix = { value: this.options.scene.activeCamera.projectionMatrix }
		this.positionSim.uniforms.uFluidStrength = this.options.simulationOpts.uniforms.uFluidStrength
		this.positionSim.uniforms.uFluidLerpSpeed = this.options.simulationOpts.uniforms.uFluidLerpSpeed

		if (store.WebGL.fluidSim) {
			this.positionSim.uniforms.tFluid = { value: store.WebGL.fluidSim.advectionSimVelocity.texture }
			this.positionSim.uniforms.tFluidMask = { value: store.WebGL.fluidSim.addForceSimMouse.texture }
		}

		this.positionSim.material.defines.USE_FLUID = true
		this.positionSim.material.needsUpdate = true

		this.positionSim.uniforms.uRandomThicknessStrength = this.options.simulationOpts.uniforms.uRandomThicknessStrength
		this.positionSim.uniforms.uRandomThicknessMax = this.options.simulationOpts.uniforms.uRandomThicknessMax
		this.positionSim.uniforms.uRandomThicknessFrequency = this.options.simulationOpts.uniforms.uRandomThicknessFrequency
		this.positionSim.uniforms.uRandomThicknessThreshold = this.options.simulationOpts.uniforms.uRandomThicknessThreshold

		this.positionSim.uniforms.uSkewThickness = this.options.simulationOpts.uniforms.uSkewThickness || { value: new Vector3(1., 1., 1.) }
	}

	animate() {
		if (store.WebGL.fluidSim && store.WebGL.fluidSim?.enabled) {
			this.positionSim.uniforms.tFluid.value = store.WebGL.fluidSim.advectionSimVelocity.texture
			this.positionSim.uniforms.tFluidMask.value = store.WebGL.fluidSim.addForceSimMouse.texture
		}

		this.positionSim.uniforms.uViewMatrix.value.copy(this.options.scene.activeCamera.matrixWorldInverse)
		this.positionSim.uniforms.uProjectionMatrix.value.copy(this.options.scene.activeCamera.projectionMatrix)

		super.animate()
	}
}