#include <fog_frag_pars>

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vInstanceWorldPosition;
varying vec3 vCameraToPlaneDirection;
varying vec3 vRandom;
varying vec3 vViewPosition;
varying vec2 vImposterUv;

uniform sampler2D tNormals;
uniform vec3 uLightPosition;
uniform vec3 uLightDirection;

uniform vec3 uColor;
uniform vec3 uAmbientColor;
uniform vec3 uEmissiveColor;
uniform vec3 uSpecularColor;
uniform float uSpecularShininess;
uniform float uSpecularStrength;
uniform vec3 uLightColor;
uniform vec3 uSunDirection;

#include <common>
#include <packing>
#include <lights_pars_begin>
#include <shadowmap_pars_fragment>
#include <shadowmask_pars_fragment>
vec3 uShadowColor = vec3(0.0);
uniform float uShadowIntensity;

#ifdef DEPTH_PACKING
varying vec2 vHighPrecisionZW;
#endif

struct BlinnPhongMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularShininess;
    float specularStrength;
};

float G_BlinnPhong_Implicit( ) {
    return 0.25;
}
float D_BlinnPhong( const in float shininess, const in float dotNH ) {
    return RECIPROCAL_PI * ( shininess * 0.5 + 1.0 ) * pow( dotNH, shininess );
}
vec3 BRDF_BlinnPhong( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in vec3 specularColor, const in float shininess ) {
    vec3 halfDir = normalize( lightDir + viewDir );
    float dotNH = saturate( dot( normal, halfDir ) );
    float dotVH = saturate( dot( viewDir, halfDir ) );
    vec3 F = F_Schlick( specularColor, 1.0, dotVH );
    float G = G_BlinnPhong_Implicit( );
    float D = D_BlinnPhong( shininess, dotNH );
    return F * ( G * D );
}

void RE_Direct_BlinnPhong( const in IncidentLight directLight, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    float dotNL = saturate( dot( geometryNormal, directLight.direction ) );
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
    reflectedLight.directSpecular += irradiance * BRDF_BlinnPhong( directLight.direction, geometryViewDir, geometryNormal, material.specularColor, material.specularShininess ) * material.specularStrength;
}

void RE_IndirectDiffuse_BlinnPhong( const in vec3 irradiance, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in BlinnPhongMaterial material, inout ReflectedLight reflectedLight ) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
}

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main() {
    vec4 normal = texture2D(tNormals, vImposterUv);

    normal.rgb = normal.rgb * 2. - 1.;
    normal.rgb = normalize(normal.rgb);

    if (normal.a < 0.5) discard;

    float alpha = normal.a;

    #ifdef DEPTH_PACKING

        gl_FragColor = vec4(vec3(1.), alpha);

    #else
        vec3 randoms = vec3(
            map(vRandom.x, 0., 1., 0.1, 1.),
            map(vRandom.y, 0., 1., 0.2, 1.8),
            map(vRandom.z, 0., 1., 0.2, 1.8)
        );

        vec4 diffuseColor = vec4( uColor * randoms.x, 1. );

        ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
        vec3 totalEmissiveRadiance = uEmissiveColor;
        vec3 irradiance = getAmbientLightIrradiance( uAmbientColor );

        IncidentLight directLight;
        DirectionalLight directionalLight;
        directionalLight.direction = normalize( uLightDirection );
        directionalLight.direction *= vNormalMatrixInverse;
        directionalLight.color = uLightColor;
        getDirectionalLightInfo( directionalLight, directLight );

        BlinnPhongMaterial material;
        material.diffuseColor = diffuseColor.rgb;
        material.specularColor = uSpecularColor;
        material.specularShininess = uSpecularShininess;
        material.specularStrength = uSpecularStrength;
        vec3 geometryPosition = - vViewPosition;
        vec3 geometryNormal = normal.xyz;
        vec3 geometryViewDir = normalize( vViewPosition );

        RE_Direct_BlinnPhong( directLight, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );
        RE_IndirectDiffuse_BlinnPhong( irradiance, geometryPosition, geometryNormal, geometryViewDir, material, reflectedLight );

        vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

        gl_FragColor = vec4(outgoingLight, 1.);

        // gl_FragColor.rgb = normal.xyz;

    #endif

    #ifdef DEPTH_PACKING
		// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
        float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        gl_FragColor = packDepthToRGBA(fragCoordZ);
    #else
        gl_FragColor.rgb = mix(gl_FragColor.rgb, uShadowColor, (1.0 - getShadowMask() ) * uShadowIntensity);
	#endif

    #include <fog_frag>

    #include <colorspace_fragment>
}