import { Component } from '_components/unseen'
import { qs } from '_utils/index'
import gsap from 'gsap'
import ChapterSelector from './ChapterSelector'
import store from '_store'

export default class WorldApartOutro extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-world-apart-outro'

	outroActive = false

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.on('AWorldApart:outroStart', this.addDarkClass)
		this.on('AWorldApart:outroEnd', this.showOutro)

		gsap.set(this.el, { autoAlpha: 0 })
	}

	addDarkClass = () => {
		this.outroActive = true
		qs('.js-header').classList.add('dark')
		qs('.js-audio-button').classList.add('dark')
		qs('.js-chapter-selector').classList.add('dark')
		qs('.js-chapter-info-toggle').classList.add('dark')
		qs('.js-subtitles').classList.add('dark')
	}

	removeDarkClass = () => {
		qs('.js-header').classList.remove('dark')
		qs('.js-audio-button').classList.remove('dark')
		qs('.js-chapter-selector').classList.remove('dark')
		qs('.js-chapter-info-toggle').classList.remove('dark')
		qs('.js-subtitles').classList.remove('dark')
	}

	showOutro = () => {
		if (!store.components.get(ChapterSelector).first().buttonClicked) {
			this.gsap.timeline()
				.to(this.el, { autoAlpha: 1, duration: 3 }, 0)
				.to(['.js-chapter-selector', '.js-chapter-info-toggle', '.js-audio-button'], {
					autoAlpha: 0,
					duration: 0.6
				}, 0)
		}
	}

	hideOutro = () => {
		this.gsap.timeline()
			.to(this.el, { autoAlpha: 0, duration: 3 }, 0)
			.to(['.js-chapter-selector', '.js-chapter-info-toggle', '.js-audio-button'], {
				autoAlpha: 1,
				duration: 0.6
			}, 0)

		this.outroActive = false
	}
}