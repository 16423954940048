import { ShaderMaterial } from 'three'

import store from '_store'
import { mergeDeep } from '_utils'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'

export default class BaseMaterial extends ShaderMaterial {
	constructor(options = {}) {
		const globalUniforms = options.globalUniforms
		delete options.globalUniforms

		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				...store.WebGL.globalUniforms
			}
		}, options)

		super(options)

		this.name = options.name || this.constructor.name

		this.globalUniforms = globalUniforms
		this.uniforms = Object.assign(this.uniforms, this.globalUniforms)
		this.extensions.derivatives = true // Ensure compatibility with WebGL1
	}

	/*
		Ensure correct cloning of uniforms with original references
	*/
	clone(uniforms) {
		const newMaterial = super.clone()
		newMaterial.uniforms = Object.assign(newMaterial.uniforms, this.globalUniforms)
		newMaterial.uniforms = Object.assign(newMaterial.uniforms, uniforms)
		return newMaterial
	}
}