#include <fog_vert_pars>

varying vec2 vUv;
varying vec2 vPosUv;
varying vec3 vNormal;
varying vec4 vWorldPosition;
varying vec3 vViewPosition;

#include <common>
#include <shadowmap_pars_vertex>

#ifdef DEPTH_PACKING
varying vec2 vHighPrecisionZW;
#endif

void main() {
    vUv = uv;

    vec4 transformedPosition = vec4(position, 1.0);

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    vec3 transformedNormal = normal;
    transformedNormal = normalMatrix * transformedNormal;
    vNormal = normalize(transformedNormal);

    #include <shadowmap_vertex>

    vec4 mvPosition = modelViewMatrix * transformedPosition;
    vViewPosition = -mvPosition.xyz;

    gl_Position = projectionMatrix * mvPosition;

    #ifdef DEPTH_PACKING
        vHighPrecisionZW = gl_Position.zw;
    #endif

    #include <fog_vert>
}