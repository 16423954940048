import { mergeDeep } from "_utils/index"
import ParticleSplineManager from "./unseen/ParticleSplineManager"
import { Color, LinearSRGBColorSpace, Vector2 } from "three"
import store from "_store"
import RootsParticleSpline from "./RootsParticleSpline"

export default class RootsParticles extends ParticleSplineManager {
	constructor(options = {}) {
		options = mergeDeep({
			key: 'root',
			name: 'Roots',
			splineGroupTextureWidth: store.isLowTierGPU ? 128 : 170, // Used for the number of particles in each spline group
			splineGroupSplineTextureWidth: 64, // Spline texture width of the spline group
			maxParticleCount: store.isLowTierGPU ? 16384 : 28900, // maximum particle count for each spline group
			globalMaterialUniforms: {
				uBaseColor1: { value: new Color(0x705238) },
				uBaseColor2: { value: new Color(0x35200e) },
				uBaseColor3: { value: new Color(0x160d05) },
				uAmbient: { value: new Color(0x5a3015) },

				uParticleSize: { value: 0.2 },
				uAmbientParticleSize: { value: 0.1 },

				uParticleSizeRange: { value: new Vector2(1.0, 1.5), gui: { step: 0.001, min: 0 } },
				uSpinAmount: { value: 1, gui: { min: 0, max: 2, step: 0.01 } },

				uMainParticleSizeRange: { value: new Vector2(0.5, 1.5) },
				uMainParticleSizeEdges: { value: new Vector2(0.0, 1.0) },

				uShininess: { value: 2.8 },
				uSpecularStrength: { value: 2.7 },

				uMainFadeOut: { value: new Vector2(0.0, 0.35), gui: { min: -1, max: 10, step: 0.01 } },
				uMidFadeOut: { value: new Vector2(0.0, 0.35), gui: { min: -1, max: 10, step: 0.01 } },
				uSmallFadeOut: { value: new Vector2(0.0, 0.2), gui: { min: -1, max: 10, step: 0.01 } }

			},
			globalSimulationUniforms: {
				uFluidMaskEnabled: { value: true },
				uFluidStrength: { value: 8 },
				uFluidLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uCurl: { value: false },
				uCurlSize: { value: 0.5, gui: { min: 0.0, max: 1, step: 0.001 } },
				uCurlStrength: { value: 0.5, gui: { min: 0.0, max: 1.0, step: 0.01 } },
				uCurlNoiseSpeed: { value: 2 },

				uSplineThickness: { value: 0.1, gui: { min: 0, max: 3, step: 0.01 } },
				uAmbientSplineThickness: { value: 1.25, gui: { min: 0, max: 5, step: 0.01 } },

				uMainSplineTaper: { value: new Vector2(0.5, 1.0), gui: { step: 0.01 } },
				uMidSplineTaper: { value: new Vector2(0.3, 0.6), gui: { step: 0.01 } },
				uSmallSplineTaper: { value: new Vector2(0.18, 0.3), gui: { step: 0.01 } },
				uAmbientSplineTaper: { value: new Vector2(0.5, 1.0), gui: { step: 0.01 } },

				uLerpSpeed: { value: 0.056, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uTravelSpeed: { value: 1.0, gui: { min: 0.000, step: 0.001 } },
				uAfterGrowTravelSpeed: { value: 1, gui: { min: 0.000, step: 0.001 } },
				uMainSplineTravelSpeedSlowFactor: { value: 0.2 },
				uMidSplineTravelSpeedSlowFactor: { value: 0.6 },
				uAmbientSplineTravelSpeedSlowFactor: { value: 0.2 },

				uRandomThicknessStrength: { value: 1, gui: { min: 0, step: 0.01 } },
				uRandomThicknessMax: { value: 0.22, gui: { min: 0, step: 0.01 } },
				uRandomThicknessFrequency: { value: 1.31, gui: { min: 0, step: 0.01 } },
				uRandomThicknessThreshold: { value: 0.19, gui: { min: 0, max: 1, step: 0.01 } },

				uMainSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } },
				uMidSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } },
				uSmallSplineNoiseReduction: { value: 0.5, gui: { min: 0, max: 1, step: 0.01 } },

				uAnimation: { value: 0, gui: { min: 0, max: 2, step: 1 } }
			},
			useMotionBlur: true
		}, options)

		Object.assign(options.globalSimulationUniforms, {
			uMainFadeOut: options.globalMaterialUniforms.uMainFadeOut,
			uMidFadeOut: options.globalMaterialUniforms.uMidFadeOut,
			uSmallFadeOut: options.globalMaterialUniforms.uSmallFadeOut
		})

		super(options)

		this.load()
	}

	createSplines() {
		this.numOfGroups = this.options.numOfGroups > 0 ? this.options.numOfGroups : this.getNumberOfDistinctSplines(this.options.data, this.options.key)

		for (let i = 0; i <= this.numOfGroups - 1; i++) {
			const spline = new RootsParticleSpline({
				data: this.getFilteredSplines(this.options.data, `${this.options.key}-${i}`),
				name: `${this.options.key}-${i}`,
				scene: this.options.scene,
				textureWidth: this.options.splineGroupTextureWidth,
				splineTextureWidth: this.options.splineGroupSplineTextureWidth,
				maxParticleCount: this.options.maxParticleCount,
				globalMaterialUniforms: this.options.globalMaterialUniforms,
				globalSimulationUniforms: this.options.globalSimulationUniforms,
				splineGroupIndex: i,
				simulationOpts: {
					uniforms: {
						// uFluidEnabled: { value: i !== 2 } // selected main growing root group
					}
				},
				materialOpts: {
					defines: {
						GRID_SIZE: 14
					}
				}
			})

			// if (i === 0) spline.options.simulationOpts.uniforms.uReversed.value = false
			// spline.options.simulationOpts.uniforms.uReversed.value = true

			this.splines[`${this.options.key}-${i}`] = spline
		}
	}

	/**
	 * Count how many different spline groups in the data if a specific number is not specified (Distinct for this specific dataset)
	 */
	getNumberOfDistinctSplines(data, name) {
		// Filter
		const keys = Object.keys(data).filter(key => key.startsWith(name)) // Get all appropriate splines based on the input key

		const newArray = keys.map(string => {
			let trimmedKey = string.substring(name.length + 1, string.length) // get rid of the key at the beginning
			trimmedKey = Number(trimmedKey.substring(0, trimmedKey.length - 4)) // get rid of the last -001
			return trimmedKey
		})

		const unique = [...new Set(newArray)] // extract unique values

		return unique.length
	}

	/**
	 * Add extra things like global textures etc. before building the splines
	 */
	preBuild() {
		Object.assign(this.options.globalMaterialUniforms, {
			tNormals: { value: this.assets.textures.normals }
		})
	}

	load() {
		this.assets = {
			models: {},
			textures: {}
		}

		const basePath = store.publicUrl + 'webgl'

		store.AssetLoader.loadTexture(`${basePath}/textures/normals/rock-01/atlas.png`, { colorSpace: LinearSRGBColorSpace }).then(texture => {
			this.assets.textures.normals = texture
		})
	}

	addGui() {
		super.addGui({ simExcludes: ['uMainFadeOut', 'uMidFadeOut', 'uSmallFadeOut'] })
	}
}