import { mergeDeep } from "_utils/index"
import ParticleSpline from "./ParticleSpline"
import store from "_store"
import { Color, HalfFloatType, RGBAFormat, WebGLRenderTarget } from "three"
import ParticleSplineMaterial from "_webgl/materials/particleSplineMaterial/ParticleSplineMaterial"
import { types } from "@theatre/core"
import positionSimulationFrag from "_webgl/materials/particleSplineMaterial/positionSimulationFrag.glsl"

export default class ParticleSplineManager {
	constructor(options = {}) {
		this.options = mergeDeep({
			positionSimulationFrag,
			ParticleMaterial: ParticleSplineMaterial,
			data: {},
			key: 'branch',
			name: 'Simple',
			numOfGroups: 0,
			scene: undefined,
			splineGroupTextureWidth: 64, // Used for the number of particles in each spline group
			splineGroupSplineTextureWidth: 64, // Spline texture width of the spline group (aka detail level of the spline)
			globalMaterialUniforms: {},
			globalSimulationUniforms: {},
			useMotionBlur: true
		}, options)

		this.splines = {}

		this.createSplines()

		this._debugMeshes = {}

		// Rendering variables for motion blur
		this._clearColor = new Color()
		this._background = new Color(0x000000)
	}

	createSplines() {
		for (let i = 1; i <= this.options.numOfGroups; i++) {
			const spline = new ParticleSpline({
				positionSimulationFrag: this.options.positionSimulationFrag,
				ParticleMaterial: this.options.ParticleMaterial,
				data: this.getFilteredSplines(this.options.data, `${this.options.key}-${i}`),
				scene: this.options.scene,
				name: `${this.options.key}-${i}`,
				textureWidth: this.options.splineGroupTextureWidth,
				splineTextureWidth: this.options.splineGroupSplineTextureWidth,
				globalMaterialUniforms: this.options.globalMaterialUniforms,
				globalSimulationUniforms: this.options.globalSimulationUniforms,
				splineGroupIndex: i
			})

			this.splines[`${this.options.key}-${i}`] = spline
		}
	}

	getFilteredSplines(data, splineKey) {
		return Object.keys(data).filter(key =>
			key.substring(0, key.length - 4) === splineKey // Trim the last 3 characters and the dash and compare with the splineKey
		).reduce((obj, key) => {
			obj[key] = data[key]
			return obj
		}, {})
	}

	build() {
		this.preBuild()

		if (this.options.useMotionBlur) this.buildMotionRT()

		// Build the splines and add them to the scene
		for (const key in this.splines) {
			this.splines[key].build()
			this.options.scene.add(this.splines[key])
		}

		// this.addGui()
	}

	/**
	 * Add extra things like global textures etc. before building the splines
	 */
	preBuild() {
		Object.assign(this.options.globalMaterialUniforms, {
			tNormal: { value: this.options.scene.assets.textures.normalMapSphere }
		})
	}

	buildMotionRT() {
		// Build a render target for the motion blur pass
		this.motionRT = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio(), {
			format: RGBAFormat,
			type: HalfFloatType
		})
	}

	animate() {
		// Animate all splines
		for (const key in this.splines) {
			this.splines[key].animate()
		}

		if (!this.options.useMotionBlur || !this.motionRT) return

		// Don't render to RT if the motion blur pass is not enabled or not present
		if (!store.WebGL.motionBlurPass || !store.WebGL.motionBlurPass?.enabled) return

		this.setMotionMaterial() // Set the motion material on all splines

		// Disable all layers except the particles layer
		this.options.scene.activeCamera.layers.disableAll()
		this.options.scene.activeCamera.layers.enable(this.options.scene.cameraLayers.particles)

		this._background = this.options.scene.background
		this.options.scene.background = null
		store.WebGL.renderer.getClearColor(this._clearColor)

		// Render the particles to the motionRT with the motionMaterial
		store.WebGL.renderer.setRenderTarget(this.motionRT)
		store.WebGL.renderer.setClearColor(0xffffff, 0) // Set alpha to 0 to get a transparent background
		store.WebGL.renderer.clear()
		store.WebGL.renderer.render(this.options.scene, this.options.scene.activeCamera)

		// Reset the renderer
		store.WebGL.renderer.setRenderTarget(null)
		store.WebGL.renderer.setClearColor(this._clearColor)
		this.options.scene.background = this._background

		this.setParticleMaterial() // Set the particle material back to the original

		// Update the motion blur pass uniforms
		store.WebGL.motionBlurPass && (store.WebGL.motionBlurPass.material.uniforms.tMotionMap.value = this.motionRT.texture)

		this.updateMotionMaterialPrevModelMatrix()

		// Enable all layers
		this.options.scene.activeCamera.layers.enableAll()
	}

	setMotionMaterial() {
		for (const key in this.splines) {
			this.splines[key].setMotionMaterial()
		}
	}

	setParticleMaterial() {
		for (const key in this.splines) {
			this.splines[key].setParticleMaterial()
		}
	}

	updateMotionMaterialPrevModelMatrix() {
		for (const key in this.splines) {
			this.splines[key].updateMotionMaterialPrevModelMatrix()
		}
	}

	// addGui() {
	// store.guiReady.then(() => {
	// 	const folder = store.Gui.addFolder({ title: `ParticleSplineManager: Global Uniforms`, expanded: false })

	// 	if (this._debugMeshes.motion) folder.addBinding(this._debugMeshes.motion, 'visible', { label: 'motion preview' })

	// 	const materialFolder = folder.addFolder({ title: 'Material', expanded: true })
	// 	const simulationFolder = folder.addFolder({ title: 'Simulation', expanded: true })

	// 	store.Gui.autoAddUniforms(materialFolder, this.options.globalMaterialUniforms)
	// 	store.Gui.autoAddUniforms(simulationFolder, this.options.globalSimulationUniforms)
	// })
	// }

	addGui({ materialExcludes = [], simExcludes = [] } = {}) {
		const config = {
			material: {},
			simulation: {}
		}

		const updateUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.material, this.options.globalMaterialUniforms, materialExcludes)
		const updateSimUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.simulation, this.options.globalSimulationUniforms, simExcludes)
		const sheetObject = store.theatre.helper.addSheetObject(this.options.scene.prettyName, `${this.options.name} Particles Spline Manager`, config, this)

		sheetObject.onValuesChange(values => {
			for (let i = 0; i < updateUniformsCallbacks.length; i++) {
				updateUniformsCallbacks[i](this, values.material)
			}
			for (let i = 0; i < updateSimUniformsCallbacks.length; i++) {
				updateSimUniformsCallbacks[i](this, values.simulation)
			}
		}, store.theatre.rafDriver)

		// Add dev sheet object for debugging
		const devConfig = {
			splineDebugs: false,
			motionDebug: false,
			motionDebugPosition: types.compound({
				x: types.number(this._debugMeshes.motion ? this._debugMeshes.motion.position.x : 0, { nudgeMultiplier: 0.1 }),
				y: types.number(this._debugMeshes.motion ? this._debugMeshes.motion.position.y : 0, { nudgeMultiplier: 0.1 }),
				z: types.number(this._debugMeshes.motion ? this._debugMeshes.motion.position.z : 0, { nudgeMultiplier: 0.1 })
			})
		}

		const studioSheetObject = store.theatre.helper.addStudioSheetObject(this.options.scene.prettyName, `${this.options.name} Spline Particles`, devConfig)

		studioSheetObject.onValuesChange(values => {
			for (const key in this.splines) {
				this.splines[key].toggleSplineHelpers(values.splineDebugs)
			}
			if (this._debugMeshes.motion) {
				this._debugMeshes.motion.visible = values.motionDebug
				this._debugMeshes.motion.position.set(values.motionDebugPosition.x, values.motionDebugPosition.y, values.motionDebugPosition.z)
			}
		}, store.theatre.rafDriver)
	}

	destroy() {
		// console.log(`Destroying ${this.constructor.name}`)
		for (const key in this.splines) {
			this.splines[key].destroy()
		}

		this.motionRT?.dispose()
		this.options.globalMaterialUniforms.tNormal?.value?.dispose()
		this.options.globalMaterialUniforms.tNormals?.value?.dispose()
	}
}