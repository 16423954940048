import { AdditiveBlending, DoubleSide, FloatType, InstancedBufferAttribute, InstancedMesh, LinearFilter, LinearMipMapLinearFilter, MathUtils, Mesh, Object3D, PerspectiveCamera, PlaneGeometry, Raycaster, Scene, Vector2, Vector3, WebGLRenderTarget } from 'three'
import createComponent from './unseen/Component'
import ContourLinesParticlesMaterial from '_webgl/materials/contourLinesParticles/ContourLinesParticlesMaterial'
import FBOHelper from '_webgl/utils/FBOHelper'
import noiseFrag from '_webgl/materials/contourLinesParticles/noise.glsl'
import particlePositionFrag from '_webgl/materials/contourLinesParticles/position.glsl'
import persistenceShader from '_webgl/materials/contourLinesParticles/persistence.glsl'
import projectionPlaneFrag from '_webgl/materials/contourLinesParticles/projectionPlaneFrag.glsl'
import projectionPlaneVert from '_webgl/materials/contourLinesParticles/projectionPlaneVert.glsl'
import store from '_store'
import BaseMaterial from '_webgl/materials/unseen/base/BaseMaterial'
import GlobalEvents from '_utils/GlobalEvents'
import { E } from '_utils/index'
import { types } from '@theatre/core'

export default class ContourLines extends createComponent() {
	constructor() {
		super({
			name: 'Contour Lines',
			gridSizeX: 100,
			gridSizeY: 100,
			width: 25,
			cameraDistance: 15
		})

		this.raycaster = new Raycaster()
		this.smoothPointer = new Vector2()
		this.prevPointerPos = new Vector2()
		this.pointerPos = new Vector2()
	}

	build() {
		this.buildNoiseGenerator()
		this.buildPersistence()
		this.buildParticles()
		this.buildTheatreObject()
		this.onResize()
	}

	buildNoiseGenerator() {
		this.noiseGenerator = new FBOHelper({
			fragmentShader: noiseFrag,
			type: FloatType,
			filter: LinearFilter,
			createTexture: false,
			width: 1024,
			height: 1024,
			uniforms: {
				uFrequency: { value: 0.26, gui: { min: 0, max: 2, step: 0.001 } },
				uAmplitude: { value: 0.7, gui: { min: 0, max: 2, step: 0.001 } },
				uRoughness: { value: 0.267, gui: { min: 0, max: 10, step: 0.001 } },
				uDetail: { value: 75, gui: { step: 0.1 } },
				uNoiseTranslation: { value: new Vector2(100, 100), gui: { step: 0.01 } }
			}
		})

		this.noiseGenerator.update()

		store.WebGL.rtViewer?.registerRT(this.noiseGenerator.renderTargets.a, 'Noise')
	}

	buildParticles() {
		const particleData = []
		const gridSizeX = this.options.gridSizeX
		const gridSizeY = this.options.gridSizeY
		const totalParticleCount = gridSizeX * gridSizeY

		const width = this.options.width
		const halfWidth = width / 2

		// create XYZ positions from a 2D grid
		for (let x = 0; x < gridSizeX; x++) {
			for (let y = 0; y < gridSizeY; y++) {
				const index = x * gridSizeX + y

				particleData.push(
					(index % gridSizeX) / (gridSizeX - 1) * width - halfWidth, // x
					Math.floor(index / gridSizeY) / (gridSizeY - 1) * width - halfWidth, // y
					index % 12 === 0 ? 1000 + MathUtils.randFloat(0.3, 1) : MathUtils.randFloat(0.3, 1), // speed
					Math.random() // life
				)
			}
		}

		this.positionSim = new FBOHelper({
			fragmentShader: particlePositionFrag,
			data: particleData,
			count: totalParticleCount,
			type: FloatType,
			uniforms: {
				tNoise: { value: this.noiseGenerator.texture },
				uDecay: { value: 0.1, gui: { min: 0, max: 5, step: 0.01 } },
				uSpeed: { value: 1.5, gui: { min: 0, max: 5, step: 0.01 } },
				uStartOffset: { value: new Vector3(0, 0, 0) },
				uPointer: { value: new Vector2() },
				uPointerRadius: { value: 1, gui: { min: 0, max: 2, step: 0.01 } },
				uCameraPosition: { value: this.linesCamera.position.clone() },
				uReset: { value: false },
				uEnablePointer: { value: true }
			}
		})

		store.WebGL.rtViewer?.registerRT(this.positionSim.renderTargets.a, 'Contour Lines Position Sim')

		this.particlesMesh = new InstancedMesh(
			new PlaneGeometry(),
			new ContourLinesParticlesMaterial({
				uniforms: {
					tPosition: { value: this.positionSim.texture },
					tPrevPosition: { value: this.positionSim.alternateTexture }
				}
			}),
			totalParticleCount
		)

		this.particlesMesh.frustumCulled = false

		const dummyObject = new Object3D()

		const pathOffsets = []
		const sizes = []

		for (let i = 0; i < totalParticleCount; i++) {
			dummyObject.scale.set(0.1, 0.015, 0.015)
			dummyObject.updateMatrix()
			this.particlesMesh.setMatrixAt(i, dummyObject.matrix)

			pathOffsets.push(MathUtils.randFloat(-0.1, 0.1))
			sizes.push(MathUtils.randFloat(0.3, 1))
		}

		this.particlesMesh.geometry.setAttribute('aFboUv', this.positionSim.fboUv.attributeInstanced)
		this.particlesMesh.geometry.setAttribute('aPathOffset', new InstancedBufferAttribute(new Float32Array(pathOffsets), 1))
		this.particlesMesh.geometry.setAttribute('aSize', new InstancedBufferAttribute(new Float32Array(sizes), 1))

		this.linesScene.add(this.particlesMesh)
	}

	buildPersistence() {
		this.linesScene = new Scene()
		this.linesCamera = new PerspectiveCamera(90, store.window.w / store.window.w, 0.1, 1000)
		this.linesCamera.position.z = this.options.cameraDistance // zoom

		this.linesRT = new WebGLRenderTarget()

		this.persistencePass = new FBOHelper({
			fragmentShader: persistenceShader,
			uniforms: {
				uBrightness: { value: 2.41, gui: { min: 0, max: 10, step: 0.01 } },
				uFadeStrength: { value: 0.025, gui: { min: 0, max: 1, step: 0.001 } },
				uReset: { value: false }
			},
			filter: LinearFilter,
			createTexture: false,
			rtOptions: {
				generateMipmaps: true,
				minFilter: LinearMipMapLinearFilter,
				anisotropy: store.WebGL.renderer.capabilities.getMaxAnisotropy() / 2
			}
		})

		store.WebGL.rtViewer?.registerRT(this.persistencePass.renderTargets.a, 'Contour Lines Persistence Pass')

		this.projectionPlane = new Mesh(
			new PlaneGeometry(1, 1, 100, 100),
			new BaseMaterial({
				name: 'ProjectionPlaneMaterial',
				vertexShader: projectionPlaneVert,
				fragmentShader: projectionPlaneFrag,
				uniforms: {
					tDiffuse: { value: this.persistencePass.texture },
					tNoise: { value: this.noiseGenerator.texture },
					uHeight: { value: 0.5, gui: { min: 0, max: 5, step: 0.001 } },
					uAlpha: { value: 1, gui: { min: 0, max: 1, step: 0.001 } }
				},
				defines: {
					USE_FOG: true,
					SOLID_FOG: true
				},
				globalUniforms: this.parent.globalUniforms,
				transparent: true,
				forceSinglePass: true,
				side: DoubleSide,
				blending: AdditiveBlending
			})
		)

		this.projectionPlane.renderOrder = -1

		this.add(this.projectionPlane)
	}

	buildTheatreObject() {
		const config = {}

		const positionCallbacks = store.theatre.helper.autoAddUniforms(config, this.positionSim.uniforms, ['uCameraPosition', 'uPointer', 'uReset'])
		const noiseCallbacks = store.theatre.helper.autoAddUniforms(config, this.noiseGenerator.uniforms)
		const particlesCallbacks = store.theatre.helper.autoAddUniforms(config, this.particlesMesh.material.uniforms)
		const persistenceCallbacks = store.theatre.helper.autoAddUniforms(config, this.persistencePass.uniforms)
		const projectionPlaneCallbacks = store.theatre.helper.autoAddUniforms(config, this.projectionPlane.material.uniforms)
		config.linesPosition = types.compound({
			x: types.number(this.linesCamera.position.x, { nudgeMultiplier: 0.1 }),
			y: types.number(this.linesCamera.position.y, { nudgeMultiplier: 0.1 }),
			z: types.number(this.linesCamera.position.z, { nudgeMultiplier: 0.1 })
		})

		const sheetObject = store.theatre.helper.addSheetObject(this.parent.prettyName, 'Contour Lines', config)

		let updateNoise = false

		sheetObject.onValuesChange(values => {
			if (
				this.noiseGenerator.uniforms.uFrequency.value !== values.uniforms.uFrequency ||
				this.noiseGenerator.uniforms.uAmplitude.value !== values.uniforms.uAmplitude ||
				this.noiseGenerator.uniforms.uRoughness.value !== values.uniforms.uRoughness ||
				this.noiseGenerator.uniforms.uDetail.value !== values.uniforms.uDetail ||
				this.noiseGenerator.uniforms.uNoiseTranslation.value.x !== values.uniforms.uNoiseTranslation.x ||
				this.noiseGenerator.uniforms.uNoiseTranslation.value.y !== values.uniforms.uNoiseTranslation.y
			) {
				updateNoise = true
			}

			positionCallbacks.forEach(callback => callback(null, values))
			noiseCallbacks.forEach(callback => callback(null, values))
			particlesCallbacks.forEach(callback => callback(null, values))
			persistenceCallbacks.forEach(callback => callback(null, values))
			projectionPlaneCallbacks.forEach(callback => callback(null, values))
			this.linesCamera.position.copy(values.linesPosition)
			this.positionSim.uniforms.uReset.value = values.uniforms.uReset

			if (updateNoise) {
				this.noiseGenerator.update()
				updateNoise = false
			}
		}, store.theatre.rafDriver)
	}

	addEvents() {
		super.addEvents()

		E.on(GlobalEvents.RESIZE, this.onResize)
	}

	removeEvents() {
		super.removeEvents()

		E.off(GlobalEvents.RESIZE, this.onResize)
	}

	animate() {
		if (!store.pointer.glNormalized.equals(this.prevPointerPos)) {
			this.prevPointerPos.copy(store.pointer.glNormalized)
			this.pointerPos.copy(store.pointer.glNormalized)

			this.idle = false
			this.autoPointer = false
		} else if (!this.idle & !this.autoPointer) {
			this.idle = true

			clearTimeout(this._pointerTimeout)
			this._pointerTimeout = setTimeout(() => {
				this.autoPointer = true
			}, 2000)
		} else if (this.autoPointer) {
			// move the pointer around automatically if idle for a while
			this.pointerPos.x = (Math.sin(store.WebGL.clock.elapsedTime * 0.578)) * 0.65
			this.pointerPos.y = -Math.cos(store.WebGL.clock.elapsedTime * 0.523) * 0.65
		}

		// this.smoothPointer.lerp(this.pointerPos, 0.)
		this.raycaster.setFromCamera(this.pointerPos, this.parent.activeCamera)

		const intersects = this.raycaster.intersectObject(this.projectionPlane)

		this.positionSim.uniforms.tNoise.value = this.noiseGenerator.texture
		this.positionSim.uniforms.uCameraPosition.value.copy(this.linesCamera.position)

		if (intersects.length) {
			this.positionSim.uniforms.uPointer.value.copy(intersects[0].uv)
		}

		this.positionSim.update()

		this.particlesMesh.material.uniforms.tPosition.value = this.positionSim.texture
		this.particlesMesh.material.uniforms.tPrevPosition.value = this.positionSim.alternateTexture

		const currentRenderTarget = store.WebGL.renderer.getRenderTarget()
		store.WebGL.renderer.setRenderTarget(this.linesRT)
		store.WebGL.renderer.render(this.linesScene, this.linesCamera)
		store.WebGL.renderer.setRenderTarget(currentRenderTarget)

		this.persistencePass.uniforms.uBaseTexture.value = this.linesRT.texture
		this.persistencePass.update()

		this.projectionPlane.material.uniforms.tDiffuse.value = this.persistencePass.texture
		this.projectionPlane.material.uniforms.tNoise.value = this.noiseGenerator.texture
	}

	onResize = () => {
		const aspect = store.window.w / store.window.h
		const size = aspect > 1 ? store.window.w : store.window.h
		this.linesRT.setSize(size * 2, size * 2)
		this.persistencePass.setSize(this.linesRT.width, this.linesRT.height)
		this.projectionPlane.scale.setScalar(10)
		this.projectionPlane.scale.x *= this.linesRT.width / this.linesRT.height
	}

	addGui() {}

	destroy() {
		this.noiseGenerator.destroy()
		this.positionSim.destroy()
		this.persistencePass.destroy()

		this.linesScene.remove(this.particlesMesh)
		this.linesRT.dispose()

		this.particlesMesh.geometry.dispose()
		this.particlesMesh.material.dispose()

		this.projectionPlane.geometry.dispose()
		this.projectionPlane.material.dispose()

		store.WebGL.rtViewer?.unregisterRT('Contour Lines Position Sim')
		store.WebGL.rtViewer?.unregisterRT('Noise')
		store.WebGL.rtViewer?.unregisterRT('Persistence Pass')
	}
}