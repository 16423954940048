import store from "_store"
import { Component } from '_components/unseen'
import gsap from 'gsap'
import { qs } from "_utils/index"
// import GlobalEvents from "_utils/GlobalEvents"

export default class Home extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-home'

	activeItem = 1

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.createItemsWithChildren()

		this.intro()
		this.setup()
	}

	setup() {
		this.on('mouseenter', this.dom.itemArray, this.onMouseEnter)
		this.on('mouseleave', this.dom.inner, this.returnState)

		if (store.mq.md.matches) {
			this.setInitialStyles()
		} else {
			this.resetStyles()
		}
	}

	createItemsWithChildren() {
		this.itemsWithChildren = []
		this.dom.itemArray.forEach((item, index) => {
			this.itemsWithChildren.push({
				element: item,
				inner: this.dom.itemInnerArray[index],
				image: this.dom.imageArray[index],
				imageInner: this.dom.imageInnerArray[index],
				btn: this.dom.btnArray[index]
			})
		})
	}

	setInitialStyles() {
		if (store.mq.nottouch.matches) {
			gsap.set(this.dom.itemInnerArray, {
				scale: 0.64,
				opacity: 0.7
			})

			gsap.set(this.dom.btnArray, {
				opacity: 0
			})

			gsap.set(this.dom.imageInner, {
				scale: 1
			})
		}

		gsap.set(this.dom.imageArray, {
			opacity: 0.2
		})
	}

	intro() {
		this.introTl = this.gsap.timeline({
			paused: true
		})

			.title(this.dom.headingArray[0], { duration: 1, ease: 'joe.out' }, 0)
			.title(this.dom.headingArray[1], { duration: 1, ease: 'joe.out' }, 0)
			.title(this.dom.headingArray[2], { duration: 1, ease: 'joe.out' }, 0)
			.call(() => {
				this.dom.lineArray.forEach((line) => {
					line.classList.add('is-visible')
				})
			}, [], .5)
			.from(this.dom.subtitleArray, {
				opacity: 0
			}, 1)
			.to(this.dom.imageContainer, {
				opacity: 1,
				duration: 1,
				ease: 'joe.inOut'
			}, 0.5)
			.call(() => {
				this.dom.btnArray.forEach(btn => {
					const btnEl = qs('.btn', btn)
					btnEl.classList.add('is-visible')
				})
			}, null, 1)
	}

	openItem = () => {
		this.gsap.mm((context) => {
			const { nottouch, md } = context.conditions

			if (nottouch && md) {
				return this.gsap.timeline({
					defaults: {
						duration: 1.4,
						ease: 'joe.inOut'
					}
				})
					.to([
						this.dom.itemArray[this.activeItem],
						this.dom.imageArray[this.activeItem]
					], {
						width: '50vw'
					}, 0)
					.to(this.dom.itemInnerArray[this.activeItem], {
						scale: 1,
						opacity: 1
					}, 0)
					.to(this.dom.btnArray[this.activeItem], {
						opacity: 1
					}, 0)
					.to(this.dom.imageArray[this.activeItem], {
						opacity: 1
					}, 0)
					.to(this.dom.imageInnerArray[this.activeItem], {
						scale: 1.2
					}, 0)
					.to(this.inactiveItems.map(item => item.element), {
						width: '25vw'
					}, 0)
					.to(this.inactiveItems.map(item => item.inner), {
						scale: 0.64,
						opacity: 0.7
					}, 0)
					.to(this.inactiveItems.map(item => item.btn), {
						opacity: 0
					}, 0)
					.to(this.inactiveItems.map(item => item.image), {
						opacity: 0.2,
						width: '25vw'
					}, 0)
					.to(this.inactiveItems.map(item => item.imageInner), {
						scale: 1
					}, 0)
			}
		})
	}

	returnState = () => {
		this.gsap.mm((context) => {
			const { nottouch, md } = context.conditions

			if (nottouch && md) {
				return this.gsap.timeline({
					defaults: {
						duration: 1.4,
						ease: 'joe.inOut'
					}
				})

					.to(this.dom.itemArray, {
						width: '33.3333vw'
					}, 0)
					.to(this.dom.btnArray, {
						opacity: 0
					}, 0)
					.to(this.dom.itemInnerArray, {
						scale: 0.64,
						opacity: 0.7
					}, 0)
					.to(this.dom.imageArray, {
						opacity: 0.2,
						width: '33.3333vw'
					}, 0)
					.to(this.dom.imageInnerArray, {
						scale: 1
					}, 0)
			} else {
				this.resetStyles()
			}
		})
	}

	resetStyles() {
		return this.gsap.timeline()
			.set(this.dom.itemArray, {
				clearProps: 'width'
			})
			.set(this.dom.itemInnerArray, {
				scale: 1,
				opacity: 1
			})
			.set(this.dom.imageArray, {
				opacity: 1,
				scale: 1
			})
			.set(this.dom.btnArray, {
				opacity: 1
			})
	}

	onMouseEnter = (e) => {
		this.activeItem = this.dom.itemArray.indexOf(e.currentTarget)

		this.inactiveItems = this.itemsWithChildren.filter((item) => item.element !== this.dom.itemArray[this.activeItem])

		this.openItem()
	}

	onResize = () => {
		this.setup()
	}
}
