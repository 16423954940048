import { Component } from '_components/unseen'

import gsap from 'gsap'
import SplitText from '_utils/gsap/SplitText'
import { SmoothScroll } from '_utils'

export default class VintageSelector extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-vintage-selector'

	isDiscoverBtnVisible = false
	hasError = false
	isYearCorrect = false

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		gsap.set(this.dom.yearNumberArray, { xPercent: -50, yPercent: -50 })

		this.buildIntroTl()
		this.addEvents()
	}

	buildIntroTl() {
		this.introText = new SplitText(this.dom.introText, { type: 'lines' })

		this.introTl = gsap.timeline({
			paused: true,
			defaults: {
				ease: 'joe.out',
				duration: 0.4
			}
		})

		this.introTl
			.fadeUp(this.introText.lines, { stagger: 0.1 }, 0.1)
			.fromTo(this.dom.discoverBtn, {
				opacity: 0
			}, {
				opacity: 0.4,
				onComplete: () => {
					gsap.set(this.dom.discoverBtn, { clearProps: 'opacity' })
				}
			}, 0.3)
			.call(() => {
				this.dom.discoverBtn.classList.add('is-visible')
			}, [], .3)
			.fadeUp(this.dom.yearInputArray, {
				stagger: 0.02,
				duration: 0.5
			}, 0.4)
			.title(this.dom.introTitle, { duration: 1 }, 0.2)
	}

	getYear() {
		return this.dom.yearInputArray.map((input) => input.value).join('')
	}

	checkYear(isLastItem = false) {
		const year = this.getYear()
		this.isYearCorrect = false

		if (year in window.vintages) {
			this.isYearCorrect = true
			this.emit('Vintage:updateDetailsPage', window.vintages[year])
			this.dom.discoverBtn.classList.remove('disabled')
			this.isDiscoverBtnVisible = true
		} else if (isLastItem) {
			this.showError()
		}
	}

	showError() {
		this.hasError = true
		gsap.to(this.dom.error, { autoAlpha: 1, duration: 0.3, ease: 'joe.out' })
	}

	hideError() {
		if (!this.hasError) return

		this.hasError = false
		gsap.to(this.dom.error, { autoAlpha: 0, duration: 0.3, ease: 'joe.in' })
	}

	hideDiscoverBtn() {
		this.dom.discoverBtn.classList.add('disabled')
		this.isDiscoverBtnVisible = false
	}

	/**
	 * Events.
	 */

	addEvents() {
		this.on('click', this.dom.discoverBtn, this.showDetailsPage)

		this.on('keydown', this.dom.yearInputArray[this.dom.yearInputArray.length - 1], (e) => {
			if (e.key === 'Enter') {
				this.showDetailsPage()
			}
		})

		this.dom.yearInputArray.forEach((input) => {
			this.on('input', input, this.onYearInput)
			this.on('keydown', input, this.onKeyDown)
		})

		this.on('VintageSelector:goBackToIntro', this.goBackToIntro)
	}

	goBackToIntro = () => {
		const inputs = this.dom.yearInputArray

		// Clear year inputs
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].value = ''
			this.dom.yearNumberArray[i].innerHTML = ''
			this.isYearCorrect = false
			this.isDiscoverBtnVisible = false
			this.dom.discoverBtn.classList.add('disabled')
		}

		this.el.classList.add('overflow-hidden')
		gsap.set(this.dom.intro, { display: 'flex', opacity: 1 })
	}

	showDetailsPage = () => {
		if (!this.isYearCorrect) return

		gsap.to(this.dom.intro, {
			display: 'none',
			opacity: 0,
			duration: 0.3,
			onComplete: () => {
				SmoothScroll.Lenis?.scrollTo(0, {
					force: true,
					immediate: true
				})

				this.el.classList.remove('overflow-hidden')
				this.emit('VintageDetails:showDetails')
			}
		})
	}

	onYearInput = (e) => {
		if (!e.target.value) return
		this.hideError()
		this.hideDiscoverBtn()

		e.target.value = e.data

		this.dom.yearNumberArray[e.target.dataset.index].innerHTML = e.target.value
		gsap.fromTo(this.dom.yearNumberArray[e.target.dataset.index], { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: 'joe.out' })

		let areAllFilled = true
		const inputs = this.dom.yearInputArray

		for (let i = 0; i < inputs.length; i++) {
			if (inputs[i].value === "") {
				areAllFilled = false
				break
			}
		}

		const index = this.dom.yearInputArray.indexOf(e.target)
		if (index < (this.dom.yearInputArray.length - 1)) {
			inputs[index + 1].focus()
			inputs[index + 1].value = ''
			this.dom.yearNumberArray[index + 1].innerHTML = ''
		}

		if (areAllFilled) {
			this.checkYear(index === (this.dom.yearInputArray.length - 1))
		}
	}

	onKeyDown = (e) => {
		if (e.key === 'Backspace') {
			this.hideError()
			this.hideDiscoverBtn()

			this.dom.yearNumberArray[e.target.dataset.index].innerHTML = ''

			if (e.target.value === '') {
				const index = this.dom.yearInputArray.indexOf(e.target)

				if (index > 0) {
					this.dom.yearInputArray[index - 1].focus()
					this.dom.yearNumberArray[index - 1].innerHTML = ''
				}
			} else {
				e.target.value = ''
			}
		}
	}
}
