import store from "_store"
import { mergeDeep } from "_utils/index"
import FBOHelper from "_webgl/utils/FBOHelper"
import { FloatType, LinearFilter, Ray, RepeatWrapping, Vector2, Vector3 } from "three"
import addForceFrag from "_glsl/fluidSim/addForce.frag"
import curlFrag from "_glsl/fluidSim/curl.frag"
import vorticityFrag from "_glsl/fluidSim/vorticity.frag"
import divergenceFrag from "_glsl/fluidSim/divergence.frag"
import clearFrag from "_glsl/fluidSim/clear.frag"
import pressureFrag from "_glsl/fluidSim/pressure.frag"
import advectionFrag from "_glsl/fluidSim/advection.frag"
import gradientSubtractFrag from "_glsl/fluidSim/gradientSubtract.frag"

export default class FluidSim {
	constructor(options = {}) {
		this.options = mergeDeep({
			name: this.constructor.name,
			textureWidth: 128,
			raycastCamera: null,
			raycastPointer: null,
			simulationOpts: {
				uniforms: {
					uMouseRadius: { value: 0.25, gui: { min: 0, max: 1.0, step: 0.01 } },
					uPressure: { value: 0.96, gui: { min: 0.5, max: 0.99, step: 0.01 } }, // how fast the mouse trail fades out

					uCurlStrength: { value: 0.25, gui: { min: 0, step: 0.01 } },

					uDissipation: { value: 0.2, gui: { min: 0, max: 0.99, step: 0.01 } },

					uClearValue: { value: 0.1, gui: { min: 0, max: 0.99, step: 0.01 } },

					uViscosity: { value: 0.08, gui: { min: 0, max: 1.0, step: 0.01 } }
				}
			},
			fluid: {
				force: 20.0,
				iterations: 3,
				forceClamp: 100
			}
		}, options)

		this.prevMouse = new Vector2(0, 0)
		this._pointer = new Vector2(0, 0)

		this._ray = new Ray()
		this._mouse3d = this._ray.origin

		this._debugMeshes = {}

		this.isActive = false

		this.enabled = false

		this.build()
	}

	build() {
		this.advectionSimVelocity = new FBOHelper({
			fragmentShader: advectionFrag,
			uniforms: {
				tVelocity: { value: null },
				uDissipation: this.options.simulationOpts.uniforms.uDissipation
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false
		})

		this.curlSim = new FBOHelper({
			fragmentShader: curlFrag,
			uniforms: {
				tVelocity: { value: this.advectionSimVelocity.texture }
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.vorticitySim = new FBOHelper({
			fragmentShader: vorticityFrag,
			uniforms: {
				tVelocity: { value: this.advectionSimVelocity.texture },
				tCurl: { value: this.curlSim.texture },
				uCurlStrength: this.options.simulationOpts.uniforms.uCurlStrength
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.divergenceSim = new FBOHelper({
			fragmentShader: divergenceFrag,
			uniforms: {
				tVelocity: { value: this.advectionSimVelocity.texture },
				uViscosity: this.options.simulationOpts.uniforms.uViscosity
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.clearSim = new FBOHelper({
			fragmentShader: clearFrag,
			uniforms: {
				tPressure: { value: null },
				uClearValue: this.options.simulationOpts.uniforms.uClearValue
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.pressureSim = new FBOHelper({
			fragmentShader: pressureFrag,
			uniforms: {
				tPressure: { value: null },
				tDivergence: { value: this.divergenceSim.texture }
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false
		})

		this.gradientSubtractSim = new FBOHelper({
			fragmentShader: gradientSubtractFrag,
			uniforms: {
				tPressure: { value: this.pressureSim.texture },
				tVelocity: { value: this.advectionSimVelocity.texture }
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.advectionSimMouse = new FBOHelper({
			fragmentShader: advectionFrag,
			uniforms: {
				tVelocity: { value: this.advectionSimVelocity.texture },
				uDissipation: this.options.simulationOpts.uniforms.uDissipation
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false
		})

		this.addForceSimMouse = new FBOHelper({
			fragmentShader: addForceFrag,
			uniforms: {
				tBase: { value: this.advectionSimMouse.texture },
				uMouse: { value: new Vector2(-1, -1) },
				uPrevMouse: { value: new Vector2(-1, -1) },
				uMouseVelocity: { value: new Vector2() },
				uForce: { value: new Vector3() },
				uMouseRadius: this.options.simulationOpts.uniforms.uMouseRadius,
				uPressure: this.options.simulationOpts.uniforms.uPressure
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		this.addForceSimVelocity = new FBOHelper({
			fragmentShader: addForceFrag,
			uniforms: {
				tBase: { value: this.advectionSimVelocity.texture },
				uMouse: { value: new Vector2(-1, -1) },
				uPrevMouse: { value: new Vector2(-1, -1) },
				uMouseVelocity: { value: new Vector2() },
				uForce: { value: new Vector3() },
				uMouseRadius: this.options.simulationOpts.uniforms.uMouseRadius,
				uPressure: this.options.simulationOpts.uniforms.uPressure
			},
			width: this.options.textureWidth,
			height: this.options.textureWidth,
			filter: LinearFilter,
			type: FloatType,
			wrap: RepeatWrapping,
			createTexture: false,
			pingPong: false
		})

		// store.WebGL.rtViewer?.registerRT(this.addForceSimVelocity.renderTargets.a, 'Fluid Sim addForceSimVelocity') // Debugging - add more RTs to the viewer if necessary

		// this.addGui()
	}

	updateMouseRay(pointer) {
		this._ray.origin.setFromMatrixPosition(this.options.raycastCamera.matrixWorld)
		this._ray.direction.set(pointer.x, pointer.y, 0.5).unproject(this.options.raycastCamera).sub(this._ray.origin).normalize()

		const distance = this._ray.origin.length() / Math.cos(Math.PI - this._ray.direction.angleTo(this._ray.origin))
		this._ray.origin.add(this._ray.direction.multiplyScalar(distance * 1.0))
	}

	addEvents() {
		if (this.isActive) return
		store.RAFCollection.add(this.onRaf, 0)
		this.isActive = true
	}

	removeEvents() {
		if (!this.isActive) return
		store.RAFCollection.remove(this.onRaf)
		this.isActive = false
	}

	onRaf = () => {
		if (!this.enabled) return

		// Unnecessary to update the advection sim first
		// this.advectionSimVelocity.update() // Update the advection sim first to render the first ping
		// this.advectionSimMouse.update() // Update the advection sim first to render the first ping

		/**
		 * Add curl
		 */
		this.curlSim.uniforms.tVelocity.value = this.advectionSimVelocity.texture
		this.curlSim.update(false)

		/**
		 * Add vorticity
		 */
		this.vorticitySim.uniforms.tVelocity.value = this.advectionSimVelocity.texture
		this.vorticitySim.uniforms.tCurl.value = this.curlSim.texture
		this.vorticitySim.update(false)

		/**
		 * Update the mouse force
		 */
		this.addForceSimMouse.uniforms.uPrevMouse.value.copy(this.prevMouse)
		this.addForceSimVelocity.uniforms.uPrevMouse.value.copy(this.prevMouse)

		if (this.options.raycastCamera !== null) {
			// Update new pointer position
			this._pointer.copy(store.pointer.glScreenSpace)

			this.updateMouseRay(this._pointer)

			this._pointer.copy(this._mouse3d.project(this.options.raycastCamera)) // project back to gl screen space

			if (this.prevMouse.x === -1 && this.prevMouse.y === -1) {
				this.prevMouse.set(this._pointer.x, this._pointer.y)
			}

			this.addForceSimVelocity.uniforms.uMouse.value.set(this._pointer.x, this._pointer.y)

			this.addForceSimVelocity.uniforms.uForce.value.set((this._pointer.x - this.prevMouse.x) * this.options.fluid.force, (this._pointer.y - this.prevMouse.y) * this.options.fluid.force, 0.0)

			if (this.options.fluid.forceClamp) {
				this.addForceSimVelocity.uniforms.uForce.value.clampScalar(-this.options.fluid.forceClamp, this.options.fluid.forceClamp)
			}

			this.prevMouse.set(this._pointer.x, this._pointer.y)
		} else {
			const { x, y } = store.pointer.glScreenSpace
			if (this.prevMouse.x === -1 && this.prevMouse.y === -1) { this.prevMouse.set(x, y) }
			this.addForceSimVelocity.uniforms.uMouse.value.set(x, y)

			this.addForceSimVelocity.uniforms.uForce.value.set((x - this.prevMouse.x) * this.options.fluid.force, (y - this.prevMouse.y) * this.options.fluid.force, 0.0)

			if (this.options.fluid.forceClamp) {
				this.addForceSimVelocity.uniforms.uForce.value.clampScalar(-this.options.fluid.forceClamp, this.options.fluid.forceClamp)
			}

			this.prevMouse.set(x, y)
		}

		this.addForceSimVelocity.uniforms.uMouseVelocity.value.set(
			(this.addForceSimVelocity.uniforms.uMouse.value.x - this.addForceSimVelocity.uniforms.uPrevMouse.value.x) / 16,
			(this.addForceSimVelocity.uniforms.uMouse.value.y - this.addForceSimVelocity.uniforms.uPrevMouse.value.y) / 16
		)

		// Update the same mouse positions for the velocity sim
		this.addForceSimMouse.uniforms.uMouse.value.copy(this.addForceSimVelocity.uniforms.uMouse.value)
		this.addForceSimMouse.uniforms.uMouseVelocity.value.copy(this.addForceSimVelocity.uniforms.uMouseVelocity.value)
		this.addForceSimMouse.uniforms.uForce.value.set(1, 1, 1)

		this.addForceSimMouse.uniforms.tBase.value = this.advectionSimMouse.texture
		this.addForceSimMouse.update(false)

		this.addForceSimVelocity.uniforms.tBase.value = this.vorticitySim.texture
		this.addForceSimVelocity.update()

		/**
		 * Projection step 1: Calculate the divergence
		*/
		this.divergenceSim.uniforms.tVelocity.value = this.addForceSimVelocity.texture
		this.divergenceSim.update()

		/**
		 * Projection step 2: Clear the pressure texture
		*/
		this.clearSim.uniforms.tPressure.value = this.pressureSim.texture // unsure if we start with an empty texture on each frame or only at initialization step
		this.clearSim.update()

		/**
		 * Projection step 3: Calculate the pressure
		*/
		this.pressureSim.uniforms.tPressure.value = this.clearSim.texture
		this.pressureSim.uniforms.tDivergence.value = this.divergenceSim.texture
		for (let i = 0; i < this.options.fluid.iterations; i++) {
			this.pressureSim.update()
		}

		/**
		 * Projection step 4: Subtract the gradient
		*/
		this.gradientSubtractSim.uniforms.tPressure.value = this.pressureSim.texture
		this.gradientSubtractSim.uniforms.tVelocity.value = this.addForceSimVelocity.texture
		this.gradientSubtractSim.update() // this is the visualisation of the final fluid texture ?!

		/**
		 * Last step: Advection 2.0 to complete the loop
		*/
		this.advectionSimVelocity.uniforms.tVelocity.value = this.gradientSubtractSim.texture // Quantity to advect
		this.advectionSimVelocity.update()

		this.advectionSimMouse.uniforms.tVelocity.value = this.addForceSimMouse.texture // Quantity to advect
		this.advectionSimMouse.update()
	}

	destroy() {
		// console.log('Destroying FluidSim')

		this.removeEvents()

		this.advectionSimVelocity.destroy()
		this.curlSim.destroy()
		this.vorticitySim.destroy()
		this.divergenceSim.destroy()
		this.clearSim.destroy()
		this.pressureSim.destroy()
		this.gradientSubtractSim.destroy()
		this.addForceSimMouse.destroy()
		this.addForceSimVelocity.destroy()
	}
}