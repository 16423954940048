#include <default_pars>
#include <logdepthbuf_pars_fragment>

uniform sampler2D alphaMap;
uniform float useMap;
uniform float useAlphaMap;
uniform float useDash;
uniform float dashArray;
uniform float dashOffset;
uniform float dashRatio;
uniform float visibility;
uniform float alphaTest;
uniform vec2 repeat;

uniform float uDarkenAmount;
uniform float uControlAmount;

uniform float uTailBegin;
uniform float uTailSmoothness;

uniform float uBeginColorEdge;
uniform float uFadeColorEdge;

uniform vec3 uFadeColor;

varying vec2 vUv;
varying vec4 vColor;
varying float vCounters;
varying vec4 vRandom;

uniform float uRevealProgress;
uniform float uHideProgress;

vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main() {

    #include <logdepthbuf_fragment>

    vec4 c = vColor;

    c.rgb = mix(c.rgb, uFadeColor * vRandom.x, smoothstep(1.0 - uBeginColorEdge, uFadeColorEdge, vUv.x));

    // c.rgb = rgb2hsv(c.rgb);

    // Add fading in when taking control
    #ifdef USE_MOUSE
        c.rgb *= mix(uDarkenAmount, 1.0, uControlAmount);
        // c.b *= mix(0.5, 1.0, uControlAmount);

        // change alpha
        c.a = mix(c.a, 1.0, uControlAmount);
    #else
        // c.b *= 0.5;
        c.rgb *= uDarkenAmount;
    #endif

    // c.rgb = hsv2rgb(c.rgb);

    // if( useAlphaMap == 1. ) c.a *= texture2D( alphaMap, vUv * repeat ).a;
    
    if( useDash == 1. ){
        c.a *= ceil(mod(vCounters + dashOffset, dashArray) - (dashArray * dashRatio));
    }
    gl_FragColor = c;

    float mapRandomLength = map(vRandom.a, 0.0, 1.0, -0.35, 0.35);

    gl_FragColor.a *= (1.0 - smoothstep(clamp(uTailBegin + mapRandomLength, 0.0, 0.95), clamp(uTailBegin + mapRandomLength + uTailSmoothness, 0.0, 1.0), 1.0 - vUv.x));

    gl_FragColor.a *= 1.0 - smoothstep(uRevealProgress - 0.2, uRevealProgress, 1.0 - vUv.x);

    gl_FragColor.a *= smoothstep(uHideProgress - 0.2, uHideProgress, vUv.x); // hide from back to front

    gl_FragColor.a *= step(vCounters, visibility);

    if( gl_FragColor.a < alphaTest ) discard;

    gl_FragColor = clamp(gl_FragColor, vec4(0.0), vec4(1.0));
}