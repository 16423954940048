import { Mesh, PlaneGeometry, RGBADepthPacking, RepeatWrapping, ShaderMaterial, Vector3 } from 'three'
import createComponent from './unseen/Component'
import WaveTerrainMaterial from '_webgl/materials/waveTerrain/WaveTerrainMaterial'
import store from '_store'
import { mergeDeep } from '_utils/index'

export default class WaveTerrain extends createComponent(Mesh) {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Wave Terrain',
			scale: new Vector3(10, 10, 10),
			position: new Vector3(0, 0, 0),
			globalUniforms: {}
		}, options)

		super(options)

		this.rotation.x = -Math.PI / 2

		this.position.copy(this.options.position)
		this.scale.copy(this.options.scale)
	}

	build() {
		this.geometry = new PlaneGeometry(1, 1, 200, 200)
		this.material = new WaveTerrainMaterial({
			uniforms: {
				tNormal: { value: this.assets.textures.normal }
			},
			globalUniforms: Object.keys(this.options.globalUniforms).length ? { ...this.options.globalUniforms } : this.parent.globalUniforms
		})

		this.customDepthMaterial = new ShaderMaterial({
			vertexShader: this.material.vertexShader,
			fragmentShader: this.material.fragmentShader,
			uniforms: this.material.uniforms,
			defines: {
				DEPTH_PACKING: RGBADepthPacking
			}
		})

		this.castShadow = true
		this.receiveShadow = true
	}

	load() {
		store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/terrain-normal-03.png`, { wrapping: RepeatWrapping }).then(texture => {
			this.assets.textures.normal = texture
		})
	}

	addGui() {
		this.sheetObject = store.theatre.helper.autoAddObject(this, this.parent.prettyName, {
			exclude: ['uLightPosition', 'uLightDirection', 'uLightColor', 'uNoiseSeed', 'uNoiseHeight', 'uNoiseScale', 'uNoiseTimeScale']
		})
	}
}