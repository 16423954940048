#include <default_pars>
#include <default_frag_pars>
#include <packing>
#include <fog_frag_pars>

varying vec3 vRandom;
varying vec2 vUv;
varying float vLife;

varying vec3 vViewPosition;

varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vInstanceWorldPosition;
varying vec3 vCameraToPlaneDirection;

uniform sampler2D tNormalsFront;
uniform sampler2D tNormalsBack;

uniform vec3 uLightPosition;
uniform vec3 uLightDirection;

uniform sampler2D tNormal;
uniform vec3 uBaseColor1;
uniform vec3 uBaseColor2;
uniform vec3 uBaseColor3;

uniform vec3 uAmbient;

uniform float uShininess;
uniform float uSpecularStrength;

uniform vec2 uResolution;

#ifdef DYNAMIC_SHADOWS
    uniform bool uDebugShadow;
    uniform bool receiveShadow;
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>
    uniform vec3 uShadowColor;
    uniform float uShadowIntensity;
#endif

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

#ifdef MOTION_BLUR
    varying float vPrevLife;
    varying vec4 vMotion;
#endif

vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

const float gridSize = 5.;
const float angles = 2.;

void main() {

    // vec2 uv = vUv;
    // vec2 scaleOrigin = vec2(0.5, 0.5);
    // vec2 shiftCurrent = clamp(round(vCameraToPlaneDirection.xy * angles) * (1. / angles), vec2(-1.), vec2(1.));
    // scaleOrigin += shiftCurrent * 0.5;
    // uv = (uv - scaleOrigin) / gridSize + scaleOrigin;

    // vec4 normalCurrent;

    // if (vNormal.z >= 0.) {
    //     normalCurrent = texture2D(tNormalsFront, uv);
    // } else {
    //     normalCurrent = texture2D(tNormalsBack, uv);
    // }

    // normalCurrent.rgb = normalCurrent.rgb * 2. - 1.;
    // normalCurrent.rgb = normalize(normalCurrent.rgb);

    // vec4 normal = normalCurrent;

    // if (normal.a < 0.5) discard;

    // float alpha = normal.a;
    float alpha = 1.0 - step(0.9, 2.0 * length(vUv - 0.5)); // circular points

    if (alpha < 0.5) discard;

    #ifdef DEPTH_PACKING

        gl_FragColor = vec4(vec3(1.), alpha);

    #else

        vec3 lightDirection = normalize(uLightPosition - vWorldPosition.xyz);
        // vec3 lightDirection = normalize(uLightDirection);
        lightDirection *= vNormalMatrixInverse;

        // single normal map
        vec3 normal = texture2D(tNormal, vUv).xyz * 2.0 - 1.0;
        normal.y *= -1.0;

        normal = inverse(vNormalMatrix) * normal;
        normal = normalize(normal);

        vec3 randoms = vec3(
            map(vRandom.x, 0., 1., 0.5, 1.0),
            map(vRandom.y, 0., 1., 0.2, 1.8),
            map(vRandom.z, 0., 1., 0.2, 1.8)
        );

        // Add colour variation
        vec3 color = mix(uBaseColor1, uBaseColor2, vRandom.x);
        color = mix(color, uBaseColor3, vRandom.y);

        // Simple diffuse lighting
        float diffuse = clamp(dot(normal.rgb, lightDirection), 0.0, 1.0);

        // Specular highlight
        vec3 viewDir = normalize(cameraPosition - vWorldPosition.xyz);
        vec3 reflectDir = reflect(-lightDirection, normal.rgb);
        float specular = pow(max(dot(viewDir, reflectDir), 0.0), uShininess) * uSpecularStrength;

        /*
        *   Apply basic lighting
        */
        color = (uAmbient + diffuse + specular) * color;

        gl_FragColor = vec4(color, alpha);

    #endif

    #ifdef DEPTH_PACKING
		// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
        float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        gl_FragColor = packDepthToRGBA(fragCoordZ);
    #else
        #ifdef DYNAMIC_SHADOWS
            // gl_FragColor.rgb = mix(gl_FragColor.rgb, gl_FragColor.rgb * uShadowColor, (1.0 - getShadowMask() ) * uShadowIntensity);

            if (uDebugShadow) {
                gl_FragColor.rgb = vec3( getShadowMask() );
            }
        #endif
	#endif


    #ifdef MOTION_BLUR
        if (step(0.1, vPrevLife) < 0.5) // Get rid of newly spawned points to prevent strange glitches
            discard;

        gl_FragColor.rgb = vMotion.xyz;

        gl_FragColor.a = alpha * vMotion.a;
    #endif

    #include <tonemapping_fragment>
    #include <colorspace_fragment>

    #include <fog_frag>
}