import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color, Texture, Vector2, UniformsLib, FrontSide } from 'three'

export default class RootsMaterial extends BaseMaterial {
	constructor(options = {}, scene) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tPosition: { value: null },
				tPreviousPosition: { value: null },
				tNormal: { value: new Texture() },
				uDebugRootType: { value: false },
				uBaseColor1: { value: new Color(0x62462e) },
				uBaseColor2: { value: new Color(0x35261a) },
				uBaseColor3: { value: new Color(0x26180d) },
				uAmbient: { value: new Color(0x000000) },
				uParticleSize: { value: 0.4, gui: { step: 0.01 } },
				uAmbientParticleSize: { value: 0.4, gui: { step: 0.01 } },
				uParticleSizeRange: { value: new Vector2(1.0, 1.5), gui: { tooltip: 'Min and Max values for particle size.' } },
				uDebugShadow: { value: false },
				uShininess: { value: 25.0 },
				uSpecularStrength: { value: 1.5 },
				uSpinAmount: { value: 1, gui: { min: 0, max: 2, step: 0.01 } },

				uMainParticleSizeRange: { value: new Vector2(1.8, 1.) },
				uMainParticleSizeEdges: { value: new Vector2(0.8, 0.1) },

				...UniformsLib.lights,
				...scene.globalUniforms.shadow,
				...scene.globalUniforms.sun,
				...scene.globalUniforms.fog
			},
			defines: {
				DYNAMIC_SHADOWS: true,
				DEPTH_PACKING: false,
				MOTION_BLUR: false,
				USE_FOG: true,
				SOLID_FOG: false,
				GRID_SIZE: 14
			},
			lights: true,
			side: FrontSide,
			transparent: true
		}, options)

		super(options)
	}
}