#include <default_pars>
#include <packing>

#ifdef DYNAMIC_SHADOWS
    #include <shadowmap_pars_vertex> 
#endif

#include <fog_vert_pars>

attribute vec2 aFboUv;
attribute vec3 aRandom;
attribute float aScale;
attribute vec2 reference;

varying vec2 vUv;
varying float vLife;

uniform sampler2D tOriginalPosition;
uniform sampler2D tPosition;
uniform sampler2D tPreviousPosition;

uniform float uTime;
uniform float uParticleSize;
uniform vec2 uParticleSizeRange;

varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vInstanceWorldPosition;
varying vec3 vCameraToPlaneDirection;
varying vec3 vRandom;

uniform float uShapeThreshold;
uniform float uSplineThickness;
uniform vec3 uSkewThickness;

uniform float uRandomThicknessMax;
uniform float uRandomThicknessStrength;
uniform float uRandomThicknessFrequency;
uniform float uRandomThicknessThreshold;

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

#ifdef MOTION_BLUR
    uniform sampler2D tPrevPosition;
    uniform mat4 uPrevModelViewMatrix;
    varying float vPrevLife;
    varying vec4 vMotion;
#endif

// Rotates a vector based on the velocity of the particle
vec3 rotate3D(vec3 v, vec3 vel) {
    vec3 newpos = v;
    vec3 up = vec3(0, 1, 0);
    vec3 axis = normalize(cross(up, vel));
    float angle = acos(dot(up, normalize(vel)));
    newpos = newpos * cos(angle) + cross(axis, newpos) * sin(angle) + axis * dot(axis, newpos) * (1. - cos(angle));
    return newpos;
}

mat4 rotation3d(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;

  return mat4(
    oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
    0.0,                                0.0,                                0.0,                                1.0
  );
}

float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    float oldRange = oldMax - oldMin;
    float newRange = newMax - newMin;
    return (((oldValue - oldMin) * newRange) / oldRange) + newMin;
}

vec2 range(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    vec2 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    return v;
}

vec3 range(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    vec3 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    v.z = range(oldValue.z, oldMin.z, oldMax.z, newMin.z, newMax.z);
    return v;
}

float scnoise(vec3 v) {
    float t = v.z * 0.3;
    v.y *= 0.8;
    float noise = 0.0;
    float s = 0.5;
    noise += range(sin(v.x * 0.9 / s + t * 10.0) + sin(v.x * 2.4 / s + t * 15.0) + sin(v.x * -3.5 / s + t * 4.0) + sin(v.x * -2.5 / s + t * 7.1), -1.0, 1.0, -0.3, 0.3);
    noise += range(sin(v.y * -0.3 / s + t * 18.0) + sin(v.y * 1.6 / s + t * 18.0) + sin(v.y * 2.6 / s + t * 8.0) + sin(v.y * -2.6 / s + t * 4.5), -1.0, 1.0, -0.3, 0.3);
    return noise;
}

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 randomSphericalCoord(vec2 seed) {

    float r = (0.5 + rand(seed) * 0.5);
    float phi = (rand(seed + vec2(0.5, 0.15)) - 0.5) * PI;
    float theta = rand(seed + vec2(1.4, 1.3)) * PI * 2.;

    return vec3(
        r * sin(theta) * cos(phi),
        r * sin(theta) * sin(phi),
        r * cos(theta)
    );
}

vec3 getSplineThickness(vec2 uv, float random) {
    vec3 offsets = randomSphericalCoord(uv);

    float thicknessNoise = map(scnoise(vec3(uv, 0.0) * uRandomThicknessFrequency), -1.0, 1.0, 0., uRandomThicknessMax) * uRandomThicknessStrength;
    float radius = mix(uSplineThickness, uSplineThickness + thicknessNoise, step(uRandomThicknessThreshold, random)); // select about half of the particles and add some noise to their positions
    return offsets * radius * uSkewThickness;
}

void main() {

    vUv = uv;
    vRandom = aRandom;

    vNormalMatrix = normalMatrix;

    float randomVal = rand(aFboUv);

    vec4 originalPosition = texture2D( tOriginalPosition, reference );
    vec4 particlePosition = texture2D( tPosition, reference );
    #ifdef MOTION_BLUR
        vec4 prevParticlePosition = texture2D( tPrevPosition, reference ); // Get the previous position
        vPrevLife = prevParticlePosition.w;
    #endif

    float life = particlePosition.w;
    vLife = life;

    vec4 transformedPosition = vec4( position, 1.0 );

    // billboard towards camera
    vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
    vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
    transformedPosition.xyz = right * transformedPosition.x + up * transformedPosition.y;

    #ifdef MOTION_BLUR
        vec4 previousPosition = vec4(position, 1.0);
        vec3 up_prev = vec3(uPrevModelViewMatrix[0][1], uPrevModelViewMatrix[1][1], uPrevModelViewMatrix[2][1]);
        vec3 right_prev = vec3(uPrevModelViewMatrix[0][0], uPrevModelViewMatrix[1][0], uPrevModelViewMatrix[2][0]);
        previousPosition.xyz = right_prev * previousPosition.x + up_prev * previousPosition.y;
    #endif

    vInstanceWorldPosition = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;

    #ifdef USE_INSTANCING
        transformedPosition = instanceMatrix * transformedPosition;
        vInstanceWorldPosition = (modelMatrix * vec4( particlePosition.xyz, 1. )).xyz;

        #ifdef MOTION_BLUR
            previousPosition = instanceMatrix * previousPosition;
        #endif        
    #endif

    float scale = uParticleSize * mix(uParticleSizeRange.x, uParticleSizeRange.y, aRandom.z);
    // scale *= mix(0.7, 1.0, smoothstep(0.1, 0.60, life)); // Decrease with life
    // scale *= mix(0., 1.0, smoothstep(0.0, 0.20, life)); // Decrease at the end of life

    float size = min((1.0 - life) * 2.0, life * 2.0);
    size = clamp(size, 0., 1.);

    float noiseSelect = step(uShapeThreshold, randomVal);
    size *= (1. - noiseSelect + 0.8) * 0.3;
    scale *= size;

    transformedPosition.xyz *= scale;

    #ifdef MOTION_BLUR
        float scale_prev = uParticleSize * mix(uParticleSizeRange.x, uParticleSizeRange.y, aRandom.z);
        float size_prev = min((1.0 - vPrevLife) * 2.0, vPrevLife * 2.0);
        size_prev = clamp(size, 0., 1.);
        scale_prev *= size_prev;

        previousPosition.xyz *= scale_prev;
    #endif

    transformedPosition.xyz += particlePosition.xyz;

    #ifdef MOTION_BLUR
        previousPosition.xyz += prevParticlePosition.xyz;
    #endif

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    mat3 normalMatrixInverse = inverse(mat3(normalMatrix));
    vec3 transformedNormal = normal;
    vec3 transformedNormalInverse = normal;

    #ifdef USE_INSTANCING
        mat3 m = mat3( instanceMatrix );

        transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormal = m * transformedNormal;

        transformedNormalInverse /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormalInverse = m * transformedNormalInverse;
    #endif

    transformedNormal = normalMatrix * normal;
    transformedNormalInverse = normalMatrixInverse * normal;

    vNormal = normalize(transformedNormal);
    vNormalInverse = normalize(transformedNormalInverse);

    // mat4 rotationMatrix = rotation3d(aRandom, aRandom.x * 4.);
    mat4 rotationMatrix = rotation3d(vec3(0.0, 1.0, 0.0), vRandom.y * PI * 2.);
    vNormal = (inverse(rotationMatrix) * vec4(vNormal, 1.)).xyz;
    
    vNormalMatrixInverse = normalMatrixInverse;
    vNormalMatrix = mat3(normalMatrix);

    vCameraToPlaneDirection = normalize(vNormalInverse + cameraPosition - vInstanceWorldPosition);

    if (vNormal.z < 0.0) {
        vCameraToPlaneDirection.y = -vCameraToPlaneDirection.y;
    }

    vCameraToPlaneDirection = (rotationMatrix * vec4(vCameraToPlaneDirection, 1.)).xyz;

    #ifdef DYNAMIC_SHADOWS
        // #include <shadowmap_vertex>

	    // Offsetting the position used for querying occlusion along the world normal can be used to reduce shadow acne.
	    vec3 shadowWorldNormal = inverseTransformDirection( transformedNormal, viewMatrix );
	    vec4 shadowWorldPosition;

        #if defined( USE_SHADOWMAP )
			shadowWorldPosition = vec4(worldPosition.xy, worldPosition.z, worldPosition.w) + vec4( shadowWorldNormal * directionalLightShadows[ 0 ].shadowNormalBias, 0 );
			vDirectionalShadowCoord[ 0 ] = directionalShadowMatrix[ 0 ] * shadowWorldPosition;
            // vDirectionalShadowCoord[ 0 ].z -= 0.5;
	    #endif

    #endif

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;

    vViewPosition = - mvPosition.xyz;

    #ifdef DEPTH_PACKING
        vHighPrecisionZW = gl_Position.zw;
    #endif

    #ifdef MOTION_BLUR
        vec4 prevGlPosition = projectionMatrix * uPrevModelViewMatrix * previousPosition;
        vMotion = vec4((gl_Position.xyz - prevGlPosition.xyz), smoothstep(0.0, 0.2, particlePosition.w));
    #endif

    #include <fog_vert>

}