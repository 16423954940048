varying vec2 vUv;

varying vec2 vUvDown;
varying vec2 vUvUp;
varying vec2 vUvLeft;
varying vec2 vUvRight;

varying vec2 vUvDownLeft;
varying vec2 vUvUpRight;
varying vec2 vUvUpLeft;
varying vec2 vUvDownRight;

uniform vec2 uResolution;

void main() {

    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    vec2 texelSize = vec2(1.0 / uResolution.x, 1.0 / uResolution.y);

    vUvDown = uv + vec2(0.0, -1.0) * texelSize;
	vUvUp = uv + vec2(0.0, 1.0) * texelSize;
	vUvRight = uv + vec2(1.0, 0.0) * texelSize;
	vUvLeft = uv + vec2(-1.0, 0.0) * texelSize;

	vUvDownLeft = uv + vec2(-1.0, -1.0) * texelSize;
	vUvUpRight = uv + vec2(1.0, 1.0) * texelSize;
	vUvUpLeft = uv + vec2(-1.0, 1.0) * texelSize;
	vUvDownRight = uv + vec2(1.0, -1.0) * texelSize;

}