import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Matrix4, Vector3 } from 'three'

export default class GodraysMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tNormalDepth: { value: null },
				uMaxSamples: { value: 100 },
				uBias: { value: 0 },
				uObjectMaskStrength: { value: 0.8 },
				uDecayStart: { value: 0.0 },
				uDecayEnd: { value: 0.3 },
				uLightDepthMap: { value: null },
				uLight: {
					value: {
						position: new Vector3(),
						projectionMatrix: new Matrix4(),
						viewMatrix: new Matrix4()
					}
				},
				uCamera: {
					value: {
						position: new Vector3(),
						projectionMatrixInverse: new Matrix4(),
						viewMatrixInverse: new Matrix4()
					}
				}
			}
		}, options)

		super(options)
	}
}