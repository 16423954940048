// ported from https://github.com/keijiro/Klak/blob/master/Assets/Klak/Motion/BrownianMotion.cs

import { Euler, Matrix4, Quaternion, Vector3 } from 'three'

function BrownianMotion() {
	this.position = new Vector3()
	this.rotation = new Quaternion()
	this.scale = new Vector3(1, 1, 1)
	this.matrix = new Matrix4()

	this.enablePositionNoise = true
	this.enableRotationNoise = true

	this.positionFrequency = 0.25
	this.rotationFrequency = 0.25

	this.positionAmplitude = 0.3
	this.rotationAmplitude = 0.003

	this.positionScale = new Vector3(1, 1, 1)
	this.rotationScale = new Vector3(1, 1, 0)

	this.positionFractalLevel = 3
	this.rotationFractalLevel = 3

	this.times = new Float32Array(6)
	this.rehash()
}

export default BrownianMotion
const _p = BrownianMotion.prototype
_p.rehash = rehash
_p.update = update

const _e = new Euler()
const _v = new Vector3()

const FBM_NORM = 1 / 0.75

function rehash() {
	for (let i = 0; i < 6; i++) {
		this.times[i] = Math.random() * -10000
	}
}

function update(dt) {
	dt = dt === undefined ? 1000 / 60 : dt
	let i
	if (this.enablePositionNoise) {
		for (i = 0; i < 3; i++) {
			this.times[i] += this.positionFrequency * dt
		}

		_v.set(
			_fbm(this.times[0], this.positionFractalLevel),
			_fbm(this.times[1], this.positionFractalLevel),
			_fbm(this.times[2], this.positionFractalLevel)
		)

		_v.multiply(this.positionScale)
		_v.multiplyScalar(this.positionAmplitude * FBM_NORM)

		this.position.copy(_v)
	}

	if (this.enableRotationNoise) {
		for (i = 0; i < 3; i++) {
			this.times[i + 3] += this.rotationFrequency * dt
		}

		_v.set(
			_fbm(this.times[3], this.rotationFractalLevel),
			_fbm(this.times[4], this.rotationFractalLevel),
			_fbm(this.times[5], this.rotationFractalLevel))

		_v.multiply(this.rotationScale)
		_v.multiplyScalar(this.rotationAmplitude * FBM_NORM)
		_e.set(_v.x, _v.y, _v.z)
		this.rotation.setFromEuler(_e)
	}
	this.matrix.compose(this.position, this.rotation, this.scale)
}

const _noise = new Simple1DNoise()

function Simple1DNoise() {
	const MAX_VERTICES = 256
	const MAX_VERTICES_MASK = MAX_VERTICES - 1
	let amplitude = 1
	let scale = 1

	const r = []

	for (let i = 0; i < MAX_VERTICES; ++i) {
		r.push(Math.random())
	}

	const getVal = function(x) {
		const scaledX = x * scale
		const xFloor = Math.floor(scaledX)
		const t = scaledX - xFloor
		const tRemapSmoothstep = t * t * (3 - 2 * t)

		// / Modulo using &
		const xMin = xFloor & MAX_VERTICES_MASK
		const xMax = (xMin + 1) & MAX_VERTICES_MASK

		const y = lerp(r[xMin], r[xMax], tRemapSmoothstep)

		return y * amplitude
	}

	/**
     * Linear interpolation function.
     * @param a The lower integer value
     * @param b The upper integer value
     * @param t The value between the two
     * @returns {number}
     */
	const lerp = function(a, b, t) {
		return a * (1 - t) + b * t
	}

	// return the API
	return {
		getVal,
		setAmplitude: function(newAmplitude) {
			amplitude = newAmplitude
		},
		setScale: function(newScale) {
			scale = newScale
		}
	}
}

function _fbm(x, octave) {
	let f = 0.0
	let w = 0.5
	for (let i = 0; i < octave; i++) {
		f += w * _noise.getVal(x)
		x *= 2.0
		w *= 0.5
	}
	return f
}