export default class SplitTextStore {
	static splits = []

	static add(split) {
		this.splits.push(split)
	}

	static revert() {
		this.splits.forEach(split => {
			split.revert()
		})

		this.splits = []
	}
}
