import { qsa } from "_utils/index"
import TooltipToggle from "./TooltipToggle"
import gsap from "gsap"

export default class ChapterInfoToggle extends TooltipToggle {
	static selector = '.js-chapter-info-toggle'

	defaultToggle = false

	constructor(el) {
		super(el)

		this.contentArray = qsa('.js-chapter-info-toggle\\:content', this.target)

		this.on('ChapterSelector:EnterChapter', this.updateToggleContent)
		// this.on('ChapterSelector:Show', this.show)

		gsap.set(this.el, {
			autoAlpha: 0
		})
	}

	updateToggleContent = (chapterId) => {
		if (this.openProp) {
			this.close().then(() => {
				this.updateClasslist(chapterId)
			})
		} else {
			this.updateClasslist(chapterId)
		}
	}

	updateClasslist = (chapterId) => {
		this.contentArray.forEach(item => {
			item.classList.remove('active')

			if (item.dataset.chapter === chapterId) {
				item.classList.add('active')
			}
		})
	}

	show = () => {
		this.gsap
			.to(this.el, {
				autoAlpha: 1,
				duration: 0.6,
				delay: 0.6
			})
	}
}