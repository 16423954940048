varying vec2 vUv;

uniform vec2 uTexelSize;

uniform sampler2D uTexture;
uniform sampler2D tVelocity;

uniform float uNormalizedDelta;
uniform float uDelta;
uniform float uDissipation;

void main () {

    // this version produces highly different results on different framerates - not good
    // uTexture is input velocity
    // vec2 coord = vUv - texture2D(uTexture, vUv).xy * uTexelSize; // follow the velocity field "back in time"   
    // write the advected quantity to the output fragment
    // gl_FragColor = (1./uNormalizedDelta) * uDissipation * texture2D(tVelocity, coord); // quantity to advect - in this example: velocity
    // gl_FragColor.a = 1.0;

    // produces basically the same result as the above code - better on multiple screens
    // vec2 coord = vUv - texture2D(uTexture, vUv).xy * uTexelSize;
    vec2 coord = vUv - uNormalizedDelta * texture2D(uTexture, vUv).xy * uTexelSize; // or this ??
    vec4 result = texture2D(tVelocity, coord); 
    float decay = 1.0 + uDissipation * uNormalizedDelta;
    gl_FragColor = result/decay;
}

