import DefaultRenderer from '_taxi/renderers/DefaultRenderer'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '_utils/gsap/SplitText'
import { GlobalEvents, qs, E, SmoothScroll, qsa } from '_utils'
import store from '_store'
import { Rive } from '@rive-app/canvas'

export default class TimelineRenderer extends DefaultRenderer {
	headerSTs = []

	onBeforeAssetLoaderCheck() {
		super.onBeforeAssetLoaderCheck()

		this.rives = qsa('.js-rive')
		this.riveInstances = new Map()

		if (this.rives.length) {
			this.rives.forEach(canvas => {
				store.AssetLoader.add(
					new Promise((resolve, reject) => {
						const r = new Rive({
							src: `${store.publicUrl}rive/${canvas.id}.riv`,
							canvas,
							autoplay: true,
							onLoad: () => {
								this.resizeCanvas(canvas, r)

								setTimeout(() => {
									resolve()
								}, 1)
							}
						})

						this.riveInstances.set(canvas, r)
					})
				)
			})
		}
	}

	resizeCanvas(canvas, r) {
		const bcr = canvas.getBoundingClientRect()

		canvas.removeAttribute('width')
		canvas.removeAttribute('height')
		canvas.removeAttribute('style')

		if (store.mq.horizontal.matches) {
			const ratio = bcr.height / r.bounds.maxY

			canvas.setAttribute('width', r.bounds.maxX * ratio)
			canvas.setAttribute('height', r.bounds.maxY * ratio)
			canvas.style.width = `${r.bounds.maxX * ratio}px`
		} else {
			const ratio = bcr.width / r.bounds.maxX

			canvas.setAttribute('width', r.bounds.maxX * ratio)
			canvas.setAttribute('height', r.bounds.maxY * ratio)
			canvas.style.height = `${r.bounds.maxY * ratio}px`
		}

		r.resizeToCanvas()
	}

	registerComponents() {
		super.registerComponents()

		this.buildRiveIntersectionObserver()
	}

	onEnter() {
		super.onEnter()

		const content = qs('.js-timeline\\:content')
		const title = qs('.js-timeline\\:title')
		const scroll = qs('.js-timeline\\:scroll')

		this.parallaxes = []
		this.headerSTs = []

		store.mq.md.addEventListener('change', SmoothScroll.handleOrientationChange)

		// need to ensure this works responsively
		this.tl = gsap.timeline({
			paused: true,
			delay: .2,
			defaults: { ease: 'joe.out' }
		})

		if (store.mq.horizontal.matches) {
			content.style.perspective = '200px'
			content.style.perspectiveOrigin = 'center center'
			const contentSplit = new SplitText(content, {
				type: 'lines',
				lineThreshold: 0.3
			})

			const config = { duration: 1.4 }

			if (store.body.classList.contains('clos-du-marquis')) {
				config.splitType = 'words'
				config.stagger = '0.2'
			}

			this.tl
				.title(title, config, 0)
				.fromTo(contentSplit.lines, {
					rotateX: -15,
					xPercent: 25,
					z: -100,
					opacity: 0
				}, {
					rotateX: 0,
					xPercent: 0,
					z: 0,
					opacity: 1,
					stagger: 0.1,
					ease: 'joe.out',
					duration: 1.4
				}, 0)
				.call(() => {
					scroll.classList.add('is-visible')
				}, [], .6)

			this.addParallax()
		}

		this.addHeaderColorChange()

		E.on(GlobalEvents.RESIZE, this.onResize)
	}

	onEnterCompleted() {
		super.onEnterCompleted()

		this.uponEnteringWithConsent(() => {
			this.tl.play()

			store.SmoothScroll.Lenis.resize()
		})
	}

	onLeaveCompleted() {
		super.onLeaveCompleted()

		this.parallaxes.forEach(parallax => parallax.kill())
		this.headerSTs.forEach(st => st.kill())
		this.riveInstances.forEach(rive => rive.cleanup())
		this.tl.kill()

		this.parallaxes = []
		this.headerSTs = []
		this.riveInstances = new Map()
		this.tl = null

		E.off(GlobalEvents.RESIZE, this.onResize)
	}

	buildRiveIntersectionObserver() {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				const canvas = entry.target

				const riveInstance = this.riveInstances.get(canvas)

				if (riveInstance) {
					if (entry.isIntersecting) {
						riveInstance.play()
					} else {
						riveInstance.pause()
					}
				}
			})
		}, {
			threshold: 0.1
		})

		this.riveInstances.forEach((_, canvas) => {
			observer.observe(canvas)
		})
	}

	addParallax() {
		this.parallaxes.push(
			gsap.to('.js-timeline\\:text', {
				xPercent: -50,
				scrollTrigger: {
					trigger: '.js-timeline\\:intro',
					start: 'left left',
					horizontal: true,
					end: 'right left',
					scrub: true
				}
			})
		)

		this.parallaxes.push(
			gsap.to('.js-timeline\\:title', {
				xPercent: -8,
				scrollTrigger: {
					trigger: '.js-timeline\\:intro',
					start: 'left left',
					horizontal: true,
					end: 'right left',
					scrub: true
				}
			})
		)
	}

	addHeaderColorChange() {
		this.headerSTs.forEach(st => st.kill())
		this.headerSTs = []

		const header = qs('.js-header')

		this.headerSTs.push(
			ScrollTrigger.create({
				trigger: '.js-timeline\\:intro',
				horizontal: store.mq.horizontal.matches,
				end: store.mq.horizontal.matches ? `right left+=${store.window.w / 2}` : 'bottom top',
				onLeave: () => {
					header.classList.add('dark')
				},
				onEnterBack: () => {
					header.classList.remove('dark')
				}
			})
		)

		this.headerSTs.push(
			ScrollTrigger.create({
				trigger: '.js-timeline\\:light-header-start',
				horizontal: store.mq.horizontal.matches,
				start: store.mq.horizontal.matches ? `left left+=${store.window.w / 2}` : 'top top',
				onEnter: () => {
					header.classList.remove('dark')
				},
				onLeaveBack: () => {
					header.classList.add('dark')
				}
			})
		)

		this.headerSTs.push(
			ScrollTrigger.create({
				trigger: '.js-timeline\\:light-header-end',
				horizontal: store.mq.horizontal.matches,
				end: store.mq.horizontal.matches ? `right left+=${store.window.w / 2}` : 'bottom top',
				onEnterBack: () => {
					header.classList.remove('dark')
				},
				onLeave: () => {
					header.classList.add('dark')
				}
			})
		)
	}

	setupScroll() {
		SmoothScroll.enableHorizontalScroll()
	}

	onResize = () => {
		if (!store.mq.horizontal.matches && this.parallaxes.length) {
			this.parallaxes.forEach(parallax => {
				parallax.kill()
			})

			this.parallaxes = []
		}

		if (store.mq.horizontal.matches && !this.parallaxes.length) {
			this.addParallax()
		}

		this.riveInstances.forEach((r, canvas) => {
			this.resizeCanvas(canvas, r)
		})

		this.addHeaderColorChange()
	}
}
