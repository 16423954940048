import { Component } from '_components/unseen'

export default class btn extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-btn'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.isAnimating = false
		this.tl = this.gsap.timeline({
			paused: true,
			onComplete: () => {
				this.isAnimating = false
			}
		})

		this.populateDom()

		if (this.el.dataset.btn === 'next') {
			this.tl
				.to(this.dom.chevron, {
					duration: 0.3,
					xPercent: 45,
					opacity: 0,
					ease: 'joe.in'
				})
				.set(this.dom.chevron, { xPercent: -45 })
				.to(this.dom.chevron, {
					duration: 0.3,
					xPercent: 0,
					opacity: 1,
					ease: 'joe.out'
				})

			this.on('click', this.el, this.playNext)
		}

		if (this.el.dataset.btn === 'prev') {
			this.tl
				.to(this.dom.chevron, {
					duration: 0.3,
					xPercent: -45,
					opacity: 0,
					ease: 'joe.in'
				})
				.set(this.dom.chevron, { xPercent: 45 })
				.to(this.dom.chevron, {
					duration: 0.3,
					xPercent: 0,
					opacity: 1,
					ease: 'joe.out'
				})

			this.on('click', this.el, this.playNext)
		}
	}

	playNext = () => {
		if (this.isAnimating) return

		this.isAnimating = true

		this.tl.play(0)
	}
}
