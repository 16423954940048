import { Component } from '_components/unseen'
import store from '_store'

import gsap from 'gsap'

export default class Loader extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-loader'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		if (store.urlParams.has('gui')) {
			this.el.classList.add('visibility-hidden')
			return
		}

		this.populateDom()

		this.lineLength = this.dom.line.getTotalLength()
		this.lerpFactor = 0.01
		this.targetProgress = 0
		this.currentProgress = 0
		this.promises = []
		this.assetCompletePromiseResolver = null
		this.minimumLineProgressResolver = null

		this.dom.line.style.strokeDasharray = `${this.lineLength}px`
		this.dom.line.style.strokeDashoffset = `${this.lineLength}px`

		// update target progress based on asset loading
		this.on('AssetsProgress', this.onAssetsProgress)

		// when onEnterCompleted is called, lets speed up the lerp
		this.on('onEnterCompleted', () => {
			this.lerpFactor = 0.03
		})

		// animation end promise
		if (this.dom.sprite) {
			this.promises.push(new Promise((resolve) => {
				this.on('animationend', this.dom.sprite, () => {
					resolve()
				})
			}))
		}

		// add a promise for when the assets are loaded
		this.promises.push(new Promise((resolve) => {
			this.assetCompletePromiseResolver = resolve
		}))

		// add a promise so we at least playa  little bit of the line
		this.promises.push(new Promise((resolve) => {
			this.minimumLineProgressResolver = resolve
		}))

		// when all promises are resolved, run a function
		Promise.all(this.promises).then(() => {
			this.showAgeConsent()
		})

		// add the draw call
		store.RAFCollection.add(this.update)
	}

	onAssetsProgress = ({ percent }) => {
		this.targetProgress = percent

		if (this.targetProgress === 100) {
			this.assetCompletePromiseResolver()
		}
	}

	update = () => {
		this.currentProgress = gsap.utils.interpolate(this.currentProgress, this.targetProgress, this.lerpFactor)
		this.dom.line.style.strokeDashoffset = `${this.lineLength - (this.currentProgress * (this.lineLength / 100))}px`

		if (this.currentProgress > 90) {
			this.minimumLineProgressResolver()
		}
	}

	showAgeConsent() {
		this.gsap.to([this.dom.svgArray, this.dom.container], {
			autoAlpha: 0,
			duration: 0.5
		})

		const cdmFix = store.body.classList.contains('clos-du-marquis') ? .7 : .4

		this.gsap.timeline()
			.set(this.dom.age, {
				autoAlpha: 1
			}, 0)
			.from(this.dom.subtitle, {
				autoAlpha: 0,
				yPercent: 80,
				duration: .8,
				ease: 'joe.out'
			}, cdmFix)
			.title(this.dom.title, {}, 0.4)
			.call(() => {
				this.dom.consent.classList.add('is-visible')
				store.RAFCollection.remove(this.update)
			}, [], 0.8)

		this.on('click', this.dom.consent, () => {
			// this event is emitted so intro animations can delay playing until the user dismisses the age consent
			this.emit('onUserConsented')

			this.gsap.from('.js-header', {
				autoAlpha: 0,
				y: -30,
				duration: 0.8,
				ease: 'joe.out',
				clearProps: 'transform'
			})

			this.gsap.to(this.el, {
				autoAlpha: 0,
				duration: .6
			})
		})
	}
}
