import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color } from 'three'

export default class TerrainMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				uNormalSampleStep: { value: 0.007, gui: { min: 0, max: 0.2, step: 0.0001 } },
				uNormalMapStrength: { value: 0.05, gui: { min: 0, max: 1, step: 0.001 } },
				uNormalMapRepeat: { value: 15, gui: { min: 1, max: 100, step: 0.1 } },
				uLowerNormalMapStrength: { value: 0.05, gui: { min: 0, max: 1, step: 0.001 } },
				uLowerNormalMapRepeat: { value: 15, gui: { min: 1, max: 100, step: 0.1 } },
				uNoiseSeed: { value: 0, gui: { min: 0, max: 100, step: 0.01 } },
				uHeight: { value: 0.05, gui: { min: 0, max: 0.5, step: 0.001 } },
				uColor: { value: new Color(0x494949) },
				uAmbientColor: { value: new Color(0x000000) },
				uEmissiveColor: { value: new Color(0x000000) },
				uSpecularColor: { value: new Color(0xFFFFFF) },
				uSpecularStrength: { value: 0.22, gui: { min: 0, max: 1, step: 0.01 } },
				uSpecularShininess: { value: 27.2, gui: { min: 0.001, max: 100, step: 0.1 } },
				uPrimaryLineColor: { value: new Color(0x000000) },
				uSecondaryLineColor: { value: new Color(0x000000) },
				uPrimaryLineOffset: { value: 0, gui: { min: -10, max: 10, step: 0.01 } },
				uPrimaryLineScale: { value: 0, gui: { min: 0, max: 500, step: 0.1 } },
				uSecondaryLineOffset: { value: 0, gui: { min: -10, max: 10, step: 0.01 } },
				uSecondaryLineScale: { value: 0, gui: { min: 0, max: 500, step: 0.1 } }
			},
			defines: {
				USE_FOG: true,
				SOLID_FOG: true
			}
		}, options)

		super(options)
	}
}