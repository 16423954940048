import { mergeDeep } from "_utils/index"
import ParticleSplineManager from "./unseen/ParticleSplineManager"
import { Color, LinearSRGBColorSpace, RepeatWrapping, Vector2, Vector3 } from "three"
import store from "_store"
import VineyardParticlesMaterial from "_webgl/materials/vineyardParticlesMaterial/VineyardParticlesMaterial"
import positionSimulationFrag from "_webgl/materials/vineyardParticlesMaterial/positionSimulationFrag.glsl"
import VineyardParticleSpline from "./VineyardParticleSpline"

export default class VineyardParticles extends ParticleSplineManager {
	constructor(options = {}) {
		options = mergeDeep({
			positionSimulationFrag,
			ParticleMaterial: VineyardParticlesMaterial,
			name: 'Vines',
			splineGroupTextureWidth: store.isLowTierGPU ? 128 : 256, // Used for the number of particles in each spline group
			maxParticleCount: store.isLowTierGPU ? 20000 : 65000, // maximum particle count for each spline group
			useMotionBlur: false,
			globalMaterialUniforms: {
				uBaseColor1: { value: new Color(0xa5886e) },
				uBaseColor2: { value: new Color(0x694526) },
				uBaseColor3: { value: new Color(0x735135) },
				uAmbient: { value: new Color(0x282828) },
				uParticleSize: { value: 0.23 },
				uParticleSizeRange: { value: new Vector2(0.1, 0.4), gui: { step: 0.001, min: 0 } },

				uShininess: { value: 1.0 },
				uSpecularStrength: { value: 1.0 }
			},
			globalSimulationUniforms: {
				uFluidEnabled: { value: true },
				uFluidMaskEnabled: { value: true },
				uFluidStrength: { value: 20 },
				uFluidLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uCurl: { value: true },
				uCurlSize: { value: 0.69, gui: { min: 0.0, max: 1, step: 0.001 } },
				uCurlStrength: { value: 0.21, gui: { min: 0.0, max: 1.0, step: 0.01 } },
				uCurlNoiseSpeed: { value: 0.2 },
				uSplineThickness: { value: 0.8 },
				uLerpSpeed: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uDecay: { value: 0.014, gui: { min: 0.000, max: 1.0, step: 0.001 } },
				uShapeThreshold: { value: 0.36, gui: { min: 0.000, max: 1.0, step: 0.001 } },

				uSkewThickness: { value: new Vector3(.15, 1., 0.65) },
				uRandomThicknessStrength: { value: 0, gui: { min: 0, step: 0.01 } },
				uRandomThicknessMax: { value: 0.05, gui: { min: 0, step: 0.01 } },
				uRandomThicknessFrequency: { value: 2, gui: { min: 0, step: 0.01 } },
				uRandomThicknessThreshold: { value: 0.65, gui: { min: 0, max: 1, step: 0.01 } }
			}
		}, options)

		super(options)

		this.load()
	}

	createSplines() {
		this.numOfGroups = this.options.numOfGroups > 0 ? this.options.numOfGroups : this.getNumberOfDistinctSplines(this.options.data, this.options.key)

		if (store.urlParams.has('outroDebug')) {
			this.numOfGroups = 1
		}

		this.options.globalMaterialUniforms.uShapeThreshold = this.options.globalSimulationUniforms.uShapeThreshold
		this.options.globalMaterialUniforms.uSplineThickness = this.options.globalSimulationUniforms.uSplineThickness
		this.options.globalMaterialUniforms.uSkewThickness = this.options.globalSimulationUniforms.uSkewThickness
		this.options.globalMaterialUniforms.uRandomThicknessMax = this.options.globalSimulationUniforms.uRandomThicknessMax
		this.options.globalMaterialUniforms.uRandomThicknessStrength = this.options.globalSimulationUniforms.uRandomThicknessStrength
		this.options.globalMaterialUniforms.uRandomThicknessFrequency = this.options.globalSimulationUniforms.uRandomThicknessFrequency
		this.options.globalMaterialUniforms.uRandomThicknessThreshold = this.options.globalSimulationUniforms.uRandomThicknessThreshold

		for (let i = 0; i <= this.numOfGroups - 1; i++) {
			const spline = new VineyardParticleSpline({
				reversed: i === 1,
				data: this.getFilteredSplines(this.options.data, `${this.options.key}-${i}`),
				name: `${this.options.key}-${i}`,
				scene: this.options.scene,
				textureWidth: this.options.splineGroupTextureWidth,
				maxParticleCount: this.options.maxParticleCount,
				globalMaterialUniforms: this.options.globalMaterialUniforms,
				globalSimulationUniforms: this.options.globalSimulationUniforms,
				splineGroupIndex: i
			})

			this.splines[`${this.options.key}-${i}`] = spline
		}
	}

	/**
	 * Count how many different spline groups in the data if a specific number is not specified (Distinct for this specific dataset)
	 */
	getNumberOfDistinctSplines(data, name) {
		// Filter
		const keys = Object.keys(data).filter(key => key.startsWith(name)) // Get all appropriate splines based on the input key

		const newArray = keys.map(string => {
			let trimmedKey = string.substring(name.length + 1, string.length) // get rid of the key at the beginning
			trimmedKey = Number(trimmedKey.substring(0, trimmedKey.length - 4)) // get rid of the last -001
			return trimmedKey
		})

		const unique = [...new Set(newArray)] // extract unique values

		return unique.length
	}

	/**
	 * Add extra things like global textures etc. before building the splines
	 */
	preBuild() {
		Object.assign(this.options.globalMaterialUniforms, {
			tNormal: { value: this.assets.textures.tNormal }
		})
	}

	load() {
		this.assets = {
			models: {},
			textures: {}
		}

		const basePath = store.publicUrl + 'webgl'

		store.AssetLoader.loadTexture(`${basePath}/textures/normalMapSphere.jpg`, { flipY: false, wrapping: RepeatWrapping, colorSpace: LinearSRGBColorSpace }).then(texture => {
			this.assets.textures.tNormal = texture
		})
	}
}