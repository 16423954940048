#include <default_pars>
#include <packing>

attribute vec3 aRandom;
attribute float aScale;
attribute vec2 reference;

varying vec2 vUv;
varying float vLife;

uniform sampler2D tPosition;

uniform float uTime;
uniform float uParticleSize;

varying vec3 vViewPosition;
varying vec3 vNormal;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vRandom;

// Rotates a vector based on the velocity of the particle
vec3 rotate3D(vec3 v, vec3 vel) {
    vec3 newpos = v;
    vec3 up = vec3(0, 1, 0);
    vec3 axis = normalize(cross(up, vel));
    float angle = acos(dot(up, normalize(vel)));
    newpos = newpos * cos(angle) + cross(axis, newpos) * sin(angle) + axis * dot(axis, newpos) * (1. - cos(angle));
    return newpos;
}

mat4 rotation3d(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;

  return mat4(
    oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
    0.0,                                0.0,                                0.0,                                1.0
  );
}

void main() {

    vUv = uv;
    vRandom = aRandom;

    vNormalMatrix = normalMatrix;

    vec4 particlePosition = texture2D( tPosition, reference );

    float life = particlePosition.w;
    vLife = life;

    vec4 transformedPosition = vec4( position, 1.0 );

    // billboard towards camera
    vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
    vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
    transformedPosition.xyz = right * transformedPosition.x + up * transformedPosition.y;

    #ifdef USE_INSTANCING
        transformedPosition = instanceMatrix * transformedPosition;
    #endif

    float scale = uParticleSize * aScale;
    scale *= mix(1.0, 0.1, smoothstep(0.92, 1.0, life)); // Decrease at the beginning of life between life 1.0 and 0.88
    scale *= mix(0.1, 1.0, smoothstep(0.0, 0.60, life)); // Decrease in the last 20% of life

    transformedPosition.xyz *= scale;

    transformedPosition.xyz += particlePosition.xyz;

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    mat3 normalMatrixInverse = inverse(mat3(normalMatrix));
    vec3 transformedNormal = normal;

    #ifdef USE_INSTANCING
        mat3 m = mat3( instanceMatrix );

        transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormal = m * transformedNormal;
    #endif

    transformedNormal = normalMatrix * normal;

    vNormal = normalize(transformedNormal);

    vNormalMatrixInverse = normalMatrixInverse;
    vNormalMatrix = mat3(normalMatrix);

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;

    vViewPosition = - mvPosition.xyz;

}