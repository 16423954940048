import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color, Texture, Vector2, UniformsLib, FrontSide } from 'three'

export default class SpinningParticlesMaterial extends BaseMaterial {
	constructor(options = {}, scene) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tPosition: { value: null },
				tPreviousPosition: { value: null },
				tNormal: { value: new Texture() },
				uBaseColor1: { value: new Color(0x9b6d47) },
				uBaseColor2: { value: new Color(0x9b6d47) },
				uBaseColor3: { value: new Color(0x9b6d47) },
				uAmbient: { value: new Color(0x000000) },
				uDecayColor: { value: new Color(0xf3f7ff) },
				uParticleSize: { value: 1.0, gui: { step: 0.01 } },
				uParticleSizeRange: { value: new Vector2(0.5, 1.5), gui: { tooltip: 'Min and Max values for particle size.' } },
				uDebugShadow: { value: false },
				uUseAmbient: { value: false },
				uLightingRange: { value: new Vector2(0.00, 1.0), gui: { tooltip: 'Min and Max values for lighting. Only when no Ambient Light is applied.' } },
				uShininess: { value: 25.0 },
				uSpecularStrength: { value: 1.5 },
				...UniformsLib.lights,
				...scene.globalUniforms.shadow,
				...scene.globalUniforms.sun,
				...scene.globalUniforms.fog
			},
			defines: {
				DYNAMIC_SHADOWS: true,
				DEPTH_PACKING: false,
				MOTION_BLUR: false,
				USE_FOG: true,
				SOLID_FOG: false,
				GRID_SIZE: 14
			},
			lights: true,
			side: FrontSide,
			transparent: true
		}, options)

		super(options)
	}
}