import store from "_store"

const objectDataImport = import.meta.glob('/public/webgl/*.unseen', { as: 'raw', eager: true })

export default function parseWebGLScenes() {
	if (store.urlParams.has('emptyScene')) return

	store.objectDatas = {}
	store.scenes = []

	// load specific scene from ?scene= url or first one found in folder
	for (const path in objectDataImport) {
		const sceneName = path.replace(/^.*[\\/]/, '').replace('.unseen', '')
		store.scenes.push(sceneName)
		store.objectDatas[sceneName] = objectDataImport[path]
	}

	store.sceneName = store.scenes[0]

	// if (store.urlParams.has('scene')) {
	// 	store.sceneName = store.urlParams.get('scene')
	// 	if (store.scenes.find(val => val === store.sceneName) === undefined) {
	// 		alert('No scene found called: ' + store.sceneName)
	// 		window.location.href = window.location.origin + '/pages/webgl/webgl.html' // Change the /pages/webgl/ bit for real projects
	// 	}
	// }
}