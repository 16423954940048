/**
 * Represents a custom scrollbar.
 */
import store from '_store'
import { E, GlobalEvents } from '_utils'

export default class Scrollbar {
	/**
	 * Creates an instance of Scrollbar.
	 * @param {import("@studio-freight/lenis").default} controller - The Lenis instance.
	 */
	constructor(controller) {
		this.controller = controller

		this.options = {
			wrapperClass: '.unseen-scrollbar'
		}

		this.position = 0
		this.mousePos = 0
		this.prevMousePos = 0

		this.buildHTML()
		this.addEvents()
		this.onResize()
	}

	/**
	 * Transforms the scrollbar handle based on the scroll position.
	 */
	transform = () => {
		if (!this.mouseDown) {
			this.position = this.controller.scroll / this.controller.limit * (store.window.h - this.handleElHeight)
		}
		this.handleEl.style.transform = `translate(0px, ${this.position}px)`
	}

	/**
	 * Shows the scrollbar.
	 */
	show() {
		this.wrapperEl.classList.add('show')
	}

	/**
	 * Hides the scrollbar.
	 */
	hide() {
		this.wrapperEl.classList.remove('show')
	}

	/**
	 * Adds event listeners.
	 */
	addEvents() {
		this.controller.on('scroll', this.onScroll)
		E.on('mousedown', this.handleEl, this.onMouseDown)
		E.on('mousemove', window, this.onMouseMove)
		E.on('mouseup', window, this.onMouseUp)
		E.on(GlobalEvents.RESIZE, this.onResize)
		E.on('NAVIGATE_END', this.onResize)
	}

	/**
	 * Handles the scroll event.
	 */
	onScroll = () => {
		this.transform()
		Math.abs(this.controller.velocity) > 0 ? this.show() : this.hide()
	}

	/**
	 * Handles the resize event.
	 */
	onResize = () => {
		this.scale = (this.controller.limit + store.window.h) / store.window.h

		if (this.scale <= 1) {
			this.handleEl.style.height = '0'
			return
		}

		this.trueSize = store.window.h / this.scale
		this.handleElHeight = Math.max(this.trueSize, 40)
		this.handleEl.style.height = `${this.handleElHeight}px`
		this.maxY = store.window.h - this.handleElHeight
	}

	/**
	 * Handles the mousemove event.
	 * @param {MouseEvent} e - The mousemove event object.
	 */
	onMouseMove = (e) => {
		if (!this.mouseDown) return

		this.mousePos = e.clientY
		this.position -= this.prevMousePos - this.mousePos
		this.position = Math.min(Math.max(this.position, 0), this.maxY)
		this.prevMousePos = this.mousePos

		this.controller.scrollTo(this.position / this.maxY * this.controller.limit, { immediate: true })
	}

	/**
	 * Handles the mousedown event.
	 * @param {MouseEvent} e - The mousedown event object.
	 */
	onMouseDown = (e) => {
		this.mousePos = this.prevMousePos = e.clientY
		this.mouseDown = true
		store.body.style.userSelect = 'none'
		this.wrapperEl.classList.add('active')
	}

	/**
	 * Handles the mouseup event.
	 */
	onMouseUp = () => {
		this.mouseDown = false
		store.body.style.removeProperty('user-select')
		this.wrapperEl.classList.remove('active')
	}

	/**
	 * Builds the HTML structure for the scrollbar.
	 */
	buildHTML() {
		this.wrapperEl = document.createElement('div')
		this.wrapperEl.classList.add(this.options.wrapperClass.substring(1))

		this.handleEl = document.createElement('div')
		this.handleEl.innerHTML = `<div></div>`
		this.wrapperEl.appendChild(this.handleEl)

		document.body.appendChild(this.wrapperEl)
	}

	/**
	 * Destroys the scrollbar and removes event listeners.
	 */
	destroy() {
		this.controller.off('scroll', this.onScroll)
		E.off('mousedown', this.handleEl, this.onMouseDown)
		E.off('mousemove', window, this.onMouseMove)
		E.off('mouseup', window, this.onMouseUp)
		E.off(GlobalEvents.RESIZE, this.onResize)
		E.off('NAVIGATE_END', this.onResize)
		document.body.removeChild(this.wrapperEl)
	}
}
