import store from '_store'
import { mergeDeep } from '_utils/index'
import { Group } from 'three'

/**
 * Creates a new WebGL Component class.
 * @function createComponent
 * @param {Group} [parentClass=Group] - The parent class to extend from.
 * @returns {Component} The component class.
 */
export default function createComponent(parentClass = Group) {
	/**
	 * @class Component
	 * @extends parentClass
	 * @description The base class for all WebGL components.
	 */
	class Component extends parentClass {
		/**
		 * Creates a new instance of the Component class.
		 * @constructor
		 * @param {Object} options - The options for the component.
		 * @param {...*} args - Additional arguments for the parent class constructor.
		 */
		constructor(options = {}, ...args) {
			super(...args)

			// default options
			this.options = mergeDeep({
				name: this.constructor.name
			}, options)

			this.name = this.options.name
			this.enabled = false

			this.assets = {
				models: {},
				textures: {}
			}

			this.load()
		}

		/**
		 * Builds the component using the provided object data.
		 * @param {Object} objectData - The Blender data used to build the component.
		 */
		build(objectData) {}

		/**
		 * Adds event listeners for the component.
		 */
		addEvents() {}

		/**
		 * Removes event listeners from the component.
		 */
		removeEvents() {}

		/**
		 * Destroys the component.
		 */
		destroy() {
			this.stop()
		}

		/**
		 * Starts the component.
		 */
		start() {
			if (this.enabled) return

			this.enabled = true
			this.addEvents()
		}

		/**
		 * Stops the component.
		 */
		stop() {
			if (!this.enabled) return

			this.enabled = false
			this.removeEvents()
		}

		animate() {}

		/**
		 * Loads any required assets for the component.
		 */
		load() {}

		/**
		 * Adds the component to the GUI.
		 * @param {Object} options - The options for the `autoAddObject()` function.
		 */
		addGui(options = {}) {
			return store.theatre.helper.autoAddObject(this, this.parent.prettyName, options)
		}
	}

	return Component
}