#include <default_pars>
#include <default_frag_pars>

varying vec2 vUv;
varying float vSize;
varying float vPathOffset;
varying float vSpeed;

uniform float uTime;
uniform vec3 uColor1;
uniform vec3 uColor2;

void main() {
    vec3 color = mix(uColor1, uColor2, step(0.8, vSpeed));

    // darken the color on particles that are further away from the path
    color *= map(abs(vPathOffset), 0., 0.1, 0.1, 1.5);

    // color *= sin(uTime * 0.2 + vSize * 23.972) + 0.5;

    gl_FragColor = vec4(color, 1.);

    #include <colorspace_fragment>
}