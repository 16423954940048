varying vec2 vUv;

uniform sampler2D uBaseTexture;
uniform sampler2D uTexture;
uniform float uBrightness;
uniform float uFadeStrength;
uniform bool uReset;

void main() {
	vec4 currentFrame = texture2D(uBaseTexture, vUv);
	vec4 previousFrame = texture2D(uTexture, vUv);
	vec4 color = mix(currentFrame * uBrightness, previousFrame, 1. - uFadeStrength);

	if (uReset) {
		color = vec4(0., 0., 0., 0.);
	}

	gl_FragColor = color;

	#include <colorspace_fragment>
}