import { E, GlobalEvents } from '_utils'

/**
 * Stuff
 */
export default class RAFCollection {
	constructor() {
		this.callbacks = []
		E.on(GlobalEvents.RAF, this.fire)
	}

	/**
	 * Add a callback to the collection at a given index.
	 * @param {function} cb
	 * @param {number} index
	 */
	add(cb, index = 0) {
		for (let i = 0; i < this.callbacks.length; i++) {
			if (this.callbacks[i].cb === cb) {
				console.warn('Duplicate RAFCollection callback detected')
			}
		}

		this.callbacks.push({ index: index || 0, cb })
		this.callbacks.sort(this.sort)
	}

	/**
	 * Remove a callback from the collection.
	 * @param {function} cb
	 */
	remove(cb) {
		for (let i = 0; i < this.callbacks.length; i++) {
			if (this.callbacks[i].cb === cb) {
				this.callbacks.splice(i, 1)
			}
		}
	}

	/**
	 * @private
	 * @param {any} a
	 * @param {any} b
	 * @returns {number}
	 */
	sort(a, b) {
		return a.index > b.index ? 1 : -1
	}

	/**
	 * @private
	 * @param {number} time
	 */
	fire = (time) => {
		for (let i = 0; i < this.callbacks.length; i++) {
			this.callbacks[i].cb(time)
		}
	}
}
