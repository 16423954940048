import { Component } from '_components/unseen'
import store from '_store'
import { E } from '_utils/index'
import ChapterSelector from './ChapterSelector'

export default class ChapterTitleCard extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-chapter-title-card'

	chapterSelectorHidden = true

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.chapterId = this.el.dataset.chapter
		this.visible = false

		this.on('chapterTitle:show', this.show)
		this.on('chapterTitle:hide', this.hide)
	}

	show = (chapterId) => {
		if (chapterId !== this.chapterId || store.urlParams.has('gui')) return

		if (this.chapterId === 'chapter-one' && this.chapterSelectorHidden) {
			this.gsap.to(['.js-chapter-selector', '.js-chapter-info-toggle'], {
				autoAlpha: 1,
				duration: 0.6,
				delay: 0.6
			})

			this.chapterSelectorHidden = false
			store.components.get(ChapterSelector).first().updateProgress = true
		} else {
			E.emit('ChapterSelector:EnterChapter', chapterId)
			// store.components.get(ChapterSelector).first().updateProgress = true
		}

		this.visible = true

		this.introTL = this.gsap.timeline()

		this.introTL
			.set(this.dom.bg, { opacity: 1 })
			.to(this.el, { autoAlpha: 1, duration: 1 })
			.call(() => { E.emit('chapterTitle:visible', chapterId) })
			.title(this.dom.title, { duration: 3, ease: 'joe.inOut' }, 0.3)
			.from([this.dom.subtitle, this.dom.instructions], { opacity: 0, duration: 1 }, 1)
	}

	hide = (chapterId) => {
		if (chapterId !== this.chapterId) return

		this.outroTL = this.gsap.timeline()
			.to(this.dom.bg, { opacity: 0.5, duration: 8, ease: 'linear' })
			.to(this.el, { autoAlpha: 0, duration: 4, ease: 'linear' }, 1)
			.call(() => {
				E.emit('ChapterSelector:showTitle')
			}, null, 5)
			.call(() => {
				E.emit('ChapterSelector:Show')
				store.components.get(ChapterSelector).first().isAnimating = false
			}, null, 6)
			.then(() => {
				this.visible = false
				E.emit('ChapterSelector:Show')
			})
	}
}
