#include <default_pars>

float test;

varying vec2 vUv;

uniform sampler2D uBaseTexture;
uniform sampler2D uTexture;

uniform sampler2D tSpline;
uniform sampler2D tAttributes;

uniform float uDecay;
uniform float uLerpSpeed;

uniform float uTime;
uniform float uDelta;
uniform float uNormalizedDelta;

uniform float uRadius;
uniform float uShapeThreshold;
uniform bool uCurl;
uniform float uCurlSize;
uniform float uCurlNoiseSpeed;
uniform float uCurlStrength;

uniform float uSetup;

uniform float uSplineThickness;
uniform vec3 uSkewThickness;

uniform float uRandomThicknessMax;
uniform float uRandomThicknessStrength;
uniform float uRandomThicknessFrequency;
uniform float uRandomThicknessThreshold;

#ifdef USE_FLUID
    uniform sampler2D tFluid;
    uniform sampler2D tFluidMask;
    uniform bool uFluidEnabled;
    uniform bool uFluidMaskEnabled;
    uniform float uFluidStrength;

    uniform float uFluidLerpSpeed;

    uniform mat4 uModelMatrix;
    uniform mat4 uViewMatrix;
    uniform mat4 uProjectionMatrix;
#endif

vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

float mod289(float x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 permute(vec4 x) {
    return mod289(((x*34.0)+1.0)*x);
}

float permute(float x) {
    return mod289(((x*34.0)+1.0)*x);
}

vec4 taylorInvSqrt(vec4 r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

float taylorInvSqrt(float r) {
    return 1.79284291400159 - 0.85373472095314 * r;
}

vec4 grad4(float j, vec4 ip) {
    const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
    vec4 p,s;

    p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
    p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
    s = vec4(lessThan(p, vec4(0.0)));
    p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;

    return p;
}

#define F4 0.309016994374947451

vec4 snoise4 (vec4 v) {
    const vec4  C = vec4( 0.138196601125011,0.276393202250021,0.414589803375032,-0.447213595499958);

    vec4 i  = floor(v + dot(v, vec4(F4)) );
    vec4 x0 = v -   i + dot(i, C.xxxx);

    vec4 i0;
    vec3 isX = step( x0.yzw, x0.xxx );
    vec3 isYZ = step( x0.zww, x0.yyz );
    i0.x = isX.x + isX.y + isX.z;
    i0.yzw = 1.0 - isX;
    i0.y += isYZ.x + isYZ.y;
    i0.zw += 1.0 - isYZ.xy;
    i0.z += isYZ.z;
    i0.w += 1.0 - isYZ.z;

    vec4 i3 = clamp( i0, 0.0, 1.0 );
    vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
    vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

    vec4 x1 = x0 - i1 + C.xxxx;
    vec4 x2 = x0 - i2 + C.yyyy;
    vec4 x3 = x0 - i3 + C.zzzz;
    vec4 x4 = x0 + C.wwww;

    i = mod289(i);
    float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
    vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));


    vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

    vec4 p0 = grad4(j0,   ip);
    vec4 p1 = grad4(j1.x, ip);
    vec4 p2 = grad4(j1.y, ip);
    vec4 p3 = grad4(j1.z, ip);
    vec4 p4 = grad4(j1.w, ip);

    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;
    p4 *= taylorInvSqrt(dot(p4,p4));

    vec3 values0 = vec3(dot(p0, x0), dot(p1, x1), dot(p2, x2)); //value of contributions from each corner at point
    vec2 values1 = vec2(dot(p3, x3), dot(p4, x4));

    vec3 m0 = max(0.5 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0); //(0.5 - x^2) where x is the distance
    vec2 m1 = max(0.5 - vec2(dot(x3,x3), dot(x4,x4)), 0.0);

    vec3 temp0 = -6.0 * m0 * m0 * values0;
    vec2 temp1 = -6.0 * m1 * m1 * values1;

    vec3 mmm0 = m0 * m0 * m0;
    vec2 mmm1 = m1 * m1 * m1;

    float dx = temp0[0] * x0.x + temp0[1] * x1.x + temp0[2] * x2.x + temp1[0] * x3.x + temp1[1] * x4.x + mmm0[0] * p0.x + mmm0[1] * p1.x + mmm0[2] * p2.x + mmm1[0] * p3.x + mmm1[1] * p4.x;
    float dy = temp0[0] * x0.y + temp0[1] * x1.y + temp0[2] * x2.y + temp1[0] * x3.y + temp1[1] * x4.y + mmm0[0] * p0.y + mmm0[1] * p1.y + mmm0[2] * p2.y + mmm1[0] * p3.y + mmm1[1] * p4.y;
    float dz = temp0[0] * x0.z + temp0[1] * x1.z + temp0[2] * x2.z + temp1[0] * x3.z + temp1[1] * x4.z + mmm0[0] * p0.z + mmm0[1] * p1.z + mmm0[2] * p2.z + mmm1[0] * p3.z + mmm1[1] * p4.z;
    float dw = temp0[0] * x0.w + temp0[1] * x1.w + temp0[2] * x2.w + temp1[0] * x3.w + temp1[1] * x4.w + mmm0[0] * p0.w + mmm0[1] * p1.w + mmm0[2] * p2.w + mmm1[0] * p3.w + mmm1[1] * p4.w;

    return vec4(dx, dy, dz, dw) * 49.0;
}

vec3 curl( in vec3 p, in float noiseTime, in float persistence ) {

    vec4 xNoisePotentialDerivatives = vec4(0.0);
    vec4 yNoisePotentialDerivatives = vec4(0.0);
    vec4 zNoisePotentialDerivatives = vec4(0.0);

    for (int i = 0; i < 3; ++i) {

        float twoPowI = pow(2.0, float(i));
        float scale = 0.5 * twoPowI * pow(persistence, float(i));

        xNoisePotentialDerivatives += snoise4(vec4(p * twoPowI, noiseTime)) * scale;
        yNoisePotentialDerivatives += snoise4(vec4((p + vec3(123.4, 129845.6, -1239.1)) * twoPowI, noiseTime)) * scale;
        zNoisePotentialDerivatives += snoise4(vec4((p + vec3(-9519.0, 9051.0, -123.0)) * twoPowI, noiseTime)) * scale;
    }

    return vec3(
        zNoisePotentialDerivatives[1] - yNoisePotentialDerivatives[2],
        xNoisePotentialDerivatives[2] - zNoisePotentialDerivatives[0],
        yNoisePotentialDerivatives[0] - xNoisePotentialDerivatives[1]
    );

}

float range(float oldValue, float oldMin, float oldMax, float newMin, float newMax) {
    float oldRange = oldMax - oldMin;
    float newRange = newMax - newMin;
    return (((oldValue - oldMin) * newRange) / oldRange) + newMin;
}

vec2 range(vec2 oldValue, vec2 oldMin, vec2 oldMax, vec2 newMin, vec2 newMax) {
    vec2 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    return v;
}

vec3 range(vec3 oldValue, vec3 oldMin, vec3 oldMax, vec3 newMin, vec3 newMax) {
    vec3 v;
    v.x = range(oldValue.x, oldMin.x, oldMax.x, newMin.x, newMax.x);
    v.y = range(oldValue.y, oldMin.y, oldMax.y, newMin.y, newMax.y);
    v.z = range(oldValue.z, oldMin.z, oldMax.z, newMin.z, newMax.z);
    return v;
}

float scnoise(vec3 v) {
    float t = v.z * 0.3;
    v.y *= 0.8;
    float noise = 0.0;
    float s = 0.5;
    noise += range(sin(v.x * 0.9 / s + t * 10.0) + sin(v.x * 2.4 / s + t * 15.0) + sin(v.x * -3.5 / s + t * 4.0) + sin(v.x * -2.5 / s + t * 7.1), -1.0, 1.0, -0.3, 0.3);
    noise += range(sin(v.y * -0.3 / s + t * 18.0) + sin(v.y * 1.6 / s + t * 18.0) + sin(v.y * 2.6 / s + t * 8.0) + sin(v.y * -2.6 / s + t * 4.5), -1.0, 1.0, -0.3, 0.3);
    return noise;
}

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 randomSphericalCoord(vec2 seed) {

    float r = (0.5 + rand(seed) * 0.5);
    float phi = (rand(seed + vec2(0.5, 0.15)) - 0.5) * PI;
    float theta = rand(seed + vec2(1.4, 1.3)) * PI * 2.;

    return vec3(
        r * sin(theta) * cos(phi),
        r * sin(theta) * sin(phi),
        r * cos(theta)
    );
}

vec3 getSplineThickness(vec2 uv, float random) {
    vec3 offsets = randomSphericalCoord(uv);

    float thicknessNoise = map(scnoise(vec3(uv, 0.0) * uRandomThicknessFrequency), -1.0, 1.0, 0., uRandomThicknessMax) * uRandomThicknessStrength;
    float radius = mix(uSplineThickness, uSplineThickness + thicknessNoise, step(uRandomThicknessThreshold, random)); // select about half of the particles and add some noise to their positions
    return offsets * radius * uSkewThickness;
}

void main() {

	vec2 uv = vUv;
    float randomVal = rand(vUv);

    vec4 currentData = texture2D(uTexture, vUv);
    vec3 currentPos = currentData.xyz;
    float life = currentData.a;

    vec4 originalPositionSample = texture2D(uBaseTexture, vUv);
    vec3 originalPosition = originalPositionSample.xyz;
    float originalLife = originalPositionSample.a;
    
    if(life < 0.0) { // Reset life and position
        life = 1.0;
        currentPos = originalPosition + getSplineThickness(uv, randomVal); // Add spline thickness to the original position
    }

    if (uSetup > 0.5) { // if we're setting up -> generate the original positions around the spline and don't add interaction or noise
        currentPos = originalPosition + getSplineThickness(uv, randomVal); // Add spline thickness to the original position
        gl_FragColor = vec4(currentPos, originalLife);
        return;
    }

    float noiseSelect = step(uShapeThreshold, randomVal); // add curl only on some of the particles

    #ifdef USE_FLUID
        vec4 worldPos = uModelMatrix * vec4(currentPos.xyz, 1.0);
        vec4 earlyProjection = uProjectionMatrix * uViewMatrix * worldPos;
        vec2 screenSpace = earlyProjection.xy / earlyProjection.w * 0.5 + vec2(0.5);

        vec3 fluidTarget = vec3(0.);
        vec3 fluidPosition = vec3(0.);

        if (uFluidEnabled) {
            fluidPosition = texture2D(tFluid, screenSpace).rgb;
            fluidPosition.z *= -1. + 1.;
            fluidPosition.xyz *= 0.1;

            if(uFluidMaskEnabled) fluidPosition *= clamp(texture2D(tFluidMask, screenSpace).r, 0.0, 1.0); // optional masking out the fluid where the mouse affects it // needs clamping ? todo ?
        }

        fluidTarget += fluidPosition.xyz * uFluidStrength * (noiseSelect + 0.1);
    #endif

    if (uCurl) {
        // float noiseSelect = smoothstep(0.9, 1.4, (originalPosition + getSplineThickness(uv, randomVal)).y); // add curl only on top of the vines and only on some of them
        vec3 curlNoise = curl(originalPosition * uCurlSize, uTime * uCurlNoiseSpeed, 0.1 + (1.0 - life) * 0.1) * uCurlStrength * (noiseSelect + 0.1);

        currentPos += curlNoise * uNormalizedDelta * uLerpSpeed;
        currentPos.y += 0.003 * uNormalizedDelta * noiseSelect;
    }

    #ifdef USE_FLUID
        currentPos += (fluidTarget) * uFluidLerpSpeed * uNormalizedDelta; // add fluid separately
    #endif

    life -= uDecay * uNormalizedDelta * (1. - noiseSelect + 0.8);

	gl_FragColor = vec4(currentPos, life);

}