import { Toggle } from "_components/unseen"
import { qs, qsa } from "_utils/index"
import gsap from 'gsap'

export default class TooltipToggle extends Toggle {
	static selector = '.js-tooltip-toggle'

	animating = false
	defaultToggle = true

	constructor(el) {
		super(el)

		this.closeBtn = qs('.js-close', this.target)

		this.text = qsa('p, h2', this.target)

		this.on('click', this.closeBtn, () => {
			this.close()
		})
	}

	onOpen(done) {
		if (this.animating === false) {
			this.animating = true

			gsap.set(this.target, {
				display: 'block'
			})

			gsap.fromTo(this.text, {
				opacity: 0,
				y: 20
			}, {
				opacity: 1,
				y: 0,
				stagger: this.defaultToggle ? .2 : 0,
				ease: 'power2.out',
				duration: 1
			})

			gsap.fromTo(this.target, {
				opacity: 0
			}, {
				opacity: 1,
				ease: 'power2.inOut',
				duration: .3,
				onComplete: () => {
					this.closeBtn.classList.add('is-visible')
					this.target.classList.add('is-open')
					this.animating = false
					done()
				}
			})
		}
	}

	onClose(done) {
		if (this.animating === false) {
			this.animating = true

			this.closeBtn.classList.remove('is-visible')
			this.target.classList.remove('is-open')

			gsap.to(this.text, {
				opacity: 0,
				y: 20,
				stagger: this.defaultToggle ? -.2 : 0,
				ease: 'sine.inOut',
				duration: 1
			})

			gsap.to(this.target, {
				opacity: 0,
				ease: 'power2.inOut',
				duration: .6,
				delay: .2,
				onComplete: () => {
					this.animating = false
					gsap.set(this.target, { display: 'none' })
					done()
				}
			})
		}
	}
}
