import { Renderer } from '@unseenco/taxi'
import store from "_store"
import { E, qs, SmoothScroll } from "_utils"
import ImageFull from "_components/horizontal/ImageFull"
import Parallax from "_components/Parallax"
import CentralText from "_components/horizontal/CentralText"
import Section from "_components/horizontal/Section"
import { Toggle } from '_components/unseen'
import Line from "_components/horizontal/Line"
import TooltipToggle from '_components/TooltipToggle'
import ChapterInfoToggle from '_components/ChapterInfoToggle'
import Btn from '_components/Btn'
import Subtitles from "_components/ChapterAudio"
import Cookies from '_components/Cookies'

export default class DefaultRenderer extends Renderer {
	initialLoad() {
		this.onEnter()

		store.AssetLoader.loaded.then(() => {
			this.onEnterCompleted()
		})
	}

	onEnter() {
		this.onBeforeAssetLoaderCheck()

		this.setupScroll()

		this.updateLangLinks()

		store.ScrollAnimations?.build(this.content)
		SmoothScroll.Lenis?.scrollTo(0, {
			force: true,
			immediate: true
		})

		// replace header classes
		const targetHeader = qs('.js-header', this.page)
		const header = qs('.js-header')

		if (targetHeader.classList.contains('dark')) {
			header.classList.add('dark')
		} else {
			header.classList.remove('dark')
		}

		// initialise the check to see if all asset promises have resolved
		store.AssetLoader.checkIfLoaded({ element: this.content }).then(() => {
			this.registerComponents()

			// Init components
			store.components.add(
				ImageFull,
				Parallax,
				CentralText,
				Section,
				Toggle,
				Cookies,
				Line,
				TooltipToggle,
				ChapterInfoToggle,
				Btn,
				Subtitles
			)
		})
	}

	registerComponents() {
		// Child components
		// store.components.add(Example)
	}

	onEnterCompleted() {
		E.emit('onEnterCompleted')

		store.ScrollAnimations?.enable()

		this.uponEnteringWithConsent(() => {
			E.emit('CookieBanner:AnimateIn')
		})

		this.scrollToAnchor()
		SmoothScroll?.unlock()
	}

	onLeave() {
		store.ScrollAnimations?.destroy()
		SmoothScroll?.lock()
		store.mq.md.removeEventListener('change', SmoothScroll.handleOrientationChange)
		store.isInitialLoad = false

		if (store.Audio?.isPlaying('sfx.bg')) {
			store.Audio.fadeAllToStop({ duration: 0.5 })
		}
	}

	onLeaveCompleted() {
		store.components.destroy()
	}

	scrollToAnchor() {
		if (window.location.hash) {
			const el = document.querySelector(window.location.hash)

			if (el) {
				SmoothScroll.scrollTo(el, { force: true })
			}
		}
	}

	setupScroll() {
		SmoothScroll.disableHorizontalScroll()
	}

	onBeforeAssetLoaderCheck() {
	}

	uponEnteringWithConsent(callback) {
		if (store.isInitialLoad && !store.urlParams.has('gui')) {
			E.on('onUserConsented', callback)
		} else {
			callback()
		}
	}

	updateLangLinks() {
		const links = qs('.js-lang-links')
		links.innerHTML = qs('.js-lang-links', this.page).innerHTML
	}
}
