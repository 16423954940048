#include <default_pars>
#include <default_frag_pars>

varying vec2 vUv;

uniform bool uEnabled;
uniform bool uChromaticAberration;
uniform bool uVignette;

uniform vec2 uResolution;

uniform bool uNoise;
uniform float uNoiseStrength;

uniform sampler2D tDiffuse; // lens flare texture
uniform sampler2D tOriginal; // original scene texture

// vignette
uniform float uVignetteStrength;
uniform float uVignetteInnerStrength;
uniform float uVignetteOuterStrength;
uniform vec3 uVignetteColor;

// chromatic aberration
uniform float uMaxDistort;
uniform float uBendAmount;
const int iterations = 5;

// lens flare
uniform bool uLensHalo;
uniform float uLensHaloOpacity;

uniform float uTime;

vec2 barrelDistortion(vec2 coord, float amt) {
	vec2 cc = coord - 0.5;
	float dist = dot(cc, cc);
	return coord + cc * dist * amt;
}

float sat(float t) {
	return clamp(t, 0.0, 1.0);
}

float linterp(float t) {
	return sat(1.0 - abs(2.0 * t - 1.0));
}

float remap(float t, float a, float b) {
	return sat((t - a) / (b - a));
}

vec4 spectrum_offset(float t) {
	vec4 ret;
	float lo = step(t, 0.5);
	float hi = 1.0 - lo;
	float w = linterp(remap(t, 1.0 / 6.0, 5.0 / 6.0));
	ret = vec4(lo, 1.0, hi, 1.) * vec4(1.0 - w, w, 1.0 - w, 1.);

	return pow(ret, vec4(1.0 / 2.2));
}

float random(vec2 p) {
	vec3 p3 = fract(vec3(p.xyx) * 443.8975);
	p3 += dot(p3, p3.yzx + 19.19);
	return fract((p3.x + p3.y) * p3.z);
}

void main() {
	if (uEnabled) {

		if (uLensHalo) {

			if (uChromaticAberration) {
				vec4 sumcol = vec4(0.0);
				vec4 sumw = vec4(0.0);
				float reci_num_iter_f = 1.0 / float(iterations);
				for (int i = 0; i < iterations; i++) {
					float t = float(i) * reci_num_iter_f;
					vec4 w = spectrum_offset(t);
					sumw += w;

					sumcol += w * texture2D(tOriginal, barrelDistortion(vUv, uBendAmount * uMaxDistort * t));
				}

				gl_FragColor = vec4(sumcol / sumw);

			} else {	
				gl_FragColor = texture2D(tOriginal, vUv);
			}

			vec4 lensHalo = texture2D(tDiffuse, vUv);
			gl_FragColor.rgb = blendScreen(gl_FragColor.rgb, lensHalo.rgb, uLensHaloOpacity);

		} else {

			if (uChromaticAberration) {
				vec4 sumcol = vec4(0.0);
				vec4 sumw = vec4(0.0);
				float reci_num_iter_f = 1.0 / float(iterations);
				for (int i = 0; i < iterations; i++) {
					float t = float(i) * reci_num_iter_f;
					vec4 w = spectrum_offset(t);
					sumw += w;

					sumcol += w * texture2D(tDiffuse, barrelDistortion(vUv, uBendAmount * uMaxDistort * t));
				}

				gl_FragColor = vec4(sumcol / sumw);

			} else {	
				gl_FragColor = texture2D(tDiffuse, vUv);
			}


		}

		if (uVignette) {
			vec2 coord = (vUv - 0.5) * (uResolution.x / uResolution.y) * 2.0;
			float rf = 1.0 + dot(coord, coord) * uVignetteInnerStrength * uVignetteInnerStrength;
			float vignette = 1.0 / (rf * rf);
			// vignette = pow(vignette, uVignetteStrength);

			vec2 uv2 = vUv;
			uv2 *= 1.0 - vUv.yx;
			float vig = uv2.x * uv2.y * 20.0;
			vignette *= pow(vig, uVignetteOuterStrength);
			vignette = clamp(vignette, 0.0, 1.0);
			
			gl_FragColor.rgb = mix(uVignetteColor, gl_FragColor.rgb, saturate(vignette + (1. - uVignetteStrength)));
		}

		if (uNoise) {
			gl_FragColor.rgb = blendSoftLight(gl_FragColor.rgb, vec3(random(vUv) - 0.5), uNoiseStrength);
		}

	} else {
		gl_FragColor = texture2D(tDiffuse, vUv);
	}

	#include <colorspace_fragment>
}