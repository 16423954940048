/**
 * @param {number} input
 * @returns {number}
 */
export default function closestPowerOf2(input) {
	const powers = [4, 16, 64, 256, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144, 1048576]
	const result = powers.reduce((prev, curr) => Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev)
	if (result < input) {
		return Math.sqrt(powers[powers.indexOf(result) + 1])
	}
	return Math.sqrt(result)
}
