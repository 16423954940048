import store from "_store"
import { GlobalEvents, E } from "_utils"

export default function populateVars() {
	store.html.style.setProperty('--dvh', window.innerHeight * 0.01 + 'px')
	store.html.style.setProperty('--svh', document.documentElement.clientHeight * 0.01 + 'px')

	// This will update as the UI hides, so lets not throttle it
	E.on('resize', window, () => {
		store.html.style.setProperty('--dvh', window.innerHeight * 0.01 + 'px')
	})

	// This will only really be applicable on orientation change, so it;s fine to throttle
	E.on(GlobalEvents.RESIZE, window, () => {
		store.html.style.setProperty('--svh', document.documentElement.clientHeight * 0.01 + 'px')
	})
}
