import { InstancedMesh, MeshPhysicalMaterial, Object3D, PlaneGeometry, Vector3 } from "three"
import createComponent from "./unseen/Component"
import { mergeDeep } from "_utils/index"
import copyObjectDataTransforms from "_utils/functions/copyObjectDataTransforms"
import store from "_store"
import { types } from "@theatre/core"

export default class OutroWall extends createComponent(InstancedMesh) {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Outro Wall'
		}, options)

		super(options,
			new PlaneGeometry(),
			new MeshPhysicalMaterial({
				color: 0xb89973,
				normalScale: new Vector3(1, -1), // flip on y
				roughness: 1.0
			}),
			options.objectData[0].length
		)

		this.material.onBeforeCompile = this.onBeforeCompile.bind(this)
	}

	onBeforeCompile(shader) {
		shader.defines.USE_FOG = true
		shader.defines.SOLID_FOG = false

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_pars_vertex>',
			`
            varying vec3 vWorldPosition;
            #include <fog_vert_pars>
            `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_vertex>',
			`
            vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            #include <fog_vert>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_pars_fragment>',
			`
			uniform vec2 uResolution;
            varying vec3 vWorldPosition;
            #include <fog_frag_pars>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_fragment>',
			`
            #include <fog_frag>
            `
		)
	}

	build() {
		this.geometry = this.parent.assets.models.wall.geometry

		this.material.normalMap = this.parent.assets.textures.wall.normal
		this.material.roughnessMap = this.parent.assets.textures.wall.roughness

		const instanceDummy = new Object3D()

		for (let i = 0; i < this.count; i++) {
			copyObjectDataTransforms(instanceDummy, this.options.objectData[0][i])

			instanceDummy.updateMatrix()
			this.setMatrixAt(i, instanceDummy.matrix)
		}

		this.castShadow = true
		this.receiveShadow = true
	}

	addGui() {
		const sheetObject = store.theatre.helper.autoAddObject(this, this.parent.prettyName, {
			additionalConfig: {
				color: store.theatre.helper.parseColor(this.material.color),
				roughness: types.number(this.material.roughness, { range: [0, 1], nudgeMultiplier: 0.01 }),
				specularIntensity: this.material.specularIntensity,
				specularColor: store.theatre.helper.parseColor(this.material.specularColor)
			}
		})

		sheetObject.onValuesChange(values => {
			this.material.color.copy(values.color)
			this.material.roughness = values.roughness
			this.material.specularIntensity = values.specularIntensity
			this.material.specularColor.copy(values.specularColor)
		})
	}

	destroy() {
		super.destroy()
		// console.log('Destroying OutroWall')
		this.geometry.dispose()
		this.material.dispose()

		this.parent.remove(this)
	}
}