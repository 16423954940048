#include <fog_frag_pars>

varying vec2 vUv;
varying vec4 vWorldPosition;

uniform sampler2D tDiffuse;
uniform float uAlpha;

void main() {
	vec4 color = texture2D(tDiffuse, vUv);

	// fade out colour around edges of UVs
	float edge = 0.1;
	float mask = smoothstep(0.0, edge, vUv.x) * smoothstep(1.0, 1.0 - edge, vUv.x) * smoothstep(0.0, edge, vUv.y) * smoothstep(1.0, 1.0 - edge, vUv.y);
	color = mix(vec4(0.0, 0.0, 0.0, 1.0), color, mask);
	
	color.a *= uAlpha;

	gl_FragColor = color;

	#include <fog_frag>

	#include <colorspace_fragment>
}