import Terrain from '_webgl/components/Terrain'
import BaseScene from './BaseScene'
import { Color, Mesh, MeshBasicMaterial, SphereGeometry, Vector3 } from 'three'
import store from '_store'
import { types } from '@theatre/core'
import AmbientParticles from '_webgl/components/AmbientParticles'
import { E } from '_utils/index'

export default class ChapterOneScene extends BaseScene {
	constructor() {
		super('chapter-one', {
			currentChapterTitleHideDelay: 2,
			audioFiles: ['chapter-1-1', 'chapter-1-2', 'chapter-1-3', 'chapter-1-4']
		})

		Object.assign(this.components, {
			terrain: new Terrain(),
			ambientParticles: new AmbientParticles()
		})

		this.sun = {
			position: new Vector3(100.20, 33.80, 2.30),
			target: new Vector3(0, 0, 0)
		}

		Object.assign(this.globalUniforms, {
			uSunDirection: { value: new Vector3() },
			uSunColor: { value: new Color(0xFFFFFF) },
			uEnableFog: { value: true },
			uDebugFog: { value: false },
			uSolidFogColor: { value: new Color(0x111111) },
			uFogNear_D: { value: 20, gui: { min: 0, step: 0.01 } },
			uFogFar_D: { value: 85, gui: { min: 0, step: 0.01 } },
			uFogStrength_D: { value: 1., gui: { min: 0, max: 1, step: 0.01 } },
			uFogNear_H: { value: -1, gui: { step: 0.01 } },
			uFogFar_H: { value: 1, gui: { step: 0.01 } },
			uFogStrength_H: { value: 0.0, gui: { min: 0, max: 1, step: 0.01 } },
			uSceneFogMix: { value: 1, gui: { min: 0, max: 1, step: 0.01 } }
		})

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.z, { nudgeMultiplier: 0.1 })
			}),
			color: store.theatre.helper.parseColor(this.globalUniforms.uSunColor.value)
		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.set(values.target.x, values.target.y, values.target.z)
			this.globalUniforms.uSunColor.value.copy(values.color)
		})

		this.background.set(0x111111)

		const backgroundTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Background', {
			color: store.theatre.helper.parseColor(this.background)
		})

		backgroundTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		}, store.theatre.rafDriver)
	}

	build() {
		super.build()

		this.sunMesh = new Mesh(new SphereGeometry(1), new MeshBasicMaterial({ color: 0xffffff }))
		this.sunMesh.visible = false
		this.add(this.sunMesh)

		// add global uniforms to theatre
		const config = {}
		const globalUniformCallbacks = store.theatre.helper.autoAddUniforms(config, this.globalUniforms, ['uSunDirection', 'uSunColor'], [], '')
		const sheetObject = store.theatre.helper.addSheetObject(this.prettyName, 'Global Settings', config)

		sheetObject.onValuesChange(values => {
			globalUniformCallbacks.forEach(callback => callback(null, values))
		}, store.theatre.rafDriver)
	}

	animate() {
		super.animate()

		this.sunMesh.position.copy(this.sun.position)

		// update global light direction
		this.globalUniforms.uSunDirection.value.copy(this.sun.position)
		this.globalUniforms.uSunDirection.value.sub(this.sun.target)
		this.globalUniforms.uSunDirection.value.transformDirection(this.activeCamera.matrixWorldInverse)
	}

	start() {
		super.start()
		E.on('FPSChecked', this.onFPSChecked)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			if (store.WebGL.lensFlareFX && store.WebGL.lensFlareFX.enabled) {
				store.WebGL.lensFlareFX.toggle(false)
				store.WebGL.compositePass.material.uniforms.uLensHalo.value = false
				this.postFxTheatreOverrides.lensFlare.enabled = false
			}
		}
	}

	stop() {
		super.stop()
		E.off('FPSChecked', this.onFPSChecked)
	}
}
