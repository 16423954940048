import Component from '_components/unseen/Component'
import SplitText from '_utils/gsap/SplitText'

export default class CentralText extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-central-text'
	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.gsap.mm((context) => {
			const { horizontal } = context.conditions

			if (horizontal && this.dom.text) {
				if (this.dom.btn) {
					this.dom.btn.classList.remove('is-visible')
				}
				this.dom.text.style.perspective = '200px'
				this.dom.text.style.perspectiveOrigin = 'center center'
				this.split = new SplitText(this.dom.text, { type: 'lines', lineThreshold: 0.3 })

				const tl = this.gsap.timeline({
					scrollTrigger: {
						trigger: this.dom.text,
						once: true,
						start: 'left right-=20%',
						horizontal: true
					}
				})

				tl.fromTo(this.split.lines, {
					rotateX: -15,
					xPercent: 25,
					z: -100,
					opacity: 0
				}, {
					rotateX: 0,
					xPercent: 0,
					z: 0,
					opacity: 1,
					stagger: 0.1,
					ease: 'joe.out',
					duration: 1.2
				})

				if (this.dom.btn) {
					tl.call(() => {
						this.dom.btn.classList.add('is-visible')
					}, [], .5)
				}
			} else {
				this.split?.revert()

				if (this.dom.btn) {
					this.dom.btn.classList.add('is-visible')
				}
			}
		})
	}
}
