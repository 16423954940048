/**
* Maps a variable from one spread of values to another.
*
* @param {Number} value - Input value
* @param {Number} min1 - Minimum value in the original interval of the input value
* @param {Number} max1 - Maximum value in the original interval of the input value
* @param {Number} min2 - Minimum value in the new interval
* @param {Number} max2 - Maximum value in the new interval
* @returns {Number} The value, changed to be in the desired interval
*/
export default function map(value, min1, max1, min2, max2) {
	return min2 + (value - min1) * (max2 - min2) / (max1 - min1)
}