import ContourLines from '_webgl/components/ContourLines'
import BaseScene from './BaseScene'
import AmbientParticles from '_webgl/components/AmbientParticles'
import { Color, Vector3 } from 'three'
import store from '_store'
import { E } from '_utils/index'

export default class ChapterTwoOneScene extends BaseScene {
	constructor() {
		super('chapter-two-one', {
			cameraZOffset: 5,
			currentChapterTitleHideDelay: 2,
			audioFiles: ['chapter-2-1', 'chapter-2-2', 'chapter-2-3']
		})

		Object.assign(this.components, {
			contourLines: new ContourLines(),
			ambientParticles: new AmbientParticles({
				bounds: new Vector3(3, 3, 3)
			})
		})

		Object.assign(this.globalUniforms, {
			uEnableFog: { value: true },
			uDebugFog: { value: false },
			uSolidFogColor: { value: new Color(0x000000) },
			uFogNear_D: { value: 20, gui: { min: 0, step: 0.01 } },
			uFogFar_D: { value: 85, gui: { min: 0, step: 0.01 } },
			uFogStrength_D: { value: 1., gui: { min: 0, max: 1, step: 0.01 } },
			uFogNear_H: { value: -1, gui: { step: 0.01 } },
			uFogFar_H: { value: 1, gui: { step: 0.01 } },
			uFogStrength_H: { value: 0.0, gui: { min: 0, max: 1, step: 0.01 } },
			uSceneFogMix: { value: 1, gui: { min: 0, max: 1, step: 0.01 } }
		})

		this.background.set(0x020202)

		const backgroundTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Background', {
			color: store.theatre.helper.parseColor(this.background)
		})

		backgroundTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		}, store.theatre.rafDriver)
	}

	build() {
		super.build()

		// add global uniforms to theatre
		const config = {}
		const globalUniformCallbacks = store.theatre.helper.autoAddUniforms(config, this.globalUniforms, [], [], '')
		const sheetObject = store.theatre.helper.addSheetObject(this.prettyName, 'Global Settings', config)

		sheetObject.onValuesChange(values => {
			globalUniformCallbacks.forEach(callback => callback(null, values))
		}, store.theatre.rafDriver)
	}

	start() {
		super.start()
		E.on('FPSChecked', this.onFPSChecked)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			if (store.WebGL.lensFlareFX && store.WebGL.lensFlareFX.enabled) {
				store.WebGL.lensFlareFX.toggle(false)
				store.WebGL.compositePass.material.uniforms.uLensHalo.value = false
				this.postFxTheatreOverrides.lensFlare.enabled = false
			}
		}
	}

	stop() {
		super.stop()
		E.off('FPSChecked', this.onFPSChecked)
	}
}
