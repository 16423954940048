#include <default_pars>
#include <default_frag_pars>

varying vec2 vUv;
varying float vRandom;
varying float vOpacity;

uniform float uTime;
uniform vec3 uColor;
uniform float uRadius;
uniform float uBlur;
uniform float uOpacity;

float circle(in vec2 _st, in float _radius, in float _blur){
    vec2 dist = _st-vec2(0.5);
	return 1.-smoothstep(_radius-(_radius*_blur),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*4.0);
}

void main() {
    vec2 uv = vUv;

    float circleMask = circle(uv, uRadius, uBlur);
    if (circleMask < 0.001) discard;

    float pulse = cos(uTime * 2. + vRandom * 10.) * 0.5 + 0.5;

    vec3 color = uColor;
    color += smoothstep(0., 1., pulse) * 0.1;
    color = clamp(color, 0., 1.);

    gl_FragColor = vec4(color, circleMask * uOpacity * vOpacity);

    #include <colorspace_fragment>
}