import { InstancedBufferAttribute, InstancedMesh, MathUtils, MeshBasicMaterial, Object3D, PlaneGeometry, Vector3 } from "three"
import createComponent from "./unseen/Component"
import AmbientParticlesMaterial from "_webgl/materials/ambientParticlesMaterial/AmbientParticlesMaterial"
import { mergeDeep } from "_utils/index"

export default class AmbientParticles extends createComponent(InstancedMesh) {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Ambient Particles',
			visible: false,
			count: 2000,
			bounds: new Vector3(150, 150, 150),
			materialOpts: {
				uniforms: {}
			}
		}, options)

		super(options,
			new PlaneGeometry(),
			new MeshBasicMaterial(),
			options.count
		)

		this.visible = this.options.visible

		this.frustumCulled = false
	}

	build() {
		this.options.scene = this.parent

		this.buildMaterial()

		const instanceDummy = new Object3D()
		const aPositions = []
		const aRandoms = []

		for (let i = 0; i < this.count; i++) {
			aPositions.push(
				MathUtils.randFloat(-this.options.bounds.x * 0.5, this.options.bounds.x * 0.5),
				MathUtils.randFloat(-this.options.bounds.y * 0.5, this.options.bounds.y * 0.5),
				MathUtils.randFloat(-this.options.bounds.z * 0.5, this.options.bounds.z * 0.5)
			)
			aRandoms.push(MathUtils.seededRandom(i), Math.random(), Math.random())
			instanceDummy.updateMatrix()
			this.setMatrixAt(i, instanceDummy.matrix)
		}

		this.geometry.setAttribute('aPosition', new InstancedBufferAttribute(new Float32Array(aPositions), 3))
		this.geometry.setAttribute('aRandom', new InstancedBufferAttribute(new Float32Array(aRandoms), 3))
	}

	buildMaterial() {
		this.material = new AmbientParticlesMaterial(this.options.materialOpts)
		this.material.uniforms.uBounds.value.copy(this.options.bounds)
	}

	destroy() {
		super.destroy()
		// console.log(`Destroying AmbientParticles in ${this.parent.name}`)
		this.material.dispose()
		this.geometry.dispose()
		this.parent.remove(this)
	}
}