import gsap from 'gsap'
import SplitText from '_utils/gsap/SplitText'
import SplitTextStore from "_utils/gsap/SplitTextStore"

export default {
	name: 'fadeUp',
	extendTimeline: true,
	onResize: () => {
		// Leave this empty as the splittexts are handled elsewhere, but this animation still needs to be rebuilt each resize
	},
	effect: (targets, config) => {
		const fromY = config.y
		const fromOpacity = config.opacity

		// set the config Y and opacity to the "to" values
		config.y = 0
		config.opacity = 1

		if (config.split) {
			const opts = typeof config.split === 'string' ? { type: config.split } : config.split
			const split = new SplitText(targets[0], opts)

			SplitTextStore.add(split)

			// let's revert lines based splits after completion
			if (split.lines.length) {
				config.onComplete = () => {
					split.revert()
				}
			}

			targets = [...split.lines, ...split.words, ...split.chars]
		}

		delete config.split

		return gsap.fromTo(targets, { y: fromY, opacity: fromOpacity }, config)
	},
	defaults: {
		y: 30,
		opacity: 0,
		ease: 'joe.out',
		duration: 0.6,
		stagger: false
	}
}
