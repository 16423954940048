import { LinearFilter, Mesh, MeshBasicMaterial, PlaneGeometry, Raycaster, RepeatWrapping, Vector2 } from 'three'
import createComponent from './unseen/Component'
import TerrainMaterial from '_webgl/materials/terrain/TerrainMaterial'
import store from '_store'
import FBOHelper from '_webgl/utils/FBOHelper'
import drawShader from '_webgl/materials/terrain/draw.glsl'
import { types } from '@theatre/core'

export default class Terrain extends createComponent(Mesh) {
	constructor() {
		super({
			name: 'Terrain'
		})

		this.scale.setScalar(200)
		this.rotation.x = -Math.PI / 2

		this.snapPointer = false
		this.prevSnapPointer = false
		this.disableDraw = false
		this.resetDraw = false
		this.prevResetDraw = false
		this.prevPointerPos = new Vector2()
		this.pointerPos = new Vector2()
		this.raycastPos = new Vector2()
	}

	build() {
		this.geometry = new PlaneGeometry(1, 1, 500, 500)
		this.material = new TerrainMaterial({
			uniforms: {
				tNormal: { value: this.assets.textures.normal },
				tPointer: { value: null }
			},
			globalUniforms: this.parent.globalUniforms
		})

		this._raycastPlane = new Mesh(new PlaneGeometry(), new MeshBasicMaterial({ visible: false }))
		this._raycastPlane.position.z = -0.02
		this.add(this._raycastPlane)

		this._raycaster = new Raycaster()

		this.pointerDrawer = new FBOHelper({
			fragmentShader: drawShader,
			uniforms: {
				uMouse: { value: new Vector2() },
				uReset: { value: false }
			},
			width: 512,
			height: 512,
			filter: LinearFilter,
			createTexture: false
		})

		// this.add(this.pointerDrawer.debugPlane)
		// this.pointerDrawer.debugPlane.position.y = 1
	}

	animate() {
		if (!this.disableDraw) {
			if (!store.pointer.glNormalized.equals(this.prevPointerPos)) {
				this.prevPointerPos.copy(store.pointer.glNormalized)
				this.pointerPos.copy(store.pointer.glNormalized)

				this.idle = false
				this.autoPointer = false
			} else if (!this.idle & !this.autoPointer) {
				this.idle = true

				clearTimeout(this._pointerTimeout)
				this._pointerTimeout = setTimeout(() => {
					this.autoPointer = true
				}, 2000)
			} else if (this.autoPointer) {
				// move the pointer around automatically if idle for a while
				this.pointerPos.x = (Math.sin(store.WebGL.clock.elapsedTime * 0.578)) * 0.65
				this.pointerPos.y = -Math.cos(store.WebGL.clock.elapsedTime * 0.523) * 0.65
			}

			this._raycaster.setFromCamera(this.pointerPos, this.parent.activeCamera)
			const intersects = this._raycaster.intersectObject(this._raycastPlane, false)

			if (intersects.length) {
				this.raycastPos.set(intersects[0].uv.x, intersects[0].uv.y)

				if (this.snapPointer) {
					this.snapPointer = false
					this.pointerDrawer.uniforms.uMouse.value.copy(this.raycastPos)
				} else {
					this.pointerDrawer.uniforms.uMouse.value.lerp(this.raycastPos, 0.1)
				}
			} else {
				this.pointerDrawer.uniforms.uMouse.value.set(-1, -1)
			}

			if (this.resetDraw) {
				this.resetDraw = false
				this.pointerDrawer.uniforms.uReset.value = true
			} else {
				this.pointerDrawer.uniforms.uReset.value = false
			}

			this.pointerDrawer.update()

			this.material.uniforms.tPointer.value = this.pointerDrawer.texture
		}
	}

	snapPointerTo(x, y) {
		this.pointerPos.set(x, y)
		this.prevPointerPos.set(x, y)
		this.autoPointer = false
	}

	load() {
		store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/terrain-normal-01.jpg`, { wrapping: RepeatWrapping }).then(texture => {
			this.assets.textures.normal = texture
		})
	}

	addGui() {
		const sheetObject = store.theatre.helper.autoAddObject(this, this.parent.prettyName, {
			exclude: ['uSunDirection'],
			additionalConfig: {
				snapPointer: types.boolean(false, { label: 'snap pointer (toggle to snap)' }),
				resetDraw: types.boolean(false, { label: 'reset draw (toggle to reset)' }),
				disableDraw: types.boolean(false, { label: 'disable draw' })
			}
		})

		sheetObject.onValuesChange(values => {
			if (values.snapPointer !== this.prevSnapPointer) {
				this.prevSnapPointer = values.snapPointer
				this.snapPointer = true
			}

			if (values.resetDraw !== this.prevResetDraw) {
				this.prevResetDraw = values.resetDraw
				this.resetDraw = true
			}

			this.disableDraw = values.disableDraw
		}, store.theatre.rafDriver)
	}

	destroy() {
		this.pointerDrawer.destroy()
		this.pointerDrawer = null

		this.material.dispose()
		this.geometry.dispose()
		this._raycastPlane.geometry.dispose()
		this._raycastPlane.material.dispose()
		this._raycastPlane = null
		this._raycaster = null
	}
}