varying vec2 vUv;

uniform sampler2D tDiffuse; // Rendered screen
// uniform vec2 uScreenSize;

uniform int uSampleCount;
uniform float uBlur;
uniform float uFallOff;

void main() {

    // float screenRes = uScreenSize.y / uScreenSize.x;
    // vec3 renderer = texture2D(tDiffuse, vUv).rgb;

    // vec2 uvs = vUv - 0.5; // Make origin in center of screen
    // uvs.x *= uScreenSize.x/uScreenSize.y; // Fix aspect ratio, so it's always circular

    // vec2 uvs =  vUv * (1.0 - vUv.yx); 
    // float vig = uvs.x * uvs.y * 15.0; // multiply with sth for intensity
    // vig = pow(vig, 0.25); // change pow for modifying the extend of the  vignette


    vec2 centerUV = vUv - 0.5;
    vec2 direction = normalize(centerUV); 
    vec2 velocity = direction * uBlur * pow( length(centerUV), uFallOff );
    float inverseSampleCount = 1.0 / float(uSampleCount); 

    /**
        matnxm: A matrix with n columns and m rows (examples: mat2x2, mat4x3). Note that this is backward from convention in mathematics!
        matn: Common shorthand for matnxn: a square matrix with n columns and n rows.
    */
    // mat3x2 increments = mat3x2(velocity * 1. * inverseSampleCount,
    //                            velocity * 2. * inverseSampleCount,
    //                            velocity * 4. * inverseSampleCount);
    // vec3 accumulator = vec3(0.);
    // mat3x2 offsets = mat3x2(0); 


    // Add a row cause we gotta for webgl1
    vec3 column0 = vec3(velocity * 1. * inverseSampleCount, 0.0);
    vec3 column1 = vec3(velocity * 2. * inverseSampleCount, 0.0);
    vec3 column2 = vec3(velocity * 4. * inverseSampleCount, 1.0);

    mat3 increments = mat3(column0, column1, column2);

    // mat3x2 increments = mat3x2(velocity * 1. * inverseSampleCount,
    //                            velocity * 2. * inverseSampleCount,
    //                            velocity * 4. * inverseSampleCount);
    vec3 accumulator = vec3(0.);
    mat3 offsets = mat3(0.); 


    // TODO IDK if this is correct
    // mat3 increments = mat3(vec3(velocity * 1. * inverseSampleCount, 0.0),
    //                            vec3(velocity * 2. * inverseSampleCount, 0.0),
    //                            vec3(velocity * 4. * inverseSampleCount, 0.0),
    //                            vec3(1.0));
    // vec3 accumulator = vec3(0.);
    // mat3 offsets = mat3(0); 

    // Loop workaround for webgl1
    for(int i = 0; i < 20; ++i) { 
        // your code here
        accumulator.r += texture2D(tDiffuse, vUv + offsets[0].xy).r; 
        accumulator.g += texture2D(tDiffuse, vUv + offsets[1].xy).g; 
        accumulator.b += texture2D(tDiffuse, vUv + offsets[2].xy).b; 
        
        offsets -= increments;

        if(i >= uSampleCount){
            break;
        }
    }
    
    // for (int i = 0; i < uSampleCount; i++) {
    //     accumulator.r += texture2D(tDiffuse, vUv + offsets[0].xy).r; 
    //     accumulator.g += texture2D(tDiffuse, vUv + offsets[1].xy).g; 
    //     accumulator.b += texture2D(tDiffuse, vUv + offsets[2].xy).b; 
        
    //     offsets -= increments;
    // }
    vec3 finalColor = accumulator / float(uSampleCount);

	gl_FragColor = vec4(finalColor, 1.);

}