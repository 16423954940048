import WineBottle from '_webgl/components/WineBottle'
import BaseScene from './BaseScene'
import { AmbientLight, CameraHelper, DirectionalLight, DirectionalLightHelper, EquirectangularReflectionMapping, SRGBColorSpace, Vector2, Vector3 } from 'three'
import store from '_store'
import { types } from '@theatre/core'
import WineContourLines from '_webgl/components/WineContourLines'
import { E, qs } from '_utils/index'

export default class ANatureUntamedScene extends BaseScene {
	constructor(name, options, webgl) {
		super('a-nature-untamed', {
			mouseMoveAngle: new Vector2(0.0, 0.0)
		}, webgl)

		Object.assign(this.components, {
			contourLines: new WineContourLines(),
			wineBottle: new WineBottle()
		})

		this.activePreset = null

		this.background = null

		this.desktopCameraPosition = new Vector3(0, 0, 50)
	}

	build() {
		this.buildSun()

		super.build()

		this.setCameraZ()
	}

	start() {
		super.start()
		this.onSceneResize()

		E.on('VintageSelector:vintageSelected', this.onVintageSelected)
		E.on('VintageSelector:goBackToIntro', this.onVintageCleared)
		E.on('VintageDetails:showDetails', this.onShowVintageDetails)
	}

	stop() {
		super.stop()

		E.off('VintageSelector:vintageSelected', this.onVintageSelected)
		E.off('VintageSelector:goBackToIntro', this.onVintageCleared)
		E.off('VintageDetails:showDetails', this.onShowVintageDetails)
	}

	setCameraZ() {
		if (!store.mq.md.matches) { // smaller than md
			const fov = this.cameras.camera1.fov
			const z = store.window.h / Math.tan(fov * Math.PI / 360) * 0.5
			this.cameras.camera1.position.set(0, 0, z)
			this.cameras.camera1._position.set(0, 0, z)
		} else {
			this.cameras.camera1.position.copy(this.desktopCameraPosition)
			this.cameras.camera1._position.copy(this.desktopCameraPosition)
		}
	}

	onVintageSelected = () => {
		const preset = store.urlParams.get('preset') || qs('#a-nature-untamed').dataset.preset

		if (preset === this.activePreset) return

		if (this.activePreset) {
			store.theatre.helper.deleteSheet(this.activePreset)
		}

		this.activePreset = preset

		store.theatre.sheets[preset] = store.theatre.project.sheet(preset)
		store.theatre.sheets[preset]._webglScene = this

		this.components.contourLines.buildTheatreObject(preset)

		this.components.contourLines.persistencePass.uniforms.uReset.value = true

		requestAnimationFrame(() => {
			this.components.contourLines.persistencePass.uniforms.uReset.value = false
		})
	}

	onVintageCleared = () => {
		store.theatre.helper.deleteSheet(this.activePreset)
		this.activePreset = null
	}

	onShowVintageDetails = () => {
		this.components.wineBottle.playIntro()
	}

	onSceneResize() {
		// update appropriate focal length
		this.components.wineBottle?.resize()
		this.setCameraZ()
	}

	buildSun() {
		this.ambientLight = new AmbientLight(0x454341, 0.0)
		this.add(this.ambientLight)

		this.dirLight2 = new DirectionalLight(0xffe7d1, 0.0)
		this.dirLight2.position.set(-1, 0, 2)
		this.add(this.dirLight2.target)
		this.add(this.dirLight2)

		this.dirLight2Helper = new DirectionalLightHelper(this.dirLight2)
		this.dirLight2Helper.visible = false
		this.add(this.dirLight2Helper)

		const dirLight2TheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Directional Light 2', {
			position: types.compound({
				x: types.number(this.dirLight2.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.dirLight2.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.dirLight2.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.dirLight2.color)
		})

		dirLight2TheatreObject.onValuesChange(values => {
			this.dirLight2.position.set(values.position.x, values.position.y, values.position.z)
			this.dirLight2.target.position.set(values.target.x, values.target.y, values.target.z)
			this.dirLight2.intensity = values.intensity
			this.dirLight2.color.copy(values.color)
		}, store.theatre.rafDriver)

		const dirLight2StudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Directional Light 2', {
			visible: types.boolean(this.dirLight2Helper.visible, { label: 'helper' })
		})

		dirLight2StudioObject.onValuesChange(values => {
			this.dirLight2Helper.visible = values.visible
			this.dirLight2Helper.update()
		}, store.theatre.rafDriver)

		this.sun = new DirectionalLight(0xffe7d1, 2.2)

		this.sun.shadow.autoUpdate = false
		this.sun.position.set(-15.20, 6.89, 8.29)
		this.sun.target.position.set(-7.79, 2.50, 0.0)
		this.sun.castShadow = true
		this.sun.shadow.mapSize.width = store.mq.touch.matches ? 1024 : 2048
		this.sun.shadow.mapSize.height = store.mq.touch.matches ? 1024 : 2048

		this.sun.shadow.camera.near = -5.8
		this.sun.shadow.camera.far = 1.9
		this.sun.shadow.camera.left = -9.1
		this.sun.shadow.camera.right = 6
		this.sun.shadow.camera.top = 2.9
		this.sun.shadow.camera.bottom = -4.6
		this.sun.shadow.blurSamples = 6

		this.sun.shadow.radius = 5.4
		this.sun.shadow.bias = -0.00039
		this.sun.shadow.normalBias = 0.00073

		this.add(this.sun)
		this.add(this.sun.target)

		this.sunHelper = new DirectionalLightHelper(this.sun)
		this.sunHelper.visible = false
		this.add(this.sunHelper)

		this.sunShadowHelper = new CameraHelper(this.sun.shadow.camera)
		this.sunShadowHelper.visible = false
		this.add(this.sunShadowHelper)

		const sunStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Sun', {
			visible: types.boolean(this.sunHelper.visible, { label: 'sun helper' }),
			shadowHelper: types.boolean(this.sunShadowHelper.visible, { label: 'shadow helper' })
		})

		sunStudioObject.onValuesChange(values => {
			this.sunHelper.visible = values.visible
			this.sunShadowHelper.visible = values.shadowHelper
			this.sunHelper.update()
			this.sunShadowHelper.update()
		}, store.theatre.rafDriver)

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.sun.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.sun.color),
			ambientColor: store.theatre.helper.parseColor(this.ambientLight.color),
			ambientIntensity: types.number(this.ambientLight.intensity, { nudgeMultiplier: 0.1 })
		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.position.set(values.target.x, values.target.y, values.target.z)
			this.sun.intensity = values.intensity
			this.sun.color.copy(values.color)
			this.sunHelper.update()

			this.ambientLight.color.copy(values.ambientColor)
			this.ambientLight.intensity = values.ambientIntensity
		}, store.theatre.rafDriver)
	}

	load() {
		super.load()

		store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/wine_hdr_v1.png`, {
			mapping: EquirectangularReflectionMapping,
			colorSpace: SRGBColorSpace
		}).then(texture => {
			this.assets.textures.envmap = texture
			this.assets.textures.envmap.name = 'envmap'
			this.assets.textures.envmap.isHDR = false
		})
	}

	destroy() {
		super.destroy()

		if (this.activePreset) {
			store.theatre.helper.deleteSheet(this.activePreset)
		}
	}
}
