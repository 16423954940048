varying vec2 vUv;

uniform sampler2D tPressure;
uniform float uClearValue;

void main () {

    gl_FragColor = uClearValue * texture2D(tPressure, vUv);
    // gl_FragColor = vec4(vec3(0.), 1.0);

}