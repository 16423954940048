import { Component } from '_components/unseen'
import SplitText from '_utils/gsap/SplitText'

export default class WorldApartIntro extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-world-apart-intro'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.buildIntro()
	}

	buildIntro() {
		const title = this.dom.title
		const text = this.dom.text

		title.style.perspective = '200px'
		title.style.perspectiveOrigin = 'center center'
		text.style.perspective = '200px'
		text.style.perspectiveOrigin = 'center center'

		const titleSplit = new SplitText(title, {
			type: 'lines',
			lineThreshold: 0.3
		})

		const textSplit = new SplitText(text, {
			type: 'lines',
			lineThreshold: 0.3
		})

		this.introTl = this.gsap.timeline({
			paused: true
		})
			.fromTo([titleSplit.lines, textSplit.lines], {
				rotateX: -15,
				xPercent: 25,
				z: -100,
				opacity: 0
			}, {
				rotateX: 0,
				xPercent: 0,
				z: 0,
				opacity: 1,
				stagger: 0.1,
				ease: 'joe.out',
				duration: 1.4
			}, 0)
			.call(() => {
				this.dom.btn.classList.add('is-visible')
			}, null, 1.2)
	}
}