#include <fog_vert_pars>

varying vec2 vUv;
varying vec4 vWorldPosition;

uniform sampler2D tNoise;
uniform float uHeight;

uniform bool uPerspective;

void main()	{
    vUv = uv;
  
    vec4 noise = texture2D( tNoise, vUv );

    vec4 transformedPosition = vec4( position, 1.0 );
    transformedPosition.z += noise.z * 0.05 * uHeight;

    vec4 worldPosition = modelMatrix * transformedPosition;

    vWorldPosition = worldPosition;

    vec4 mvPosition = viewMatrix * worldPosition;

    gl_Position = projectionMatrix * mvPosition;

    #include <fog_vert>
}