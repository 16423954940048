import gsap from "gsap"
import store from "_store"

export default class TweenManager {
	/**
	 * @type {gsap.Context}
	 */
	context

	/**
	 * @type {gsap.MatchMedia}
	 */
	matchMedia

	/**
	 * @type {{string:string}}
	 */
	mediaQueries = {}

	constructor() {
		this.context = gsap.context((self) => {
			self.add('to', (targets, vars) => {
				return gsap.to(targets, vars)
			})

			self.add('from', (targets, vars) => {
				return gsap.from(targets, vars)
			})

			self.add('fromTo', (targets, fromVars, toVars) => {
				return gsap.fromTo(targets, fromVars, toVars)
			})

			self.add('timeline', (timelineVars) => {
				return gsap.timeline(timelineVars)
			})
		})
	}

	mm(callback, breakpoints = []) {
		if (!this.matchMedia) {
			this.matchMedia = gsap.matchMedia()

			// Iterate over breakpoints and add them to the mediaQueries object
			for (const bp in store.mq) {
				this.mediaQueries[bp] = store.mq[bp].media
			}
		}

		if (!breakpoints.length) {
			this.matchMedia.add(this.mediaQueries, callback)
		} else {
			// only add the specified breakpoints
			const mediaQueries = {}
			breakpoints.forEach((bp) => {
				mediaQueries[bp] = this.mediaQueries[bp]
			})

			this.matchMedia.add(mediaQueries, callback)
		}
	}

	/**
	 * Proxy gsap.to.
	 *
	 * @param {gsap.TweenTarget} targets
	 * @param {gsap.TweenVars} vars
	 * @returns {gsap.core.Tween}
	 */
	to(targets, vars) {
		return this.context.to(targets, vars)
	}

	/**
	 * Proxy gsap.from.
	 *
	 * @param {gsap.TweenTarget} targets
	 * @param {gsap.TweenVars} vars
	 * @returns {gsap.core.Tween}
	 */
	from(targets, vars) {
		return this.context.from(targets, vars)
	}

	/**
	 * Proxy gsap.fromTo.
	 *
	 * @param {gsap.TweenTarget} targets
	 * @param {gsap.TweenVars} fromVars
	 * @param {gsap.TweenVars} toVars
	 * @returns {gsap.core.Tween}
	 */
	fromTo(targets, fromVars, toVars) {
		return this.context.fromTo(targets, fromVars, toVars)
	}

	/**
	 * Proxy gsap.timeline.
	 *
	 * @param {gsap.TimelineVars} timelineVars
	 * @returns {gsap.core.Timeline}
	 */
	timeline(timelineVars) {
		return this.context.timeline(timelineVars)
	}
}
