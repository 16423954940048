import { E, qsa } from "_utils"
import TweenManager from "_components/unseen/concerns/TweenManager"

export default class Component {
	/**
	 * The selector this component is instanced to
	 * @type {?string}
	 */
	static selector = null

	/**
	 * Whether this component is instanced or not
	 * @type {boolean}
	 */
	static isSingleton = false

	/**
	 * Holds the current instance element
	 * @type {HTMLElement|Element}
	 */
	el = null

	/**
	 * Hold child DOM references
	 * @type {Object.<string, HTMLElement|HTMLElement[]>}
	 */
	dom = {}

	/**
	 * Hold the components registered events for later removal.
	 *
	 * @type {{}[]}
	 * @private
	 */
	_eventStack = []

	/**
	 * Hold the registered tweens for later removal.
	 *
	 * @type {TweenManager}
	 * @private
	 */
	gsap = null

	/**
	 * Init
	 * @param {HTMLElement|Element} el
	 */
	constructor(el) {
		this.el = el

		this.gsap = new TweenManager()
	}

	/**
	 * Populates this.dom with all child DOM elements.
	 * Children must have a classname containing {this.selector}: to be found automatically.
	 */
	populateDom() {
		this.dom = {}
		const selector = `${this.constructor.selector.replace(/[.#]/g, '')}`
		const childNodes = qsa(`[class^="${selector}:"], [class*=" ${selector}:"]`, this.el)

		if (!childNodes.length) {
			return
		}

		for (let i = 0; i < childNodes.length; i++) {
			const match = childNodes[i].classList.value.match(new RegExp(`${selector}[:-]([^ ]+)`))

			if (match) {
				if (!this.dom[match[1]]) {
					this.dom[match[1]] = childNodes[i]
				}

				if (!this.dom[match[1] + 'Array']) {
					this.dom[match[1] + 'Array'] = [childNodes[i]]
					continue
				}
				this.dom[match[1] + 'Array'].push(childNodes[i])
			}
		}
	}

	/**
	 * Proxy E.on method
	 *
	 * @param {string} event
	 * @param {string|NodeList|HTMLElement|Element|Window|Document|array|function} el
	 * @param {*} [callback]
	 * @param {{}|boolean} [options]
	 */
	on(event, el, callback, options) {
		E.on(event, el, callback, options)
		this._eventStack.push({
			event,
			el,
			callback,
			options
		})
	}

	/**
	 * Proxy E.delegate method
	 *
	 * @param {string} event
	 * @param {string} delegate
	 * @param {*} [callback]
	 */
	delegate(event, delegate, callback) {
		E.delegate(event, delegate, callback)
		this._eventStack.push({
			event,
			el: delegate,
			callback,
			options: undefined
		})
	}

	/**
	 * Remove a callback from a DOM element, or one or all Bus events.
	 *
	 * @param {string} event
	 * @param {string|NodeList|HTMLElement|Element|Window|undefined} [el]
	 * @param {*} [callback]
	 * @param {{}|boolean} [options]
	 */
	off(event, el, callback, options) {
		E.off(event, el, callback, options)
	}

	/**
	 * Emit a DOM or Bus event.
	 *
	 * @param {string} event
	 * @param {...*} args
	 */
	emit(event, ...args) {
		E.emit(event, ...args)
	}

	destroy() {
		for (let i = 0; i < this._eventStack.length; i++) {
			const { event, el, callback, options } = this._eventStack[i]
			this.off(event, el, callback, options)
		}

		this.gsap?.matchMedia?.kill()
		this.gsap?.context?.kill()
		this.gsap = null

		this._eventStack = []
	}
}
