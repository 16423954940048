import Component from "./Component"
import { qs, attr } from "_utils"

export default class Toggle extends Component {
	/**
	 * The selector this component is instanced to
	 * @type {string}
	 */
	static selector = '.js-toggle'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		const target = this.el.getAttribute('aria-controls')

		this.target = qs(`#${target}`)
		this.openProp = attr(this.el, 'aria-expanded') || 'false'

		if (this.target === null) {
			throw new Error(`The Toggle element must have a valid aria-controls attribute and a matching target element within the DOM. "#${target}" could not be found.`)
		}

		if (attr(this.el, 'aria-expanded') === null) {
			attr(this.el, 'aria-expanded', 'false')
		}

		this.on('click', this.el, this.toggle)
	}

	/**
	 * Open the toggle
	 */
	open() {
		return new Promise((resolve) => {
			this.onOpen(resolve)
		}).then(() => {
			this.openProp = 'true'
			attr(this.el, 'aria-expanded', 'true')
		})
	}

	/**
	 * Open the toggle callback
	 * @private
	 * @param {function} resolve
	 */
	onOpen(resolve) {
		this.target.style.display = 'block'
		resolve()
	}

	/**
	 * Close th toggle
	 */
	close() {
		return new Promise((resolve) => {
			this.onClose(resolve)
		}).then(() => {
			this.openProp = 'false'
			attr(this.el, 'aria-expanded', 'false')
		})
	}

	/**
	 * Close the toggle callback
	 * @private
	 * @param {function} resolve
	 */
	onClose(resolve) {
		this.target.style.display = 'none'
		resolve()
	}

	/**
	 * Toggle click handler.
	 */
	toggle = () => {
		this.isOpen() ? this.close() : this.open()
	}

	/**
	 * Whether the toggle is open or not
	 * @return {boolean}
	 */
	isOpen() {
		return this.openProp === 'true'
	}
}
