attribute vec2 aFboUv;
attribute vec3 aRandom;

#include <fog_vert_pars>

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vInstanceWorldPosition;
varying vec3 vCameraToPlaneDirection;
varying vec3 vRandom;
varying vec3 vViewPosition;
varying vec2 vImposterUv;

uniform float uTime;
uniform sampler2D tPosition;

mat4 rotation3d(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;

  return mat4(
    oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
    0.0,                                0.0,                                0.0,                                1.0
  );
}

#include <common>
#include <shadowmap_pars_vertex>

#ifdef DEPTH_PACKING
varying vec2 vHighPrecisionZW;
#endif

void main()	{
    vUv = uv;
    vRandom = aRandom;

    vec4 transformedPosition = vec4( position, 1.0 );
    vec4 nonBillboardedPosition = transformedPosition;

    // billboard (face camera)
	vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
	vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
	transformedPosition.xyz = right * transformedPosition.x + up * transformedPosition.y;

    vec4 particlePosition = texture2D(tPosition, aFboUv);
    float size = smoothstep(0., 0.1, particlePosition.w);
    size *= 1. - smoothstep(0.9, 1., particlePosition.w);
    size = clamp(size, 0., 1.);

    vInstanceWorldPosition = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;

    #ifdef USE_INSTANCING
        transformedPosition = instanceMatrix * transformedPosition;
        nonBillboardedPosition = instanceMatrix * nonBillboardedPosition;
        vInstanceWorldPosition = (modelMatrix * vec4( particlePosition.xyz, 1. )).xyz;
    #endif

    transformedPosition.xyz *= size;
    transformedPosition.xyz += particlePosition.xyz;

    nonBillboardedPosition.xyz *= size;
    nonBillboardedPosition.xyz += particlePosition.xyz;

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    mat3 normalMatrixInverse = inverse(mat3(normalMatrix));
    vec3 transformedNormal = normal;
    vec3 transformedNormalInverse = normal;

    #ifdef USE_INSTANCING
        mat3 m = mat3( instanceMatrix );

        transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormal = m * transformedNormal;

        transformedNormalInverse /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormalInverse = m * transformedNormalInverse;
    #endif

    transformedNormal = normalMatrix * normal;
    transformedNormalInverse = normalMatrixInverse * normal;

    vNormal = normalize(transformedNormal);
    vNormalInverse = normalize(transformedNormalInverse);

    // mat4 rotationMatrix = rotation3d(vec3(0., 1., 0.), uTime);
    mat4 rotationMatrix = rotation3d(aRandom, aRandom.x * 20.);
    // vNormal = (inverse(rotationMatrix) * vec4(vNormal, 1.)).xyz;
    
    vNormalMatrixInverse = normalMatrixInverse;
    vNormalMatrix = mat3(normalMatrix);

    vCameraToPlaneDirection = normalize(vNormal + cameraPosition - vInstanceWorldPosition);
    vCameraToPlaneDirection = (rotationMatrix * vec4(vCameraToPlaneDirection, 1.)).xyz; // TODO: do rotation that actually works

    vImposterUv = vUv;
    float gridSize = float(GRID_SIZE);
    float cellSize = 1. / gridSize;
    vec2 uvScale = vec2(cellSize, cellSize);

    float angleY = dot(vCameraToPlaneDirection, vec3(0., 1., 0.));
    angleY = asin(angleY) / PI + 0.5;

    vCameraToPlaneDirection[1] = 0.0;
    float angleX = dot(vCameraToPlaneDirection, vec3(1., 0., 0.));
    angleX = asin(angleX) / PI + 0.5;
    if(vCameraToPlaneDirection.z < 0.0) {
        angleX = 2.0 - angleX;
    }
    
    angleX = 1. - angleX;
    angleX = -angleX * 0.5 + 0.5;
    angleX = round(angleX * gridSize) / gridSize;

    angleY = angleY - cellSize;
    // angleY = angleY * (1. - cellSize) - cellSize * cellSize; TODO: fix bottom angle switching to behind too soon
    angleY = round(angleY * gridSize) / gridSize;

    vImposterUv = vImposterUv * uvScale + vec2(angleX, angleY);

    #include <shadowmap_vertex>

    // apply spherical billboarding
    vec4 mvPosition = modelViewMatrix * transformedPosition;
    vViewPosition = -mvPosition.xyz;
    // vViewPosition = -(modelViewMatrix * nonBillboardedPosition).xyz;

    // vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;

    #ifdef DEPTH_PACKING
        vHighPrecisionZW = gl_Position.zw;
    #endif

    #include <fog_vert>
}