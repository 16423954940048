import Component from '_components/unseen/Component'
import ScrollTrigger from "gsap/ScrollTrigger"
import { GlobalEvents, qs } from "_utils"
import gsap from 'gsap'
import store from '_store'

export default class Line extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-line'

	yearDotPositions = [
		[5, 43.5],
		[8.6, 64.4],
		[15.6, 81.8],
		[23.2, 59.4, 'story-colored-light'],
		[33.9, 77.9],
		[42, 63.6],
		[48.2, 59.3],
		[52.8, 53.5, 'story-colored-dark'],
		[57.6, 61.3, 'story-colored-dark'],
		[64.67, 48.1],
		[69.5, 44.8],
		[83.2, 49.2],
		[87.5, 51.9]
	]

	yearDotPositionsFr = [
		[5, 44.9],
		[8.6, 60.2],
		[15.6, 77.3],
		[23.2, 59.1, 'story-colored-light'],
		[33.9, 74],
		[41.5, 63.3],
		[48.2, 46.9],
		[52.8, 45.4, 'story-colored-dark'],
		[56.7, 61.9, 'story-colored-dark'],
		[65.37, 58.8],
		[70.1, 44.7],
		[83.6, 72.3],
		[87.8, 63.8]
	]

	cdmYearDotPositions = [
		// [20.3, 78.7]
	]

	yearDots = []
	yearDotSTs = []

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.alt = qs('.js-line\\:alt')
		this.path = this.el.querySelector('path')
		this.balloon = qs('.js-balloon')
		this.balloonImg = qs('.js-balloon\\:img')
		this.balloonVertical = qs('.js-balloon\\:vertical')
		this.balloonRotate = qs('.js-balloon\\:rotate')
		this.balloonScale = qs('.js-balloon\\:scale')
		this.story = qs('.js-story')

		this.lastRotation = 0

		this.gsap.mm((context) => {
			const { horizontal } = context.conditions
			this.st?.kill()

			this.removeBalloonMovement()

			this.ensureSizeParityBetweenLines()

			if (horizontal) {
				this.st = ScrollTrigger.create({
					trigger: this.el,
					start: `left left`,
					horizontal: true,
					scrub: true,
					onUpdate: (self) => {
						this.alt.style.transform = `translate3d(-${self.progress * 100}%, 0, 0)`
					}
				})

				this.positionYearDots()

				if (this.balloon) {
					this.addAmbientBalloonMovement()
					this.addVerticalBalloonMovement()
					store.RAFCollection.add(this.setBalloonVelocity)
				}
			}
		}, ['horizontal'])

		this.on(GlobalEvents.RESIZE, () => {
			this.ensureSizeParityBetweenLines()
			this.yearDotSTs.forEach((st) => st.refresh())

			if (this.balloon) {
				this.addVerticalBalloonMovement()
			}
		})
	}

	ensureSizeParityBetweenLines() {
		const bcr = this.el.getBoundingClientRect()
		this.alt.style.width = `${bcr.width}px`
	}

	addAmbientBalloonMovement() {
		gsap.set(this.balloonRotate, { rotate: -25 })

		this.balloonAmbientTL = this.gsap.timeline({
			repeat: -1,
			defaults: {
				duration: 3
			}
		})
			.fromTo(this.balloon, {
				yPercent: -15
			}, {
				yPercent: 10,
				ease: 'sine.inOut'
			})
			.to(this.balloon, {
				yPercent: -15,
				ease: 'sine.inOut'
			})

		this.balloonAmbientRotationTL = this.gsap.timeline({
			repeat: -1,
			yoyo: true,
			defaults: {
				duration: 5
			}
		})
			.fromTo(this.balloonRotate, {
				rotate: -10
			}, {
				rotate: 5,
				ease: 'sine.inOut'
			})
	}

	addVerticalBalloonMovement() {
		let previousY = store.window.h / 2
		const balloonBCR = this.balloon.getBoundingClientRect()
		this.dotTL?.kill()

		if (!this.yearDots.length) {
			return
		}

		let start = 0
		this.dotTL = this.gsap.timeline({
			scrollTrigger: {
				trigger: this.story,
				horizontal: true,
				scrub: true,
				start: `left left+=${balloonBCR.x}`,
				end: `right left+=${balloonBCR.x}`
			}
		})

		// initially show balloon
		this.dotTL.fromTo(this.balloonScale, {
			x: -store.window.w / 2,
			scale: .8
		}, {
			x: 0,
			scale: 1,
			duration: 3
		}, 4.9)

		// hide balloon
		this.dotTL.to(this.balloonScale, { opacity: 0, duration: 2 }, 100 - start)

		this.getYearDotPositions().forEach((dot, i) => {
			const newY = this.yearDots[i].getBoundingClientRect().top

			this.dotTL.fromTo(this.balloonVertical,
				{ y: previousY },
				{
					y: newY,
					duration: dot[0] - start
				}, start)
				.call(this.showFirstMarker, [this.yearDots[i - 1]], start - 0.2)

			start = dot[0]
			previousY = newY
		})

		this.dotTL.to(this.balloonVertical, {
			opacity: 0,
			duration: 100 - start
		}, start)
	}

	showFirstMarker = (marker) => {
		if (!marker) {
			return
		}

		const expanded = marker.querySelector('.js-expanded')

		marker.classList.add('is-active')
		this.gsap.to(expanded, {
			duration: 0.8,
			scale: 1,
			ease: 'joe.out',
			autoAlpha: 1
		})
	}

	setBalloonVelocity = () => {
		this.lastRotation = gsap.utils.interpolate(
			this.lastRotation,
			gsap.utils.clamp(-40, 40, store.SmoothScroll.Lenis.velocity),
			0.04
		)

		this.balloonImg.style.transform = `rotate(${this.lastRotation}deg) translateX(${this.lastRotation * -8}px)`
	}

	removeBalloonMovement() {
		this.balloonAmbientTL?.kill()
		this.balloonAmbientRotationTL?.kill()
		store.RAFCollection.remove(this.setBalloonVelocity)
	}

	positionYearDots() {
		const tpl = qs('.js-dot-template')

		// add the dots to the page initially
		if (!this.yearDots.length) {
			this.getYearDotPositions().forEach((position) => {
				const dot = tpl.content.firstElementChild.cloneNode(true)
				this.story.appendChild(dot)
				this.yearDots.push(dot)

				if (position[2]) {
					dot.classList.add('story__line-dot--' + position[2])
				}
			})
		}

		// position the dots. This can happen on resize
		this.getYearDotPositions().forEach((position, i) => {
			const dot = this.yearDots[i]

			dot.style.left = `${position[0]}%`
			dot.style.top = `${position[1]}%`
		})
	}

	getYearDotPositions() {
		if (store.body.classList.contains('clos-du-marquis')) {
			return this.cdmYearDotPositions
		}

		return store.html.lang === 'fr-FR' ? this.yearDotPositionsFr : this.yearDotPositions
	}

	destroy() {
		super.destroy()

		this.st?.kill()
		this.removeBalloonMovement()
		this.yearDotSTs.forEach((st) => st.kill())
		this.dotTL?.kill()
	}
}
