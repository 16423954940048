#include <default_pars>
#include <packing>

#ifdef DYNAMIC_SHADOWS
    #include <shadowmap_pars_vertex> 
#endif

#include <fog_vert_pars>

attribute vec3 aRandom;
attribute float aScale;
attribute vec2 reference;

varying vec2 vUv;
varying float vLife;

uniform sampler2D tAttributes;
uniform sampler2D tPosition;
uniform sampler2D tPreviousPosition;

uniform float uSplineCount;

varying float vSplineIndex;

uniform float uTime;
uniform float uParticleSize;
uniform float uAmbientParticleSize;
uniform vec2 uParticleSizeRange;
uniform float uSpinAmount;

uniform vec2 uFadeOut;

// uniform vec2 uMainParticleSizeRange;
// uniform vec2 uMainParticleSizeEdges;

varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec3 vInstanceWorldPosition;
varying vec3 vCameraToPlaneDirection;
varying vec3 vRandom;
varying float vRandomAttribute;
varying float vSplineType;
varying vec2 vImposterUv;

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

#ifdef MOTION_BLUR
    uniform sampler2D tPrevPosition;
    uniform mat4 uPrevModelViewMatrix;
    varying float vPrevLife;
    varying vec4 vMotion;
#endif

// Rotates a vector based on the velocity of the particle
vec3 rotate3D(vec3 v, vec3 vel) {
    vec3 newpos = v;
    vec3 up = vec3(0, 1, 0);
    vec3 axis = normalize(cross(up, vel));
    float angle = acos(dot(up, normalize(vel)));
    newpos = newpos * cos(angle) + cross(axis, newpos) * sin(angle) + axis * dot(axis, newpos) * (1. - cos(angle));
    return newpos;
}

mat4 rotation3d(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  float oc = 1.0 - c;

  return mat4(
    oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
    0.0,                                0.0,                                0.0,                                1.0
  );
}

void main() {

    vUv = uv;
    vRandom = aRandom;

    vNormalMatrix = normalMatrix;

    vec4 attributes = texture2D(tAttributes, reference);
    float splineType = attributes.y;
    vSplineType = splineType;
    vRandomAttribute = attributes.w;

    vSplineIndex = map(attributes.x, 0., 1., 0., uSplineCount-1.);

    vec4 particlePosition = texture2D( tPosition, reference );
    #ifdef MOTION_BLUR
        vec4 prevParticlePosition = texture2D( tPrevPosition, reference ); // Get the previous position
        vPrevLife = prevParticlePosition.w;
    #endif

    float life = particlePosition.w;
    vLife = life;

    vec4 transformedPosition = vec4( position, 1.0 );

    // billboard towards camera
    vec3 up = vec3(modelViewMatrix[0][1], modelViewMatrix[1][1], modelViewMatrix[2][1]);
    vec3 right = vec3(modelViewMatrix[0][0], modelViewMatrix[1][0], modelViewMatrix[2][0]);
    transformedPosition.xyz = right * transformedPosition.x + up * transformedPosition.y;

    #ifdef MOTION_BLUR
        vec4 previousPosition = vec4(position, 1.0);
        vec3 up_prev = vec3(uPrevModelViewMatrix[0][1], uPrevModelViewMatrix[1][1], uPrevModelViewMatrix[2][1]);
        vec3 right_prev = vec3(uPrevModelViewMatrix[0][0], uPrevModelViewMatrix[1][0], uPrevModelViewMatrix[2][0]);
        previousPosition.xyz = right_prev * previousPosition.x + up_prev * previousPosition.y;
    #endif

    vInstanceWorldPosition = (modelMatrix * vec4(0.0, 0.0, 0.0, 1.0)).xyz;

    #ifdef USE_INSTANCING
        transformedPosition = instanceMatrix * transformedPosition;
        vInstanceWorldPosition = (modelMatrix * vec4( particlePosition.xyz, 1. )).xyz;

        #ifdef MOTION_BLUR
            previousPosition = instanceMatrix * previousPosition;
        #endif        
    #endif

    float scale;

    if(splineType > 0.0) {

        scale = uParticleSize * mix(uParticleSizeRange.x, uParticleSizeRange.y, aRandom.z);
        scale *= mix(0.0, 1.0, smoothstep(1.0, 0.95, life)); // Decrease at the beginning of life
        scale *= mix(0.0, 1.0, smoothstep(uFadeOut.x, uFadeOut.y, life)); // Decrease at the end of life
        
    } else { // ambient particles
        scale = uAmbientParticleSize * mix(uParticleSizeRange.x, uParticleSizeRange.y, aRandom.z);
    }
    
    // decrease particle scale with life based on the spline type
    // if(splineType > 0.1 && splineType < 0.4) { // main spline
    //     scale *= mix(0.0, 1.0, smoothstep(1.0, 0.9, life)); // Decrease at the beginning of life
    //     scale *= mix(0.0, 1.0, smoothstep(uDeathMainScaleEnd, uDeathMainScaleStart, life)); // Decrease at the end of life

    //     // scale *= mix(uMainParticleSizeRange.x, uMainParticleSizeRange.y, smoothstep(uMainParticleSizeEdges.x, uMainParticleSizeEdges.y, life));

    // } else if (splineType == 0.5) { // mid splines
    //     scale *= mix(0.0, 1.0, smoothstep(1.0, 0.95, life)); // Decrease at the beginning of life
    //     scale *= mix(0.0, 1.0, smoothstep(uDeathMidScaleEnd, uDeathMidScaleStart, life)); // Decrease at the end of life

    //     // scale *= mix(uMainParticleSizeRange.x, uMainParticleSizeRange.y, smoothstep(uMainParticleSizeEdges.x, uMainParticleSizeEdges.y, life));

    // } else { // small splines
    //     scale *= mix(0.0, 1.0, smoothstep(1.0, 0.95, life)); // Decrease at the beginning of life
    //     scale *= mix(0.0, 1.0, smoothstep(uDeathSmallScaleEnd, uDeathSmallScaleStart, life)); // Decrease at the end of life
    // }

    transformedPosition.xyz *= scale;

    #ifdef MOTION_BLUR
        float scale_prev = uParticleSize * mix(uParticleSizeRange.x, uParticleSizeRange.y, aRandom.z);

        // if(splineType < 0.4) { // main spline
        //     scale_prev *= mix(0.0, 1.0, smoothstep(1.0, 0.9, vPrevLife));
        //     scale_prev *= mix(0.0, 1.0, smoothstep(uDeathMainScaleEnd, uDeathMainScaleStart, vPrevLife));
        // } else if (splineType == 0.5) { // mid splines
        //     scale_prev *= mix(0.0, 1.0, smoothstep(1.0, 0.95, vPrevLife));
        //     scale_prev *= mix(0.0, 1.0, smoothstep(uDeathMidScaleEnd, uDeathMidScaleStart, vPrevLife));
        // } else { // small splines
        //     scale_prev *= mix(0.0, 1.0, smoothstep(1.0, 0.95, vPrevLife));
        //     scale_prev *= mix(0.0, 1.0, smoothstep(uDeathSmallScaleEnd, uDeathSmallScaleStart, vPrevLife));
        // }

        if(splineType > 0.0) {
            scale_prev *= mix(0.0, 1.0, smoothstep(1.0, 0.95, vPrevLife));
            scale_prev *= mix(0.0, 1.0, smoothstep(uFadeOut.x, uFadeOut.y, vPrevLife));
        }

        previousPosition.xyz *= scale_prev;
    #endif

    transformedPosition.xyz += particlePosition.xyz;

    #ifdef MOTION_BLUR
        previousPosition.xyz += prevParticlePosition.xyz;
    #endif

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    mat3 normalMatrixInverse = inverse(mat3(normalMatrix));
    vec3 transformedNormal = normal;
    vec3 transformedNormalInverse = normal;

    #ifdef USE_INSTANCING
        mat3 m = mat3( instanceMatrix );

        transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormal = m * transformedNormal;

        transformedNormalInverse /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormalInverse = m * transformedNormalInverse;
    #endif

    transformedNormal = normalMatrix * normal;
    transformedNormalInverse = normalMatrixInverse * normal;

    vNormal = normalize(transformedNormal);
    vNormalInverse = normalize(transformedNormalInverse);

    mat4 rotationMatrix = rotation3d(aRandom, aRandom.x * 20. + uTime * aRandom.y * 2. * uSpinAmount);
    
    vNormalMatrixInverse = normalMatrixInverse;
    vNormalMatrix = mat3(normalMatrix);

    vCameraToPlaneDirection = normalize(vNormal + cameraPosition - vInstanceWorldPosition);
    vCameraToPlaneDirection = (rotationMatrix * vec4(vCameraToPlaneDirection, 1.)).xyz; // TODO: do rotation that actually works

    vImposterUv = vUv;
    float gridSize = float(GRID_SIZE);
    float cellSize = 1. / gridSize;
    vec2 uvScale = vec2(cellSize, cellSize);

    float angleY = dot(vCameraToPlaneDirection, vec3(0., 1., 0.));
    angleY = asin(angleY) / PI + 0.5;

    vCameraToPlaneDirection[1] = 0.0;
    float angleX = dot(vCameraToPlaneDirection, vec3(1., 0., 0.));
    angleX = asin(angleX) / PI + 0.5;
    if(vCameraToPlaneDirection.z < 0.0) {
        angleX = 2.0 - angleX;
    }
    
    angleX = 1. - angleX;
    angleX = -angleX * 0.5 + 0.5;
    angleX = round(angleX * gridSize) / gridSize;

    angleY = angleY - cellSize;
    // angleY = angleY * (1. - cellSize) - cellSize * cellSize; TODO: fix bottom angle switching to behind too soon
    angleY = round(angleY * gridSize) / gridSize;

    vImposterUv = vImposterUv * uvScale + vec2(angleX, angleY);

    #ifdef DYNAMIC_SHADOWS
        // #include <shadowmap_vertex>

	    // Offsetting the position used for querying occlusion along the world normal can be used to reduce shadow acne.
	    vec3 shadowWorldNormal = inverseTransformDirection( transformedNormal, viewMatrix );
	    vec4 shadowWorldPosition;

        #if defined( USE_SHADOWMAP )
			shadowWorldPosition = vec4(worldPosition.xy, worldPosition.z, worldPosition.w) + vec4( shadowWorldNormal * directionalLightShadows[ 0 ].shadowNormalBias, 0 );
			vDirectionalShadowCoord[ 0 ] = directionalShadowMatrix[ 0 ] * shadowWorldPosition;
            // vDirectionalShadowCoord[ 0 ].z -= 0.5;
	    #endif

    #endif

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;

    vViewPosition = - mvPosition.xyz;

    #ifdef DEPTH_PACKING
        vHighPrecisionZW = gl_Position.zw;
    #endif

    #ifdef MOTION_BLUR
        vec4 prevGlPosition = projectionMatrix * uPrevModelViewMatrix * previousPosition;
        vMotion = vec4((gl_Position.xyz - prevGlPosition.xyz), smoothstep(0.0, 0.2, particlePosition.w));
    #endif

    #include <fog_vert>

}