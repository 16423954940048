import gsap from 'gsap'
import SplitText from '_utils/gsap/SplitText'

export default {
	name: 'title',
	extendTimeline: true,
	onResize: () => {
		// Leave this empty as the splittexts are handled elsewhere, but this animation still needs to be rebuilt each resize
	},
	effect: (targets, config) => {
		targets[0].classList.add('split-text-title-animation')
		if (targets[0].children.length > 0) {
			gsap.set(targets[0].children, { transformStyle: 'preserve-3d', display: 'inline-block' })
		}

		const split = new SplitText(targets[0], { type: config.splitType, lineThreshold: 0.3 })
		gsap.set(targets[0], { perspective: 2000, perspectiveOrigin: config.perspectiveOrigin })
		gsap.set(split.words, { transformStyle: 'preserve-3d' })

		delete config.perspectiveOrigin
		delete config.splitType

		const splitTarget = split.chars.length > 0 ? split.chars : split.words

		return gsap.timeline({ defaults: config })
			.fromTo(splitTarget, {
				rotateX: '-90deg',
				z: -100,
				opacity: 0
			}, {
				rotateX: 0,
				z: 0,
				opacity: 1
			}, 0)
			.fromTo(targets[0], {
				scale: 0.7
			}, {
				scale: 1
			}, 0)
	},
	defaults: {
		stagger: 0.02,
		ease: 'joe.out',
		perspectiveOrigin: 'center bottom',
		splitType: 'words chars',
		duration: 1
	}
}
