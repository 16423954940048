import AmbientParticles from '_webgl/components/AmbientParticles'
import BaseScene from './BaseScene'
import store from '_store'
import { AmbientLight, CameraHelper, Color, DirectionalLight, DirectionalLightHelper, Vector3 } from 'three'
import { types } from '@theatre/core'
import RockImposters from '_webgl/components/RockImposters'
import WaveTerrain from '_webgl/components/WaveTerrain'
import { E } from '_utils/index'

export default class ChapterThreeScene extends BaseScene {
	constructor() {
		super('chapter-three', {
			currentChapterTitleHideDelay: 2,
			fluidEnabled: true,
			audioFiles: ['chapter-3-1', 'chapter-3-2']
		})

		Object.assign(this.components, {
			waveTerrain: new WaveTerrain(),
			rockImposters: new RockImposters(),
			ambientParticles: new AmbientParticles({
				count: 2000,
				bounds: new Vector3(5, 5, 5)
			})
		})

		Object.assign(this.globalUniforms, {
			uLightDirection: { value: new Vector3() },
			uLightPosition: { value: new Vector3() },
			uLightColor: { value: new Color() },
			uNoiseSeed: { value: 0, gui: { min: 0, max: 100, step: 0.01 } },
			uNoiseHeight: { value: 0.05, gui: { min: 0, max: 0.5, step: 0.001 } },
			uNoiseScale: { value: 5, gui: { min: 0, max: 50, step: 0.01 } },
			uNoiseTimeScale: { value: 0.05, gui: { min: 0, max: 1, step: 0.01 } },
			uEnableFog: { value: true },
			uDebugFog: { value: false },
			uSolidFogColor: { value: new Color(0x111111) },
			uFogNear_D: { value: 20, gui: { min: 0, step: 0.01 } },
			uFogFar_D: { value: 85, gui: { min: 0, step: 0.01 } },
			uFogStrength_D: { value: 1., gui: { min: 0, max: 1, step: 0.01 } },
			uFogNear_H: { value: -1, gui: { step: 0.01 } },
			uFogFar_H: { value: 1, gui: { step: 0.01 } },
			uFogStrength_H: { value: 0.0, gui: { min: 0, max: 1, step: 0.01 } },
			uSceneFogMix: { value: 1, gui: { min: 0, max: 1, step: 0.01 } }
		})

		this.background.set(0x111111)

		const backgroundTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Background', {
			color: store.theatre.helper.parseColor(this.background)
		})

		backgroundTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		}, store.theatre.rafDriver)
	}

	start() {
		super.start()
		store.WebGL.renderer.shadowMap.enabled = true

		this.prevShadowAutoUpdate = store.WebGL.renderer.shadowMap.autoUpdate
		store.WebGL.renderer.shadowMap.autoUpdate = true
	}

	stop() {
		super.stop()
		store.WebGL.renderer.shadowMap.autoUpdate = this.prevShadowAutoUpdate
	}

	addEvents() {
		super.addEvents()

		E.on('FPSChecked', this.onFPSChecked)
	}

	removeEvents() {
		super.removeEvents()

		E.off('FPSChecked', this.onFPSChecked)
	}

	build() {
		super.build()

		this.buildLights()

		// add global uniforms to theatre
		const config = {}
		const globalUniformCallbacks = store.theatre.helper.autoAddUniforms(config, this.globalUniforms, ['uLightDirection', 'uLightPosition'], [], '')
		const sheetObject = store.theatre.helper.addSheetObject(this.prettyName, 'Global Settings', config)

		sheetObject.onValuesChange(values => {
			globalUniformCallbacks.forEach(callback => callback(null, values))
		}, store.theatre.rafDriver)
	}

	buildLights() {
		this.ambientLight = new AmbientLight(0x454341, 0.5)
		this.add(this.ambientLight)

		this.sun = new DirectionalLight(0xffe7d1, 2.7)

		this.sun.position.set(7.9, 5, -5.6)
		this.sun.target.position.set(0, 0.2, -1.7)
		this.sun.castShadow = true
		this.sun.shadow.mapSize.width = store.mq.touch.matches || store.isLowTierGPU ? 1024 : 2048
		this.sun.shadow.mapSize.height = store.mq.touch.matches || store.isLowTierGPU ? 1024 : 2048

		this.sun.shadow.camera.near = -5.8
		this.sun.shadow.camera.far = 1.9
		this.sun.shadow.camera.left = -9.1
		this.sun.shadow.camera.right = 6
		this.sun.shadow.camera.top = 2.9
		this.sun.shadow.camera.bottom = -4.6
		this.sun.shadow.blurSamples = 6

		this.sun.shadow.radius = 5.4
		this.sun.shadow.bias = -0.00039
		this.sun.shadow.normalBias = 0.00073

		this.add(this.sun)
		this.add(this.sun.target)

		this.sunHelper = new DirectionalLightHelper(this.sun)
		this.sunHelper.visible = false
		this.add(this.sunHelper)

		this.sunShadowHelper = new CameraHelper(this.sun.shadow.camera)
		this.sunShadowHelper.visible = false
		this.add(this.sunShadowHelper)

		const sunStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Sun', {
			visible: types.boolean(this.sunHelper.visible, { label: 'sun helper' }),
			shadowHelper: types.boolean(this.sunShadowHelper.visible, { label: 'shadow helper' })
		})

		sunStudioObject.onValuesChange(values => {
			this.sunHelper.visible = values.visible
			this.sunShadowHelper.visible = values.shadowHelper
			this.sunHelper.update()
			this.sunShadowHelper.update()
		}, store.theatre.rafDriver)

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.sun.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.sun.color),
			ambientColor: store.theatre.helper.parseColor(this.ambientLight.color),
			ambientIntensity: types.number(this.ambientLight.intensity, { nudgeMultiplier: 0.1 }),
			shadowCamera: types.compound({
				near: types.number(this.sun.shadow.camera.near, { nudgeMultiplier: 0.1 }),
				far: types.number(this.sun.shadow.camera.far, { nudgeMultiplier: 0.1 }),
				left: types.number(this.sun.shadow.camera.left, { nudgeMultiplier: 0.1 }),
				right: types.number(this.sun.shadow.camera.right, { nudgeMultiplier: 0.1 }),
				top: types.number(this.sun.shadow.camera.top, { nudgeMultiplier: 0.1 }),
				bottom: types.number(this.sun.shadow.camera.bottom, { nudgeMultiplier: 0.1 }),
				bias: types.number(this.sun.shadow.bias, { nudgeMultiplier: 0.0001 }),
				normalBias: types.number(this.sun.shadow.normalBias, { nudgeMultiplier: 0.0001 }),
				blurSamples: types.number(this.sun.shadow.blurSamples, { nudgeMultiplier: 1 })
			}),
			shadowRadius: types.number(this.sun.shadow.radius, { nudgeMultiplier: 0.1 })
		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.position.set(values.target.x, values.target.y, values.target.z)
			this.sun.intensity = values.intensity
			this.sun.color.copy(values.color)

			this.sun.shadow.camera.near = values.shadowCamera.near
			this.sun.shadow.camera.far = values.shadowCamera.far
			this.sun.shadow.camera.left = values.shadowCamera.left
			this.sun.shadow.camera.right = values.shadowCamera.right
			this.sun.shadow.camera.top = values.shadowCamera.top
			this.sun.shadow.camera.bottom = values.shadowCamera.bottom
			this.sun.shadow.bias = values.shadowCamera.bias
			this.sun.shadow.normalBias = values.shadowCamera.normalBias
			this.sun.shadow.blurSamples = values.shadowCamera.blurSamples
			this.sun.shadow.radius = values.shadowRadius

			this.sun.shadow.camera.updateProjectionMatrix()
			this.sun.shadow.needsUpdate = true
			store.WebGL.renderer.shadowMap.needsUpdate = true
			this.sunHelper.update()
			this.sunShadowHelper.update()

			this.ambientLight.color.copy(values.ambientColor)
			this.ambientLight.intensity = values.ambientIntensity
		}, store.theatre.rafDriver)
	}

	animate() {
		super.animate()

		this.updateLightUniforms()
	}

	updateLightUniforms() {
		this.globalUniforms.uLightPosition.value.copy(this.sun.position)

		this.globalUniforms.uLightDirection.value.copy(this.sun.position)
		this.globalUniforms.uLightDirection.value.sub(this.sun.target.position)
		this.globalUniforms.uLightDirection.value.transformDirection(this.activeCamera.matrixWorldInverse)

		this.globalUniforms.uLightColor.value.copy(this.sun.color)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			this.components.ambientParticles.visible = false
			this.components.rockImposters.instancedMesh.count = Math.round(this.components.rockImposters.originalCount * 0.75)
		}
	}
}
