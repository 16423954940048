import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { AdditiveBlending, Color, FrontSide, Vector2, Vector4 } from 'three'

export default class CursorMeshLinesMaterial extends BaseMaterial {
	constructor(options = {}, scene) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				color: { value: new Color(0xe9a261) },
				uFadeColor: { value: new Color(0xa23e0c) },
				lineWidth: { value: 0.036, gui: { step: 0.001 } },
				opacity: { value: 0.84, gui: { step: 0.01, min: 0, max: 1 } },
				sizeAttenuation: { value: 1, gui: { step: 1, min: 0, max: 1 } },
				useDash: { value: false },
				dashOffset: { value: 0.1 },
				dashArray: { value: 0.1 },
				dashRatio: { value: 0.1 },
				visibility: { value: 0.8, gui: { step: 0.01, min: 0, max: 1 } },
				alphaTest: { value: 0.0, gui: { step: 0.01, min: 0, max: 1 } },
				repeat: { value: new Vector2(1, 1) },

				uTailBegin: { value: 0.0 },
				uTailSmoothness: { value: 1.0 },

				uBeginColorEdge: { value: 1.0 },
				uFadeColorEdge: { value: 0.0 },

				uDarkenAmount: { value: 0.65 },

				uRevealProgress: { value: 0, gui: { step: 0.01, min: 0, max: 1 } },
				uHideProgress: { value: 0, gui: { step: 0.01, min: 0, max: 1 } },

				uRandom: { value: new Vector4() },

				...scene.globalUniforms.mouse
			},
			defines: {
				USE_MOUSE: false
			},
			side: FrontSide,
			transparent: true,
			depthTest: false,
			blending: AdditiveBlending
		}, options)

		super(options)
	}
}