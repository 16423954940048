import { Core } from '@unseenco/taxi'

import DefaultRenderer from '_taxi/renderers/DefaultRenderer'
import DefaultTransition from '_taxi/transitions/DefaultTransition'
import VintageRenderer from '_taxi/renderers/VintageRenderer'
import AWorldApartRenderer from './renderers/AWorldApartRenderer'
import TimelineRenderer from '_taxi/renderers/TimelineRenderer'
import HomeRenderer from './renderers/HomeRenderer'

export default class Taxi extends Core {
	constructor() {
		super({
			links: 'a:not([target]):not([href^=\\#]):not([download]):not([data-router-disabled]):not(.sf-dump-toggle):not(.sf-dump-str-toggle):not(.js-almond):not(#wpadminbar a)',
			renderers: {
				default: DefaultRenderer,
				vintage: VintageRenderer,
				'a-world-apart': AWorldApartRenderer,
				timeline: TimelineRenderer,
				home: HomeRenderer
			},
			transitions: {
				default: DefaultTransition
			}
		})

		this.setDefaultRenderer('default')
		this.setDefaultTransition('default')

		// this.addRoute('/pages/taxi/', '/pages/taxi/inner.html', 'override')

		this.on('NAVIGATE_IN', this.onNavigateIn)
		this.on('NAVIGATE_END', this.onNavigateEnd)
	}

	onNavigateIn({ to }) {
		// Update body classes
		if (to) {
			document.body.className = to.page.body.className
		}
	}

	onNavigateEnd({ from, to }) {
		// Analytics
		// if (typeof window.gtag !== 'undefined') {
		//   window.gtag('config', 'UA-49120579-1', {
		//     'page_path': window.location.pathname,
		//     'page_title': to.title,
		//     'page_location': window.location.href
		//   });
		// }
	}
}
