varying vec2 vUv;

uniform sampler2D uBaseTexture; // original
uniform sampler2D uTexture; // updating
uniform float uTime;
uniform float uDelta;
uniform float uDecay;
uniform float uSpeed;
uniform float uFrequency;
uniform float uAmplitude;
uniform float uRoughness;
uniform float uDetail;
uniform vec2 uNoiseTranslation;
uniform vec2 uPointer;
uniform float uCameraDistance;

vec3 permute(vec3 x) {
    return mod(((x * 34.0) + 1.0) * x, 289.0);
}

float snoise(vec2 v) {
    const vec4 C = vec4(0.211324865405187, 0.366025403784439, -0.577350269189626, 0.024390243902439);
    vec2 i = floor(v + dot(v, C.yy));
    vec2 x0 = v - i + dot(i, C.xx);

    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;

    i = mod(i, 289.0);
    vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0)) + i.x + vec3(0.0, i1.x, 1.0));

    vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
    m = m * m;
    m = m * m;

    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;

    m *= 1.79284291400159 - 0.85373472095314 * (a0 * a0 + h * h);

    vec3 g;
    g.x = a0.x * x0.x + h.x * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
}

const int OCTAVES = 4;

float turbulence(vec2 position, float initialFreq, float initialAmp, float persistence) {
    float total = 0.0;
    float frequency = initialFreq;
    float amplitude = initialAmp;

    for (int i = 0; i < OCTAVES; i++) {
        total += (snoise(position * frequency)) * amplitude;
        frequency *= 2.;
        amplitude *= persistence;
    }

    return total;
}

void main() {
    vec4 outColor = vec4(0., 0., 0., 1.);

    // Generate a turbulent noise value
    float noiseValue = turbulence(vUv.xy * 0.5 + uNoiseTranslation, uFrequency, uAmplitude, uRoughness);
    // Use the noise value to determine the angle of movement
    float angle = noiseValue * uDetail;
    // Calculate the direction of movement
    vec2 dir = vec2(cos(angle), sin(angle));
    outColor.xy += dir;
    outColor.z = noiseValue;

    gl_FragColor = outColor;
}