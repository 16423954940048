import { AmbientLight, CameraHelper, Color, DirectionalLight, DirectionalLightHelper, Mesh, OrthographicCamera, PlaneGeometry, Vector3, WebGLRenderTarget } from 'three'
import BaseScene from './BaseScene'
// import AmbientParticles from '_webgl/components/AmbientParticles'
import IntroArch from '_webgl/components/IntroArch'
import store from '_store'
import { types } from '@theatre/core'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import BaseMaterial from '_webgl/materials/unseen/base/BaseMaterial'
import defaultVert from '_glsl/default.vert'
import combinePassFrag from '_glsl/godrays/frag.glsl'
import GodraysMaterial from '_webgl/materials/godraysMaterial/GodraysMaterial'
import AmbientParticles from '_webgl/components/AmbientParticles'

export default class IntroScene extends BaseScene {
	constructor() {
		super('intro', {
			currentChapterTitleHideDelay: 0
		})

		Object.assign(this.components, {
			arch: new IntroArch(),
			ambientParticles: new AmbientParticles({
				bounds: new Vector3(15, 15, 15),
				count: 3000
			})
		})

		Object.assign(this.cameraLayers, {
			godrays: 1
		})

		Object.assign(this.globalUniforms, {
			shadow: {
				uShadowColor: { value: new Color(0x000000) },
				uShadowIntensity: { value: 1.0 }
			},
			sun: {
				uLightDirection: { value: new Vector3() },
				uLightPosition: { value: new Vector3() }
			}
		})

		const shadowTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Shadow', {
			color: store.theatre.helper.parseColor(this.globalUniforms.shadow.uShadowColor.value, 'shadow color'),
			intensity: types.number(this.globalUniforms.shadow.uShadowIntensity.value, { label: 'shadow intensity', range: [0, 1], nudgeMultiplier: 0.01 })
		})

		shadowTheatreObject.onValuesChange(values => {
			this.globalUniforms.shadow.uShadowColor.value.copy(values.color)
			this.globalUniforms.shadow.uShadowIntensity.value = values.intensity
		}, store.theatre.rafDriver)

		this.background.set(0x111111)

		const backgroundTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Background', {
			color: store.theatre.helper.parseColor(this.background)
		})

		backgroundTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		}, store.theatre.rafDriver)
	}

	build() {
		this.buildSun()

		this.prevShadowAutoUpdate = store.WebGL.renderer.shadowMap.autoUpdate

		super.build()

		this.buildGodrays()
	}

	addPostFXToTheatre() {
		const sheetObject = super.addPostFXToTheatre()

		sheetObject.initialValue = {
			lensFlare: {
				enabled: false,
				blurEnabled: false
			}
		}
	}

	buildPasses() {
		super.buildPasses()

		this.combinePass = new ShaderPass(new BaseMaterial({
			vertexShader: defaultVert,
			fragmentShader: combinePassFrag,
			uniforms: {
				tDiffuse: { value: null },
				tGodrays: { value: null },
				uColor: { value: new Color('#FFFFFF') },
				uIntensity: { value: 0.2 },
				uContrast: { value: 0.3 }
			}
		}))

		store.WebGL.composerPasses.add(this.combinePass, store.WebGL.composerPassOrder[this.name] + 1)
	}

	buildSun() {
		this.ambientLight = new AmbientLight(0x454341, 0.5)
		this.add(this.ambientLight)

		this.dirLight2 = new DirectionalLight(0xffe7d1, 0.1)
		this.dirLight2.position.set(-1, 0, 2)
		this.add(this.dirLight2.target)
		this.add(this.dirLight2)

		this.dirLight2Helper = new DirectionalLightHelper(this.dirLight2)
		this.dirLight2Helper.visible = false
		this.add(this.dirLight2Helper)

		const dirLight2TheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Directional Light 2', {
			position: types.compound({
				x: types.number(this.dirLight2.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.dirLight2.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.dirLight2.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.dirLight2.color)
		})

		dirLight2TheatreObject.onValuesChange(values => {
			this.dirLight2.position.set(values.position.x, values.position.y, values.position.z)
			this.dirLight2.target.position.set(values.target.x, values.target.y, values.target.z)
			this.dirLight2.intensity = values.intensity
			this.dirLight2.color.copy(values.color)
		}, store.theatre.rafDriver)

		const dirLight2StudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Directional Light 2', {
			visible: types.boolean(this.dirLight2Helper.visible, { label: 'helper' })
		})

		dirLight2StudioObject.onValuesChange(values => {
			this.dirLight2Helper.visible = values.visible
			this.dirLight2Helper.update()
		}, store.theatre.rafDriver)

		this.sun = new DirectionalLight(0xffe7d1, 2.7)

		this.sun.shadow.autoUpdate = false
		this.sun.position.set(7.9, 5, -5.6)
		this.sun.target.position.set(0, 0.2, -1.7)
		this.sun.castShadow = true
		this.sun.shadow.mapSize.width = store.mq.touch.matches ? 1024 : 2048
		this.sun.shadow.mapSize.height = store.mq.touch.matches ? 1024 : 2048

		this.sun.shadow.camera.near = -5.8
		this.sun.shadow.camera.far = 1.9
		this.sun.shadow.camera.left = -9.1
		this.sun.shadow.camera.right = 6
		this.sun.shadow.camera.top = 2.9
		this.sun.shadow.camera.bottom = -4.6
		this.sun.shadow.blurSamples = 6

		this.sun.shadow.radius = 5.4
		this.sun.shadow.bias = -0.00039
		this.sun.shadow.normalBias = 0.00073

		this.add(this.sun)
		this.add(this.sun.target)

		this.sunHelper = new DirectionalLightHelper(this.sun)
		this.sunHelper.visible = false
		this.add(this.sunHelper)

		this.sunShadowHelper = new CameraHelper(this.sun.shadow.camera)
		this.sunShadowHelper.visible = false
		this.add(this.sunShadowHelper)

		const sunStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Sun', {
			visible: types.boolean(this.sunHelper.visible, { label: 'sun helper' }),
			shadowHelper: types.boolean(this.sunShadowHelper.visible, { label: 'shadow helper' })
		})

		sunStudioObject.onValuesChange(values => {
			this.sunHelper.visible = values.visible
			this.sunShadowHelper.visible = values.shadowHelper
			this.sunHelper.update()
			this.sunShadowHelper.update()
		}, store.theatre.rafDriver)

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.sun.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.sun.color),
			ambientColor: store.theatre.helper.parseColor(this.ambientLight.color),
			ambientIntensity: types.number(this.ambientLight.intensity, { nudgeMultiplier: 0.1 }),
			shadowCamera: types.compound({
				near: types.number(this.sun.shadow.camera.near, { nudgeMultiplier: 0.1 }),
				far: types.number(this.sun.shadow.camera.far, { nudgeMultiplier: 0.1 }),
				left: types.number(this.sun.shadow.camera.left, { nudgeMultiplier: 0.1 }),
				right: types.number(this.sun.shadow.camera.right, { nudgeMultiplier: 0.1 }),
				top: types.number(this.sun.shadow.camera.top, { nudgeMultiplier: 0.1 }),
				bottom: types.number(this.sun.shadow.camera.bottom, { nudgeMultiplier: 0.1 }),
				bias: types.number(this.sun.shadow.bias, { nudgeMultiplier: 0.0001 }),
				normalBias: types.number(this.sun.shadow.normalBias, { nudgeMultiplier: 0.0001 }),
				blurSamples: types.number(this.sun.shadow.blurSamples, { nudgeMultiplier: 1 })
			}),
			shadowRadius: types.number(this.sun.shadow.radius, { nudgeMultiplier: 0.1 })
		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.position.set(values.target.x, values.target.y, values.target.z)
			this.sun.intensity = values.intensity
			this.sun.color.copy(values.color)

			this.sun.shadow.camera.near = values.shadowCamera.near
			this.sun.shadow.camera.far = values.shadowCamera.far
			this.sun.shadow.camera.left = values.shadowCamera.left
			this.sun.shadow.camera.right = values.shadowCamera.right
			this.sun.shadow.camera.top = values.shadowCamera.top
			this.sun.shadow.camera.bottom = values.shadowCamera.bottom
			this.sun.shadow.bias = values.shadowCamera.bias
			this.sun.shadow.normalBias = values.shadowCamera.normalBias
			this.sun.shadow.blurSamples = values.shadowCamera.blurSamples
			this.sun.shadow.radius = values.shadowRadius

			this.sun.shadow.camera.updateProjectionMatrix()
			this.sun.shadow.needsUpdate = true
			store.WebGL.renderer.shadowMap.needsUpdate = true
			this.sunHelper.update()
			this.sunShadowHelper.update()

			this.ambientLight.color.copy(values.ambientColor)
			this.ambientLight.intensity = values.ambientIntensity
		}, store.theatre.rafDriver)
	}

	buildGodrays() {
		this.depthRT = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		store.WebGL.rtViewer?.registerRT(this.depthRT, 'Godrays Normal Depth RT')

		this.godraysCameraRT = new WebGLRenderTarget(512, 512)
		store.WebGL.rtViewer?.registerRT(this.godraysCameraRT, 'Godrays Camera Depth Texture')

		this.godraysPostFxRT = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio() * 0.5, store.window.h * store.WebGL.renderer.getPixelRatio() * 0.5)
		store.WebGL.rtViewer?.registerRT(this.godraysPostFxRT, 'Godrays Final Target')

		this.combinePass.uniforms.tGodrays.value = this.godraysPostFxRT.texture

		const f = 2.85
		this.godraysCam = new OrthographicCamera(-f, f, f, -f, 1, 8)
		this.godraysCam.lookAt(this.sun.target.position)

		// hide all objects on the godrays cam except those on the godrays layer
		if (this.cameraLayers.godrays) {
			this.godraysCam.layers.disableAll()
			this.godraysCam.layers.enable(this.cameraLayers.godrays)
		}

		// add camera helper
		const godraysCamHelper = new CameraHelper(this.godraysCam)
		godraysCamHelper.visible = false
		this.add(godraysCamHelper)

		this.godraysPassMaterial = new GodraysMaterial({
			uniforms: {
				tNormalDepth: { value: this.depthRT.texture },
				uLightDepthMap: { value: this.godraysCameraRT.texture },
				uLight: {
					value: {
						position: this.godraysCam.position,
						projectionMatrix: this.godraysCam.projectionMatrix,
						viewMatrix: this.godraysCam.matrixWorldInverse
					}
				},
				uCamera: {
					value: {
						position: this.activeCamera.position,
						projectionMatrixInverse: this.activeCamera.projectionMatrixInverse,
						viewMatrixInverse: this.activeCamera.matrixWorld
					}
				},
				uMaxSamples: { value: 100 },
				uBias: { value: 0 }
			}
		})

		this.godraysFullScreenQuad = new Mesh(new PlaneGeometry(2, 2), this.godraysPassMaterial)
		this.godraysPassCamera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)

		// add to studio sheet
		const godraysStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Godrays Cam', {
			helper: types.boolean(godraysCamHelper.visible, { label: 'helper' })
		})

		const godraysTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Godrays', {
			left: types.number(this.godraysCam.left, { nudgeMultiplier: 0.01 }),
			right: types.number(this.godraysCam.right, { nudgeMultiplier: 0.01 }),
			top: types.number(this.godraysCam.top, { nudgeMultiplier: 0.01 }),
			bottom: types.number(this.godraysCam.bottom, { nudgeMultiplier: 0.01 }),
			near: types.number(this.godraysCam.near, { range: [0, 999], nudgeMultiplier: 0.1 }),
			far: types.number(this.godraysCam.far, { range: [0, 999], nudgeMultiplier: 0.1 }),
			maxSamples: types.number(this.godraysPassMaterial.uniforms.uMaxSamples.value, { range: [0, 999], nudgeMultiplier: 1 }),
			bias: types.number(this.godraysPassMaterial.uniforms.uBias.value, { nudgeMultiplier: 0.001 }),
			color: store.theatre.helper.parseColor(this.combinePass.uniforms.uColor.value),
			intensity: types.number(this.combinePass.uniforms.uIntensity.value, { range: [0, 999], nudgeMultiplier: 0.01 }),
			contrast: types.number(this.combinePass.uniforms.uContrast.value, { range: [0, 999], nudgeMultiplier: 0.01 }),
			decayStart: types.number(this.godraysPassMaterial.uniforms.uDecayStart.value, { range: [-1.0, 1.0], nudgeMultiplier: 0.001 }),
			decayEnd: types.number(this.godraysPassMaterial.uniforms.uDecayEnd.value, { range: [0, 1.0], nudgeMultiplier: 0.001 }),
			objectMaskStrength: types.number(this.godraysPassMaterial.uniforms.uObjectMaskStrength.value, { range: [0, 1.0], nudgeMultiplier: 0.001 })
		})

		godraysStudioObject.onValuesChange(values => {
			godraysCamHelper.visible = values.helper
		}, store.theatre.rafDriver)

		godraysTheatreObject.onValuesChange(values => {
			this.godraysCam.left = values.left
			this.godraysCam.right = values.right
			this.godraysCam.top = values.top
			this.godraysCam.bottom = values.bottom
			this.godraysCam.near = values.near
			this.godraysCam.far = values.far

			this.godraysCam.updateProjectionMatrix()
			this.godraysCam.updateMatrixWorld()

			// update the helper
			godraysCamHelper.update()

			this.godraysPassMaterial.uniforms.uLight.value.position.copy(this.godraysCam.position)
			this.godraysPassMaterial.uniforms.uLight.value.projectionMatrix = this.godraysCam.projectionMatrix
			this.godraysPassMaterial.uniforms.uLight.value.viewMatrix = this.godraysCam.matrixWorldInverse

			this.combinePass.uniforms.uColor.value.copy(values.color)
			this.combinePass.uniforms.uIntensity.value = values.intensity
			this.combinePass.uniforms.uContrast.value = values.contrast
			this.godraysPassMaterial.uniforms.uMaxSamples.value = values.maxSamples
			this.godraysPassMaterial.uniforms.uBias.value = values.bias
			this.godraysPassMaterial.uniforms.uDecayStart.value = values.decayStart
			this.godraysPassMaterial.uniforms.uDecayEnd.value = values.decayEnd
			this.godraysPassMaterial.uniforms.uObjectMaskStrength.value = values.objectMaskStrength
		}, store.theatre.rafDriver)
	}

	start() {
		super.start()
		store.WebGL.renderer.shadowMap.enabled = true

		this.prevShadowAutoUpdate = store.WebGL.renderer.shadowMap.autoUpdate
		store.WebGL.renderer.shadowMap.autoUpdate = false

		this.sun.shadow.needsUpdate = true
		store.WebGL.renderer.shadowMap.needsUpdate = true

		this.combinePass.enabled = true
	}

	stop() {
		super.stop()

		store.WebGL.renderer.shadowMap.autoUpdate = this.prevShadowAutoUpdate

		this.combinePass.enabled = false
	}

	animate() {
		super.animate()

		this.godraysPassMaterial.uniforms.uCamera.value.position = this.activeCamera.position
		this.godraysPassMaterial.uniforms.uCamera.value.projectionMatrixInverse = this.activeCamera.projectionMatrixInverse
		this.godraysPassMaterial.uniforms.uCamera.value.viewMatrixInverse = this.activeCamera.matrixWorld

		// match godrays to sun
		this.godraysCam.position.copy(this.sun.position)
		this.godraysCam.lookAt(this.sun.target.position)

		this.upateLightUniforms()

		// render godrays
		// 1. render the depth
		store.WebGL.renderer.setRenderTarget(this.godraysCameraRT)
		// override the material on the arch
		this.components.arch.setDepthMaterial()
		// render the scene through the godrays light cam
		store.WebGL.renderer.render(this, this.godraysCam)

		// 2. render mrts -> only render the godrays layer
		if (this.cameraLayers.godrays) {
			this.activeCamera.layers.disableAll()
			this.activeCamera.layers.enable(this.cameraLayers.godrays)
		}
		store.WebGL.renderer.setRenderTarget(this.depthRT)
		// set the material of the arch to the godrays material & render
		this.components.arch.setGodraysMaterial()
		store.WebGL.renderer.render(this, this.activeCamera)

		// reset the layers
		this.activeCamera.layers.enableAll()

		// 3. render godrays to the full screen quad
		store.WebGL.renderer.setRenderTarget(this.godraysPostFxRT)
		store.WebGL.renderer.render(this.godraysFullScreenQuad, this.godraysPassCamera)

		// return the material on the arch to the default to render final scene
		this.components.arch.setDefaultMaterial()

		// reset the render target
		store.WebGL.renderer.setRenderTarget(null)
	}

	upateLightUniforms() {
		this.globalUniforms.sun.uLightPosition.value.copy(this.sun.position)

		this.globalUniforms.sun.uLightDirection.value.copy(this.sun.position)
		this.globalUniforms.sun.uLightDirection.value.sub(this.sun.target.position)
		this.globalUniforms.sun.uLightDirection.value.transformDirection(this.activeCamera.matrixWorldInverse)
	}

	onSceneResize() {
		const dpr = store.WebGL.renderer.getPixelRatio()
		this.depthRT.setSize(store.window.w * dpr, store.window.h * dpr)
		this.godraysPostFxRT.setSize(store.window.w * dpr, store.window.h * dpr)
	}

	destroy() {
		super.destroy()

		this.depthRT.dispose()
		this.godraysCameraRT.dispose()
		this.godraysPostFxRT.dispose()
		this.godraysPassMaterial.dispose()
		this.godraysFullScreenQuad.geometry.dispose()

		store.WebGL.rtViewer?.unregisterRT('Godrays Normal Depth RT')
		store.WebGL.rtViewer?.unregisterRT('Godrays Camera Depth Texture')
		store.WebGL.rtViewer?.unregisterRT('Godrays Final Target')
	}
}