import TheatreHelper from '_utils/TheatreHelper'
import { Vector2 } from "three"

export default {
	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 * General
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	env: import.meta.env.MODE,
	html: document.documentElement,
	body: document.body,
	window: {
		w: window.innerWidth,
		h: window.innerHeight,
		dpr: window.devicePixelRatio
	},
	keys: { UP: 38, DOWN: 40, ENTER: 13, ESC: 27, HOME: 36, END: 35 },
	isDarkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
	isIOS: ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document),
	isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
	isLowTierGPU: false,

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 * Used by components
	 * -----------------------------------------------------------------------------------------------------------------
	 */

	pointer: {
		x: -1,
		y: -1,
		isDragging: false,
		gl: new Vector2(),
		glNormalized: new Vector2(),
		glScreenSpace: new Vector2()
	},

	/** @type {boolean} **/
	cookieNoticeAccepted: false,

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 * Components storage
	 * -----------------------------------------------------------------------------------------------------------------
	 */

	/** @type {{add(...import("utils").ComponentManager): void, get(import('./components/unseen/Component').default): import("utils").ComponentManager|null, destroy(): void}} */
	staticComponents: null,

	/** @type {{add(...import("utils").ComponentManager): void, get(import('./components/unseen/Component').default): import("utils").ComponentManager|null, destroy(): void}} */
	components: null,

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 * Object storage
	 * -----------------------------------------------------------------------------------------------------------------
	 */

	/** @type {import("@unseenco/taxi").Core|null} **/
	Taxi: null,

	/** @type { import("./utils/AssetLoader").default|import("./utils/WebGLAssetLoader").default|null } **/
	AssetLoader: null,

	/** @type { import("./utils/RAFCollection").default|null } **/
	RAFCollection: null,

	/** @type { import("./utils/SmoothScroll").default|null } */
	SmoothScroll: null,

	/** @type { import("./webgl/WebGL").default|null } */
	WebGL: null,

	/** @type {?URLSearchParams} **/
	urlParams: new URLSearchParams(window.location.search),

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 * Project specific
	 * -----------------------------------------------------------------------------------------------------------------
	 */

	/** @type {Object.<string, MediaQueryList>} **/
	mq: {},

	theatre: {
		/** @type { import("./utils/TheatreManager").default} */
		manager: null,
		/** @type { import("@theatre/studio").default|null } */
		studio: null,
		/** @type { import('@theatre/core').IProject } */
		studioProject: null,
		/** @type { Object.<string, import('@theatre/core').ISheet> } */
		studioSheets: {},
		/**
		 * @type { Object.<string, {
		 * 	[key: string]: import('@theatre/core').ISheetObject
		 * } }
		*/
		studioObjects: {},
		/** @type { import('@theatre/core').IProject } */
		project: null,
		/** @type { Object.<string, import('@theatre/core').ISheet> } */
		sheets: {},
		/**
		 * @type { Object.<string, {
		 * 	[key: string]: import('@theatre/core').ISheetObject
		 * } }
		*/
		objects: {},
		/** @type { TheatreHelper } */
		helper: new TheatreHelper(),
		/** @type { String } */
		persistenceKey: null,
		/** @type { import('@theatre/core').IRafDriver } */
		rafDriver: null,
		/** @type { Function } */
		updateExtensionToolbar: null,
		/** @type { boolean } */
		previewCamOpen: false
	},

	isInitialLoad: true,

	devMode: new URLSearchParams(window.location.search).get('devmode')
}
