import { Component } from '_components/unseen'
import { E, qs } from '_utils/index'
import gsap from 'gsap'
import WorldApartOutro from './WorldApartOutro'
import store from '_store'

export default class ChapterSelector extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-chapter-selector'

	activeIndex = 0
	isAnimating = false
	updateProgress = false
	reachedFirstChapter = false
	buttonClicked = false

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.populateDom()

		this.on('click', this.dom.buttonArray, this.onClickButton)
		this.on('ChapterSelector:EnterChapter', this.enterChapter)
		this.on('ChapterSelector:showTitle', this.showTitle)
		this.on('ChapterSelector:Show', this.show)
		this.on('chapter:progress', this.onChapterProgress)

		this.setInitialStyles()

		this.buildToggleTl()

		this.on('mouseenter', this.el, this.onMouseEnterToggle)
		this.on('mouseleave', this.el, this.onMouseLeaveToggle)
	}

	setInitialStyles() {
		gsap.set(this.el, {
			autoAlpha: 0,
			pointerEvents: 'none'
		})

		gsap.set([
			this.dom.numberArray[1],
			this.dom.numberArray[2],
			this.dom.numberArray[3]
		], {
			opacity: 0,
			scale: 0.8
		})

		const xPos = [0, -10, -10, -10]
		const yPos = [10, 10, 0, -10]

		gsap.set(this.dom.buttonArray, {
			autoAlpha: 0,
			yPercent: (index) => yPos[index],
			xPercent: (index) => xPos[index]
		})

		gsap.set(this.dom.titlesArray, {
			yPercent: 100,
			rotate: 10,
			transformOrigin: '0% 50%'
		})

		gsap.set(this.dom.toggle, {
			display: 'none'
		})
	}

	buildToggleTl() {
		this.toggleTl = this.gsap.timeline({
			paused: true
		})

			.set(this.dom.toggle, {
				display: 'block'
			}, 0)
			.to(this.dom.buttonArray, {
				autoAlpha: 1,
				yPercent: 0,
				xPercent: 0,
				stagger: 0.1
			}, 0)
			.to(this.dom.titlesContainer, {
				opacity: 0,
				duration: 0.3
			}, 0)
	}

	onMouseEnterToggle = () => {
		if (this.isAnimating) return
		this.toggleTl.restart()
	}

	onMouseLeaveToggle = () => {
		if (this.isAnimating) return
		this.toggleTl.reverse()
	}

	getButtonIndex(chapterId) {
		const button = qs(`[data-chapter="${chapterId}"]`, this.el)
		return this.dom.buttonArray.indexOf(button)
	}

	showTitle = () => {
		this.gsap
			.fromTo(this.dom.titlesArray[this.activeIndex], {
				yPercent: 100,
				rotate: 10,
				transformOrigin: '0% 50%'
			}, {
				yPercent: 0,
				rotate: 0,
				duration: 1,
				ease: 'joe.out'
			})
	}

	hideTitle() {
		if (this.buttonClicked) return

		this.gsap
			.fromTo(this.dom.titlesArray[this.activeIndex], {
				yPercent: 0,
				rotate: 0,
				transformOrigin: '100% 50%'
			}, {
				yPercent: -100,
				rotate: 10,
				duration: 1,
				ease: 'joe.in'
			})
	}

	enterChapter = (chapterId) => {
		if (!this.buttonClicked) {
			this.nextIndex = this.getButtonIndex(chapterId)
			this.onSelectChapter()
		}

		this.hideTitle()
	}

	onClickButton = (e) => {
		if (this.isAnimating) return

		this.buttonClicked = true
		this.isAnimating = true
		this.nextIndex = this.dom.buttonArray.indexOf(e.target)

		E.emit('chapterSelect', e.target.dataset.chapter)

		this.resetTitles()
		this.onSelectChapter()
		this.toggleTl.reverse()

		const outro = store.components.get(WorldApartOutro).first()

		if (outro.outroActive) {
			outro.removeDarkClass()
			outro.hideOutro()
		}
	}

	resetTitles() {
		gsap.set(this.dom.titlesArray, {
			yPercent: 100,
			rotate: 10,
			transformOrigin: '0% 50%'
		})
	}

	onSelectChapter = () => {
		this.dom.buttonArray.forEach((button) => {
			button.classList.remove('active')
		})

		this.dom.buttonArray[this.nextIndex].classList.add('active')
		this.el.classList.remove('is-interactive')

		this.updateProgress = false

		this.tl = this.gsap.timeline()

		this.tl
			.set([
				this.el
			], {
				pointerEvents: 'none'
			})
			.to(this.dom.progress, {
				opacity: 0,
				duration: 0.3
			})
			.set(this.dom.progress, {
				strokeDashoffset: 235
			})
			.set(this.dom.progress, {
				opacity: 1
			})
			.fromTo(this.dom.numberArray[this.activeIndex], {
				opacity: 1,
				scale: 1
			}, {
				opacity: 0,
				scale: 0.8,
				duration: 0.6,
				ease: 'joe.in'
			}, 0)
			.fromTo(this.dom.numberArray[this.nextIndex], {
				opacity: 0,
				scale: 0.8
			}, {
				opacity: 1,
				scale: 1,
				duration: 0.6,
				ease: 'joe.out'
			}, 0.6)
			.then(() => {
				this.activeIndex = this.nextIndex
				this.updateProgress = true
				this.buttonClicked = false
			})
	}

	show = () => {
		this.el.classList.add('is-interactive')

		this.gsap
			.to(this.el, {
				pointerEvents: 'auto',
				autoAlpha: 1,
				duration: 0.6
			})
	}

	onChapterProgress = (progress, name) => {
		let chapterProgress = progress

		if (name === 'Intro') return

		if (!this.updateProgress) {
			chapterProgress = 0
			return
		}

		gsap.set(this.dom.progress, {
			strokeDashoffset: 235 * (1 - chapterProgress)
		})
	}
}
