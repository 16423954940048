import { Component } from '_components/unseen'
import store from '_store'
import { GlobalEvents } from "_utils"

export default class Parallax extends Component {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '[data-parallax]'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.tween = null

		this.addTween()

		this.on(GlobalEvents.RESIZE, () => {
			if (!store.mq.horizontal.matches && this.tween) {
				this.gsap.context.revert()
				this.tween?.kill()
				this.tween = null
			}

			if (!this.tween) {
				this.addTween()
			}
		})
	}

	addTween() {
		if (!store.mq.horizontal.matches) {
			return
		}

		let fullRange = (1 - parseFloat(this.el.dataset.parallax)) * store.window.w

		if (store.SmoothScroll.Lenis.options.orientation === 'horizontal') {
			fullRange = (1 - parseFloat(this.el.dataset.parallax)) * store.window.h
		}

		const from = this.el.dataset.parallaxRange === 'full' ? -fullRange / 2 : 0
		const to = fullRange / 2
		let movement = Math.abs(fullRange / 2)

		if (this.el.dataset.parallax < 1) {
			movement = Math.abs(fullRange)
		}

		if (store.SmoothScroll.Lenis.options.orientation === 'horizontal') {
			const start = this.el.dataset.parallaxRange === 'full' ? `left right+=${movement}px` : 'left right'

			this.tween = this.gsap.fromTo(this.el, { x: from },
				{
					x: to,
					ease: "none",
					scrollTrigger: {
						trigger: this.el,
						invalidateOnRefresh: true,
						start,
						end: `right left-=${movement}px`,
						horizontal: true,
						scrub: this.el.dataset.scrub ? parseInt(this.el.dataset.scrub) : true
					}
				})
		} else {
			const start = this.el.dataset.parallaxRange === 'full' ? `top bottom+=${movement}px` : 'top bottom'

			this.tween = this.gsap.fromTo(this.el, { y: from },
				{
					y: to,
					ease: "none",
					scrollTrigger: {
						trigger: this.el,
						invalidateOnRefresh: true,
						start,
						end: `bottom top-=${movement}px`,
						scrub: this.el.dataset.scrub ? parseInt(this.el.dataset.scrub) : true
					}
				})
		}
	}
}
