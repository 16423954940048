import store from "_store"
import DefaultRenderer from "./DefaultRenderer"
import Home from "_components/Home"

export default class HomeRenderer extends DefaultRenderer {
	async onEnter() {
		super.onEnter()

		// Init components
		store.components.add(
			Home
		)
	}

	onEnterCompleted() {
		super.onEnterCompleted()

		this.uponEnteringWithConsent(() => {
			store.components.get(Home).get(0).introTl.play()
		})
	}

	onLeave() {
		super.onLeave()
	}
}