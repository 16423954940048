#include <default_pars>
#include <default_frag_pars>
#include <packing>

varying vec3 vRandom;
varying vec2 vUv;
varying float vLife;

varying vec3 vViewPosition;

varying vec3 vNormal;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;

uniform vec3 uLightPosition;
uniform vec3 uLightDirection;

uniform sampler2D tNormal;
uniform vec3 uBaseColor;
uniform vec3 uBirthDecayColor;

uniform bool uUseAmbient;
uniform vec2 uLightingRange;
uniform vec3 uAmbient;

uniform float uOpacity;

void main() {

    float alpha = 1.0 - step(0.9, 2.0 * length(vUv - 0.5)); // circular points
    if (alpha < 0.5) discard;

    // vec3 lightDirection = normalize(uLightPosition - vWorldPosition.xyz);
    // // vec3 lightDirection = normalize(uLightDirection);

    // // single normal map
    // vec3 normal = texture2D(tNormal, vUv).xyz * 2.0 - 1.0;

    // normal = vNormalMatrixInverse * normal;
    // normal = normalize(normal);

    // vec3 randoms = vec3(
    //     map(vRandom.x, 0., 1., 0.5, 1.0),
    //     map(vRandom.y, 0., 1., 0.2, 1.8),
    //     map(vRandom.z, 0., 1., 0.2, 1.8)
    // );

    // vec3 color = mix(uBirthDecayColor, uBaseColor, smoothstep(0.0, 0.35, vLife) * (1.0 - smoothstep(0.65, 1.0, vLife)));
    vec3 color = mix(uBirthDecayColor * (vRandom.x + 0.5) * 0.5, uBaseColor * (vRandom.z + 0.5) * 0.5, smoothstep(0.3, 0.7, vLife));

    // Add some color variation
    // color.rgb *= randoms.x;

    // Simple diffuse lighting
    // float diffuse = clamp(dot(normal.rgb, lightDirection), 0.0, 1.0);

    /*
    *   Apply basic lighting
    */
    // if (uUseAmbient) {
    //     color = (uAmbient + diffuse) * color;
    // } else {
    //     color *= map(diffuse, 0.0, 1.0, uLightingRange.x, uLightingRange.y);
    // }

    // decrease alpha with life (in last 15%)
    alpha *= smoothstep(0.0, 0.15, vLife) * (1.0 - smoothstep(0.75, 0.9, vLife));

    alpha *= uOpacity;

    gl_FragColor = vec4(color, alpha * vRandom.x);

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}