import Component from '_components/unseen/Component'

export default class ImageFull extends Component {
	static selector = '.js-image-full'

	constructor(el) {
		super(el)

		this.populateDom()

		this.gsap.mm(context => {
			const { horizontal } = context.conditions

			if (horizontal) {
				this.gsap.to(this.dom.img, {
					xPercent: 30,
					ease: 'linear',
					scrollTrigger: {
						trigger: this.el,
						horizontal: true,
						scrub: true
					}
				})
			}
		}, ['horizontal'])
	}
}
