import { mergeDeep } from "_utils/index"
import createComponent from "./unseen/Component"
import { AdditiveBlending, Color, CustomBlending, MathUtils, Mesh, MultiplyBlending, NoBlending, NormalBlending, SubtractiveBlending, Vector3 } from "three"
import { MeshLineGeometry } from 'meshline'
import CursorMeshLinesMaterial from "_webgl/materials/cursorMeshLineMaterial/CursorMeshLinesMaterial"
import getRandomSeededFloat from "_utils/functions/getRandomSeededFloat"
import store from "_store"
import { types } from "@theatre/core"
import gsap from "gsap"

export default class CursorMeshLines extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			visible: true,
			scene: null,
			name: 'CursorMeshLines',
			sheetObjectName: 'Cursor Mesh Lines',
			lineCount: 16,
			maxPointCount: store.isLowTierGPU ? 100 : 200,
			groupIndex: 0,
			radius: 1,
			materialOpts: {
				uniforms: {},
				globalUniforms: {
					color: { value: new Color(0xde7723) },
					uFadeColor: { value: new Color(0xba3801) },
					lineWidth: { value: 0.028, gui: { step: 0.001 } },

					opacity: { value: 0.89, gui: { step: 0.01, min: 0, max: 1 } },

					useDash: { value: false },
					dashOffset: { value: 0.1 },
					dashArray: { value: 0.1 },
					dashRatio: { value: 0.1 },
					visibility: { value: 1.0, gui: { step: 0.01, min: 0, max: 1 } },
					alphaTest: { value: 0.0, gui: { step: 0.01, min: 0, max: 1 } },

					uTailBegin: { value: 0.7, gui: { step: 0.01, min: 0, max: 1 } },
					uTailSmoothness: { value: 0.3, gui: { step: 0.01, min: 0, max: 1 } },

					uBeginColorEdge: { value: 0.24, gui: { step: 0.01, min: 0, max: 1 } },
					uFadeColorEdge: { value: 0.38, gui: { step: 0.01, min: 0, max: 1 } },

					uDarkenAmount: { value: 0.65, gui: { step: 0.01, min: 0, max: 1 } },

					uRevealProgress: { value: 1, gui: { step: 0.01, min: 0, max: 1 } }
				},
				defines: {}
			}
		}, options)

		super(options)

		this.lines = []

		this.frameCount = 0

		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines.push({
				points: [],
				mesh: null,
				geometry: null,
				material: null,
				offset: new Vector3(),
				randoms: new Vector3(MathUtils.randFloat(0, 20), MathUtils.randFloat(0.5, 1), MathUtils.randFloat(0.5, 1))
			})
		}

		this._targetPosition = new Vector3()
	}

	build() {
		let r, phi, theta

		if (this.options.groupIndex === 0) {
			this.options.materialOpts.defines.USE_MOUSE = true
		}

		for (let i = 0; i < this.options.lineCount; i++) {
			const startPoints = [] // populate the points array for each line
			for (let j = 0; j < this.options.maxPointCount * 3; j += 3) {
				startPoints.push(100, 100, j) // have to start with some points, position them off the screen
			}

			this.lines[i].geometry = new MeshLineGeometry()
			this.lines[i].points = [...startPoints]
			this.lines[i].geometry.setPoints(this.lines[i].points)

			this.lines[i].material = new CursorMeshLinesMaterial(this.options.materialOpts, this.options.scene)

			// Add random uniforms
			this.lines[i].material.uniforms.uRandom.value.set(
				getRandomSeededFloat(i + 55 + this.options.groupIndex, 0.35, 1.0), // line width
				getRandomSeededFloat(i + 56 + this.options.groupIndex, 0.1, 2.0), // opacity
				getRandomSeededFloat(i + 57 + this.options.groupIndex, 0.1, 2.0), // color
				MathUtils.seededRandom(i + 58 + this.options.groupIndex) // line length
			)

			this.lines[i].mesh = new Mesh(this.lines[i].geometry, this.lines[i].material)
			this.lines[i].mesh.frustumCulled = false
			this.add(this.lines[i].mesh)

			// set some initial offset
			r = (0.5 + MathUtils.seededRandom(i + this.options.groupIndex) * 0.5) * this.options.radius
			phi = (MathUtils.seededRandom(i + 1 + this.options.groupIndex) - 0.5) * Math.PI
			theta = MathUtils.seededRandom(i + 2 + this.options.groupIndex) * Math.PI * 2

			this.lines[i].offset.set(r * Math.cos(theta) * Math.cos(phi), r * Math.sin(phi), r * Math.sin(theta) * Math.cos(phi))
		}
	}

	addGui() {
		const config = {
			visible: this.visible,
			blending: types.stringLiteral(this.options.materialOpts.blending || NormalBlending, {
				[NoBlending]: 'NoBlending',
				[NormalBlending]: 'NormalBlending',
				[AdditiveBlending]: 'AdditiveBlending',
				[SubtractiveBlending]: 'SubtractiveBlending',
				[MultiplyBlending]: 'MultiplyBlending',
				[CustomBlending]: 'CustomBlending'
			}, { as: 'menu' }),
			material: {}
		}

		const updateUniformsCallbacks = store.theatre.helper.autoAddUniforms(config.material, this.options.materialOpts.globalUniforms)

		const sheetObject = store.theatre.helper.addSheetObject(this.options.scene.prettyName, this.options.sheetObjectName, config)

		sheetObject.onValuesChange(values => {
			this.visible = values.visible

			// update all lines materials
			for (let i = 0; i < this.options.lineCount; i++) {
				this.lines[i].mesh.material.blending = parseInt(values.blending)
			}

			for (let i = 0; i < updateUniformsCallbacks.length; i++) {
				updateUniformsCallbacks[i](this, values.material)
			}
		}, store.theatre.rafDriver)
	}

	animate() {
		// if (this.frameCount % 2 === 0) {
		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].geometry.advance(
				this._targetPosition.copy(this.options.scene.globalUniforms.mouse.uMouse.value).add(
					this.lines[i].offset.addScalar(
						Math.sin(this.lines[i].randoms.x + store.WebGL.clock.elapsedTime * 3 * this.lines[i].randoms.z) * 0.01 * this.lines[i].randoms.y
					)
				)
			)
		}
		// }

		this.frameCount++
	}

	hideLine(index) {
		gsap.set(this.lines[index].material.uniforms.uHideProgress, { value: 1.0 })
		this.lines[index].mesh.visible = false
	}

	reset() {
		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].mesh.visible = true
			this.lines[i].material.uniforms.uHideProgress.value = 0.0
		}

		this.frameCount = 0

		this.resetPoints()
	}

	popPoints() {
		// pop half of the points off the end
		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].points = this.lines[i].points.slice(0, -Math.floor((this.options.maxPointCount * 3) * 0.5))
			this.lines[i].geometry.setPoints(this.lines[i].points) // reset the points
		}
	}

	resetPoints() {
		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].geometry.setPoints(this.lines[i].points) // reset the points
		}
	}

	destroy() {
		super.destroy()
		// console.log(`Destroying ${this.name}`)
		for (let i = 0; i < this.options.lineCount; i++) {
			this.lines[i].geometry.dispose()
			this.lines[i].material.dispose()
			this.remove(this.lines[i].mesh)
		}

		this.frameCount = 0
	}
}