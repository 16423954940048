import BaseScene from './BaseScene'
import store from '_store'
import { types } from '@theatre/core'
import { Vector2, Vector3 } from 'three'
import CursorMeshLines from '_webgl/components/CursorMeshLines'
import BackgroundSphere from '_webgl/components/BackgroundSphere'
import AmbientParticles from '_webgl/components/AmbientParticles'
import { E, GlobalEvents } from '_utils/index'
import gsap from 'gsap'
import CursorParticles from '_webgl/components/CursorParticles'
import AmbientMeshLines from '_webgl/components/AmbientMeshLines'

export default class ChapterTwoTwoScene extends BaseScene {
	constructor() {
		super('chapter-two-two', {
			audioFiles: ['chapter-2-1', 'chapter-2-2', 'chapter-2-3']
		})

		const numberOfLines = store.isLowTierGPU ? 16 : 28

		Object.assign(this.components, {
			meshLines: new CursorMeshLines({ scene: this, lineCount: numberOfLines }),
			particles: new CursorParticles({ scene: this, simulationOpts: { numberOfLines } }),
			background: new BackgroundSphere({ scene: this }),
			ambientParticles: new AmbientParticles({ bounds: new Vector3(50, 50, 50) }),
			ambientLines: new AmbientMeshLines({ scene: this, lineCount: store.isLowTierGPU ? 14 : numberOfLines })
		})

		Object.assign(this.globalUniforms, {
			mouse: {
				uMouse: { value: new Vector3() },
				uControlAmount: { value: 0 }
			}
		})

		this.mouseOptions = {
			lerpMouseFactor: 0.1,
			controlAmount: 0,
			xSpeed: 0.23,
			ySpeed: 0.364,
			xFrequency: 0.1,
			yFrequency: 0.1,
			radius: 1,
			depthFrequency: 1,
			depthMin: 1.96,
			depthMax: 6.83
		}

		this.speedMultiplier = store.isLowTierGPU ? 2.0 : 1.0 // speed up the cursor for low tier GPUs so the lines look longer to account for less points in them

		this._vec3 = new Vector3()
		this.pointer2D = new Vector2()
		this.pointer3D = new Vector3()
		this.pointerIdle = true
		this.needsToResetPointer = true

		this.elapsedTime = 0

		this.gsapElasticXTo = gsap.quickTo(this.pointer2D, 'x', { duration: 5, ease: 'elastic' })
		this.gsapElasticYTo = gsap.quickTo(this.pointer2D, 'y', { duration: 5, ease: 'elastic' })

		const sheetObject = store.theatre.helper.addSheetObject(this.prettyName, 'Cursor', {
			lerpMouseFactor: types.number(this.mouseOptions.lerpMouseFactor, { label: 'Lerp Factor', range: [0, 1], nudgeMultiplier: 0.001 }),
			radius: types.number(this.mouseOptions.radius, { label: 'Radius', range: [0, 5], nudgeMultiplier: 0.01 }),

			depthFrequency: types.number(this.mouseOptions.depthFrequency, { label: 'Depth Frequency', range: [0, 10], nudgeMultiplier: 0.01 }),
			depthMin: types.number(this.mouseOptions.depthMin, { label: 'Depth Min', range: [-10, 10], nudgeMultiplier: 0.01 }),
			depthMax: types.number(this.mouseOptions.depthMax, { label: 'Depth Max', range: [0, 10], nudgeMultiplier: 0.01 }),

			xSpeed: types.number(this.mouseOptions.xSpeed, { label: 'X Speed', range: [0, 2], nudgeMultiplier: 0.001 }),
			ySpeed: types.number(this.mouseOptions.ySpeed, { label: 'Y Speed', range: [0, 2], nudgeMultiplier: 0.001 }),
			xFrequency: types.number(this.mouseOptions.xFrequency, { label: 'X Frequency', range: [0, 10], nudgeMultiplier: 0.01 }),
			yFrequency: types.number(this.mouseOptions.yFrequency, { label: 'Y Frequency', range: [0, 10], nudgeMultiplier: 0.01 })
		})

		sheetObject.onValuesChange(values => {
			this.mouseOptions.lerpMouseFactor = values.lerpMouseFactor
			this.mouseOptions.radius = values.radius

			this.mouseOptions.depthFrequency = values.depthFrequency
			this.mouseOptions.depthMin = values.depthMin
			this.mouseOptions.depthMax = values.depthMax

			this.mouseOptions.xSpeed = values.xSpeed
			this.mouseOptions.ySpeed = values.ySpeed
			this.mouseOptions.xFrequency = values.xFrequency
			this.mouseOptions.yFrequency = values.yFrequency
		}, store.theatre.rafDriver)

		this.background.set(0x0d0f10)

		const sceneBgTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Scene Background', {
			color: store.theatre.helper.parseColor(this.background, 'bg color')
		})

		sceneBgTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		})
	}

	addPostFXToTheatre() {
		const sheetObject = super.addPostFXToTheatre()

		sheetObject.initialValue = {
			bloom: {
				enabled: !store.isLowTierGPU,
				strength: 0.4,
				threshold: 0.08,
				radius: 0.42
			},
			noise: {
				strength: 0.175
			}
		}
	}

	start() {
		gsap.to(this.mouseOptions, { controlAmount: 0, duration: 3, ease: 'power2.inOut', overwrite: 'auto' })
		gsap.to(this.mouseOptions, { xFrequency: 0.6, yFrequency: 0.6, duration: 3, ease: 'power2.inOut', overwrite: 'auto' })

		this.onRaf()

		super.start()

		if (store.urlParams.has('gui')) return // don't animate the reveal if we're in the gui
		const tl = gsap.timeline() // start the reveal animation
		tl.fromTo([this.components.particles.mesh.material.uniforms.uOpacity,
			this.components.ambientLines.options.materialOpts.globalUniforms.uRevealProgress
		], { value: 0 }, { delay: .4, value: 1.0, duration: 4, ease: 'power1.inOut' })
			.fromTo(this.components.meshLines.options.materialOpts.globalUniforms.uRevealProgress, { value: 0.0 }, { value: 1.0, duration: 3.5, ease: 'power1.inOut' }, 0.0)
	}

	stop() {
		super.stop()

		// Reset the scene
		this._vec3.set(0, 0, 0)
		this.pointer2D.set(0, 0)
		this.pointer3D.set(0, 0, 0)
		this.globalUniforms.mouse.uMouse.value.set(0, 0, 0)
		this.elapsedTime = 0
		this.pointerIdle = true // make sure to go into automatic pointer
		this.needsToResetPointer = true

		this.components.meshLines?.reset()
		this.components.ambientLines?.reset()

		if (store.urlParams.has('gui')) return // don't animate the reveal if we're in the gui
		gsap.set([this.components.particles.mesh.material.uniforms.uOpacity,
			this.components.ambientLines.options.materialOpts.globalUniforms.uRevealProgress,
			this.components.meshLines.options.materialOpts.globalUniforms.uRevealProgress
		], { value: 0 })
	}

	addEvents() {
		super.addEvents()
		E.on(GlobalEvents.POINTERMOVE, this.onPointerMove)
		E.on('FPSChecked', this.onFPSChecked)
	}

	removeEvents() {
		super.removeEvents()

		E.off(GlobalEvents.POINTERMOVE, this.onPointerMove)
		E.off('FPSChecked', this.onFPSChecked)
	}

	onFPSChecked = (gpuTier) => {
		if (gpuTier < 4) {
			if (store.WebGL.bloomPass && store.WebGL.bloomPass.enabled) {
				store.WebGL.bloomPass.enabled = false
				this.postFxTheatreOverrides.bloom.enabled = false
			}
		}

		if (gpuTier < 4 && !this.minimumQuality) {
			// remove some points in the lines to improve performance
			this.components.meshLines?.popPoints()
			this.components.ambientLines?.popPoints()

			// Hide 1/2 of the lines to improve perf
			const meshHalf = Math.floor(this.components.meshLines.options.lineCount / 2)
			for (let i = 0; i < meshHalf; i++) {
				this.components.meshLines.hideLine(i)
			}

			const ambientHalf = Math.floor(this.components.ambientLines.options.lineCount / 2)
			for (let i = 0; i < ambientHalf; i++) {
				this.components.ambientLines.hideLine(i)
			}

			// Hide some of the particles
			this.components.particles.reduceCount()

			this.minimumQuality = true
		}
	}

	animate() {
		this.update3DPointer()

		super.animate()

		this.elapsedTime += store.WebGL.clockDelta
	}

	destroy() {
		super.destroy()
		this.minimumQuality = false
	}

	onPointerMove = () => {
		this.gsapElasticXTo(store.pointer.glNormalized.x)
		this.gsapElasticYTo(store.pointer.glNormalized.y)
		this.pointerIdle = false

		if (this.needsToResetPointer) {
			gsap.to(this.mouseOptions, { controlAmount: 1, duration: 1, ease: 'power2.inOut', overwrite: 'auto' })
			gsap.to(this.mouseOptions, { xFrequency: 0.1, yFrequency: 0.1, duration: 2, ease: 'power2.inOut', overwrite: 'auto' })
			this.needsToResetPointer = false
		}

		clearTimeout(this._pointerTimeout)
		this._pointerTimeout = setTimeout(() => {
			this.pointerIdle = true
			this.needsToResetPointer = true

			gsap.to(this.mouseOptions, { controlAmount: 0, duration: 3, ease: 'power2.inOut', overwrite: 'auto' })
			gsap.to(this.mouseOptions, { xFrequency: 0.6, yFrequency: 0.6, duration: 3, ease: 'power2.inOut', overwrite: 'auto' })
		}, 1500)
	}

	update3DPointer() {
		this._vec3.set(this.pointer2D.x * this.mouseOptions.controlAmount, this.pointer2D.y * this.mouseOptions.controlAmount, 0.5)

		this._vec3.x += Math.sin(this.elapsedTime * this.mouseOptions.xSpeed * this.speedMultiplier) * this.mouseOptions.xFrequency
		this._vec3.y += Math.cos(this.elapsedTime * this.mouseOptions.ySpeed * this.speedMultiplier) * this.mouseOptions.yFrequency

		this._vec3.unproject(this.activeCamera)
		this._vec3.sub(this.activeCamera.position).normalize()

		const distance = ((this.activeCamera.position.z - 20) - this.activeCamera.position.z) / this._vec3.z
		this.pointer3D.copy(this.activeCamera.position).add(this._vec3.multiplyScalar(distance))

		this.pointer3D.x *= this.mouseOptions.radius
		this.pointer3D.y *= this.mouseOptions.radius
		// this.pointer3D.z = this.activeCamera.position.z + Math.sin(this.elapsedTime * this.mouseOptions.depthFrequency) * this.mouseOptions.depthMax + this.mouseOptions.depthMin
		this.pointer3D.z = (this.activeCamera.position.z - 20) + Math.sin(this.elapsedTime * this.mouseOptions.depthFrequency) * this.mouseOptions.depthMax + this.mouseOptions.depthMin

		this.globalUniforms.mouse.uMouse.value.lerp(this.pointer3D, this.mouseOptions.lerpMouseFactor)
		this.globalUniforms.mouse.uControlAmount.value = this.mouseOptions.controlAmount
	}
}
