import { Toggle } from '_components/unseen'
import { qsa } from '_utils'

import gsap from 'gsap'

export default class LangToggle extends Toggle {
	/**
	 * The selector this component is instanced to
	 */
	static selector = '.js-lang-toggle'

	/**
	 * Init
	 * @param {Document|window|HTMLElement|Element} el
	 */
	constructor(el) {
		super(el)

		this.langLinks = qsa('.js-header-lang-links')
		this.chevron = qsa('.js-header-chevron')

		this.buildTl()
	}

	buildTl() {
		this.tl = gsap.timeline({
			paused: true,
			defaults: {
				ease: 'joe.out',
				duration: 0.2,
				stagger: 0.05
			}
		})

		this.tl
			.from(this.langLinks, {
				ease: 'joe.out',
				y: 10,
				autoAlpha: 0
			})
			.to(this.chevron, {
				scaleY: -1,
				ease: 'joe.inOut'
			}, 0)
	}

	onOpen(resolve) {
		this.target.style.display = 'block'

		this.tl
			.eventCallback('onComplete', () => {
				resolve()
				this.tl.eventCallback('onComplete', null)
			})
			.play()
	}

	onClose(resolve) {
		this.tl
			.eventCallback('onReverseComplete', () => {
				this.target.style.display = 'none'
				resolve()
				this.tl.eventCallback('onReverseComplete', null)
			})
			.reverse()
	}
}
