#define PI 3.141592653589793
#define PI2 6.283185307179586
#define PI_HALF 1.5707963267948966
#define RECIPROCAL_PI 0.3183098861837907
#define RECIPROCAL_PI2 0.15915494309189535
#define EPSILON 1e-6

#define saturate( a ) clamp( a, 0.0, 1.0 )

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

#if defined(USE_ENV)
  // Shader chunk that handles sampling the mipmapped image
  #include <cubeUVreflectionFrag>
#endif

vec3 transformDirection(in vec3 dir, in mat4 matrix) {
  return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
		// dir can be either a direction vector or a normal vector
		// upper-left 3x3 of matrix is assumed to be orthogonal
  return normalize((vec4(dir, 0.0) * matrix).xyz);
}